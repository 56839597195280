import { Component, OnInit } from '@angular/core';
import { Keyword, KeywordService, KeywordModel } from "../../keyword.Service";
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { MissionService } from "../../MissionService";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription, Observable } from "rxjs";
import { tap } from 'rxjs/operators';
import { ShortCode, UserService } from "../../user.Service";


@Component({
  selector: 'app-keyword-details',
  templateUrl: './keyword-details.component.html',
  styleUrls: ['./keyword-details.component.css']
})
export class KeywordDetailsComponent implements OnInit {
  subscription: Subscription;
  formGroup: FormGroup = new FormGroup({});
  keyword: Keyword;
  tempKeyword?: Observable<Keyword>;
  keywordId: string;
  numbers: ShortCode[] = [];
  tempNumbers?: Observable<ShortCode[]>;
  showNumbers: boolean;
  errorMsg: string;
  success: boolean;
  timeout: any = null;

  constructor(
    private missionService: MissionService,
    private router: Router,
    private keywordService: KeywordService,
    private userService: UserService,
    private actRoute: ActivatedRoute
  ) {
    this.errorMsg = "";
    this.success = false;
    this.showNumbers = false;
    this.keyword = new Keyword();
    this.keywordId = this.actRoute.snapshot.params.id;
    this.subscription = missionService.missionAnnounced$.subscribe(
      mission => {
        if (mission === "Back") {
          this.onSubmit();
        }
      });
  }

  ngOnInit(): void {
    this.initForm();
    this.userService.getNumbersForCurrentUser().subscribe(
      result => {
        this.numbers = result;
        if (this.numbers.length > 0) {
          this.showNumbers = true;
        }
      });

    if (this.keywordId === "0") {
      this.formGroup.patchValue(this.keyword);
      this.tempKeyword = new Observable((observer) => {
        observer.next(this.keyword);
        observer.complete();
      });
    } else {
      this.tempKeyword = this.keywordService.getKeyword(this.keywordId).pipe(tap<Keyword>(
        result => {
          this.keyword = result;
          this.formGroup.patchValue(this.keyword);
          this.formGroup.controls.Code.disable();
          if (this.keyword.ShortCodeReferences) {
            let temp: string[] = [];
            this.keyword.ShortCodeReferences.forEach(item => temp.push(item.ShortCode.Code.toString()));
            this.formGroup.controls.ShortCodesSelected.setValue(temp);
          }
        }));
    }

  }

  initForm() {
    this.formGroup = new FormGroup({
      Code: new FormControl("", [Validators.required]),
      Message: new FormControl(),
      EmailAddress: new FormControl(),
      EmailSubject: new FormControl(),
      EmailBody: new FormControl(),
      ShortCodesSelected: new FormControl(),
      KeywordType: new FormControl()
    });
  }

  hasRole() {
    return false;
  }


  checkKeyword(event: any) {
    if (this.timeout != null) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      this.errorMsg = "";
      this.success = false;
      let code = this.formGroup.controls.Code.value;
      if (code === "") {
        return;
      }

      let list = this.formGroup.controls.ShortCodesSelected.value;
      let networks = "";
      if (list) {
        networks = list.join(',');
      }

      this.keywordService.checkKeyword(code, networks).subscribe(result => {
        if (result.KeywordID === this.keyword.KeywordID) {
          this.errorMsg = "";
          this.success = false;
        } else {
          this.errorMsg = "This keyword already exists";
          this.success = false;
        }
      },
        error => {
          this.errorMsg = "";
          this.success = true;
        });
    }, 1000);
  }

  onSubmit() {
    if (this.formGroup.invalid) {
      if (this.keywordId === "0") {
        this.router.navigate(["/keywords"]);
      }

      return;
    }

    if (this.errorMsg !== "") {
      return;
    }

    const model = new KeywordModel();
    if (this.keywordId === "0") {
      model.Code = this.formGroup.value.Code;
    } else {
      model.Code = this.keyword.Code;
    }

    const list = this.formGroup.controls.ShortCodesSelected.value;
    model.Numbers = "";
    if (list) {
      model.Numbers = list.join(",");
    }
    model.Body = this.formGroup.value.EmailBody;
    model.Subject = this.formGroup.value.EmailSubject;
    model.Message = this.formGroup.value.Message;
    model.Created = this.keyword.Created;
    model.ActionListID = this.keyword.ActionListID;
    model.To = this.formGroup.value.EmailAddress;
    model.KeywordType = this.formGroup.value.KeywordType;
    this.keywordService.setKeyword(this.keywordId, model).subscribe(
      results => {
        this.router.navigate(["/keywords"]);
      });

  }
}
