import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Metadata } from '../../authentication.Service';

@Component({
  selector: 'app-metadata-table',
  templateUrl: './metadata-table.component.html',
  styleUrls: ['./metadata-table.component.css']
})
export class MetadataTableComponent implements OnInit {

  formGroup: FormGroup;

  constructor(private fb: FormBuilder) {
    this.formGroup = this.fb.group({
      element: this.fb.array([this.newRow()])
    });

  }

  ngOnInit(): void {
    this.loadMetadata();
  }

  get optionArr(): FormArray {
    return this.formGroup.get('element') as FormArray;
  }

  newRow() {
    return this.fb.group({
      Name: new FormControl(),
      DataType: new FormControl(),
      Value: new FormControl()
    });
  }

  newRowWithData(data: Metadata) {
    return this.fb.group({
      Name: [data.Name],
      DataType: [data.DataType].toString(),
      Value: [data.Value]
    });
  }

  addNewRow(index: number) {
    let data = new Metadata();
    if (index + 1 === this._metadata.length) {
      this._metadata.push(data);
      this.optionArr.controls.push(this.newRowWithData(data));
    }
    else {
      this._metadata.splice(index + 1, 0, data);
      this.optionArr.controls.splice(index + 1, 0, this.newRowWithData(data));
    }

    return false;
  }

  deleteRow(index: number) {
    if (index < this._metadata.length) {
      this._metadata.splice(index, 1);
      this.optionArr.controls.splice(index, 1);
    }

    if (this._metadata.length == 0) {
      this._metadata.push(new Metadata());
      this.optionArr.controls.push(this.newRow());
    }

    return false;
  }

  loadMetadata() {
    this.optionArr.clear();
    for (let i = 0; i < this._metadata.length; i++) {
      this.optionArr.controls.push(this.newRowWithData(this._metadata[i]));
    }

    if (this._metadata.length === 0) {
      this._metadata.push(new Metadata());
      this.optionArr.controls.push(this.newRow());
    }
  }

  @Input()
  get metadata(): Metadata[] {
    for (let i = 0; i < this._metadata.length; i++) {
      let form = this.optionArr.controls[i] as FormGroup;
      this._metadata[i].MetaId = 0;
      this._metadata[i].Sequence = i;
      this._metadata[i].Name = form.controls.Name.value;
      this._metadata[i].DataType = Number(form.controls.DataType.value);
      this._metadata[i].Value = form.controls.Value.value;
    }
    return this._metadata;
  }
  set metadata(value) {
    this._metadata = value;
    if (this._metadata === undefined || this._metadata === null) {
      this._metadata = [];
    }
  }
  private _metadata: Metadata[] = [];
}
