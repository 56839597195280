<div class="wa-main-container">
  <div class="threequarterWidth column-flex-flow">
    <form [formGroup]="formGroup">
      <div>
        <h2>Check Number</h2>
        <p>
          Use this feature to search for mobile numbers in the system and see what state they are in.
        </p>
      </div>
      <div>
        <div>
          <mat-form-field appearance="outline">
            <mat-label>Number</mat-label>
            <input formControlName="number" matInput placeholder="Number">
          </mat-form-field>&nbsp;&nbsp;
          <button mat-raised-button color="primary" (click)="onSubmit()" type="submit" [disabled]="formGroup.invalid">Search</button>
        </div>
        <div>
          <br />
          <mat-divider></mat-divider>
          <br />
        </div>
        <div *ngIf="references != null">
          <div *ngIf="references.length == 0" class="alert-danger alert" role="alert">
            No active surveys found
          </div>
          <div *ngIf="references.length > 0">
            Number of active surveys {{references.length}}
          </div>
          <div *ngIf="references.length > 0" class="mb-5">
            <button mat-raised-button color="accent" [disabled]="gettingDetails" (click)="getSurveyDetails()">Details</button>
          </div>
          <div *ngIf="surveys.length > 0">
            <div *ngFor="let survey of surveys; let i = index" style="margin-top:5px;">
              {{survey.SurveyID}} - {{survey.Name}} (Owned by {{users[i].UserName}} ({{users[i].UserID}}) {{users[i].EmailAddress}})&nbsp;
              <strong>{{getStatus(i)}}</strong>&nbsp;
              <button mat-stroked-button (click)="abandon(i)">Abandon</button>&nbsp;&nbsp;
              <button mat-stroked-button (click)="login(i)">Goto Survey</button>
            </div>
          </div>
        </div>
        <div *ngIf="stopList != null" style="margin-top:5px">
          <div *ngIf="stopList.length == 0" class="alert-danger alert" role="alert">
            Not found in the Global Stop List
          </div>
          <div *ngIf="stopList.length > 0">
            <div *ngFor="let stop of stopList; let i = index" style="margin-top:5px;">
              Network Number:{{stop.Source}} - <strong>{{stop.Destination}}</strong> (Created:{{stop.Created}}, Amended:{{stop.Amended}}, OwnerId:{{stop.OwnerID}}) <strong>{{keyword(i)}}</strong>&nbsp;
              <button mat-stroked-button (click)="unstop(i)">Unstop</button>&nbsp;&nbsp;
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
