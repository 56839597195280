import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SurveyModel, JobMasterModel, SurveyService } from '../survey.Service';
import { CommsModel } from '../comms.Service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageBuilderComponent } from './message-builder/message-builder.component';
import { PanelModel } from '../panel.Service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CintSurvey } from '../cint.Service';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-survey-distribution',
  templateUrl: './survey-distribution.component.html',
  styleUrls: ['./survey-distribution.component.css']
})
export class SurveyDistributionComponent implements OnInit {
  comms: CommsModel[] = [];
  comm: CommsModel = new CommsModel();
  formGroup: FormGroup = new FormGroup({});
  fb = new FormBuilder();
  cintSurvey: CintSurvey = new CintSurvey();
  cintJson: string = "";

  displayQRTiny: boolean = false;
  displayKeyword: boolean = false;
  displayWhatsApp: boolean = false;
  show: boolean = true;
  surveyUrl = 'https://diysurveys.com/';
  tinyUrl = 'https://diysurveys.com/';

  constructor(private dialog: MatDialog,
    private surveyService: SurveyService,
    private snackbar: MatSnackBar,
    private clipboard: Clipboard
    ) { }

  ngOnInit(): void {

    this.formGroup = this.fb.group({
      subject: [this.comm.Subject, Validators.required],
      content: [this.comm.EmailContent, Validators.required]
    });
  }

  onSendClick(channel: number) {
    const dialogRef = this.dialog.open(MessageBuilderComponent, { data: { survey: this.survey, channel: channel } });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.survey) {
        this.survey = result.survey;
      }
      else {
        this.cancelChoice();
      }

      return false;
    });
    return false;
  }

  copied(text: string) {
    this.clipboard.copy(text);
    this.openSnackbar("Copied", "");
    return text;
  }

  cancelChoice() {
    if (this.survey?.JobMaster != null && this.survey.JobMaster.JobMasterId !== 0) {
      this.survey.JobMaster.Subject = "";
      this.survey.JobMaster.Content = "";
      this.survey.JobMaster.ChannelID = 0;
      this.surveyService.saveJobMaster(this.survey.JobMaster).subscribe();
    }
    return false;
  }

  get distributionSetup(): boolean {
    if (this.survey?.JobMaster != null && this.survey.JobMaster.JobMasterId !== 0) {
      return this.survey.JobMaster.ChannelID != 0;
    }

    return this.survey?.Channel == 1;
  }

  @Input()
  get survey(): SurveyModel | null {
    return this._survey;
  }
  set survey(value: SurveyModel | null) {
    this._survey = value;
    if (this._survey != null) {
      this.displayQRTiny = false;
      this.displayKeyword = false;
      if (this._survey.PanelID === -1) {
        if (this._survey.JobMaster === undefined || this._survey.JobMaster === null) {
          this._survey.JobMaster = new JobMasterModel();
        }
        this._survey.JobMaster.Subject = "";
        this._survey.JobMaster.Content = "";
        this._survey.JobMaster.ChannelID = 3;
        this._survey.JobMaster.Launched = new Date();
        this._survey.JobMaster.SurveyID = this._survey.SurveyID;
        this.surveyService.saveJobMasterForDistribution(this._survey.JobMaster).subscribe(
          result => {
            if (this._survey != null && this._survey.JobMaster != null) {
              this._survey.JobMaster.JobMasterId = result.JobMasterId;
            }
            this.surveyUrl = result.Sid;
            this.tinyUrl = result.TinyUrl;
          }
        );
        if (this._survey.Channel == 1) {
          this.displayKeyword = true;
        }
        else {
          this.displayQRTiny = true;
        }
      }
    }
  }
  private _survey: SurveyModel | null = null;

  @Input()
  get panel(): PanelModel | null {
    return this._panel;
  }
  set panel(value: PanelModel | null) {
    this._panel = value;
  }
  private _panel: PanelModel | null = null;

  private openSnackbar(message: string, action: string) {
    if (action == "") {
      this.snackbar.open(message, action, { duration: 2000 });
    }
    else {
      this.snackbar.open(message, action, { duration: 7000 });
    }
  }

}
