import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { ProcessErrorModel, ProcessService, ProcessServiceModel } from '../../process.Service';
import { MatSort } from '@angular/material/sort';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';
import { catchError, map, Observable, startWith, Subscription, switchMap, timer, of as observableOf } from 'rxjs';


@Component({
  selector: 'app-file-status',
  templateUrl: './file-status.component.html',
  styleUrls: ['./file-status.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class FileStatusComponent implements OnInit, AfterViewInit {

  processes: ProcessServiceModel[] = [];
  errors: ProcessErrorModel[] = [];
  searchKey: string = "";
  resultsLength = 0;
  expandedProcess: ProcessServiceModel = new ProcessServiceModel();
  processPageSize = 10;
  isRateLimitReached: boolean = false;
  page: number = 0;
  get columnsToDisplay(): string[] {
    return ['name', 'amended', 'progress', 'status', 'action'];
  }

  @ViewChild('table', { static: true }) table: any | undefined;
  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;

  constructor(private processService: ProcessService,
    public translate: TranslateService) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.paginator?.page
      .pipe(
        startWith({}),
        switchMap(() => {
          this.page = this.paginator?.pageIndex ?? 0 + 1;
          return this.processService.getProcesses(this.page, this.processPageSize)
            .pipe(catchError(() => observableOf(null)));
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isRateLimitReached = data === null;
          if (data === null) {
            return [];
          }

          // Only refresh the result length if there is new data. In case of rate
          // limit errors, we do not want to reset the paginator to zero, as that
          // would prevent users from re-triggering requests.
          return data;
        }),
      )
      .subscribe(data => (this.processes = data));
  }

  percentageCompleted(process: ProcessServiceModel) {
    return process.Progress;
  }

  edit(process: ProcessServiceModel) {
    this.expandedProcess = process;
    this.errors = [];
    this.processService.getProcessErrors(process.ProcessId, 10).subscribe(
      result => {
        this.errors = result;
        if (this.errors.length == 0 && process.Task.Type == "EXPORT") {
          this.processService.getFile(process).subscribe(
            response => {
              this.downLoadFile(response, process.Name, ProcessService.FindDownloadFromTask(process.Task));
            });
        }
      });
  }

  downLoadFile(data: any, filename: string, types: string[]) {
    let blob = new Blob([data], { type: types[0] });
    let url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename + types[1];
    a.click();
    window.URL.revokeObjectURL(url);
  }

  status(process: ProcessServiceModel) {
    switch (process.StatusId) {
      case 3:
        return "done";
      case 4:
        return "clear";
      default:
        return "pending";
    }
  }

  delete(process: ProcessServiceModel) {
    this.processService.deleteProcess(process).subscribe(result => {
      this.getProcesses();
    });
  }

  getProcesses() {
    this.page = 1;
    if (this.paginator != undefined) {
      this.paginator.pageIndex = this.page - 1;
    }

    this.processService.getProcesses(this.page, this.processPageSize).subscribe(
      result => {
        this.processes = result;
      });
  }
}
