import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthenticationService, OrganizationModel } from '../authentication.Service';
import { SurveyService } from '../survey.Service';

@Component({
  selector: 'app-transfer-item',
  templateUrl: './transfer-item.component.html',
  styleUrls: ['./transfer-item.component.css']
})
export class TransferItemComponent {
  formGroup: FormGroup = new FormGroup({});
  title: string = "Transfer";
  name: string = "Unknown";
  organizations: OrganizationModel[] = [];
  transferType: string;
  id: string;
  exists: boolean = false;
  subscription: Subscription;
  oldRename: string = "";
  error: string = "";
  selected: boolean = false;
  isOrganization: boolean = false;

  constructor(private authService: AuthenticationService,
    private surveyService: SurveyService,
    private router: Router,
    private actRoute: ActivatedRoute,
    private snackbar: MatSnackBar
  ) {
    this.id = this.actRoute.snapshot.params.id;
    this.transferType = this.actRoute.snapshot.params.type;
    switch (this.transferType) {
      case "securitykey":
        this.title = "Key";
        this.authService.getSecurityKey(this.id).subscribe(result => { this.name = result.Name });
        break;
      case "survey":
        this.title = "Survey";
        this.surveyService.getSurveyDetail(Number(this.id)).subscribe(result => { this.name = result.Name });
        break;
      case "organization":
        this.title = "Organization";
        this.authService.getOrganization(this.id).subscribe(result => { this.name = result.Name });
        this.isOrganization = true;
        break;
      default:
        this.title = "unknown";
        break;
    }

    this.formGroup = new FormGroup({
      type: new FormControl("1"),
      name: new FormControl(""),
      name2: new FormControl(""),
      rename: new FormControl("")
    });
    this.subscription = this.formGroup.valueChanges.subscribe(result => {
      if (this.oldRename != this.formGroup.controls.rename.value) {
        this.oldRename = this.formGroup.controls.rename.value;
        this.alreadyExists();
      }
    });
    if (this.isOrganization) {
      this.formGroup.controls.type.setValue("2");
      this.formGroup.controls.type.disable();
      return;
    }

    this.authService.getOrganizationSummariesForMember().subscribe(result => {
      let orgs = result;
      let j = this.transferType == "organization" ? Number(this.id) : this.authService.currentOrganization();
      for (let i = 0; i < orgs.length; i++) {
        if (orgs[i].OrganizationId != j) {
          this.organizations.push(orgs[i]);
          this.selected = true;
        }
      }

      if (!this.selected) {
        this.organizations.splice(0, 0, new OrganizationModel(this.authService.getMemberName(), "", [], 0));
      }

      if (this.organizations.length == 0) {
        this.formGroup.controls.type.setValue("2");
        this.formGroup.controls.type.disable();
      }
    });
  }

  alreadyExists() {
    if (!this.orgSelected()) {
      this.exists = false;
      return;
    }

    if (this.formGroup.controls.name.value == "") {
      this.exists = false;
      return;
    }

    switch (this.transferType) {
      case "securitykey":
        this.authService.checkSecurityKey(this.name, this.formGroup.controls.name.value).subscribe(result => {
          this.exists = true;
          if (this.formGroup.controls.rename.value != "") {
            this.authService.checkSecurityKey(this.formGroup.controls.rename.value, this.formGroup.controls.name.value).subscribe(result => {
              this.formGroup.controls.rename.setErrors({ invalid: 'Already exists' });
              this.error = "Already Exists";
            }, error => this.formGroup.controls.rename.setErrors(null));
          } else {
            this.formGroup.controls.rename.setErrors({ invalid: 'Required' });
            this.error = "Must enter a new name";
          }
        }, error => { this.exists = false; this.error = ""; this.formGroup.controls.rename.clearValidators(); });
        break;
      case "survey":
        this.surveyService.checkSurvey(this.name, this.formGroup.controls.name.value).subscribe(result => {
          this.exists = true;
          if (this.formGroup.controls.rename.value != "") {
            this.surveyService.checkSurvey(this.formGroup.controls.rename.value, this.formGroup.controls.name.value).subscribe(result => {
              this.formGroup.controls.rename.setErrors({ invalid: 'Already exists' });
              this.error = "Already Exists";
            }, error => this.formGroup.controls.rename.setErrors(null));
          } else {
            this.formGroup.controls.rename.setErrors({ invalid: 'Required' });
            this.error = "Must enter a new name";
          }
        }, error => { this.exists = false; this.error = ""; this.formGroup.controls.rename.clearValidators(); });
        break;
      case "organization":
        this.exists = false;
    }
  }

  orgSelected(): boolean {
    if (this.formGroup.controls.type.value == 1) {
      this.formGroup.controls.name.addValidators([Validators.required]);
      this.formGroup.controls.name2.clearValidators();
      this.formGroup.controls.name2.disable();
    }
    else {
      this.formGroup.controls.name2.addValidators([Validators.required]);
      this.formGroup.controls.name.clearValidators();
      this.formGroup.controls.name2.enable();
    }

    return this.formGroup.controls.type.value == "1";
  }

  username(): string {
    if (this.formGroup.controls.type.value == 1) {
      if (this.formGroup.controls.name.value == "") {
        return "username";
      }

      return this.formGroup.controls.name.value
    }

    if (this.formGroup.controls.name2.value == "") {
      return "username";
    }

    return this.formGroup.controls.name2.value;
  }

  onSubmit() {
    switch (this.transferType) {
      case "securityKey":
        this.authService.transferSecurityKey(this.id,
          this.exists ? this.formGroup.controls.rename.value : this.name, this.username(),
          this.orgSelected ? this.formGroup.controls.name.value : "").subscribe(
            result => {
              this.openSnackbar("Successfully Transferred", "");
              this.router.navigate(['../surveydashboard']);
            },
            error => {
              this.openSnackbar("There was a problem transferring this " + this.title, "Cancel")
            }
          );
        break;
      case "survey":
        this.surveyService.transferSurvey(Number(this.id),
          this.exists ? this.formGroup.controls.rename.value : this.name, this.username(),
          this.orgSelected ? this.formGroup.controls.name.value : "").subscribe(
            result => {
              this.openSnackbar("Successfully Transferred", "");
              this.router.navigate(['../surveydashboard']);
            },
            error => {
              this.openSnackbar("There was a problem transferring this " + this.title, "Cancel")
            }
          );
        break;
      case "organization":
        this.authService.transferOrganization(this.id, this.username()).subscribe({
          next: () => {
            this.openSnackbar("Successfully Transferred", "");
            this.router.navigate(['../profile']);
          },
          error: () => {
            this.openSnackbar("There was a problem transferring this " + this.title, "Cancel")
          }
        });
        break;
    }
  }

  private openSnackbar(message: string, action: string) {
    if (action == "") {
      this.snackbar.open(message, action, { duration: 7000 });
    }
    else {
      this.snackbar.open(message, action, { duration: 7000 });
    }
  }

}
