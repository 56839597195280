<div class="sms-tools-container">
  <h2>SMS Tools</h2>
  <div *ngIf="showAlert" class="alert alert-success" role="alert">
    {{alertMessage}}
  </div>
  <form class="new-communication-form column-flex flex-stretch-column" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <mat-accordion>
      <mat-expansion-panel hideToggle [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="sms-tools-titlepanel-div">
              Keywords
            </div>
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div>
          <app-keywords></app-keywords>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="sms-tools-titlepanel-div">
              Message Insights
            </div>
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div>
          <app-message-history></app-message-history>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="sms-tools-titlepanel-div">
              Test SMS
            </div>
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div>
          <app-send></app-send>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </form>
</div>
<ng-template #loading>
  <div class="loading-container">
    <span class="loading-content">
      <div class="leftFloat"><mat-spinner [diameter]="25"></mat-spinner></div>
      <div class="spinner-text"> Please Wait...</div>
    </span>
  </div>
</ng-template>
<ng-template #loadingSurveys>
  <div class="loading-container">
    <span class="loading-content">
      <div class="leftFloat"><mat-spinner [diameter]="25"></mat-spinner></div>
      <div class="spinner-text"> Gathering stats...</div>
    </span>
  </div>
  <br /><br />
</ng-template>
