import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService, Organization, OrganizationModel } from '../../authentication.Service';

@Component({
  selector: 'app-edit-org-name',
  templateUrl: './edit-org-name.component.html',
  styleUrls: ['./edit-org-name.component.css']
})
export class EditOrgNameComponent implements OnInit {

  formGroup: FormGroup;
  displayError: boolean = false;
  model: OrganizationModel;
  errorMsg: string = "";

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<EditOrgNameComponent>,
    private fb: FormBuilder,
    private authService: AuthenticationService
  ) {
    this.model = data.model;
    this.formGroup = this.fb.group({
      OrgName: [this.model.Name, Validators.required],
      OrgDescription: [this.model.Description, Validators.required]
    });
  }

  ngOnInit(): void {
  }

  onSubmit() {
    this.model.Name = this.formGroup.controls.OrgName.value;
    this.model.Description = this.formGroup.controls.OrgDescription.value;
    this.authService.saveOrganization(this.data.id, this.model.Name, this.model.Description, [], this.model.mediaId).subscribe(
      result => {
        this.dialogRef.close({ name: this.model.Name });
      });
  }

}
