import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-sms-tools-landing-page',
  templateUrl: './sms-tools-landing-page.component.html',
  styleUrls: ['./sms-tools-landing-page.component.css']
})
export class SmsToolsLandingPageComponent implements OnInit {

  temp: Observable<any>;
  showAlert = false;
  alertMessage = "";
  formGroup: FormGroup;

  constructor() {
    this.temp = new Observable();
    this.formGroup = new FormGroup({});
  }

  ngOnInit(): void {
  }

  onSubmit() {

  }
}
