import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormArray, FormGroup, Validators } from '@angular/forms';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RuleList, RuleModel, RuleGroup, QuestionModel, CategoriesModel } from '../../question.service';
import { UserFeatures } from '../../user.Service';

@Component({
  selector: 'app-advanced-routing',
  templateUrl: './advanced-routing.component.html',
  styleUrls: ['./advanced-routing.component.scss']
})
export class AdvancedRoutingComponent implements OnInit {
  selectionTypesConstants = [
    { questionType: "information", ruleType: ["goto", "skip" ] },
    { questionType: "mcsa", ruleType: [ "goto", "question", "options", "carry", "skip"] },
    { questionType: "mcma", ruleType: [ "goto", "question", "options", "carry", "skip"] },
    { questionType: "block", ruleType: ["goto", "skip" ] },
    { questionType: "textbox", ruleType: [ "goto", "question", "string", "skip" ] },
    { questionType: "email", ruleType: ["goto", "question", "string", "skip" ] },
    { questionType: "geocode", ruleType: ["goto", "skip" ] },
    { questionType: "barcode", ruleType: ["goto", "skip" ] },
    { questionType: "boolean", ruleType: ["goto", "question", "boolean", "skip" ] },
    { questionType: "integer", ruleType: ["goto", "question", "numeric", "skip" ] },
    { questionType: "media", ruleType: ["goto", "skip" ] },
    { questionType: "date", ruleType: ["goto", "question", "date", "skip" ] },
    { questionType: "currency", ruleType: ["goto", "question", "numeric", "skip" ] },
    { questionType: "rating", ruleType: ["goto", "question", "numeric", "skip" ] },
    { questionType: "matrix", ruleType: ["goto", "skip" ] },
    { questionType: "maxDiff", ruleType: ["goto", "skip" ] },
    { questionType: "imageMap", ruleType: ["goto", "skip" ] },
    { questionType: "bucket", ruleType: ["goto", "skip" ] },
    { questionType: "ranking", ruleType: ["goto", "question", "rank", "skip" ] },
    { questionType: "sendEmail", ruleType: ["goto", "skip" ] },
    { questionType: "userlist", ruleType: [ ] },
    { questionType: "advRouting", ruleType: ["goto", "skip" ] },
    { questionType: "sendSMS", ruleType: [ "goto", "skip" ] },
    { questionType: "terminate", ruleType: [ "goto", "skip" ] }
  ];

  conditionListConstants = [
    { conditionType: "boolean", conditions: [{ id: "true", value: "Yes" }, { id: "false", value: "No" }] },
    {
      conditionType: "numeric", conditions:
        [{ id: "eq", value: "Equal To" }, { id: "ne", value: "Not Equal To" }, { id: "gt", value: "Greater Than" }, { id: "lt", value: "Less Than" },
        { id: "ge", value: "Greater Than or Equal To" }, { id: "le", value: "Less Than or Equal To" }]
    },
    {
      conditionType: "string", conditions:
        [{ id: "eq", value: "Equal To" }, { id: "ne", value: "Not Equal To" }, { id: "ct", value: "Contains" }, { id: "nc", value: "Does Not Contain" },
        { id: "sw", value: "Starts With" }, { id: "ew", value: "Ends With" }]
    },
    {
      conditionType: "date", conditions:
        [{ id: "eq", value: "Equal To" }, { id: "ne", value: "Not Equal To" }, { id: "gt", value: "Greater Than" }, { id: "lt", value: "Less Than" },
          { id: "ge", value: "Greater Than or Equal To" }, { id: "le", value: "Less Than or Equal To" }, { id: "se", value: "Has Been Set" }, {id: "sx", value: "Has Not Been Set"}]
    },
    {
      conditionType: "options", conditions:
        [{ id: "is", value: "Is Selected" }, { id: "ns", value: "Is Not Selected" }]
    },
    {
      conditionType: "object", conditions:
        [{ id: "eq", value: "Equal To" }, { id: "ne", value: "Not Equal To" }, { id: "gt", value: "Greater Than" }, { id: "lt", value: "Less Than" },
          { id: "ge", value: "Greater Than or Equal To" }, { id: "le", value: "Less Than or Equal To" },
          { id: "is", value: "Is Selected" }, { id: "ns", value: "Is Not Selected" }]
    },
    {
      conditionType: "rank", conditions:
        [{ id: "eq", value: "Is Set At" }, { id: "gt", value: "Higher Than" }, { id: "ge", value: "Higher Than or Equal To" },
          { id: "lt", value: "Lower Than" }, { id: "le", value: "Lower Than or Equal To" }]
    },
    {
      conditionType: "carry", conditions:
        [{ id: "all", value: "All Choices" }, { id: "dis", value: "All that are Displayed" }, { id: "nds", value: "All that are not Displayed" },
          { id: "sel", value: "All that are Selected" }, { id: "nsl", value: "All that are not Selected" } ]
    },
    {
      conditionType: "skip", conditions:
        [{ id: "is", value: "Is Selected" }, { id: "ns", value: "Is Not Selected" }]
    }
  ];

  errorTypeConstants = [
    { errorType: "Attempts", description: "Exceed Attempts" },
    { errorType: "Any", description: "Any Error" },
    { errorType: "NotInRange", description: "Not In Range" },
    { errorType: "TooManyDigits", description: "Too Many Digits" },
    { errorType: "NotNumeric", description: "Not Numeric" },
    { errorType: "NotDate", description: "Not a Date" },
    { errorType: "TooFewAnswers", description: "Too Few Answers" },
    { errorType: "TooManyDecimalPlaces", description: "Too Many Decimal Places" },
    { errorType: "NotSingleAnswer", description: "Not a Single Answer" },
    { errorType: "TooManyAnswers", description: "Too Many Answers" },
    { errorType: "CannotCombine", description: "Cannot Combine" },
    { errorType: "MissingAnswer", description: "Missing Answer" },
    { errorType: "OtherNotSelected", description: "Other Not Selected" },
    { errorType: "InvalidText", description: "Invalid Text" },
    { errorType: "QuestionTooMuchText", description: "Too Much Text" },
    { errorType: "QuestionTooLittleText", description: "Too Little Text" }
  ];

  errorTypeListConstants = [
    { questionType: "mcsa", errorTypes: ["Attempts", "Any", "TooManyAnswers", "MissingAnswer", "OtherNotSelected"] },
    { questionType: "mcma", errorTypes: ["Attempts", "Any", "TooManyAnswers", "TooFewAnswers", "MissingAnswer", "OtherNotSelected", "CannotCombine"] },
    { questionType: "textbox", errorTypes: ["Attempts", "Any", "InvalidText", "QuestionTooMuchText", "QuestionTooLittleText", "MissingAnswer"] },
    { questionType: "email", errorTypes: ["Attempts", "Any", "InvalidText", "QuestionTooMuchText", "QuestionTooLittleText", "MissingAnswer"] },
    { questionType: "geocode", errorTypes: ["Attempts", "Any", "InvalidText", "QuestionTooMuchText", "QuestionTooLittleText", "MissingAnswer"] },
    { questionType: "barcode", errorTypes: ["Attempts", "Any", "InvalidText", "QuestionTooMuchText", "QuestionTooLittleText", "MissingAnswer"] },
    { questionType: "boolean", errorTypes: ["Attempts", "Any", "InvalidText", "MissingAnswer"] },
    { questionType: "integer", errorTypes: ["Attempts", "Any", "NotInRange", "TooManyDigits", "NotNumeric", "MissingAnswer"] },
    { questionType: "date", errorTypes: ["Attempts", "Any", "NotInRange", "NotDate", "MissingAnswer"] },
    { questionType: "integer", errorTypes: ["Attempts", "Any", "NotInRange", "TooManyDecimalPlaces", "NotNumeric", "MissingAnswer"] },
    { questionType: "rating", errorTypes: ["Attempts", "Any", "NotInRange", "TooManyDigits", "NotNumeric", "MissingAnswer"] },
    { questionType: "mcma", errorTypes: ["Attempts", "Any", "TooManyAnswers", "TooFewAnswers", "MissingAnswer"] },
    { questionType: "matrix", errorTypes: ["Attempts", "Any", "TooManyAnswers", "TooFewAnswers", "MissingAnswer"] }
  ];

  selectionTypes: SelectionType[] = this.selectionTypesConstants;

  conditionTypes: ConditionType[] = this.conditionListConstants;

  errorTypes: ErrorType[] = this.errorTypeConstants;

  errorTypeLists: ErrorTypeList[] = this.errorTypeListConstants;

  questionTypeForSelection: QuestionTypesForSelection = new QuestionTypesForSelection(this.selectionTypes);

  questionsForRuleType(index: number, listType: string): QuestionModel[] {
    return this.rules?.Question?.Interview.questionList(true,
      this.questionTypeForSelection.findQuestionTypesForSelection(this.options(index).controls['ruleType'].value), listType) ?? [];
  }

  questionsForErrors(index: number): QuestionModel[] {
    return this.rules?.Question?.Interview.questionList(true, [], "branch") ?? [];
  }

  errorTypeForQuestions(index: number): ErrorType[] {
    if (this.rules == null) {
      return [];
    }

    for (let i = 0; i < this.errorTypeLists.length; i++) {
      if (this.rules.Question?.QuestionType == this.errorTypeLists[i].questionType) {
        let et: ErrorType[] = [];
        for (let j = 0; j < this.errorTypeLists[i].errorTypes.length; j++) {
          if (this)
          et.push(this.findErrorType(this.errorTypeLists[i].errorTypes[j])?? new ErrorType());
        }

        return et;
      }
    }

    return [];
  }

  findErrorType(error: string): ErrorType | null {
    for (let i = 0; i < this.errorTypes.length; i++) {
      if (error == this.errorTypes[i].errorType) {
        return this.errorTypes[i];
      }
    }

    return null;
  }

  selectionType(index: number): SelectionType | null {
    if (this._rules == null) {
      return null;
    }

    const ruleType = this.options(index).controls["ruleType"].value;
    let q: QuestionModel | null = this.question(index);
    if (ruleType === "skip") {
      q = this._rules.Question;
    }
    if (q == null) {
      return null;
    }

    return this.findSelectionType(q);
  }

  categoryName(name: string): string {
    if (name.startsWith("_")) {
      return name.substring(1);
    }

    return name;
  }

  findSelectionType(question: QuestionModel): SelectionType | null {
    let questionType = question.QuestionType;
    if (question.QuestionType == "advanced") {
      switch (question.Action) {
        case "embed":
          if (question.Questions.List.length > 0) {
            questionType = question.Questions.List[0].QuestionType;
          }

          break;
      }
    }
    for (var i = 0; i < this.selectionTypes.length; i++) {
      if (this.selectionTypes[i].questionType === questionType) {
        return this.selectionTypes[i];
      }
    }
    return null;
  }

  conditionType(index: number): ConditionType | null {
    if (this._rules == null) {
      return null;
    }

    const ruleType = this.options(index).controls["ruleType"].value;
    let q: QuestionModel | null = this.question(index);
    if (ruleType === "skip") {
      q = this._rules.Question;
    }
    if (q == null) {
      return null;
    }


    const selectionType = this.findSelectionType(q);
    const elementType = this.options(index).controls.questionElement.value;
    if (selectionType === null) {
      return null;
    }

    if (elementType == "" || elementType == "Response") {
      return this.findConditionType(selectionType, this.options(index).controls['ruleType'].value === "carry", this.options(index).controls['ruleType'].value === "skip");
    }

    let cType = "date";
    if (elementType == "Factor") {
      cType = "object";
    }

    for (let i = 0; i < this.conditionTypes.length; i++) {
      if (this.conditionTypes[i].conditionType == cType) {
        return this.conditionTypes[i];
      }
    }

    return null;
  }

  findConditionType(selectionType: SelectionType, withCarry: boolean, withSkip: boolean): ConditionType | null {
    if (selectionType === undefined) {
      return null;
    }

    for (let i = 0; i < this.conditionTypes.length; i++) {
      for (let j = 0; j < selectionType.ruleType.length; j++) {
        if (selectionType.ruleType[j] === this.conditionTypes[i].conditionType) {
          if (withCarry) {
            if (selectionType.ruleType[j] !== "options") {
              return this.conditionTypes[i];
            }
          }
          else {
            if (withSkip) {
              if (selectionType.ruleType[j] !== "options" && selectionType.ruleType[j] !== "carry") {
                return this.conditionTypes[i];
              }
            }
            else {
              return this.conditionTypes[i];
            }
          }
        }
      }
    }

    return null;
  }

  findConditionName(conditionType: ConditionType, id: string): string {
    for (let i = 0; i < conditionType.conditions.length; i++) {
      if (conditionType.conditions[i].id === id) {
        return conditionType.conditions[i].value;
      }
    }

    return "";
  }

  findErrorName(errorType: string): string {
    for (let i = 0; i < this.errorTypes.length; i++) {
      if (errorType == this.errorTypes[i].errorType) {
        return this.errorTypes[i].description;
      }
    }

    return "";
  }

  ruleTypeCheck(index: number, rule: string): boolean {
    const selectionType = this.selectionType(index);
    if (selectionType == null) {
      return false;
    }

    for (let i = 0; i < selectionType.ruleType.length; i++) {
      if (selectionType.ruleType[i] === rule) {
        return true;
      }
    }

    return false;
  }

  constructor(private fb: FormBuilder, private breakpointObserver: BreakpointObserver) {
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));


  public optionFormGroup: FormGroup = new FormGroup({});

  ngOnInit() {
    this.optionFormGroup = this.fb.group({
      questionOption: this.fb.array([this.initOptionRow()])
    });
    this.loadRules();
  }

  get optionArr() {
    return this.optionFormGroup.get('questionOption') as FormArray;
  }

  options(index: number): FormGroup {
    return this.optionArr.controls[index] as FormGroup;
  }

  isMultipleAnswer(index: number): boolean {
    return this.question(index)?.QuestionType == 'mcma' || this.question(index)?.QuestionType == 'mcsa';
  }

  question(index: number): QuestionModel | null {
    return this.rules?.Question?.Interview.findQuestion(this.options(index).controls["questionId"].value) ?? null;
  }

  categories(index: number): CategoriesModel {
    let form = this.options(index);
    if (this.rules == null || form == undefined) {
      return new CategoriesModel();
    }

    if (this.isSkip(index)) {
      return this.rules.Question?.Categories ?? new CategoriesModel();
    }

    return this.rules.Question?.Interview.findQuestion(this.options(index).controls["questionId"].value)?.Categories ?? new CategoriesModel();
  }

  addNewCondition(index: number) {
    let rule = new RuleModel();
    if (this.rules == null) {
      return;
    }

    if (this.rules.Rules[0].Rules[index].RuleType == "carry") {
      rule.RuleType = "carry";
    }

    if (this.rules.Rules[0].Rules[index].RuleType == "skip") {
      rule.RuleType = "skip";
    }
    else if (this.rules.Rules[0].Rules[index].RuleType == "error") {
      rule.RuleType = "error";
    }

    if (index + 1 == this.rules.Rules[0].Rules.length) {
      this.rules.Rules[0].Rules.push(rule);
      this.optionArr.controls.push(this.initOptionRowWithRule(rule) ?? new FormGroup({}));
    }
    else {
      this.rules.Rules[0].Rules.splice(index + 1, 0, rule);
      this.optionArr.controls.splice(index + 1, 0, this.initOptionRowWithRule(rule) ?? new FormGroup({}));
    }

    this.addAction.emit(this);
  }

  deleteCondition(index: number) {
    if (this.rules == null) {
      return;
    }

    if (index < this.rules.Rules[0].Rules.length) {
      this.rules.Rules[0].Rules.splice(index, 1);
      this.optionArr.controls.splice(index, 1);
    }
  }

  getFieldFormats(question: QuestionModel) {
    switch (question.QuestionType) {
      case "text":
        return ["Response", "Asked", "Answered"];
      case "mcma":
      case "mcsa":
        if (this.userFeatures?.QuestionEditorFeatures?.EditAnswerOptionFactors) {
          return ["Response", "Asked", "Answered", "Factor"];
        }

        return ["Response", "Asked", "Answered"];
      case "information":
      case "terminate":
        return ["Asked"];
    }

    return ["Response", "Asked", "Answered"];
  }

  questionSelected($event, question: QuestionModel, index: number, format: string) {
    // $event.stopPropagation();
    let form = this.options(index);
    form.controls.questionId.setValue(question.QuestionId);
    form.controls.questionName.setValue(question.FullName);
    form.controls.questionElement.setValue(format);
  }

  shouldDisplayQuestion(index: number): boolean {
    switch (this.options(index).controls["ruleType"].value) {
      case "question":
      case "carry":
      case "skip":
        return true;
      default:
        return false;
    }
  }

  shouldDisplayProfile(index: number): boolean {
    return this.options(index).controls.ruleType.value == "profile";
  }

  isSkip(index: number): boolean {
    return this.options(index).controls["ruleType"].value === "skip";
  }

  isCarry(index: number): boolean {
    return this.options(index).controls["ruleType"].value === "carry";
  }

  isError(index: number): boolean {
    return this.options(index).controls["ruleType"].value === "error";
  }

  isEmbed(index: number): boolean {
    return this.options(index).controls["ruleType"].value === "embed";
  }

  shouldDisplayEmbeddedData(index: number): boolean {
    switch (this.options(index).controls["ruleType"].value) {
      case "embed":
        return true;
      default:
        return false;
    }
  }

  optionValid(index: number): boolean {
    const form = this.options(index);
    if (form.controls.ruleType.value === "goto") {
      return false;
    }
    if (form) {
      if (this.isError(index)) {
        return form.controls.answer.value != '' && form.controls.ruleType.value != '';
      }

      if (this.isEmbed(index)) {
        return form.controls.answer.value != '' && form.controls.ruleType.value != '';
      }

      return !form.invalid;
    }

    return false;
  }

  shouldDisplayOptions(index: number): boolean {
    let form = this.options(index);
    if (form.controls.questionElement.value == "" || form.controls.questionElement.value == "Response") {
      if (this.shouldDisplayCondition(index) && this.ruleTypeCheck(index, 'options')) {
        if (form.controls["ruleType"].value === "carry") {
          return false;
        }

        return true;
      }
    }

    return false;
  }

  shouldDisplayAttempts(index: number): boolean {
    const errorType = this.options(index).controls.condition.value;
    if (errorType == "Attempts") {
      return true;
    }

    return false;
  }

  shouldDisplayErrorMessage(index: number): boolean {
    const errorType = this.options(index).controls.condition.value;
    if (errorType && errorType != "Attempts") {
      return true;
    }

    return false;
  }

  shouldDisplayCondition(index: number): boolean {
    const ruleType = this.options(index).controls["ruleType"].value;
    let q = this.question(index);
    if (ruleType === "skip") {
      q = this._rules?.Question ?? null;
    }
    if (q == null) {
      return false;
    }

    switch (ruleType) {
      case "goto":
        return false;
      case "question":
      case "carry":
      case "skip":
      case "embed":
        return true;
      default:
        return false;
    }
  }

  shouldDisplayConditionValue(index: number): boolean {
    let form = this.options(index);
    const ruleType = form.controls.ruleType.value;
    const condition = form.controls.condition.value;
    const questionElement = form.controls.questionElement.value;
    let q = this.question(index);
    if (ruleType === "skip") {
      q = this._rules?.Question ?? null;
    }

    if (q == null) {
      return false;
    }

    const selectionType = this.findSelectionType(q);
    switch (ruleType) {
      case "goto":
        return false;
      case "question":
      case "embed":
        if (selectionType == null) {
          return true;
        }

        if (condition == "se" || condition == "sx") {
          return false;
        }

        if (questionElement != "" && questionElement != "Response") {
          return true;
        }

        if (selectionType.ruleType.includes("options")) {
          return false;
        }

        if (q.QuestionType === "boolean") {
          return false;
        }

        return true;
      case "skip":
        return !this.shouldDisplayOptions(index);
      default:
        return false;
    }
  }

  hideGoto(index: number): boolean {
    switch (this._name) {
      case "Display ":
      case "Carry Forward ":
      case "Skip ":
      case "Export Filter ":
      case "Reminder Filter ":
        return true;
      default:
        return false;
    }
  }

  shouldDisplayRuleType(index: number): boolean {
    if (this._rules != null && this._rules.Rules.length > 0 && this._rules.Rules[0].Rules.length > 0) {
      switch (this._rules.Rules[0].Rules[index].RuleType) {
        case "carry":
        case "skip":
        case "error":
          return false;
        default:
          return true;
      }
    }

    return true;
  }

  shouldDisplayErrorTypes(index: number): boolean {
    if (this.isError(index)) {

    }
    if (this._rules != null && this._rules.Rules.length > 0 && this._rules.Rules[0].Rules.length > 0) {
      switch (this._rules.Rules[0].Rules[index].RuleType) {
        case "error":
          return true;
        default:
          return false;
      }
    }

    return false;
  }

  shouldDisplayAction(): boolean {
    const options = this.options(0);
    if (options === undefined || this._rules == null) {
      return false;
    }

    if (this._rules.Rules.length > 0 && this._rules.Rules[0].TargetId !== undefined && this._rules.Rules[0].TargetId !== "") {
      return false;
    }

    const routeType = options.controls["ruleType"].value;
    if (routeType === "goto" || routeType === "") {
      return false;
    }

    if (this._rules.Rules.length > 0) {
      return false;
    }

    return true;
  }

  initOptionRow() {
    const formGroup = this.fb.group({
      link: [''],
      ruleType: [''],
      errorType: [''],
      questionId: ['', Validators.required],
      questionName: ['', Validators.required],
      questionElement: [''],
      condition: [''],
      answer: [''],
      options: ['']
    });

    formGroup.valueChanges.subscribe(x => {
      if (this._rules == null || this._rules.Rules == null) {
        return;
      }

      if (this._rules.Rules.length > 0) {
        this._rules.Rules[0] = this.createRuleGroup(this._rules.Rules[0]);
      }
      else {
        this._rules.Rules = [ this.createRuleGroup(null) ];
      }
    });

    return formGroup;
  }

  initOptionRowWithRule(rule: RuleModel): FormGroup | null {
    let answer;
    let option = "";

    if (this._rules == null) {
      return this.initOptionRow();
    }

    let q = new QuestionModel();
    if (rule.RuleType === "question" && rule.QuestionId) {
      q = this._rules.Question?.Interview?.findQuestion(rule.QuestionId);
      if (q == null) {
        return this.initOptionRow();
        // throw "Question not found";
      }

      let st = this.findSelectionType(q);
      if (st == null) {
        throw "Selection type not found";
      }

      let c = this.findConditionType(st, false, false);
      if (c == null) {
        throw "Condition Type not found";
      }

      if (c.conditionType === "rank") {
        if (rule.Answers !== undefined && rule.Answers.length > 0) {
          option = rule.Answers[0];
          answer = rule.Answers[1];
        }
      }
      else {
        if (rule.Answers !== null && rule.Answers.length > 0) {
          if (q.QuestionType == 'mcma' || q.QuestionType == 'mcsa') {
            answer = rule.Answers;
          }
          else {
            answer = rule.Answers[0];
          }
        }

        option = answer;
      }
    }
    else {
      if (rule.Answers !== undefined && rule.Answers.length > 0) {
        answer = rule.Answers[0];
      }

      option = answer;
    }

    const formGroup =  this.fb.group({
      link: [rule.Link],
      ruleType: [rule.RuleType],
      questionId: [rule.QuestionId, Validators.required],
      questionName: [q.FullName, Validators.required],
      questionElement: [rule.QuestionElement],
      condition: [rule.Condition],
      answer: [answer],
      options: [option]
    });

    formGroup.valueChanges.subscribe(x => {
      if(this._rules == null) {
        return;
      }

      if (this._rules.Rules.length > 0) {
        this._rules.Rules[0] = this.createRuleGroup(this._rules.Rules[0]);
      }
      else {
        this._rules.Rules = [ this.createRuleGroup(null) ];
      }
    });

    return formGroup;
  }

  getOptions(question: QuestionModel, answers: string[]): string[] {
    let result = [];
    for (let i = 0; i < answers.length; i++) {
      for (let j = 0; j < question.Categories.List.length; j++) {
        if (question.Categories.List[j].Name === answers[i]) {
          result.push(question.Categories.List[j].Label);
        }
      }
    }

    return result;
  }

  loadRules() {
    if (this.optionFormGroup == null || this.optionArr == null) {
      return;
    }

    this.optionArr.clear();
    if (this._rules == null) {
      this.optionArr.controls.push(this.initOptionRow());
      return;
    }

    if (this._rules.Rules == undefined) {
      this.optionArr.controls.push(this.initOptionRow());
      return;
    }

    if (this._rules.Rules.length === 0) {
      this.optionArr.controls.push(this.initOptionRow());
      return;
    }

    if (this._rules.Rules[0].Rules.length === 0) {
      this.optionArr.controls.push(this.initOptionRow());
      return;
    }

    this._rules.Rules[0].Rules.forEach(rule => {
      this.optionArr.push(this.initOptionRowWithRule(rule));
    });
  }

  createRuleGroup(originalGroup: RuleGroup): RuleGroup {
    const options = this.optionArr;
    let group = new RuleGroup();
    if (originalGroup == null) {
      group.TargetId = "";
      group.Link = "";
    }
    else {
      group = originalGroup;
      group.Rules = [];
    }

    for (var i = 0; i < options.length; i++) {
      group.Rules.push(this.createRule(i));
    }

    return group;
  }

  createRule(index: number): RuleModel {
    const rule = new RuleModel();
    const option = this.options(index);
    rule.RuleType = option.controls["ruleType"].value;
    rule.Link = option.controls["link"].value;
    rule.QuestionId = option.controls["questionId"].value;
    rule.QuestionElement = option.controls["questionElement"].value;
    if (this.shouldDisplayOptions(index)) {
      let options = option.controls["options"].value;
      rule.Answers = this.isMultipleAnswer(index) ? options: [options];
    }
    else if (this.ruleTypeCheck(index, 'rank')) {
      rule.Answers = [option.controls["options"].value, option.controls["answer"].value];
    }
    else {
      rule.Answers = [ option.controls["answer"].value ];
    }
    rule.Condition = option.controls["condition"].value;
    return rule;
  }

  @Output()
  addAction: EventEmitter<this> = new EventEmitter<this>();

  registerOnAddAction(fn: any): void {
    this.onAddAction = fn;;
  }

  onAddAction() {
    this.addAction.emit(this);
  }

  @Output()
  deleteRule: EventEmitter<this> = new EventEmitter<this>();

  registerOnDeleteRule(fn: any): void {
    this.onAddAction = fn;;
  }

  onDeleteRule() {
    this.deleteRule.emit(this);
  }

  addNewOption() {
    this.optionArr.push(this.initOptionRow());
  }

  removeOption(index: number) {
    console.log(this.optionArr.length);
    if (this.optionArr.length > 1) {
      this.optionArr.removeAt(index);
    }
  }

  get summary(): string {
    return "";
  }

  @Input()
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value
  }
  private _name: string = "";

  @Input()
  get rules(): RuleList | null {
    return this._rules;
  }
  set rules(value: RuleList | null) {
    this._rules = value;
    this.loadRules();
  }
  private _rules: RuleList | null = new RuleList();

  @Input()
  get display(): boolean {
    return this._display;
  }
  set display(value: boolean) {
    this._display = value;
  }
  private _display = false;

  @Input()
  get showAddRemove(): boolean {
    return this._showAddRemove;
  }
  set showAddRemove(value: boolean) {
    this._showAddRemove = value;
  }
  private _showAddRemove = false;

  @Input()
  get userFeatures(): UserFeatures {
    return this._userFeatures;
  }
  set userFeatures(value: UserFeatures) {
    this._userFeatures = value;
  }
  private _userFeatures: UserFeatures;
}

export class SelectionType {
  questionType: string = "";
  ruleType: string[] = [];
}

export class QuestionTypesForSelection {
  questionTypesForSelection: QuestionTypeForSelection[] = [];

  constructor(selectionTypes: SelectionType[]) {
    for (let i = 0; i < selectionTypes.length; i++) {
      for (let j = 0; j < selectionTypes[i].ruleType.length; j++) {
        this.addSelectionAndQuestionType(selectionTypes[i].ruleType[j], selectionTypes[i].questionType);
      }
    }
  }

  findQuestionTypesForSelection(selectionType: string): string[] {
    if (selectionType === "embed") {
      return [ "embed" ];
    }

    for (let i = 0; i < this.questionTypesForSelection.length; i++) {
      if (this.questionTypesForSelection[i].selectionType == selectionType) {
        return this.questionTypesForSelection[i].questionTypes;
      }
    }

    return [];
  }

  addSelectionAndQuestionType(selectionType: string, questionType: string) {
    let index = -1;
    for (let i = 0; i < this.questionTypesForSelection.length; i++) {
      if (this.questionTypesForSelection[i].selectionType == selectionType) {
        this.questionTypesForSelection[i].addQuestionType(questionType);
        index = i;
        break;
      }
    }

    if (index > -1) {
      return;
    }

    let qtfs = new QuestionTypeForSelection();
    qtfs.selectionType = selectionType;
    qtfs.addQuestionType(questionType);
    this.questionTypesForSelection.push(qtfs);
  }
}

export class QuestionTypeForSelection {
  selectionType: string = "";
  questionTypes: string[] = [];

  addQuestionType(questionType: string) {
    if (this.questionTypes.includes(questionType)) {
      return;
    }

    this.questionTypes.push(questionType);
  }
}

export class ConditionType {
  conditionType: string = "";
  conditions: Condition[] = [];
}

export class Condition {
  id: string = "";
  value: string = "";
}

export class ErrorTypeList {
  questionType: string = "";
  errorTypes: string[] = [];
}

export class ErrorType {
  errorType: string = "";
  description: string = "";
}
