import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CintCategory, CintFeasibility, CintFullQuote, CintQuestion, CintQuota, CintQuotaGroup, CintService, CintVariable } from '../../cint.Service';

@Component({
  selector: 'app-audience',
  templateUrl: './audience.component.html',
  styleUrls: ['./audience.component.css']
})
export class AudienceComponent implements OnInit {


  selections: Map<number, Map<number, ReportedQuestion>> = new Map<number, Map<number, ReportedQuestion>>();
  requested = false;
  savedFeasibility: string = "";
  loaded?: Observable<any>;

  constructor(
    private cintService: CintService
  ) { }

  ngOnInit(): void {
  }

  @Input()
  get categories(): CintCategory[] {
    return this._categories;
  }
  set categories(value: CintCategory[]) {
    this._categories = value;
    this.buildReportedQuestions();
  }
  private _categories: CintCategory[] = [];

  @Input()
  get model(): CintFeasibility | null {
    return this._model;
  }
  set model(value: CintFeasibility | null) {
    this._model = value;
    this.buildReportedQuestions();
  }
  private _model: CintFeasibility | null = null;

  @Input()
  get preview(): boolean {
    return this._preview;
  }
  set preview(value: boolean) {
    this._preview = value;
    this.buildReportedQuestions();
  }
  private _preview: boolean = false;

  refresh() {
    this.buildReportedQuestions();
  }

  getValues(): Array<Map<number, ReportedQuestion>> {
    return Array.from(this.selections.values());
  }

  getSubValues(i: Map<number, ReportedQuestion>): ReportedQuestion[] {
    return Array.from(i.values());
  }

  getSubSubValues(i: number, j: number): ReportedQuestion {
    return this.selections.get(i).get(j);
  }

  addToReportedQuestions(id: number, quota: CintQuota) {
    for (let i = 0; i < this.categories.length; i++) {
      for (let j = 0; j < this.categories[i].Questions.length; j++) {
        for (let k = 0; k < this.categories[i].Questions[j].Variables.length; k++) {
          if (this.categories[i].Questions[j].Variables[k].Id === id) {
            this.addToReportQuestion(i, j, k, quota);
            return;
          }
        }
      }
    }
  }

  addToReportQuestion(i: number, j: number, k: number, quota: CintQuota) {
    const reportedVariable = new ReportedVariable(this.categories[i].Questions[j].Variables[k], quota)
    if (this.selections.has(i)) {
      if (this.selections.get(i)?.has(j)) {
        this.selections.get(i)?.get(j)?.variables.push(reportedVariable)
      }
      else {
        this.selections.get(i)?.set(j, new ReportedQuestion(this.categories[i].Questions[j], this.categories[i].Questions[j].Variables[k], quota))
      }
    }
    else {
      this.selections.set(i, new Map<number, ReportedQuestion>());
      this.selections.get(i)?.set(j, new ReportedQuestion(this.categories[i].Questions[j], this.categories[i].Questions[j].Variables[k], quota))
    }
  }


  buildReportedQuestions() {
    if (this.preview) {
      if (!(this.model)) {
        return;
      }

      if ((this.categories == undefined || this.categories.length == 0) && !this.requested) {
        this.requested = true;
        this.cintService.getQuestions(this.model.CountryId.toString()).subscribe(
          result => {
            this.categories = result;
            this.buildSelection();
          });
        return;
      }
    }

    this.buildSelection();
  }

  percentage(limit: any, groupLimit: number): number {
    return limit.quota.Limit * 100 / (this.model?.Limit ?? 100);
  }

  private buildSelection() {
    if (this.model == null || this.categories == null) {
      return;
    }

    let s = this.selections;
    this.loaded = new Observable<any>((s) => {
      this.selections.clear();
      if (this.model != null && this.model.QuotaGroups != null) {
        for (let i = 0; i < this.model.QuotaGroups.length; i++) {
          for (let j = 0; j < this.model.QuotaGroups[i].Quotas.length; j++) {
            const quota = this.model.QuotaGroups[i].Quotas[j];
            if (quota.TargetGroup.MinAge !== null && quota.TargetGroup.MinAge !== undefined && quota.TargetGroup.MaxAge != 0) {
              this.addToReportQuestion(0, 0, 0, quota);
            }
            else if (quota.TargetGroup.Gender !== null && quota.TargetGroup.Gender !== undefined && quota.TargetGroup.Gender != 0) {
              this.addToReportQuestion(0, 1, quota.TargetGroup.Gender - 1, quota);
            }
            else {
              let variableIds = quota.TargetGroup.VariableIds;
              if (variableIds === null || variableIds === undefined || variableIds.length === 0) {
                this.addToReportedQuestions(quota.TargetGroup.RegionIds[0], quota);
              }
              else {
                this.addToReportedQuestions(quota.TargetGroup.VariableIds[0], quota);
              }
            }
          }
        }
      }

      s.next(this.selections);
      s.complete();
    });
  }
}

class ReportedQuestion {
  question: CintQuestion;
  variables: ReportedVariable[] = [];

  constructor(question: CintQuestion, variable: CintVariable, quota: CintQuota) {
    this.question = question;
    this.variables.push(new ReportedVariable(variable, quota));
  }

  add(variable: ReportedVariable) {
    this.variables.push(variable);
  }
}

class ReportedVariable {
  variable: CintVariable;
  quota: CintQuota;

  constructor(variable: CintVariable, quota: CintQuota) {
    this.variable = variable;
    this.quota = quota;
  }
}
