import { CommonModule, DatePipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuardService as AuthGuard } from './auth-guard.service';
import { AuthenticationService } from "./authentication.Service";
import { ChatService } from './chat.Service';
import { UserService } from "./user.Service";
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtModule } from "@auth0/angular-jwt";
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatBadgeModule } from '@angular/material/badge';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DateAdapter, MatNativeDateModule, MatRippleModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatSliderModule } from '@angular/material/slider';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { SchedulerComponent } from './scheduler/scheduler.component';
import { RegisterComponent } from './register/register.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { HomeLayoutComponent } from './home-layout/home-layout.component';
import { NavigationComponent } from './navigation/navigation.component';
import { LoginLayoutComponent } from './login-layout/login-layout.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SettingsComponent } from './settings/settings.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { UsersComponent } from "./users/users.component";
import { UserDetailsComponent } from "./user-details/user-details.component";
import { HttpInterceptorService } from "./services/http-interceptor.service";
import { PanelsComponent } from './panels/panels.component';
import { SnackbarDefaultComponent } from './snackbar/snackbar-default.component';
import { NewPanelComponent } from './new-panel/new-panel.component';
import { EditPanelComponent } from './edit-panel/edit-panel.component';
import { SurveyDetailsLayoutComponent } from './survey-details-layout/survey-details-layout.component';
import { SurveyDetailsNavigationComponent } from './survey-details-navigation/survey-details-navigation.component';
import { AudienceComponent } from './panel-selector/audience/audience.component';
import { EditCommunicationComponent } from './edit-communication/edit-communication.component';
import { EditToolbarComponent } from './edit-toolbar/edit-toolbar.component';
import { EditLayoutComponent } from './edit-layout/edit-layout.component';
import { QuestionEditorComponent } from './question-editor/question-editor.component';
import { QuestionsComponent } from './questions/questions.component';
import { RandomiseQuestionsComponent } from './randomise-questions/randomise-questions.component';
import { QuestionPropertiesComponent } from './question-properties/question-properties.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { CommsService } from './comms.Service';
import { SystemService } from './system.Service';
import { SystemComponent } from "./system/system.component";
import { QuestionnaireSidebarComponent } from './questionnaire-sidebar/questionnaire-sidebar.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PanelService } from './panel.Service';
import { ScriptService } from './script.Service';
import { EditPanelistComponent } from './edit-panelist/edit-panelist.component';
import { SmsService } from './sms.Service';
import { SmsFeaturesComponent } from './sms-features/sms-features.component';
import { FindUserComponent } from './find-user/find-user.component';
import { PanelSelectorComponent } from './panel-selector/panel-selector.component';
import { CategorySelectorComponent } from './category-selector/category-selector.component';
import { CategoryButtonComponent } from './category-button/category-button.component';
import { CintService } from './cint.Service';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { RangeBoxComponent, RangerComponent } from './range-box/range-box.component';
import { HelpComponent } from './help/help.component';
import { MatTreeModule } from '@angular/material/tree';
import { CintMapperComponent } from './cint-mapper/cint-mapper.component';
import { InformationComponent } from './questions/information/information.component';
import { McmaComponent } from './questions/mcma/mcma.component';
import { McsaComponent } from './questions/mcsa/mcsa.component';
import { QeBlockComponent } from './questions/qe-block/qe-block.component';
import { QuestionService, InterviewService } from './question.service';
import { SurveyService } from './survey.Service';
import { ProcessService } from './process.Service';
import { QuestionLibraryComponent } from './question-library/question-library.component';
import { TextboxComponent } from './questions/textbox/textbox.component';
import { EmailComponent } from './questions/email/email.component';
import { QeGeocodeComponent } from './questions/qe-geocode/qe-geocode.component';
import { BarcodeComponent } from './questions/barcode/barcode.component';
import { IntegerComponent } from './questions/integer/integer.component';
import { QeDateComponent } from './questions/qe-date/qe-date.component';
import { CurrencyComponent } from './questions/currency/currency.component';
import { QeTerminateComponent } from './questions/qe-terminate/qe-terminate.component';
import { QeBooleanComponent } from './questions/qe-boolean/qe-boolean.component';
import { QeRankingComponent } from './questions/qe-ranking/qe-ranking.component';
import { MatrixComponent } from './questions/matrix/matrix.component';
import { MaxdiffComponent } from './questions/maxdiff/maxdiff.component';
import { MediaComponent } from './questions/media/media.component';
import { RatingComponent } from './questions/rating/rating.component';
import { MatConfirmDialogComponent } from './mat-confirm-dialog/mat-confirm-dialog.component';
import { SendEmailComponent } from './questions/send-email/send-email.component';
import { SendSmsComponent } from './questions/send-sms/send-sms.component';
import { UserlistComponent } from './questions/userlist/userlist.component';
import { QeBucketComponent } from './questions/qe-bucket/qe-bucket.component';
import { ImageMapComponent } from './questions/image-map/image-map.component';
import { AdvancedRoutingComponent } from './questions/advanced-routing/advanced-routing.component';
import { ImgComponent } from './questions/qe-bucket/img/img.component';
import { DiyLandingComponent } from './diy-landing/diy-landing.component';
import { DiyLandingLayoutComponent } from './diy-landing-layout/diy-landing-layout.component';
import { SurveyDistributionComponent } from './survey-distribution/survey-distribution.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { QuestionActionButtonsComponent } from './question-action-buttons/question-action-buttons.component';
import { AnswerOptionsComponent } from './questions/answer-options/answer-options.component';
import { QuestionComboComponent } from './questions/question-combo/question-combo.component';
import { MatStepperModule } from '@angular/material/stepper';
import { SurveyDashboardComponent } from './survey-dashboard/survey-dashboard.component';
import { NameSurveyFromScratchComponent } from './name-survey-from-scratch/name-survey-from-scratch.component';
import { ImageOnlyAlertComponent } from './name-survey-from-scratch/image-only-alert/image-only-alert.component';
import { SmsToolsLandingPageComponent } from './sms-tools-landing-page/sms-tools-landing-page.component';
import { SelectRespondentsLandingPageComponent } from './select-respondents-landing-page/select-respondents-landing-page.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { QuillConfigModule, QuillModule } from 'ngx-quill';

import { SafePipe } from './safe.pipe';
import { InterviewPreviewComponent } from './interview-preview/interview-preview.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { OverlayModule } from '@angular/cdk/overlay';
import { ClipboardModule } from '@angular/cdk/clipboard'
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgxMaskModule } from 'ngx-mask';
import { BarRatingModule } from "ngx-bar-rating";
import { FeatureComponent } from './questions/feature/feature.component';
import { RoutingDialogComponent } from './questions/routing-dialog/routing-dialog.component';
import { SurveyFlowComponent } from './questions/survey-flow/survey-flow.component';
import { EmbedDataComponent } from './questions/embed-data/embed-data.component';
import { RandomiseComponent } from './questions/randomise/randomise.component';
import { PanelSummaryComponent } from './panels/panel-summary/panel-summary.component';
import { CaseDataService } from './casedata.Service';
import { ImageCropperModule } from 'ngx-image-cropper';
import { BubbleChartComponent } from './charts/bubble-chart/bubble-chart.component';
import { GaugeChartComponent } from './charts/gauge-chart/gauge-chart.component';
import { GridPieChartComponent } from './charts/grid-pie-chart/grid-pie-chart.component';
import { GroupedHorizontalChartComponent } from './charts/grouped-horizontal-chart/grouped-horizontal-chart.component';
import { GroupedVerticalChartComponent } from './charts/grouped-vertical-chart/grouped-vertical-chart.component';
import { HeatMapChartComponent } from './charts/heat-map-chart/heat-map-chart.component';
import { LineChartComponent } from './charts/line-chart/line-chart.component';
import { PieChartComponent } from './charts/pie-chart/pie-chart.component';
import { HorizontalChartComponent } from './charts/horizontal-chart/horizontal-chart.component';
import { VerticalChartComponent } from './charts/vertical-chart/vertical-chart.component';
import { TableChartComponent } from './charts/table-chart/table-chart.component';
import { ImageCropComponent } from './image-cropper/image-crop.component';
import { MediaService } from './media.Service';
import { PanelSelectorTemplateComponent } from './panel-selector-template/panel-selector-template.component';
import { AddUploadRespondentComponent } from './select-respondents-landing-page/add-upload-respondent/add-upload-respondent.component';
import { CommunicationsComponent } from './communications/communications.component';
import { OrganizationsComponent } from './organizations/organizations.component';
import { NewOrganizationComponent } from './new-organization/new-organization.component';
import { OrganizationDetailComponent } from './organization-detail/organization-detail.component';
import { InvitationsComponent } from './invitations/invitations.component';
import { InvitePeopleComponent } from './invite-people/invite-people.component';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { DiyMatDatetimePickerModule, DiyMatNativeDateModule, DiyMatTimepickerModule } from 'diy-surveys-datetime-picker';
import { ReportsComponent } from './reports/reports.component';
import { JoinorgComponent } from './joinorg/joinorg.component';
import { InviteLayoutComponent } from './invite-layout/invite-layout.component';
import { EditReportNameComponent } from './reports/edit-report-name/edit-report-name.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { VerifyComponent } from './verify/verify.component';
import { SelectedQuestionsComponent } from './reports/selected-questions/selected-questions.component';
import { ChartItemComponent } from './reports/chart-item/chart-item.component';
import { StackedHorizontalChartComponent } from './charts/stacked-horizontal-chart/stacked-horizontal-chart.component';
import { StackedVerticalChartComponent } from './charts/stacked-vertical-chart/stacked-vertical-chart.component';
import { InboxComponent } from './chat/inbox/inbox.component';
// import { CreditCardDirectivesModule } from 'angular-cc-library';
import { DownloadOptionsComponent } from './reports/download-options/download-options.component';
import { FinTransComponent } from './card-payment/fin-trans/fin-trans.component';
import { FinTranDetailComponent } from './card-payment/fin-tran-detail/fin-tran-detail.component';
import { SurveyStyleComponent } from './survey-style/survey-style.component';
import { ChangeUsernameComponent } from './user-details/change-username/change-username.component';
import { FileProcessComponent } from './file-upload/file-process/file-process.component';
import { FileStatusComponent } from './file-upload/file-status/file-status.component';
import { PanelProfileComponent } from './edit-panel/panel-profile/panel-profile.component';
import { PanelPricingComponent } from './edit-panel/panel-pricing/panel-pricing.component';
import { ChangeEmailComponent } from './user-details/change-email/change-email.component';
import { MessageBuilderComponent } from './survey-distribution/message-builder/message-builder.component';
import { PanelDialogComponent } from './panels/panel-dialog/panel-dialog.component';
import { DeliverySummaryComponent } from './delivery-summary/delivery-summary.component';
//import { AvatarModule } from 'ngx-avatars';
import { InterviewTestComponent } from './interview-test/interview-test.component';
import { DeleteDataComponent } from './reports/delete-data/delete-data.component';
import { BannerComponent } from './lib/elements/banner/banner.component';
import { BlockComponent } from './lib/elements/block/block.component';
import { BooleanComponent } from './lib/elements/boolean/boolean.component';
import { CanvasComponent } from './lib/elements/canvas/canvas.component';
import { CategoricalComponent } from './lib/elements/categorical/categorical.component';
import { CategoryComponent } from './lib/elements/category/category.component';
import { DataComponent } from './lib/elements/data/data.component';
import { DateComponent } from './lib/elements/date/date.component';
import { FloatComponent } from './lib/elements/float/float.component';
import { GeocodeComponent } from './lib/elements/geocode/geocode.component';
import { LabelComponent } from './lib/elements/label/label.component';
import { LoopCategoricalComponent } from './lib/elements/loop-categorical/loop-categorical.component';
import { MediaUploadComponent } from './lib/elements/media-upload/media-upload.component';
import { NumberComponent } from './lib/elements/number/number.component';
import { PageComponent } from './lib/elements/page/page.component';
import { RankingComponent } from './lib/elements/ranking/ranking.component';
import { TextComponent } from './lib/elements/text/text.component';
import { OneScriptAngularPlayerComponent } from './lib/one-script-angular-player.component';
import { LoopNumericComponent } from './lib/elements/loop-numeric/loop-numeric.component';
import { TerminateComponent } from './lib/elements/terminate/terminate.component';
import { MaxDiffComponent } from './lib/elements/maxdiff/maxdiff.component';
import { BucketComponent } from './lib/elements/bucket/bucket.component';
import { NavbarComponent } from './lib/elements/navbar/navbar.component';
import { TinyurlsComponent } from './tinyurls/tinyurls.component';
import { TinyurlDetailComponent } from './tinyurl-detail/tinyurl-detail.component';
import { TinyUrlService } from './tinyUrl.Service';
import { CategoryListComponent } from './panel-selector/category-list/category-list.component';
import { EditOrgNameComponent } from './organization-detail/edit-org-name/edit-org-name.component';
import { CcPaymentComponent } from './credit-card/cc-payment/cc-payment.component';
import { CcBalanceComponent } from './credit-card/cc-balance/cc-balance.component';
import { CountryComboComponent } from './country-combo/country-combo.component';
import { CcCardComponent } from './credit-card/cc-card/cc-card.component';
import { PricingSummaryComponent } from './edit-panel/pricing-summary/pricing-summary.component';
import { AdvancedPropertiesComponent } from './questions/advanced-properties/advanced-properties.component';
import { AdvancedAnswerOptionComponent } from './questions/advanced-answer-option/advanced-answer-option.component';
import { JoyrideModule } from 'ngx-joyride';
import { RunByQrcodeComponent } from './interview-preview/run-by-qrcode/run-by-qrcode.component';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';
import { TermsOfServiceLayoutComponent } from './terms-of-service-layout/terms-of-service-layout.component';
import { PrivacyStatementComponent } from './privacy-statement/privacy-statement.component';
import { PrivacyStatementLayoutComponent } from './privacy-statement-layout/privacy-statement-layout.component';
import { FeatureTourDialogComponent } from './feature-tour-dialog/feature-tour-dialog.component';
import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeEnGB from '@angular/common/locales/en-GB';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';
import localeIs from '@angular/common/locales/extra/is';
import { DayMonthPickerComponent, DayMonthHeader } from './scheduler/day-month-picker/day-month-picker.component';
import { TimePickerComponent } from './scheduler/time-picker/time-picker.component';
import { AdvancedTimingsComponent } from './scheduler/advanced-timings/advanced-timings.component';
import { TimezonePickerComponent } from './scheduler/timezone-picker/timezone-picker.component';
import { TimezonePickerService } from './scheduler/timezone-picker/timezone.service';
import { TimezonePickerPipe } from './scheduler/timezone-picker/TimezonePickerPipe';
import { FinancialService } from './finacial.Service';
import { ComposeMessageComponent } from './chat/compose-message/compose-message.component';
import { SendComponent } from './Development/send/send.component';
import { WebhooksComponent } from './Development/webhooks/webhooks.component';
import { MessageService } from './message.Service';
import { KeysComponent } from './Development/keys/keys.component';
import { WebHookService } from './webhook.Service';
import { WebhookDetailsComponent } from './Development/webhook-details/webhook-details.component';
import { KeyDetailsComponent } from './Development/key-details/key-details.component';
import { MetadataTableComponent } from './Development/metadata-entrytable/metadata-table.component';
import { WindowComponent } from './Development/window-entry/window.component';
import { KeywordsComponent } from './sms-tools-landing-page/keywords/keywords.component';
import { KeywordService } from './keyword.Service';
import { KeywordDetailsComponent } from './sms-tools-landing-page/keyword-details/keyword-details.component';
import { MessageHistoryComponent } from './sms-tools-landing-page/message-history/message-history.component';
import { SmsEditorComponent } from './Development/sms-editor/sms-editor.component';
import { UserSearchComponent } from './find-user/user-search/user-search.component';
import { WordCloudChartComponent } from './charts/word-cloud-chart/word-cloud-chart.component';
import { TwoFactorAutenticationComponent } from './user-details/two-factor-autentication/two-factor-autentication.component';
import { SocialLoginModule, SocialAuthServiceConfig, GoogleLoginProvider, GoogleSigninButtonModule, FacebookLoginProvider } from '@abacritt/angularx-social-login';
import { NoDblClickDirectiveMat } from '../no-dbl-click-mat.directive';
import { AswQrCodeModule } from '@asoftwareworld/qrcode';
import { EditorComponent } from './Development/editor/editor.component';
import { DataAnalysisComponent } from './data-analysis/data-analysis.component';
import { BasicDataComponent } from './data-analysis/basic-data/basic-data.component';
import { ExportFiltersComponent } from './data-analysis/export-filters/export-filters.component';
import { TextAnalysisComponent } from './data-analysis/text-analysis/text-analysis.component';
import { SendWhatsappComponent } from './Development/send-whatsapp/send-whatsapp.component';
import { CustomFormatComponent } from './data-analysis/custom-format/custom-format.component';
import { FieldDefinitionComponent } from './data-analysis/custom-format/field-definition/field-definition.component';
import { ExportAutomationComponent } from './data-analysis/export-automation/export-automation.component';
import { FieldSelectorComponent } from './data-analysis/custom-format/field-selector/field-selector.component';
import { TaskSummaryComponent } from './data-analysis/task-summary/task-summary.component';
import { ConfiguredProcessesComponent } from './data-analysis/configured-processes/configured-processes.component';
import { FtpSetupComponent } from './data-analysis/ftp-setup/ftp-setup.component';
import { InviteioComponent } from './data-analysis/inviteio/inviteio.component';
import { TaskSummaryDialogComponent } from './data-analysis/task-summary-dialog/task-summary-dialog.component';
import { ConfiguredProcessesDialogComponent } from './data-analysis/configured-processes-dialog/configured-processes-dialog.component';
import { AvatarComponent } from './user-menu/avatar/avatar.component';
import { DevelopmentComponent } from './Development/development.component';
import { MatrixTableComponent } from './charts/matrix-table/matrix-table.component';
import { NewhomeComponent } from './newhome/newhome.component';
import { SurveySchedularComponent } from './data-analysis/survey-schedular/survey-schedular.component';
import { ReviewDataComponent } from './data-analysis/review-data/review-data.component';
import { SurveyPropertiesComponent } from './data-analysis/survey-properties/survey-properties.component';
import { TransferItemComponent } from './transfer-item/transfer-item.component';
import { SmsHomeComponent } from './sms-home/sms-home.component';
import { OrgSummaryComponent } from './organizations/org-summary/org-summary.component';
import { FavoriteOrgComponent } from './organizations/favorite-org/favorite-org.component';
import { AppearDirective } from './AppearDirective';
import { CountUpDirective } from './countup.directive';
import { TrafficAnalysisComponent } from './sms-tools-landing-page/message-history/traffic-analysis/traffic-analysis.component';
import { ManageNetworkComponent } from './network/manage-network/manage-network.component';
import { ManageRequestComponent } from './network/manage-request/manage-request.component';
import { NetworkStatusComponent } from './network/network-status/network-status.component';
import { MessageEditorComponent } from './network/message-editor/message-editor.component';
import { NetworkService } from './network.Service';
import { SafePipeModule } from 'safe-pipe';
import { ManageMultiRequestComponent } from './network/manage-multi-request/manage-multi-request.component';
import { NetworkManagementComponent } from './network/network-management/network-management.component';
import { NetworkDetailComponent } from './network/network-detail/network-detail.component';
import { CheckNumberComponent } from './Development/check-number/check-number.component';
import { DeleteLiveDataComponent } from './reports/delete-live-data/delete-live-data.component';
import { AddQuestionComponent } from './questions/add-question/add-question.component';
import { StartFromTemplateNewComponent } from './start-from-template-new/start-from-template-new.component';
import { TwoFactorTestComponent } from './user-details/two-factor-test/two-factor-test.component';
import { FieldSelectDialogComponent } from './data-analysis/custom-format/field-select-dialog/field-select-dialog.component';
import { LanguageSummaryComponent } from './languages/language-summary/language-summary.component';
import { UploadLanguagesComponent } from './languages/upload-languages/upload-languages.component';
import { AddLanguageComponent } from './languages/add-language/add-language.component';
import { LanguageDropdownComponent } from './lib/elements/language-dropdown/language-dropdown.component';
import { LanguageEditorComponent } from './languages/language-editor/language-editor.component';
import { AddQuestionButtonComponent } from './question-action-buttons/add-question-button/add-question-button.component';
import { NgxColorsModule } from 'ngx-colors';
import { SurveySummaryComponent } from './data-analysis/survey-summary/survey-summary.component';
import { AutomationsComponent } from './data-analysis/automations/automations.component';
import { ProcessDefinitionComponent } from './data-analysis/process-definition/process-definition.component';
import { GatewayDefinitionComponent } from './routing/gateway-definition/gateway-definition.component';
import { CommEditorComponent } from './communications/comm-editor/comm-editor.component';
import { CommListComponent } from './communications/comm-list/comm-list.component';
import { TableTerminateComponent } from './charts/table-terminate/table-terminate.component';
import { WhatsappApplicationComponent } from './communications/whatsapp-application/whatsapp-application.component';
import { ManageWhatsappComponent } from './communications/manage-whatsapp/manage-whatsapp.component';
import { WhatappPartComponent } from './communications/whatapp-part/whatapp-part.component';
import { DataioComponent } from './data-analysis/dataio/dataio.component';
import { UserOrgSearchComponent } from './find-user/user-org-search/user-org-search.component';
import { ZendeskComponent } from './zendesk/zendesk.component';
import { SystemDashboardComponent } from './system-dashboard/system-dashboard.component';
import { InviteAnalysisComponent } from './system-dashboard/invite-analysis/invite-analysis.component';
import { ActiveSurveysComponent } from './system-dashboard/active-surveys/active-surveys.component';
import { ActiveDataioComponent } from './system-dashboard/active-dataio/active-dataio.component';
import { ActiveUsersComponent } from './system-dashboard/active-users/active-users.component';
import { GatewayActivityComponent } from './system-dashboard/gateway-activity/gateway-activity.component';
import { RoutingSummaryComponent } from './routing/routing-summary/routing-summary.component';
import { MessageQueueComponent } from './routing/message-queue/message-queue.component';
import { ServiceListComponent } from './routing/service-list/service-list.component';
import { PivotTableComponent } from './sms-tools-landing-page/pivot-table/pivot-table.component';
import { NewsignupComponent } from './newhome/newsignup/newsignup.component';
import { NewloginComponent } from './newhome/newlogin/newlogin.component';

registerLocaleData(localeEn, 'en');
registerLocaleData(localeEnGB, 'en-GB');
registerLocaleData(localeDe, 'de', localeDeExtra);
registerLocaleData(localeFr, 'fr', localeFrExtra);
registerLocaleData(localeIs, 'is');

export function tokenGetter() {
  return localStorage.getItem("jwt");
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SchedulerComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    ToolbarComponent,
    HomeLayoutComponent,
    NavigationComponent,
    LoginLayoutComponent,
    ResetPasswordComponent,
    SettingsComponent,
    BreadcrumbComponent,
    UsersComponent,
    UserDetailsComponent,
    PanelsComponent,
    SnackbarDefaultComponent,
    NewPanelComponent,
    EditPanelComponent,
    SurveyDetailsLayoutComponent,
    SurveyDetailsNavigationComponent,
    DayMonthHeader,
    AudienceComponent,
    EditCommunicationComponent,
    EditToolbarComponent,
    EditLayoutComponent,
    QuestionEditorComponent,
    QuestionsComponent,
    RandomiseQuestionsComponent,
    QuestionPropertiesComponent,
    SystemComponent,
    QuestionnaireSidebarComponent,
    EditPanelistComponent,
    SmsFeaturesComponent,
    FindUserComponent,
    PanelSelectorComponent,
    CategorySelectorComponent,
    CategoryButtonComponent,
    RangeBoxComponent,
    HelpComponent,
    CintMapperComponent,
    InformationComponent,
    McmaComponent,
    McsaComponent,
    QeBlockComponent,
    QuestionLibraryComponent,
    RangerComponent,
    RangeBoxComponent,
    CintMapperComponent,
    TextboxComponent,
    EmailComponent,
    QeGeocodeComponent,
    BarcodeComponent,
    IntegerComponent,
    QeDateComponent,
    CurrencyComponent,
    QeTerminateComponent,
    QeBooleanComponent,
    QeRankingComponent,
    MatrixComponent,
    MaxdiffComponent,
    MediaComponent,
    RatingComponent,
    MatConfirmDialogComponent,
    SendEmailComponent,
    SendSmsComponent,
    UserlistComponent,
    QeBucketComponent,
    ImageMapComponent,
    AdvancedRoutingComponent,
    ImgComponent,
    DiyLandingComponent,
    DiyLandingLayoutComponent,
    SurveyDistributionComponent,
    QuestionActionButtonsComponent,
    AnswerOptionsComponent,
    QuestionComboComponent,
    SurveyDashboardComponent,
    NameSurveyFromScratchComponent,
    ImageOnlyAlertComponent,
    SafePipe,
    NameSurveyFromScratchComponent,
    SmsToolsLandingPageComponent,
    SelectRespondentsLandingPageComponent,
    InterviewPreviewComponent,
    FeatureComponent,
    RoutingDialogComponent,
    SurveyFlowComponent,
    EmbedDataComponent,
    RandomiseComponent,
    PanelSummaryComponent,
    BubbleChartComponent,
    GaugeChartComponent,
    GridPieChartComponent,
    GroupedHorizontalChartComponent,
    GroupedVerticalChartComponent,
    HeatMapChartComponent,
    LineChartComponent,
    PieChartComponent,
    HorizontalChartComponent,
    VerticalChartComponent,
    TableChartComponent,
    ImageCropComponent,
    PanelSelectorTemplateComponent,
    AddUploadRespondentComponent,
    CommunicationsComponent,
    OrganizationsComponent,
    NewOrganizationComponent,
    OrganizationDetailComponent,
    InvitationsComponent,
    InvitePeopleComponent,
    UserMenuComponent,
    ReportsComponent,
    JoinorgComponent,
    InviteLayoutComponent,
    EditReportNameComponent,
    FileUploadComponent,
    VerifyComponent,
    SelectedQuestionsComponent,
    ChartItemComponent,
    StackedHorizontalChartComponent,
    StackedVerticalChartComponent,
    InboxComponent,
    DownloadOptionsComponent,
    FinTransComponent,
    FinTranDetailComponent,
    SurveyStyleComponent,
    ChangeUsernameComponent,
    FileProcessComponent,
    FileStatusComponent,
    PanelProfileComponent,
    PanelPricingComponent,
    ChangeEmailComponent,
    MessageBuilderComponent,
    PanelDialogComponent,
    DeliverySummaryComponent,
    InterviewTestComponent,
    DeleteDataComponent,
    BannerComponent,
    BlockComponent,
    BooleanComponent,
    CanvasComponent,
    CategoricalComponent,
    CategoryComponent,
    DataComponent,
    DateComponent,
    FloatComponent,
    GeocodeComponent,
    LabelComponent,
    LoopCategoricalComponent,
    LoopNumericComponent,
    MediaUploadComponent,
    NumberComponent,
    PageComponent,
    RankingComponent,
    TextComponent,
    TerminateComponent,
    MaxDiffComponent,
    OneScriptAngularPlayerComponent,
    BucketComponent,
    NavbarComponent,
    TinyurlsComponent,
    TinyurlDetailComponent,
    CategoryListComponent,
    EditOrgNameComponent,
    CcPaymentComponent,
    CcBalanceComponent,
    CountryComboComponent,
    CcCardComponent,
    PricingSummaryComponent,
    AdvancedPropertiesComponent,
    AdvancedAnswerOptionComponent,
    RunByQrcodeComponent,
    TermsOfServiceComponent,
    TermsOfServiceLayoutComponent,
    PrivacyStatementComponent,
    PrivacyStatementLayoutComponent,
    FeatureTourDialogComponent,
    DayMonthPickerComponent,
    TimePickerComponent,
    AdvancedTimingsComponent,
    TimezonePickerComponent,
    TimezonePickerPipe,
    ComposeMessageComponent,
    SendComponent,
    WebhooksComponent,
    KeysComponent,
    WebhookDetailsComponent,
    KeyDetailsComponent,
    MetadataTableComponent,
    WindowComponent,
    KeywordsComponent,
    KeywordDetailsComponent,
    MessageHistoryComponent,
    SmsEditorComponent,
    UserSearchComponent,
    WordCloudChartComponent,
    TwoFactorAutenticationComponent,
    NoDblClickDirectiveMat,
    EditorComponent,
    DataAnalysisComponent,
    BasicDataComponent,
    ExportFiltersComponent,
    TextAnalysisComponent,
    SendWhatsappComponent,
    CustomFormatComponent,
    FieldDefinitionComponent,
    ExportAutomationComponent,
    FieldSelectorComponent,
    TaskSummaryComponent,
    ConfiguredProcessesComponent,
    FtpSetupComponent,
    InviteioComponent,
    TaskSummaryDialogComponent,
    ConfiguredProcessesDialogComponent,
    AvatarComponent,
    DevelopmentComponent,
    MatrixTableComponent,
    NewhomeComponent,
    NewsignupComponent,
    NewloginComponent,
    SurveySchedularComponent,
    ReviewDataComponent,
    SurveyPropertiesComponent,
    TransferItemComponent,
    SmsHomeComponent,
    OrgSummaryComponent,
    FavoriteOrgComponent,
    AppearDirective,
    CountUpDirective,
    TrafficAnalysisComponent,
    ManageNetworkComponent,
    ManageRequestComponent,
    NetworkStatusComponent,
    MessageEditorComponent,
    ManageMultiRequestComponent,
    NetworkManagementComponent,
    NetworkDetailComponent,
    CheckNumberComponent,
    DeleteLiveDataComponent,
    AddQuestionComponent,
    StartFromTemplateNewComponent,
    TwoFactorTestComponent,
    FieldSelectDialogComponent,
    LanguageSummaryComponent,
    UploadLanguagesComponent,
    AddLanguageComponent,
    LanguageDropdownComponent,
    LanguageEditorComponent,
    AddQuestionButtonComponent,
    SurveySummaryComponent,
    AutomationsComponent,
    ProcessDefinitionComponent,
    GatewayDefinitionComponent,
    CommEditorComponent,
    CommListComponent,
    TableTerminateComponent,
    WhatsappApplicationComponent,
    ManageWhatsappComponent,
    WhatappPartComponent,
    DataioComponent,
    UserOrgSearchComponent,
    ZendeskComponent,
    SystemDashboardComponent,
    InviteAnalysisComponent,
    ActiveSurveysComponent,
    ActiveDataioComponent,
    ActiveUsersComponent,
    GatewayActivityComponent,
    RoutingSummaryComponent,
    MessageQueueComponent,
    ServiceListComponent,
    PivotTableComponent
  ],
  imports: [
    SafePipeModule,
    ClipboardModule,
    OverlayModule,
    QuillConfigModule.forRoot({
      suppressGlobalRegisterWarning: true
    }),
    QuillModule.forRoot({
    }),

    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    TranslateModule.forRoot(),
    HttpClientModule,
    SocialLoginModule,
    MatChipsModule,
    DiyMatDatetimePickerModule,
    DiyMatNativeDateModule,
    DiyMatTimepickerModule,
    ImageCropperModule,
    MatAutocompleteModule,
    ScrollingModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatExpansionModule,
    DragDropModule,
    MatTreeModule,
    MatProgressBarModule,
    MatStepperModule,
    MatBottomSheetModule,
    FormsModule,
    ReactiveFormsModule,
    NgxChartsModule,
    NgxColorsModule,
    GoogleSigninButtonModule,
    //CreditCardDirectivesModule,
    JoyrideModule.forRoot(),
    AswQrCodeModule,
    RouterModule.forRoot(
      [
        { path: '', redirectTo: 'diysurveys', data: { title: 'DIYSurveys Component' }, pathMatch: 'full' },
        {
          path: 'newhome',
          component: NewhomeComponent,
          data: { title: 'DIYSurveys Home Component' },
        },
        {
          path: 'newsignup',
          component: NewsignupComponent,
          data: { title: 'DIYSurveys Signup Component' },
        },
        {
          path: 'newlogin',
          component: NewloginComponent,
          data: { title: 'DIYSurveys Login Component' },
        },
        {
          path: 'diysurveys',
          component: DiyLandingLayoutComponent,
          data: { title: 'DIYSurveys Home Component' },
          children: [
            {
              path: '',
              component: DiyLandingComponent,
              data: {
                breadcrumb: 'DIYSurveys Home',
              }
            }
          ]
        },
        {
          path: 'termsofservice',
          component: TermsOfServiceLayoutComponent,
          data: { title: 'DIYSurveys Terms of Service Component' },
          children: [
            {
              path: '',
              component: TermsOfServiceComponent,
              data: {
                breadcrumb: 'DIYSurveys Terms of Service',
              }
            }
          ]
        },
        {
          path: 'privacystatement',
          component: PrivacyStatementLayoutComponent,
          data: { title: 'DIYSurveys Privacy Statement Component' },
          children: [
            {
              path: '',
              component: PrivacyStatementComponent,
              data: {
                breadcrumb: 'DIYSurveys Privacy Statement',
              }
            }
          ]
        },
        {
          path: 'login',
          component: LoginLayoutComponent,
          data: { title: 'Login Component' },
          children: [
            {
              path: '',
              component: LoginComponent,
              data: {
                breadcrumb: 'Login',
              }
            }
          ]
        },
        {
          path: 'signup',
          component: LoginLayoutComponent,
          data: { title: 'SignUp Component' },
          children: [
            {
              path: '',
              component: RegisterComponent,
              data: {
                breadcrumb: 'Register',
              }
            }
          ]
        },
        {
          path: 'joinorg',
          component: InviteLayoutComponent,
          data: { title: 'Join Org Component' },
          children: [
            {
              path: '',
              component: JoinorgComponent,
              data: {
                breadcrumb: 'Join Organization',
              }
            }
          ]
        },
        {
          path: '2fa',
          component: InviteLayoutComponent,
          data: { title: '2FA Component' },
          children: [
            {
              path: '',
              component: TwoFactorTestComponent,
              data: {
                breadcrumb: '2fa',
              }
            }
          ]
        },
        {
          path: 'verify',
          component: InviteLayoutComponent,
          data: { title: 'Verify Component' },
          children: [
            {
              path: '',
              component: VerifyComponent,
              data: {
                breadcrumb: 'Verify',
              }
            }
          ]
        },
        {
          path: 'forgotpassword',
          component: LoginLayoutComponent,
          data: { title: 'ForgotPassword Component' },
          children: [
            {
              path: '',
              component: ForgotPasswordComponent,
              data: {
                breadcrumb: 'Forgot Password',
              }
            }
          ]
        },
        {
          path: 'resetpassword',
          component: LoginLayoutComponent,
          data: { title: 'ResetPassword Component' },
          children: [
            {
              path: '',
              component: ResetPasswordComponent,
              data: {
                breadcrumb: 'Reset Password',
              }
            }
          ]
        },
        {
          path: 'help',
          component: HomeLayoutComponent,
          data: { title: 'Help Component' },
          children: [
            {
              path: '',
              component: HelpComponent,
              data: {
                allowedRoles: ['Admin', 'sa'],
                breadcrumb: 'Help',
              },
              canActivate: [AuthGuard],
            }
          ]
        },
        {
          path: 'profile',
          component: HomeLayoutComponent,
          data: { title: 'Profile Component' },
          children: [
            {
              path: '',
              component: UserDetailsComponent,
              data: {
                allowedRoles: ['Admin', 'sa'],
                breadcrumb: 'Profile',
              },
              canActivate: [AuthGuard],
            }
          ]
        },
        {
          path: 'profile/:id',
          component: HomeLayoutComponent,
          data: { title: 'Profile Component' },
          children: [
            {
              path: '',
              component: UserDetailsComponent,
              data: {
                allowedRoles: ['Admin', 'sa'],
                breadcrumb: 'Profile Organization',
              },
              canActivate: [AuthGuard],
            }
          ]
        },
        {
          path: 'processes',
          component: HomeLayoutComponent,
          data: { title: 'Processes' },
          children: [
            {
              path: '',
              component: FileStatusComponent,
              data: {
                allowedRoles: ['Admin', 'sa'],
                breadcrumb: 'Profile',
              },
              canActivate: [AuthGuard],
            }
          ]
        },
        {
          path: 'settings',
          component: HomeLayoutComponent,
          data: { title: 'Settings Component' },
          children: [
            {
              path: '',
              component: SettingsComponent,
              data: {
                allowedRoles: ['Admin', 'sa'],
                breadcrumb: 'Settings',
              },
              canActivate: [AuthGuard],
            }
          ]
        },
        {
          path: 'tinyurls',
          component: HomeLayoutComponent,
          data: { title: 'TinyURLs Component' },
          children: [
            {
              path: '',
              component: TinyurlsComponent,
              data: {
                allowedRoles: ['Admin', 'sa'],
                breadcrumb: 'TinyURLs',
              },
              canActivate: [AuthGuard],
            }
          ]
        },
        {
          path: 'tinyurl/:id/:type/:userid',
          component: EditLayoutComponent,
          data: { title: 'TinyUrl Component' },
          children: [
            {
              path: '',
              component: TinyurlDetailComponent,
              data: {
                allowedRoles: ['Admin', 'sa'],
                breadcrumb: 'Edit Tiny URL',
              },
              canActivate: [AuthGuard],
            }
          ]
        },
        {
          path: 'inbox',
          component: HomeLayoutComponent,
          data: { title: 'Inbox Component' },
          children: [
            {
              path: '',
              component: InboxComponent,
              data: {
                allowedRoles: ['Admin', 'sa'],
                breadcrumb: 'Inbox',
              },
              canActivate: [AuthGuard],
            }
          ]
        },
        {
          path: 'respondents',
          component: HomeLayoutComponent,
          data: { title: 'Panel Component' },
          children: [
            {
              path: '',
              component: PanelsComponent,
              data: {
                allowedRoles: ['Admin', 'sa'],
                breadcrumb: 'Respondents',
              },
              canActivate: [AuthGuard],
            }
          ]
        },
        {
          path: 'transfer/:id/:type',
          component: HomeLayoutComponent,
          data: { title: 'Transfer Item Component' },
          children: [
            {
              path: '',
              component: TransferItemComponent,
              data: {
                allowedRoles: ['Admin', 'sa'],
                breadcrumb: 'Transfer Item',
              },
              canActivate: [AuthGuard],
            }
          ]
        },
        {
          path: 'edit-respondents/:id/:type',
          component: EditLayoutComponent,
          data: { title: 'Edit-Panel Component' },
          children: [
            {
              path: '',
              component: EditPanelComponent,
              data: {
                allowedRoles: ['Admin', 'sa'],
                breadcrumb: 'Edit Respondents',
              },
              canActivate: [AuthGuard],
            }
          ]
        },
        {
          path: 'edit-respondent/:id/:pid/:type',
          component: EditLayoutComponent,
          data: { title: 'Edit-Panelist Component' },
          children: [
            {
              path: '',
              component: EditPanelistComponent,
              data: {
                allowedRoles: ['Admin', 'sa'],
                breadcrumb: 'Edit Respondent',
              },
              canActivate: [AuthGuard],
            }
          ]
        },
        {
          path: 'communications',
          component: HomeLayoutComponent,
          data: { title: 'Communications Component' },
          children: [
            {
              path: '',
              component: CommListComponent,
              data: {
                allowedRoles: ['Admin', 'sa'],
                breadcrumb: 'Communications',
              },
              canActivate: [AuthGuard],
            }
          ]
        },
        {
          path: 'dataio',
          component: HomeLayoutComponent,
          data: { title: 'Data IO Component' },
          children: [
            {
              path: '',
              component: DataioComponent,
              data: {
                allowedRoles: ['Admin', 'sa'],
                breadcrumb: 'Data IO',
              },
              canActivate: [AuthGuard],
            }
          ]
        },
        {
          path: 'fintrans',
          component: HomeLayoutComponent,
          data: { title: 'Financial Transactions Component' },
          children: [
            {
              path: '',
              component: FinTransComponent,
              data: {
                allowedRoles: ['Admin', 'sa'],
                breadcrumb: 'Financial Transactions',
              },
              canActivate: [AuthGuard],
            }
          ]
        },
        {
          path: 'fintrans/:id',
          component: HomeLayoutComponent,
          data: { title: 'Financial Transactions Component' },
          children: [
            {
              path: '',
              component: FinTransComponent,
              data: {
                allowedRoles: ['Admin'],
                breadcrumb: 'Financial Transactions',
              },
              canActivate: [AuthGuard],
            }
          ]
        },
        {
          path: 'organizations',
          component: HomeLayoutComponent,
          data: { title: 'Organizations Component' },
          children: [
            {
              path: '',
              component: OrganizationsComponent,
              data: {
                allowedRoles: ['Admin' ],
                breadcrumb: 'Organizations',
              },
              canActivate: [AuthGuard],
            }
          ]
        },
        {
          path: 'adminorgs',
          component: HomeLayoutComponent,
          data: { title: 'Organizations Component' },
          children: [
            {
              path: '',
              component: OrganizationsComponent,
              data: {
                allowedRoles: ['Admin'],
                breadcrumb: 'Admin Organizations',
              },
              canActivate: [AuthGuard],
            }
          ]
        },
        {
          path: 'zendesk',
          component: HomeLayoutComponent,
          data: { title: 'ZenDesk Component' },
          children: [
            {
              path: '',
              component: ZendeskComponent,
              data: {
                allowedRoles: ['Admin', 'sa'],
                breadcrumb: 'Zendesk',
              },
              canActivate: [AuthGuard],
            }
          ]
        },
        {
          path: 'neworganization',
          component: HomeLayoutComponent,
          data: { title: 'History Component' },
          children: [
            {
              path: '',
              component: NewOrganizationComponent,
              data: {
                allowedRoles: ['Admin', 'sa'],
                breadcrumb: 'New Organization',
              },
              canActivate: [AuthGuard],
            }
          ]
        },
        {
          path: 'organization/:id/:type',
          component: EditLayoutComponent,
          data: { title: 'Organization Component' },
          children: [
            {
              path: '',
              component: OrganizationDetailComponent,
              data: {
                allowedRoles: ['Admin', 'sa'],
                breadcrumb: 'Edit Organization',
              },
              canActivate: [AuthGuard],
            }
          ]
        },
        {
          path: 'edittemplate/:id/:type',
          component: EditLayoutComponent,
          data: { title: 'Edit Communication Component' },
          children: [
            {
              path: '',
              component: EditCommunicationComponent,
              data: {
                allowedRoles: ['Admin', 'sa'],
                breadcrumb: 'Edit Template',
              },
              canActivate: [AuthGuard],
            }
          ]
        },
        {
          path: 'routing-dashboard',
          component: HomeLayoutComponent,
          data: { title: 'Routing Dashboard' },
          children: [
            {
              path: '',
              component: RoutingSummaryComponent,
              data: {
                allowedRoles: ['Admin'],
                breadcrumb: 'Routing Dashbaord',
              },
              canActivate: [AuthGuard],
            }
          ]
        },
        {
          path: 'system-dashboard',
          component: HomeLayoutComponent,
          data: { title: 'System Component' },
          children: [
            {
              path: '',
              component: SystemDashboardComponent,
              data: {
                allowedRoles: ['Admin'],
                breadcrumb: 'System Dashbaord',
              },
              canActivate: [AuthGuard],
            }
          ]
        },
        {
          path: 'system',
          component: HomeLayoutComponent,
          data: { title: 'System Component' },
          children: [
            {
              path: '',
              component: SystemComponent,
              data: {
                allowedRoles: ['Admin'],
                breadcrumb: 'System',
              },
              canActivate: [AuthGuard],
            }
          ]
        },
        {
          path: 'users',
          component: HomeLayoutComponent,
          data: { title: 'User Component' },
          children: [
            {
              path: '',
              component: UsersComponent,
              data: {
                allowedRoles: ['Admin'],
                breadcrumb: 'Users',
              },
              canActivate: [AuthGuard],
            }
          ]
        },
        {
          path: 'cint-mapper',
          component: HomeLayoutComponent,
          data: { title: 'Cint Mapper' },
          children: [
            {
              path: '',
              component: CintMapperComponent,
              data: {
                allowedRoles: ['Admin'],
                breadcrumb: 'Cint Mapper',
              },
              canActivate: [AuthGuard],
            }
          ]
        },
        {
          path: 'edituser/:id/:type',
          component: EditLayoutComponent,
          data: { title: 'Edit User Component' },
          children: [
            {
              path: '',
              component: UserDetailsComponent,
              data: {
                allowedRoles: ['Admin'],
                breadcrumb: 'Edit User',
              },
              canActivate: [AuthGuard],
            }
          ],
      },
      {
        path: 'reports/:id/:type',
        component: EditLayoutComponent,
        data: { title: 'Reports Component' },
        children: [
          {
            path: '',
            component: ReportsComponent,
            data: {
              allowedRoles: ['Admin', 'sa'],
              breadcrumb: 'Reports',
            },
            canActivate: [AuthGuard],
          }
        ]
      },
      {
        path: 'survey-properties/:id/:type',
        component: EditLayoutComponent,
        data: { title: 'Survey Properties' },
        children: [
          {
            path: '',
            component: SurveyPropertiesComponent,
            data: {
              allowedRoles: ['Admin', 'sa'],
              breadcrumb: 'Survey-Properties',
            },
            canActivate: [AuthGuard],
          }
        ]
      },
      {
        path: 'delivery-summary/:id/:type',
        component: EditLayoutComponent,
        data: { title: 'Summary Component' },
        children: [
          {
            path: '',
            component: DataAnalysisComponent,
            data: {
              allowedRoles: ['Admin', 'sa'],
              breadcrumb: 'Delivery-Summary',
            },
            canActivate: [AuthGuard],
          }
        ]
      },
      {
        path: 'questioneditor/:id/:type',
        component: EditLayoutComponent,
        data: { title: 'Question Editor Component' },
        children: [
          {
            path: '',
            component: QuestionEditorComponent,
            data: {
              allowedRoles: ['Admin', 'sa'],
              breadcrumb: 'Question Editor',
            },
            canActivate: [AuthGuard],
          }
        ]
      },
      {
        path: 'questioneditor',
        component: EditLayoutComponent,
        data: { title: 'Question Editor Component' },
        children: [
          {
            path: '',
            component: QuestionEditorComponent,
            data: {
              allowedRoles: ['Admin', 'sa'],
              breadcrumb: 'Question Editor',
            },
            canActivate: [AuthGuard],
          }
        ]
      },
      {
        path: 'surveyquestioneditor/:id/:type',
        component: SurveyDetailsLayoutComponent,
        data: { title: 'Question Editor Component' },
        children: [
          {
            path: '',
            component: QuestionEditorComponent,
            data: {
              allowedRoles: ['Admin', 'sa'],
              breadcrumb: 'Question Editor',
            },
            canActivate: [AuthGuard],
          }
        ]
      },
      {
        path: 'respondents-template/:id/:type',
        component: EditLayoutComponent,
        data: { title: 'Respondents Template Component' },
        children: [
          {
            path: '',
            component: PanelSelectorComponent,
            data: {
              allowedRoles: ['Admin', 'sa'],
              breadcrumb: 'Respondents',
            },
            canActivate: [AuthGuard]
          }
        ]
      },
      {
        path: 'uploadRespondents-template/:id/:type',
        component: EditLayoutComponent,
        data: { title: 'Respondents Template Component' },
        children: [
          {
            path: '',
            component: AddUploadRespondentComponent,
            data: {
              allowedRoles: ['Admin', 'sa'],
              breadcrumb: 'Respondents',
            },
            canActivate: [AuthGuard]
          }
        ]
      },
      {
        path: 'distribution/:id',
        component: SurveyDetailsLayoutComponent,
        data: { title: 'Distribution Component' },
        children: [
          {
            path: '',
            component: SurveyDistributionComponent,
            data: {
              allowedRoles: ['Admin', 'sa'],
              breadcrumb: 'Distribution',
            },
            canActivate: [AuthGuard]
          }
        ]
      },
      {
        path: 'activenumber',
        component: HomeLayoutComponent,
        data: { title: 'DIYSurveys Active Number Checker' },
        children: [
          {
            path: '',
            component: CheckNumberComponent,
            data: { allowedRoles: ['Admin'], breadcrumb: 'Active Number' },
            canActivate: [AuthGuard]
          }
        ]
      },
      {
        path: 'networkmanager',
        component: HomeLayoutComponent,
        data: { title: 'DIYSurveys Network Management Component' },
        children: [
          {
            path: '',
            component: NetworkManagementComponent,
            data: { allowedRoles: ['Admin', 'sms'], breadcrumb: 'Network Management' },
            canActivate: [AuthGuard]
          }
        ]
      },
      {
        path: 'network',
        component: HomeLayoutComponent,
        data: { title: 'DIYSurveys Network Management Component' },
        children: [
          {
            path: '',
            component: ManageNetworkComponent,
            data: { allowedRoles: ['Admin', 'sms'], breadcrumb: 'Network Management' },
            canActivate: [AuthGuard]
          }
        ]
      },
      {
        path: 'networkdetail/:id/:type',
        component: EditLayoutComponent,
        data: { title: 'Main Component' },
        children: [
          {
            path: '',
            component: NetworkDetailComponent,
            data: { allowedRoles: ['Admin', 'sms'], breadcrumb: 'Network Management' },
            canActivate: [AuthGuard]
          }
        ]
      },
      {
        path: 'networkrequests/:id/:type',
        component: EditLayoutComponent,
        data: { title: 'Main Component' },
        children: [
          {
            path: '',
            component: ManageMultiRequestComponent,
            data: { allowedRoles: ['Admin', 'sms'], breadcrumb: 'Request Management' },
            canActivate: [AuthGuard]
          }
        ]
      },
      {
        path: 'networkrequest/:id/:type',
        component: EditLayoutComponent,
        data: { title: 'Main Component' },
        children: [
          {
            path: '',
            component: ManageRequestComponent,
            data: { allowedRoles: ['Admin', 'sms'], breadcrumb: 'Request Management' },
            canActivate: [AuthGuard]
          }
        ]
      },
      {
        path: 'surveydashboard',
        component: HomeLayoutComponent,
        data: { title: 'Main Component' },
        children: [
          {
            path: '',
            component: SurveyDashboardComponent,
            data: { allowedRoles: ['Admin', 'sa'], breadcrumb: 'Survey Dashboard' },
            canActivate: [AuthGuard]
          }
        ]
      },
      {
        path: 'startfromtemplate',
        component: HomeLayoutComponent,
        data: { title: 'Template Home Component' },
        children: [
          {
            path: '',
            //component: StartFromTemplateLandingPageComponent,
            component: StartFromTemplateNewComponent,
            data: { allowedRoles: ['Admin', 'sa'], breadcrumb: 'Survey Template' },
            canActivate: [AuthGuard]
          }
        ]
      },
      {
        path: 'smshome',
        component: HomeLayoutComponent,
        data: { title: 'Main Component' },
        children: [
          {
            path: '',
            component: SmsHomeComponent,
            data: { allowedRoles: ['Admin', 'sa'], breadcrumb: 'SMS Services' },
            canActivate: [AuthGuard]
          }
        ]
      },
      {
        path: 'smshome/:type',
        component: HomeLayoutComponent,
        data: { title: 'Main Component' },
        children: [
          {
            path: '',
            component: SmsHomeComponent,
            data: { allowedRoles: ['Admin', 'sa'], breadcrumb: 'SMS Services' },
            canActivate: [AuthGuard]
          }
        ]
      },
      {
        path: 'sms-home',
        component: HomeLayoutComponent,
        data: { title: 'Main Component' },
        children: [
          {
            path: '',
            component: SmsToolsLandingPageComponent,
            data: { allowedRoles: ['Admin', 'sa'], breadcrumb: 'SMS Services' },
            canActivate: [AuthGuard]
          }
        ]
      },
      {
        path: 'developer/:type',
        component: HomeLayoutComponent,
        data: { title: 'Developer Component' },
        children: [
          {
            path: '',
            component: DevelopmentComponent,
            data: {
              allowedRoles: ['Admin', 'developer'],
              breadcrumb: 'Developer',
            },
            canActivate: [AuthGuard],
          }
        ]
      },
      {
        path: 'keywords',
        component: HomeLayoutComponent,
        data: { title: 'Keywords Component' },
        children: [
          {
            path: '',
            component: KeywordsComponent,
            data: {
              allowedRoles: ['Admin', 'sa'],
              breadcrumb: 'Keywords',
            },
            canActivate: [AuthGuard],
          }
        ]
      },
      {
        path: 'keyword/:id/:type',
        component: EditLayoutComponent,
        data: { title: 'Keyword Component' },
        children: [
          {
            path: '',
            component: KeywordDetailsComponent,
            data: {
              allowedRoles: ['Admin', 'sa'],
              breadcrumb: 'Edit Keyword',
            },
            canActivate: [AuthGuard],
          }
        ]
      },
      {
        path: 'webhook/:id/:type/:userid',
        component: EditLayoutComponent,
        data: { title: 'Web Hook Component' },
        children: [
          {
            path: '',
            component: WebhookDetailsComponent,
            data: {
              allowedRoles: ['Admin', 'sa'],
              breadcrumb: 'Edit Web Hook',
            },
            canActivate: [AuthGuard],
          }
        ]
      },
      {
        path: 'securitykey/:id/:type/:userid/:actionType',
        component: EditLayoutComponent,
        data: { title: 'Security Key Component' },
        children: [
          {
            path: '',
            component: KeyDetailsComponent,
            data: {
              allowedRoles: ['Admin', 'sa'],
              breadcrumb: 'Edit Security Key',
            },
            canActivate: [AuthGuard],
          }
        ]
      },
      {
        path: 'whatsapp/:id/:type/:userid/:actionType',
        component: EditLayoutComponent,
        data: { title: 'WhatsApp Application' },
        children: [
          {
            path: '',
            component: WhatsappApplicationComponent,
            data: {
              allowedRoles: ['Admin', 'sa'],
              breadcrumb: 'WhatsApp Application',
            },
            canActivate: [AuthGuard],
          }
        ]
      },
      {
        path: 'manageapplications',
        component: HomeLayoutComponent,
        data: { title: 'Manage WhatsApp Applications' },
        children: [
          {
            path: '',
            component: ManageWhatsappComponent,
            data: { allowedRoles: ['Admin', 'sa'], breadcrumb: 'Manage WhatsApp Applications' },
            canActivate: [AuthGuard]
          }
        ]
      },
      {
        path: 'sms',
        component: HomeLayoutComponent,
        data: { title: 'Main Component' },
        children: [
          {
            path: '',
            component: SmsFeaturesComponent,
            data: { allowedRoles: ['Admin', 'sa'], breadcrumb: 'SMS Features' },
            canActivate: [AuthGuard]
          }
        ]
      }
    ]),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter
        /*        whitelistedDomains: ["localhost:65258"],*/
        //        blacklistedRoutes: []
      }
    }),
    CommonModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatToolbarModule,
    ReactiveFormsModule,
    MatIconModule,
    MatSidenavModule,
    MatMenuModule,
    MatListModule,
    MatButtonToggleModule,
    MatDialogModule,
    MatTabsModule,
    MatGridListModule,
    MatRadioModule,
    MatSelectModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatNativeDateModule,
    MatSliderModule,
    NgbModule,
    MatTableModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatBadgeModule,
    MatRippleModule,
    NgxMaterialTimepickerModule,
    NgxMaskModule.forRoot(),
    BarRatingModule
  ],
  providers: [DatePipe,
    AuthGuard,
    AuthenticationService,
    PanelService,
    UserService,
    ScriptService,
    ChatService,
    CommsService,
    SystemService,
    SmsService,
    CintService,
    QuestionService,
    InterviewService,
    SurveyService,
    CaseDataService,
    MessageService,
    MediaService,
    NetworkService,
    ProcessService,
    TinyUrlService,
    TimezonePickerService,
    FinancialService,
    WebHookService,
    KeywordService,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '819191336245-knarc70u87084f4b01s01ej8ine2p5tk.apps.googleusercontent.com'
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('842605436709410')
          }
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private translate: TranslateService,
    private dateAdapter: DateAdapter<any>) {
    translate.addLangs(['en', 'en-GB', 'en-US', 'is', 'de', 'fr']);
    translate.setDefaultLang('en-GB');
    this.dateAdapter.setLocale('en-GB');
    let language = navigator.language.split('-')[0];
    if (this.translate.getLangs().indexOf(navigator.language) >= 0) {
      this.translate.use(navigator.language);
    }
    else if (this.translate.getLangs().indexOf(language)) {
      this.translate.use(language);
    }
    else {
      this.translate.use("en-GB");
    }
  }
}
