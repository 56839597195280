import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { fadeInLeftAnimation, fadeInRightAnimation, rubberBandAnimation } from 'angular-animations';
import { fromEvent, takeUntil, Subject, Observable } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { version } from "../../../environments/environment";
import { FacebookLoginProvider, SocialAuthService } from '@abacritt/angularx-social-login';

@Component({
  selector: 'app-newsignup',
  templateUrl: './newsignup.component.html',
  styleUrl: './newsignup.component.css'
})
export class NewsignupComponent {
  start: boolean = false;
  fadeInLeftState = false;
  formGroup: FormGroup;
  p1: boolean = false;
  p2: boolean = false;
  p3: boolean = false;

  destroy = new Subject();

  destroy$ = this.destroy.asObservable();
  top: number = 0;

  currentVersion: string;
  currentYear = new Date().getFullYear();

  constructor(private breakpointObserver: BreakpointObserver, private router: Router,
    private socialService: SocialAuthService
  ) {
    this.currentVersion = version;

    this.formGroup = new FormGroup({
      email: new FormControl("", [Validators.required])
    })
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));

  ngOnDestroy(): void {
    this.destroy.next(this.destroy$);
  }

  ngAfterViewInit(): void {
    fromEvent(window, 'scroll').pipe(takeUntil(this.destroy$)).subscribe((e: Event) => {
      this.top = this.getYPosition(e);
    });

    Promise.resolve(null).then(() => {
      this.start = true;
    });
  }

  logInWithFacebook(): boolean {
    this.socialService.signIn(FacebookLoginProvider.PROVIDER_ID);
    return false;
  }

  checkStyle(): string {
    if (this.top > 5) {
      return 'mat-elevation-z2';
    }

    return '';
  }

  ngOnInit(): void {
  }

  openTerms() {
    const url = this.router.createUrlTree(['/termsofservice'])
    window.open(url.toString(), '_blank')
  }

  openPrivacy() {
    const url = this.router.createUrlTree(['/privacystatement'])
    window.open(url.toString(), '_blank')
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }

  getYPosition(e): number {
    return e.target.scrollingElement.scrollTop;
  }

  startForFree() {
    this.start = !this.start;
  }

  onAppear() {
    let a = 0;
  }

}
