import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { CintCategory, CintFeasibility, CintQuestion, CintService } from '../../cint.Service';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.css']
})
export class CategoryListComponent implements OnInit {

  tempCategories?: Observable<CintCategory[]>;

  constructor(
    private cintService: CintService
  ) { }

  ngOnInit(): void {
  }

  @Input()
  get country(): string {
    return this._country;
  }
  set country(value: string) {
    let different = this._country != value;
    this._country = value;
    if (value && different) {
      this.tempCategories = this.cintService.getQuestions(value).pipe(tap(
        result => {
          this.categories = result;
          this.onLoaded();
        }));
    }
  }
  private _country: string = "";

  @Input()
  get categories(): CintCategory[] {
    return this._categories;
  }
  set categories(value: CintCategory[]) {
    this._categories = value;
  }
  private _categories: CintCategory[] = [];

  @Input()
  get model(): CintFeasibility | null {
    return this._model;
  }
  set model(value: CintFeasibility | null) {
    this._model = value;
  }
  private _model: CintFeasibility | null = null

  @Input()
  get category(): number {
    return this._category;
  }
  set category(value: number) {
    this._category = value;
  }
  private _category: number = -1;

  @Input()
  get question(): number {
    return this._question;
  }
  set question(value: number) {
    this._question = value;
  }
  private _question: number = -1;

  @Output()
  select: EventEmitter<this> = new EventEmitter<this>();

  registerOnSelect(fn: any): void {
    this.onSelect = fn;;
  }

  onSelect(event: any, i: any, j: any) {
    this.category = i;
    this.question = j;
    this.select.emit(this);
  }

  @Output()
  loaded: EventEmitter<this> = new EventEmitter<this>();

  registerOnLoaded(fn: any): void {
    this.onLoaded = fn;;
  }

  onLoaded() {
    this.loaded.emit(this);
  }

  checkQuestion(index: number, subIndex: number, id: number): boolean {
    for (let k = 0; k < this.categories[index].Questions[subIndex].Variables.length; k++) {
      if (this.categories[index].Questions[subIndex].Variables[k].Id === id) {
        return true;
      }
    }

    return false;
  }

  checkCategory(index: number, id: number): boolean {
    for (let j = 0; j < this.categories[index].Questions.length; j++) {
      for (let k = 0; k < this.categories[index].Questions[j].Variables.length; k++) {
        if (this.categories[index].Questions[j].Variables[k].Id === id) {
          return true
        }
      }
    }

    return false;
  }

  isSelectedQuestion(index: number, subIndex: number) {
    if (!(this.model)) {
      return false;
    }

    if (this.model.QuotaGroups == undefined) {
      return false;
    }

    for (let i = 0; i < this.model.QuotaGroups.length; i++) {
      if (this.model.QuotaGroups[i].Quotas.length > 0) {
        const quota = this.model.QuotaGroups[i].Quotas[0]
        if (quota.TargetGroup.MinAge != null && quota.TargetGroup.MinAge != undefined && quota.TargetGroup.MaxAge != 0) {
          if (index == 0 && subIndex == 0) {
            return true;
          }
        }
        else if (quota.TargetGroup.Gender != null && quota.TargetGroup.Gender != undefined && quota.TargetGroup.Gender != 0) {
          if (index == 0 && subIndex == 1) {
            return true;
          }
        }
        else {
          let variableIds = quota.TargetGroup.VariableIds;
          if (variableIds == null || variableIds == undefined || variableIds.length == 0) {
            variableIds = quota.TargetGroup.RegionIds;
          }

          if (variableIds == undefined) {
            return false;
          }

          for (let i = 0; i < variableIds.length; i++) {
            if (this.checkQuestion(index, subIndex, variableIds[i])) {
              return true;
            }
          }
        }
      }
    }

    return false;
  }

  isSelectedCategory(index: number): boolean {
    if (!(this.model)) {
      return false;
    }

    if (this.model.QuotaGroups == undefined) {
      return false;
    }

    for (let i = 0; i < this.model.QuotaGroups.length; i++) {
      if (this.model.QuotaGroups[i].Quotas.length > 0) {
        const quota = this.model.QuotaGroups[i].Quotas[0]
        if (quota.TargetGroup.MinAge !== null && quota.TargetGroup.MinAge !== undefined) {
          if (index === 0) {
            return true;
          }
        }
        else if (quota.TargetGroup.Gender !== null && quota.TargetGroup.Gender !== undefined) {
          if (index == 0) {
            return true;
          }
        }
        else {
          let variableIds = quota.TargetGroup.VariableIds;
          if (variableIds === null || variableIds === undefined || variableIds.length === 0) {
            variableIds = quota.TargetGroup.RegionIds;
          }

          if (this.checkCategory(index, variableIds[0])) {
            return true;
          }
        }
      }
    }

    return false;
  }
}
