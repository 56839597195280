import { Component } from '@angular/core';

@Component({
  selector: 'app-gateway-definition',
  templateUrl: './gateway-definition.component.html',
  styleUrls: ['./gateway-definition.component.css']
})
export class GatewayDefinitionComponent {

}
