import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, Inject, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InterviewModel, InterviewService, ParamModel, QuestionModel } from '../question.service';
import { AuthenticationService } from "../authentication.Service";
import { OneScriptAngularPlayerComponent } from '../lib/one-script-angular-player.component';
import { AnswerModel } from '../lib/models/AnswerModel';
import { ThemeService } from '../survey-style/theme.service';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { JsonCyclic } from '../utils';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-interview-preview',
  templateUrl: './interview-preview.component.html',
  styleUrls: ['./interview-preview.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', maxHeight: '0' })),
      state('expanded', style({ height: '*', })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])
  ]
})
export class InterviewPreviewComponent implements OnInit {
  model: any;
  dialogRef: MatDialogRef<any, any>;
  question: any;
  jsonString: string;
  @ViewChild('player') player: OneScriptAngularPlayerComponent | undefined;
  show = false;
  error = false;
  errorMsg = "";
  errorDetail = "";
  isReview = false;
  edit = false;
  dataModel: InterviewModel;
  deviceType = "desktop";
  previewWidth = "100%";
  isSms = false;
  isEmbed = false;
  messages: ChatMessage[] = [];
  form: FormGroup;
  selectedTheme: string = "";
  changed = false;
  horizontalscroll: string = "";
  overflow: string = "";
  width: number = 9999;
  id: string = "";
  embeddedQuestions: QuestionModel[] = [];
  embedForm: FormGroup = null;
  started = false;

  @ViewChild('scrollMe') private myScrollContainer: ElementRef | undefined;
  @ViewChild('scriptDialog') scriptDialog: TemplateRef<any>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private datePipe: DatePipe,
    private translate: TranslateService,
    private authenticationService: AuthenticationService,
    private interviewService: InterviewService,
    private theme: ThemeService,
    private fb: FormBuilder) {
    this.form = this.fb.group({
      message: ['']
    });
    this.dataModel = data.model;
    this.isReview = data.isReview != undefined ? data.isReview : false;
    if (this.isReview) {
      this.id = data.id;
    }


    this.embeddedQuestions = this.dataModel.getUrlPanelQuestions();
    if (this.isTester) {
      this.getDetails();
    }

    this.isEmbed = this.embeddedQuestions.length > 0;
    this.isSms = this.dataModel.Channel == "sms" || this.dataModel.Channel == "whatsapp";
    if (this.isEmbed) {
      this.embedForm = new FormGroup({
        values: new FormArray([])
      });
      let values = this.embedForm.controls.values as FormArray;
      for (let i = 0; i < this.embeddedQuestions.length; i++) {
        let form = new FormGroup({
          value: new FormControl('')
        });
        values.push(form);
      }

      return;
    }

    this.restartSurvey();
  }

  restartSurvey() {
    this.started = true;
    this.messages = [];
    this.show = false;
    let params: ParamModel[] = [];
    if (this.isEmbed) {
      let values = this.embedForm.controls.values as FormArray;
      for (let i = 0; i < this.embeddedQuestions.length; i++) {
        let form = values.controls[i] as FormGroup;
        params.push(new ParamModel(this.embeddedQuestions[i].QuestionName, form.controls.value.value));
      }
    }

    this.interviewService.interviewSetup(this.isReview, this.id, this.dataModel, params).subscribe(
      result => {
        this.model = result;
        this.pushMessage(new ChatMessage(this.model.Question.Label.Text, 1), true)
        this.show = true;
        if (this.isSms && this.model.Question.QuestionDataType == 0) {
          this.sendSms();
        }
      },
      error => {
        this.error = true;
        this.errorMsg = error.error;
      }
    );
  }

  isTester() {
    return this.authenticationService.isAuthorized([ 'diy' ]);
  }

  ngOnInit(): void {
    this.selectedTheme = this.theme.getTheme() ?? "";
  }

  showScript() {
    this.dialogRef = this.dialog.open(this.scriptDialog, { height: '80vh'});
  }

  runOnMobile() {
    this.previewWidth = '375px';
    this.width = 375;
    this.isSms = false;
    this.isEmbed = false;
    this.deviceType = "mobile";
  }

  runOnTablet() {
    this.previewWidth = '1024px';
    this.width = 1024;
    this.isSms = false;
    this.isEmbed = false;
    this.deviceType = "tablet";
  }

  runOnDesktop() {
    this.previewWidth = '100%';
    this.width = 9999;
    this.isSms = false;
    this.isEmbed = false;
    this.deviceType = "desktop";
    this.horizontalscroll = "auto";
    this.overflow = "auto";
 }

  runAsSms() {
    this.isSms = true;
    this.isEmbed = false;
    this.deviceType = "sms";
  }

  getDetails() {
    this.interviewService.getScript(this.dataModel).subscribe(
      result => {
        this.errorDetail = result;
        this.edit = true;
      },
      error => {
        this.errorDetail = JSON.stringify(error.error);
        this.edit = true;
      }
    );
  }

  copyModel() {
    this.copyMessage(this.jsonString);
  }

  copyScript() {
    this.interviewService.getScriptPlainText(this.dataModel).subscribe(
      result => {
        this.copyMessage(result);
      },
    );
  }

  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  download() {
    this.interviewService.downloadErrorBundle(this.dataModel)
     .subscribe(response => {
       this.downLoadFile(response, "application/zip", ".zip")
     });
  }

  downLoadFile(data: any, type: string, ext: string) {
    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    const fileName = "ScriptBundle";
    a.href = url;
    a.download = fileName + ext;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  languageChanged(language: string) {
    this.show = false;
    this.interviewService.changeLanguage(this.model.SessionToken, language).subscribe(result => {
      this.model = result;
      this.show = true;
    })
  }

  navigateQuestions($event: any) {
    this.show = false;
    const answers: AnswerModel[] = this.player?.canvas?.data?.answers ?? [];
    switch ($event.type) {
      case 0:
        // Pressed Next
        this.interviewService.interviewAction(this.model.SessionToken, 0, answers).subscribe(result => {
          this.model = result;
          this.show = true;
        });
        break;
      case 1:
        // Previous
        this.interviewService.interviewAction(this.model.SessionToken, 1, answers).subscribe(result => {
          this.model = result;
          this.show = true;
        });
        break;
      default:
        // ????
        break;
    }
  }

  sendSms() {
    let answers: AnswerModel[] = [];
    this.question = this.model.Question;
    /*if (this.model.Question.EditorType == 23) {
      return;
    }*/

    if (this.model.Question.QuestionDataType != 0) {
      if (this.isReview) {
        answers.push(new AnswerModel(this.model.Question.QuestionFullName, this.model.Question.Response.Value));
        let answer = this.model.Question.Response.Value as string;
        if (answer.startsWith("_")) {
          answer = answer.substr(1);
        }

        this.pushMessage(new ChatMessage(answer, 2), true);
      }
      else {
        let answer = this.form.controls.message.value;
        answers.push(new AnswerModel(this.model.Question.QuestionFullName, answer));
        this.pushMessage(new ChatMessage(this.form.controls.message.value, 2), true);
      }
    }

    this.form.controls.message.setValue("");
    this.interviewService.interviewAction(this.model.SessionToken, 0, answers).subscribe(result => {
      this.model = result;
      if (this.model.Question.Errors.Count > 0) {
        this.pushMessage(new ChatMessage(this.buildQuestionWithErrorMessages(), 1), true);
        if (this.model.Style.ErrorHandling == 1) {
          this.interviewService.interviewContinue(this.model.SessionToken).subscribe(result => {
            this.model = result;
            if (this.model.Style.ErrorHandling == 0 || this.model.Question.QuestionName != this.question.QuestionName) {
              this.pushMessage(new ChatMessage(this.model.Question.Label.Text, 1), true);
            }

            this.question = this.model.Question;
          });
        }
      }
      else {
        if (this.model.Question.QuestionName != this.question.QuestionName) {
          this.pushMessage(new ChatMessage(this.model.Question.Label.Text, 1), true);
        }
        else {
          if (this.model.Style.ErrorHandling == 1) {
            this.interviewService.interviewContinue(this.model.SessionToken).subscribe(result => {
              this.model = result;
              this.pushMessage(new ChatMessage(this.model.Question.Label.Text, 1), true);
              this.question = this.model.Question;
            });
          }
        }

        this.question = this.model.Question;
        if ((this.isReview || this.question.QuestionDataType == 0) && this.question.EditorType != 23) {
          this.sendSms();
        }
      }
    });
  }

  buildQuestionWithErrorMessages(): string {
    var message = "";
    for (let i = 0; i < this.model.Question.Errors.Count; i++) {
      var first = i == 0;
      message += this.model.Question.Errors.Items[i].Text;
      if (message.endsWith(".")) {
        message += " ";
      }

      if (!message.endsWith(". ")) {
        message += ". ";
      }
    }

    if (!message.endsWith(". ")) {
      message += ". ";
    }

    message += this.model.Question.Label.Text;
    return message;
  }

  whenFormatted(index: number) {
    let date = this.datePipe.transform(this.messages[index].When, 'EEEE, h:mm a');
    if (index == 0) {
      return date;
    }

    /*if (this.messages[index].Type != this.messages[index - 1].Type) {
      return date;
    }*/

    let date2 = this.datePipe.transform(this.messages[index].When, 'EEEE, h:mm a');
    if (date2 != date) {
      return date;
    }

    return "";
  }

  scrollToBottom(index: number) {
    if (this.myScrollContainer && this.changed && index + 1 == this.messages.length) {
      this.myScrollContainer.nativeElement.scroll({
        top: this.myScrollContainer.nativeElement.scrollHeight,
        left: 0,
        behavior: 'smooth'
      });
      this.changed = false;
    }
  }

  pushMessage(message: ChatMessage, shouldChange: boolean) {
    message.Message = message.Message.replace(/<\/?[^>]+(>|$)/g, "").replace("&nbsp;", " ").replace("&amp;", "&");
    this.messages.push(message);
    this.changed = shouldChange;
  }
}

class ChatMessage {
  Message: string;
  Type: number;
  When: Date;

  constructor(message: string, type: number) {
    this.Message = message;
    this.Type = type;
    this.When = new Date();
  }
}

