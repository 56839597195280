<h2>Network Management</h2>
<div class="fieldRow" style="height:90px; vertical-align:top">
  <div style="height:90px">
    <button mat-flat-button color="primary" (click)="newNetwork()">New</button>
  </div>
  <div style="height:90px">
    <app-file-upload accept=".xlsx" (uploaded)="onFileUploaded($event)" text="Import Network Data"></app-file-upload>
  </div>
  <div style="height:90px">
    <button mat-flat-button color="primary" (click)="exportNetwork()">Export Network Data</button>
  </div>
</div>
<div>
  <div>
    <a href="assets/files/SampleCountriesNetworkDetails.xlsx" class="add-upld-download-temp-link">Click here</a> to download a template file to upload countries details.
  </div>
</div>
<div class="outer-table-container mat-elevation-z8">

  <div class="table-container custom-scroll-bar">
    <table mat-table #table [dataSource]="dataSource"
           multiTemplateDataRows
           matSort
           matSortActive="created"
           matSortDisableClear matSortDirection="desc">

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let network"> {{network.Name}} </td>
      </ng-container>
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
        <td mat-cell *matCellDef="let network"> {{network.Description}} </td>
      </ng-container>
      <ng-container matColumnDef="number">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Numbers </th>
        <td mat-cell *matCellDef="let network"> {{network.Numbers.length}} </td>
      </ng-container>
      <ng-container matColumnDef="created">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Created </th>
        <td mat-cell *matCellDef="let network"> {{network.Created |  date: 'short': '' : translate.currentLang}} </td>
      </ng-container>
      <ng-container matColumnDef="amended">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Modified </th>
        <td mat-cell *matCellDef="let network"> {{network.Amended | date:'short': '' : translate.currentLang}} </td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td mat-cell *matCellDef="let network">
          <div>
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="icon-button with a menu" (click)="$event.stopPropagation()">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="edit(network)">
                <mat-icon>edit</mat-icon>
                <span>Edit</span>
              </button>
              <mat-divider></mat-divider>
              <button mat-menu-item (click)="delete(network)">
                <mat-icon>delete</mat-icon>
                <span>Delete</span>
              </button>
            </mat-menu>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
      <tr mat-row *matRowDef="let network; columns: columns;"
          class="users-element-row" (click)="edit(network)">
      </tr>
    </table>
  </div>
</div>
