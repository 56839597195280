<div *ngIf="!preview && nextQuestion" [@filterIn]="isIn" [@filterOut]="isOut" (@filterIn.done)="startEndQuestion()" (@filterIn.start)="endQuestion()" (@filterOut.done)="showQuestion()">
  <div *ngIf="isTerminate() != ''" class="full-width-height">
    <iframe [src]="isTerminate() | safe: 'resourceUrl'" class="full-width-height" target="_parent"></iframe>
  </div>
  <div *ngIf="isTerminate() == ''" [ngClass]="selectedTheme" [style.background]="surveyBackground" #background class="onescript-playr-main-content fullHeight" [style.color]="backgroundColor()">
    <div #themeHeader class="theme-toolbar fullWidth txt-align-center mat-elevation-z6 onescript-playr-header-div" *ngIf="isTheme" color="primary"><img *ngIf="media" [src]="media   | safe: 'resourceUrl'" class="onescript-playr-logo" /></div>
    <div #customHeader class="theme-toolbar fullWidth txt-align-center mat-elevation-z6 onescript-playr-header-div" *ngIf="isHeader" [style.background]="headerBackground"><img *ngIf="media" [src]="media   | safe: 'resourceUrl'" class="onescript-playr-logo" /></div>
    <diy-canvas #canvas [show]="true" [model]="nextQuestion" [playerWidth]="playerWidth" [preview]="preview" (navigate)="onNavigate($event)" (changeLanguage)="languageChanged($event)"></diy-canvas>
    <br />
    <div [class]="poweredByClass">
      Powered by<br /> <img [src]="poweredBySource" class="onescript-playr-poweredByImage" />
      <br /><span class="onescript-playr-poweredByVersion">{{currentVersion}}</span>
    </div>
    <br />
    <pre *ngIf="showJson" id="json">{{jsonView}}</pre>
  </div>
</div>
<div *ngIf="preview" [class]="nextQuestion ? 'onescript-playr-main-content-preview custom-scroll-bar' : ''"
      [style]="(isHandset$ | async) ? 'overflow-y: hidden; overflow-x: scroll;' : ''">
  <diy-canvas #canvas [show]="true" [model]="model" [preview]="preview" (navigate)="onNavigate($event)"></diy-canvas>
  <div *ngIf="showJson">
    <br />
    <br />
    <pre id="json">{{jsonView}}</pre>
  </div>
</div>
