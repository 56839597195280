import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ProcessService, ProcessServiceModel } from '../../process.Service';

@Component({
  selector: 'app-ftp-setup',
  templateUrl: './ftp-setup.component.html',
  styleUrls: ['./ftp-setup.component.css']
})
export class FtpSetupComponent implements OnInit {

  testing: boolean = false;
  errors: string[] = [];
  successes: string[] = [];
  formGroup: FormGroup = new FormGroup({});

  constructor(private service: ProcessService) { }

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      Host: new FormControl(this.process.Task.FileTransfer.Server),
      Port: new FormControl(this.process.Task.FileTransfer.Port),
      Username: new FormControl(this.process.Task.FileTransfer.Username),
      Password: new FormControl(this.process.Task.FileTransfer.Password),
      Folder: new FormControl(this.process.Task.FileTransfer.ServerFolder),
      File: new FormControl(this.process.Task.FileTransfer.Pattern),
      Passive: new FormControl(this.process.Task.FileTransfer.Passive)
    });

    this.formGroup.valueChanges.subscribe(result => {
      this.process.Task.FileTransfer.Server = this.formGroup.controls.Host.value;
      this.process.Task.FileTransfer.Port = this.formGroup.controls.Port.value;
      this.process.Task.FileTransfer.Username = this.formGroup.controls.Username.value;
      this.process.Task.FileTransfer.Password = this.formGroup.controls.Password.value;
      this.process.Task.FileTransfer.ServerFolder = this.formGroup.controls.Folder.value;
      this.process.Task.FileTransfer.Pattern = this.formGroup.controls.File.value;
      this.process.Task.FileTransfer.Passive = this.formGroup.controls.Passive.value;
    })
  }

  @Input()
  get process(): ProcessServiceModel {
    return this._process;
  }
  set process(value: ProcessServiceModel) {
    this._process = value;
  }
  private _process: ProcessServiceModel = new ProcessServiceModel();

  refresh() {
    if (this.formGroup) {
      this.formGroup.controls.Port.setValue(this._process.Task.FileTransfer.Port);
    }
  }

  testService() {
    this.testing = true;
    this.successes = [];
    this.errors = [];
    this.service.testFTP(this.process).subscribe(
      result => {
        this.successes = result;
        this.testing = false;
        let a = result;
      },
      error => {
        this.testing = false;
        this.errors = error.error;
      }
    )
  }
}
