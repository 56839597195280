import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CintFeasibility } from '../../cint.Service';
import { FinancialService } from '../../finacial.Service';
import { AudienceComponent } from '../../panel-selector/audience/audience.component';
import { EstimateModel, PanelModel } from '../../panel.Service';
import { SurveyModel } from '../../survey.Service';

@Component({
  selector: 'app-pricing-summary',
  templateUrl: './pricing-summary.component.html',
  styleUrls: ['./pricing-summary.component.css']
})
export class PricingSummaryComponent implements OnInit {

  @ViewChild("audience") audience: AudienceComponent | undefined;

  completionRate: number = 100;
  number: number = 0;
  model?: CintFeasibility = null;
  enoughBalance = true;

  constructor(private financialService: FinancialService) { }

  ngOnInit(): void {
  }

  @Input()
  get estimate(): EstimateModel {
    if (this._estimate) {
      return this._estimate;
    }

    this._estimate = new EstimateModel();
    return this._estimate;
  }
  set estimate(value: EstimateModel) {
    this._estimate = value;
    this.completionRate = this._estimate.CompletionRate;
    this.number = this._estimate.Respondents;
    if (this.completionRate == 0) {
      this.completionRate = 100;
    }

    this.checkModelAndEstimates();
  }
  private _estimate: EstimateModel = new EstimateModel();

  refresh() {
    if (this.audience) {
      this.audience.buildReportedQuestions();
    }
  }


  @Input()
  get panel(): PanelModel | null {
    return this._panel;
  }
  set panel(value: PanelModel | null) {
    this._panel = value;
    if (this._panel) {
      if (this.panel?.Quota && this.panel.Quota !== "") {
        this.model = JSON.parse(this.panel.Quota) as CintFeasibility;
        this.checkModelAndEstimates();
      }
    }
    else {
      this.number = this.panel.PanellistCount;
    }
  }
  private _panel: PanelModel | null = null;

  @Input()
  get survey(): SurveyModel | null {
    return this._survey;
  }
  set survey(value: SurveyModel | null) {
    this._survey = value;
    this.calculateCosts();
  }
  private _survey: SurveyModel | null = null;

  @Input()
  get summary(): string {
    return this._summary;
  }
  set summary(value: string) {
    this._summary = value;
  }
  private _summary: string = "";

  checkNumber($event: any) {
    this._estimate.Respondents = this.number;
    this.calculateCosts();
  }

  checkModelAndEstimates() {
    if (this._estimate && this.model) {
      this._estimate.CompletionRate = this.model.IncidenceRate;
      this._estimate.Respondents = this.model.Limit;
    }

    if (this._estimate) {
      this.calculateCosts();
      this.completionRate = this._estimate.CompletionRate;
      this.number = this._estimate.Respondents;
    }
  }

  calculateCosts() {
    if (this._estimate) {
      this._estimate.Total = this._estimate.Rate * this._estimate.Respondents; // * this._estimate.CompletionRate / 100;
    }

    this.checkBalance();
  }

  checkBalance() {
    if (this._survey && this._estimate) {
      this.financialService.checkBalanceForReference(this._survey.SurveyID, this._estimate.Total).subscribe(
        result => {
          this.enoughBalance = result;
        });
    }
  }

  @Input()
  get showBalance(): boolean {
    return this._showBalance;
  }
  set showBalance(value: boolean) {
    this._showBalance = value;
  }
  private _showBalance: boolean = false;

  purchaseMade() {
    this.checkBalance();
  }
}
