import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { BasicData, CaseDataService } from '../../casedata.Service';
import { CategoryModel, QuestionModel, RuleList } from '../../question.service';
import { DataAnalysisComponent } from '../data-analysis.component';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { RoutingDialogComponent } from '../../questions/routing-dialog/routing-dialog.component';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { SnackbarDefaultComponent } from '../../snackbar/snackbar-default.component';
import { ProcessService, ProcessServiceModel } from '../../process.Service';
import { ParameterModel } from '../../lib/models/ParameterModel';
import { InterviewPreviewComponent } from '../../interview-preview/interview-preview.component';
import { ReviewDataComponent } from '../review-data/review-data.component';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-basic-data',
  templateUrl: './basic-data.component.html',
  styleUrls: ['./basic-data.component.css']
})
export class BasicDataComponent implements OnInit, AfterViewInit {

  columns: string[] = [];
  columnsToView: ColumnToView[] = [];
  columnIds: string[] = [];
  columnNames: string[] = [];
  questions: QuestionModel[] = [];
  data: BasicData[][] = [];
  page: number = 1;
  size: number = 20;
  resultsLength: number = 40;
  isLoadingResults = true;
  isRateLimitReached = false;
  question: QuestionModel = new QuestionModel();
  sb?: MatSnackBarRef<any>;
  mainFilterType: number = -1;
  importProcesses: ProcessServiceModel[] = [];
  exportProcesses: ProcessServiceModel[] = [];
  activeProcesses: ProcessServiceModel[] = [];

  mainFilter = [
    { value: 1, name: "Started", total: 0 },
    { value: 2, name: "Completed", total: 0 },
    { value: 3, name: "Quota Exceeded", total: 0 },
    { value: 4, name: "Terminated", total: 0 },
    { value: 5, name: "Timed Out", total: 0 },
    { value: 6, name: "Shutdown", total: 0 },
    { value: 7, name: "Screened Out", total: 0 },
    { value: 8, name: "Failed", total: 0 },
    { value: 9, name: "Anomalies", total: 0 },
    { value: 10, name: "Bounced", total: 0 }
  ];

  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;

  constructor(private caseService: CaseDataService,
    private processService: ProcessService,
    private snackbar: MatSnackBar,
    public dialog: MatDialog,
    public translate: TranslateService) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.paginator?.page
        .pipe(
          startWith({}),
          switchMap(() => {
            this.isLoadingResults = true;
            this.page = (this.paginator?.pageIndex ?? 0) + 1;
            this.size = this.paginator?.pageSize ?? 20;
            this.sb = this.snackbar.openFromComponent(SnackbarDefaultComponent);
            if (this.analysis != undefined && this.analysis.survey && this.question) {
              return this.caseService.getResultsWithErrors(this.analysis.survey.SurveyID, this.mainFilterType, this.page, this.size, this.analysis.interview, this.question.Rules[0])
                .pipe(catchError(() => observableOf(null)));
            }

            return [];
          }),
          map(data => {
            // Flip flag to show that loading has finished.
            this.sb?.dismiss();
            this.isLoadingResults = false;
            this.isRateLimitReached = data === null;

            if (data === null) {
              return [];
            }

            // Only refresh the result length if there is new data. In case of rate
            // limit errors, we do not want to reset the paginator to zero, as that
            // would prevent users from re-triggering requests.
            return data;
          }),
        )
        .subscribe(data => (this.data = data));
  }

  @Input()
  get analysis(): DataAnalysisComponent | undefined {
    return this._analysis
  }
  set analysis(value: DataAnalysisComponent | undefined) {
    this._analysis = value;
  }
  private _analysis: DataAnalysisComponent | undefined;

  @Input()
  get modelChecked(): boolean {
    return this._modelChecked;
  }
  set modelChecked(value: boolean) {
    this._modelChecked = value;
    if (value) {
      this.questions = this.analysis?.interview.dataQuestions() ?? [];
      this.question = new QuestionModel(this.analysis?.interview);
      this.question.QuestionType = "advanced";
      this.question.Action = "branch";
      this.question.Rules = [];
      this.question.Rules.push(new RuleList());
      this.question.Rules[0].Question = this.question;
      this.question.Rules[0].Rules = [];
      this.checkTotals();
      this.buildColumns();
      this.buildPageData();
    }
  }
  private _modelChecked: boolean = false;

  checkTotals() {
    for (let i = 0; i < this.mainFilter.length; i++) {
      this.mainFilter[i].total = 0;
    }

    let temp = -1;
    for (let i = 0; i < this.analysis?.survey.Totals.length; i++) {
      if (this.analysis.survey.Totals[i].Status > 0) {
        this.mainFilter[this.analysis.survey.Totals[i].Status - 1].total = this.analysis.survey.Totals[i].Total;
        if (temp == -1 && this.analysis.survey.Totals[i].Total > 0) {
          temp = this.analysis.survey.Totals[i].Status;
        }

        if (this.analysis.survey.Totals[i].Total == 0 && this.mainFilterType == this.analysis.survey.Totals[i].Status) {
          this.mainFilterType = -1;
        }
      }
    }

    if (this.mainFilterType == -1) {
      this.mainFilterType = temp;
    }
  }

  getTotals() {
    let totals = [];
    for (let i = 0; i < this.mainFilter.length; i++) {
      if (this.mainFilter[i].total > 0) {
        totals.push(this.mainFilter[i]);
      }
    }

    return totals;
  }

  buildColumns() {
    this.columns = [];
    this.columnsToView = [];
    this.columnIds = [];
    this.columnNames = [];
    this.columns.push("Id");
    this.columnNames.push("row");
    this.columnIds.push("");
    for (let i = 0; i < this.questions.length; i++) {
      switch (this.questions[i].QuestionType) {
        case "mcma":
        case "mcsa":
          this.columnsToView.push(new ColumnToView(this.questions[i].QuestionId, this.questions[i].FullName, this.questions[i].FullSummary));
          this.columns.push(this.questions[i].FullSummary);
          this.columnNames.push(this.questions[i].FullName);
          this.columnIds.push(this.questions[i].QuestionId);
          for (let j = 0; j < this.questions[i].Categories.List.length; j++) {
            this.checkForOther(this.questions[i], this.questions[i].Categories.List[j]);
          }

          break;
        case "maxDiff":
          for (let j = 0; j < this.questions[i].Categories.List.length; j++) {
            let name = this.questions[i].FullName + "." + this.questions[i].Categories.List[j].Name;
            let summary = this.questions[i].FullSummary + "." + this.questions[i].Categories.List[j].Label;
            this.columnsToView.push(new ColumnToView(this.questions[i].QuestionId, name, summary));
            this.columns.push(summary);
            this.columnNames.push(name);
            this.columnIds.push(this.questions[i].QuestionId);
          }

          break;
        case "matrix":
          for (let j = 0; j < this.questions[i].Categories.List.length; j++) {
            let name = this.questions[i].FullName + "." + this.questions[i].Categories.List[j].Name;
            let summary = this.questions[i].FullSummary + "." + this.questions[i].Categories.List[j].Label;
            this.columnsToView.push(new ColumnToView(this.questions[i].QuestionId, name, summary));
            this.columns.push(summary);
            this.columnNames.push(name);
            this.columnIds.push(this.questions[i].QuestionId);
          }

          break;
        case "bucket":
          for (let j = 0; j < this.questions[i].Parameters.length; j++) {
            let name = this.questions[i].FullName + "." + this.questions[i].Parameters[j].Name;
            let summary = this.questions[i].FullSummary + "." + this.questions[i].Parameters[j].Value;
            this.columnsToView.push(new ColumnToView(this.questions[i].QuestionId, name, summary));
            this.columns.push(summary);
            this.columnNames.push(name);
            this.columnIds.push(this.questions[i].QuestionId);
          }

          break;
        case "advanced":
          if (this.questions[i].Action == "embed") {
            for (let j = 0; j < this.questions[i].Questions.List.length; j++) {
              if (this.questions[i].Questions.List[j].Fix) {
                let name = this.questions[i].Questions.List[j].FullName.replace(" ", "_");
                var found = false;
                for (let k = 0; k < this.columnNames.length; k++) {
                  if (name == this.columnNames[k]) {
                    found = true;
                    break;
                  }
                }

                if (found) {
                  continue;
                }

                let summary = this.questions[i].Questions.List[j].FullSummary;
                this.columnsToView.push(new ColumnToView(this.questions[i].Questions.List[j].QuestionId, name, summary));
                this.columns.push(summary);
                this.columnNames.push(name);
                this.columnIds.push(this.questions[i].Questions.List[j].QuestionId);
              }
            }
          }

          break;
        default:
          this.columnsToView.push(new ColumnToView(this.questions[i].QuestionId, this.questions[i].FullName, this.questions[i].FullSummary));
          this.columns.push(this.questions[i].FullSummary);
          this.columnNames.push(this.questions[i].FullName);
          this.columnIds.push(this.questions[i].QuestionId);
          break;
      }
    }

    this.columns.push("..");
    this.columnNames.push("..");
    this.columnIds.push("");
  }

  buildPageData() {
    if (this.analysis == undefined) {
      return;
    }

    this.sb = this.snackbar.openFromComponent(SnackbarDefaultComponent);
    this.caseService.getTotalResults(this.analysis.survey.SurveyID, this.mainFilterType == -1 ? 0 : this.mainFilterType, this.analysis.interview, this.question.Rules[0]).subscribe(results => {
      this.resultsLength = results;
      this.caseService.getResultsWithErrors(this.analysis.survey.SurveyID, this.mainFilterType == -1 ? 0 : this.mainFilterType, this.page, this.size, this.analysis.interview, this.question.Rules[0]).subscribe(results => {
        this.data = results;
        this.sb?.dismiss();
      });
    });
  }

  checkForOther(question: QuestionModel, category: CategoryModel) {
    if (category.Other == null) {
      return;
    }

    let name = question.FullName + "." + category.Name + ".Other";
    let summary = question.FullSummary + "." + category.Label + ".Other";
    this.columnsToView.push(new ColumnToView(this.question.QuestionId, name, summary));
    this.columns.push(summary);
    this.columnNames.push(name);
    this.columnIds.push(question.QuestionId);
  }

  getMatrixColumn(element: BasicData[], index: number): BasicData {
    let parts = this.columnNames[index].split('.');
    let name = "";
    for (let i = 0; i < parts.length - 1; i++) {
      name += (i == 0 ? "" : ".") + parts[i];
    }

    name += "[{" + parts[parts.length - 1] + "}].matrix";
    name = name.toLowerCase();
    for (let i = 0; i < element.length; i++) {
      if (element[i].VariableName.toLowerCase() == name) {
        return element[i];
      }
    }

    return new BasicData();
  }

  getMatrixTextColumn(element: BasicData[], index: number): string {
    let parts = this.columnNames[index].split('.');
    let name = "";
    for (let i = 0; i < parts.length - 1; i++) {
      name += (i == 0 ? "" : ".") + parts[i];
    }

    name += "[{" + parts[parts.length - 1] + "}]";
    name = name.toLowerCase();
    let answers = [];
    for (let i = 0; i < element.length; i++) {
      if (element[i].VariableName.toLowerCase().startsWith(name)) {
        answers.push(element[i].Response);
      }
    }

    return answers.join(",");
  }

  getBucketColumns(element: BasicData[], index: number): BasicData[] {
    let parts = this.columnNames[index].split('.');
    let name = "";
    for (let i = 0; i < parts.length - 1; i++) {
      name += (i == 0 ? "" : ".") + parts[i];
    }

    name += "[{" + parts[parts.length - 1] + "}].Bucket";
    name = name.toLowerCase();
    let answers = [];
    for (let i = 0; i < element.length; i++) {
      if (element[i].VariableName.toLowerCase().startsWith(name)) {
        answers.push(element[i])
      }
    }

    return answers;
  }

  getOtherText(element: BasicData[], index: number): string {
    let data = this.getColumn(element, index);
    return data.Response?.toString();
  }

  getDate(element: BasicData[], index: number): BasicData {
    for (let i = 0; i < element.length; i++) {
      if (element[i].CommonName == this.columnNames[index].toLowerCase()) {
        return element[i];
      }
    }

    return new BasicData();
  }

  getColumn(element: BasicData[], index: number): BasicData {
    for (let i = 0; i < element.length; i++) {
      if (element[i].CommonName == this.columnNames[index].toLowerCase()) {
        return element[i];
      }
    }

    return new BasicData();
  }

  getColumnByName(element: BasicData[], name: string): BasicData {
    for (let i = 0; i < element.length; i++) {
      if (element[i].CommonName == name.toLowerCase()) {
        return element[i];
      }
    }

    return new BasicData();
  }

  getColumns(element: BasicData[], index: number): BasicData[] {
    let temp = [];
    for (let i = 0; i < element.length; i++) {
      if (element[i].CommonName.toLowerCase() == this.columnNames[index].toLowerCase()) {
        temp.push(element[i]);
      }
    }

    return temp;
  }

  getMaxDiffColumns(element: BasicData[], index: number, setCount: number): BasicData[] {
    let temp = [];
    let names = this.columnNames[index].toLowerCase().split(".");
    for (let i = 0; i < element.length; i++) {
      if (element[i].CommonName.startsWith(names[0] + "[{_" + setCount + "}]")) {
        temp.push(element[i]);
      }
    }

    return temp;
  }

  isMultipleChoice(index: number): boolean {
    if (this.columnIds[index] == "" || this.analysis == undefined) {
      return false;
    }

    if (this.columnNames[index].endsWith(".Other")) {
      return false;
    }

    let question = this.analysis.interview.findQuestion(this.columnIds[index]);
    return question?.QuestionType == "mcsa" || question?.QuestionType == "mcma" || question?.QuestionType == "boolean";
  }

  isOther(index: number): boolean {
    return this.columnNames[index].endsWith(".Other");
  }

  isMaxDiff(index: number) {
    if (this.columnIds[index] == "" || this.analysis == undefined) {
      return false;
    }

    let question = this.analysis.interview.findQuestion(this.columnIds[index]);
    return question?.QuestionType == "maxDiff";
  }

  isDate(index: number) {
    if (this.columnIds[index] == "" || this.analysis == undefined) {
      return false;
    }

    let question = this.analysis.interview.findQuestion(this.columnIds[index]);
    return question?.QuestionType == "date";
  }

  isMatrix(index: number) {
    if (this.columnIds[index] == "" || this.analysis == undefined) {
      return false;
    }

    let question = this.analysis.interview.findQuestion(this.columnIds[index]);
    return question?.QuestionType == "matrix";
  }

  isMatrixTextbox(index: number) {
    if (this.columnIds[index] == "" || this.analysis == undefined) {
      return false;
    }

    let question = this.analysis.interview.findQuestion(this.columnIds[index]);
    let temp = question?.QuestionType == "matrix" && question?.Control == "textbox";
    if (temp) {
      let a = 90;
    }
    return temp;
  }

  isBucket(index: number) {
    if (this.columnIds[index] == "" || this.analysis == undefined) {
      return false;
    }

    let question = this.analysis.interview.findQuestion(this.columnIds[index]);
    return question?.QuestionType == "bucket";
  }

  isRanked(index: number): boolean {
    if (this.columnIds[index] == "" || this.analysis == undefined) {
      return false;
    }

    let question = this.analysis.interview.findQuestion(this.columnIds[index]);
    return question?.QuestionType == "ranking";
  }

  getMultipleChoiceLabels(element: BasicData[], index: number): string {
    let e = this.getColumn(element, index);
    if (e == undefined || e.Response == undefined || this.analysis == undefined) {
      return " - ";
    }

    let responses = e.Response.toString().split(',');
    let question = this.analysis.interview.findQuestion(this.columnIds[index]);
    if (question == null){
      return " - ";
    }
    let response = "";
    for (let i = 0; i < question.Categories.List.length; i++) {
      if (responses.includes(question.Categories.List[i].Name) || responses.includes("_" + question.Categories.List[i].Name)) {
        response += (response == "" ? "" : ", ") + question.Categories.List[i].Label;
      }
    }

    return response == "" ? e.Response.toString().replace("_", "") + " - Invalid" : response;
  }

  getMatrixLabels(element: BasicData[], index: number): string {
    let e = this.getMatrixColumn(element, index);
    if (e == undefined || e.Response == undefined || this.analysis == undefined) {
      return " - ";
    }

    let responses = e.Response.toString().split(',');
    let question = this.analysis.interview.findQuestion(this.columnIds[index]);
    if (question == null) {
      return " - ";
    }
    let response = "";
    for (let i = 0; i < question.Parameters.length; i++) {
      if (responses.includes(question.Parameters[i].Name) || responses.includes("_" + question.Parameters[i].Name)) {
        response += (response == "" ? "" : ", ") + question.Parameters[i].Value;
      }
    }

    return response == "" ? " - " : response;
  }

  getBucketLabels(element: BasicData[], index: number): string {
    let question = this.analysis.interview.findQuestion(this.columnIds[index]);
    let e = this.getBucketColumns(element, index);
    if (e == undefined || e.length == 0 || this.analysis == undefined) {
      return " - ";
    }

    if (e.length == 0) {
      return " - ";
    }

    let response = "";
    for (let j = 0; j < e.length; j++) {
      for (let i = 0; i < question.Categories.List.length; i++) {
        if (e[j].VariableName.endsWith(".Bucket" + i)) {
          response += (response == "" ? "" : ", ") + question.Categories.List[i].Label;
        }
      }
    }

    return response == "" ? " - " : response;
  }

  getRankedChoiceLabels(element: BasicData[], index: number): string {
    let e = this.getColumns(element, index);
    if (e.length == 0 || this.analysis == undefined) {
      return " - ";
    }

    let question = this.analysis.interview.findQuestion(this.columnIds[index]);
    if (question == null) {
      return " - ";
    }

    let response = "";
    for (let i = 1; i < question.Categories.List.length + 1; i++) {
      for (let j = 0; j < e.length; j++) {
        let m = -1;
        if (Number(e[j].Response) == i) {
          for (let k = 0; k < question.Categories.List.length; k++) {
            if (e[j].VariableName.endsWith("[{" + question.Categories.List[k].Name + "}].Rank")) {
              m = k
              response += (response == "" ? "" : ", ") + question.Categories.List[k].Label;
              break;
            }
          }

          if (m > -1) {
            for (let k = 0; k < e.length; k++) {
              if (e[k].VariableName.endsWith("]." + question.Categories.List[m].Name + ".Other")) {
                response += " (" + e[k].Response + ")";
                break;
              }
            }
          }
        }
      }
    }

    return response == "" ? " - " : response;
  }

  getBucketCategories(element: BasicData[], index: number): CategoryModel[] {
    let question = this.analysis.interview.findQuestion(this.columnIds[index]);
    let e = this.getBucketColumns(element, index);
    if (e == undefined || e.length == 0 || this.analysis == undefined) {
      return [];
    }

    if (e.length == 0) {
      return [];
    }

    let c = [];
    for (let j = 0; j < e.length; j++) {
      for (let i = 0; i < question.Categories.List.length; i++) {
        if (e[j].VariableName.endsWith(".Bucket" + i)) {
          c.push(question.Categories.List[i]);
        }
      }
    }

    return c;
  }

  getRankedCategories(element: BasicData[], index: number): CategoryModel[] {
    if (this.analysis == undefined) {
      return [];
    }

    let e = this.getColumns(element, index);
    let question = this.analysis.interview.findQuestion(this.columnIds[index]);
    if (question == null) {
      return [];
    }

    let c = [];
    for (let i = 1; i < question.Categories.List.length + 1; i++) {
      for (let j = 0; j < e.length; j++) {
        let m = -1;
        if (Number(e[j].Response) == i) {
          for (let k = 0; k < question.Categories.List.length; k++) {
            if (e[j].VariableName.endsWith("[{" + question.Categories.List[k].Name + "}].Rank")) {
              m = k
              c.push(question.Categories.List[k]);
              break;
            }
          }
        }
      }
    }

    return c;
  }

  getPosition(values: string[], name: string): number {
    for (let i = 0; i < values.length; i++) {
      if (values[i].toLowerCase() == name.toLowerCase()) {
        return i;
      }
    }

    return -1;
  }

  getMaxDiffValue(element: BasicData[], index: number): string {
    if (this.analysis == undefined) {
      return "";
    }

    let values = [];
    let question = this.analysis.interview.findQuestion(this.columnIds[index]);
    let sets = Number(question.findParameter("setCount").Value);
    for (let i = 1; i <= sets; i++) {
      let e = this.getMaxDiffColumns(element, index, i);
      let pos = [0, 0];
      let longs = [0, 0];
      for (let j = 0; j < e.length; j++) {
        pos[j] = this.getPosition(values, e[j].ReportHelper.Parts[0]);
        longs[j] = e[j].Response as unknown as number;
        if (pos[j] == -1) {
          values.push(e[j].ReportHelper.Parts[0]);
          pos[j] = values.length - 1;
        }
      }

      if (e.length > 0) {
        if (longs[0] < longs[1] && pos[0] > pos[1]) {
          let temp = values[pos[0]];
          values[pos[0]] = values[pos[1]];
          values[pos[1]] = temp;
        }
      }
    }

    let pointer = -1;

    for (let i = 0; i < question.Categories.List.length; i++) {
      if (this.columnNames[index].endsWith("." + question.Categories.List[i].Name)) {
        pointer = i;
      }
    }

    if (pointer == -1) {
      return "";
    }

    let i = this.getPosition(values, question.Categories.List[pointer].Name);
    return i == -1 ? "" : i.toString();
  }

  getCategories(element: BasicData[], index: number): CategoryModel[] {
    if (this.analysis == undefined) {
      return [];
    }

    let question = this.analysis.interview.findQuestion(this.columnIds[index]);
    let e = this.getColumn(element, index);
    if (e == undefined || e.Response == undefined) {
      return question?.Categories.List ?? [];
    }

    let responses = e.Response.toString().split(',');
    let cats = [];
    for (let i = 0; i < responses.length; i++) {
      let found = false;
      for (let subIndex = 0; subIndex < question.Categories.List.length; subIndex++) {
        if (responses[i] == question.Categories.List[subIndex].Name || responses[i] == "_" + question.Categories.List[subIndex].Name) {
          cats.push(question.Categories.List[subIndex]);
          found = true;
          break;
        }
      }

      if (!found) {
        responses[i] = responses[i].replace("_", "");
        let cat = new CategoryModel(cats.length, responses[i], "Invalid Answer", false, "");
        cats.push(cat);
      }
    }

    return cats;
  }

  getParameters(element: BasicData[], index: number): ParameterModel[] {
    if (this.analysis == undefined) {
      return [];
    }

    let question = this.analysis.interview.findQuestion(this.columnIds[index]);
    return question?.Parameters ?? [];
  }

  getCategoryIcon(element: BasicData[], index: number, subIndex: number): string {
    let icon = "check_box_outline_blank";
    if (this.analysis == undefined) {
      return icon;
    }

    let question = this.analysis.interview.findQuestion(this.columnIds[index]);
    if (question == null) {
      return icon;
    }

    if (question?.QuestionType == "mcsa") {
      icon = "radio_button_unchecked";
    }

    if (question?.QuestionType == "boolean") {
      icon = "check_box_outline_blank";
    }

    let e = this.getColumn(element, index);
    if (e == undefined || e.Response == undefined) {
      return icon;
    }

    let responses = e.Response.toString().split(',');
    if (question.Categories.List[subIndex] != undefined
        && (responses.includes(question.Categories.List[subIndex].Name) || responses.includes("_" + question.Categories.List[subIndex].Name))) {
      return question.QuestionType == "mcsa" ? "radio_button_checked" : (question.QuestionType == "boolean" ? "indeterminate_check_box" : "check_box");
    }

    return icon;
  }

  getParameterIcon(element: BasicData[], index: number, subIndex: number): string {
    let icon = "check_box_outline_blank";
    if (this.analysis == undefined) {
      return icon;
    }

    let question = this.analysis.interview.findQuestion(this.columnIds[index]);
    if (question == null) {
      return icon;
    }

    if (question?.QuestionType == "mcsa") {
      icon = "radio_button_unchecked";
    }

    if (question?.QuestionType == "boolean") {
      icon = "check_box_outline_blank";
    }

    let e = this.getMatrixColumn(element, index);
    if (e == undefined || e.Response == undefined) {
      return icon;
    }

    let responses = e.Response.toString().split(',');
    if (question.Categories.List[subIndex] != undefined
        && (responses.includes(question.Categories.List[subIndex].Name) || responses.includes("_" + question.Categories.List[subIndex].Name))) {
      return question.QuestionType == "mcsa" ? "radio_button_checked" : (question.QuestionType == "boolean" ? "indeterminate_check_box" : "check_box");
    }

    return icon;
  }

  getColumnChecked(index: number): boolean {
    return this.columnsToView[index].View;
  }

  toggleColumn(index: number) {
    this.columnsToView[index].View = !this.columnsToView[index].View;
    if (this.columnsToView[index].View) {
      for (let i = 0; i < this.columnIds.length; i++) {
        if (this.columnIds[i] == this.columnsToView[index].Id && this.columnNames[i] == this.columnsToView[index].Name) {
          return;
        }
      }

      this.columnIds.splice(index + 1, 0, this.columnsToView[index].Id);
      this.columns.splice(index + 1, 0, this.columnsToView[index].Summary);
      this.columnNames.splice(index + 1, 0, this.columnsToView[index].Name)
      return;
    }

    for (let i = 0; i < this.columnIds.length; i++) {
      if (this.columnIds[i] == this.columnsToView[index].Id && this.columnNames[i] == this.columnsToView[index].Name) {
        this.columnIds.splice(i, 1);
        this.columns.splice(i, 1);
        this.columnNames.splice(i, 1)
        return;        
      }
    }
  }

  selectMainFilter(index: number) {
    this.mainFilterType = this.getMainFilter(index).value;
    this.page = 1;
    if (this.paginator != undefined) {
      this.paginator.pageIndex = 0;
    }

    this.buildPageData();
  }

  getMainFilter(index: number) {
    return this.mainFilter[index - 1];
  }

  isNot(element: BasicData[], index: number): boolean {
    return !this.isMatrix(index) && !this.isMaxDiff(index) && !this.isMultipleChoice(index) && !this.isRanked(index) && !this.isDate(index) && index + 1 < this.columns.length;
  }

  viewInterview(element: BasicData[]) {
    let model = this.analysis.interview;
    const dialogRef = this.dialog.open(ReviewDataComponent, {
      data: element,
      width: '80%'
    });
  }


  makeComplete(element: BasicData[]) {

  }

  refresh() {
    this.caseService.getTotals(this.analysis.survey.SurveyID).subscribe(
      result => {
        this.analysis.survey.Totals = result;
        this.checkTotals();
        this.page = 1;
        if (this.paginator != undefined) {
          this.paginator.pageIndex = 0;
        }

        this.buildPageData();
      });
  }

  replayInterview(element: BasicData[]) {
    let model = this.analysis.interview;
    let id = this.getColumnByName(element, "datacollection.panellistreferenceid").Response;
    const dialogRef = this.dialog.open(InterviewPreviewComponent, {
      data: { model: model, isReview: true, id: id },
      width: '80%'
    });
  }

  noFilter(): boolean {
    if (this.question == undefined || this.question.Rules == undefined || this.question.Rules.length == 0) {
      return true;
    }

    return this.question.Rules[0].Rules.length == 0;
  }

  clearFilters() {
    this.question.Rules[0].Question = this.question;
    this.question.Rules[0].Rules = [];
    this.page = 1;
    if (this.paginator != undefined) {
      this.paginator.pageIndex = 0;
    }

    this.buildPageData();
  }

  showFilters() {
    const dialogRef = this.dialog.open(RoutingDialogComponent,
      {
        data: { name: "Export Filter ", rules: this.question.Rules[0] },
        height: '50%',
        width: '90%'
      });

    dialogRef.afterClosed().subscribe(
      result => {
        this.page = 1;
        if (this.paginator != undefined) {
          this.paginator.pageIndex = 0;
        }

        this.buildPageData();
      });
  }

  edit(column: any) {

  }
}

class ColumnToView {
  View: boolean = true;
  Id: string;
  Name: string;
  Summary: string;

  constructor(id: string, name: string, summary: string) {
    this.Id = id;
    this.Name = name;
    this.Summary = summary;
  }
}

