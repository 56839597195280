import { Component, Input } from '@angular/core';
import { CaseDataService } from '../../casedata.Service';
import { RuleList } from '../../question.service';
import { SurveyService, SurveySummaryModel } from '../../survey.Service';
import { DataAnalysisComponent } from '../data-analysis.component';

@Component({
  selector: 'app-survey-summary',
  templateUrl: './survey-summary.component.html',
  styleUrls: ['./survey-summary.component.css']
})
export class SurveySummaryComponent {

  surveySummary: SurveySummaryModel;
  inviteMessage: string = "";
  results: StatData[] = [];
  invites: StatData = null;
  dropoffRates: StatData[] = [];
  total: number;
  rules: RuleList = new RuleList();
  inviteTotal: StatData = null;

  constructor(private caseService: CaseDataService,
    private surveyService: SurveyService) {
  }

  @Input()
  get analysis(): DataAnalysisComponent | undefined {
    return this._analysis
  }
  set analysis(value: DataAnalysisComponent | undefined) {
    this._analysis = value;
  }
  private _analysis: DataAnalysisComponent | undefined;

  @Input()
  get modelChecked(): boolean {
    return this._modelChecked;
  }
  set modelChecked(value: boolean) {
    this._modelChecked = value;
    if (this._modelChecked) {
      this.surveyService.getSurveySummary(this._analysis.survey.SurveyID)
        .subscribe(result => {
          this.surveySummary = result;
          switch (this.surveySummary.Channel) {
            case 1:
              this.inviteMessage = "SMS Two Way Messaging Survey";
              break;
            case 3:
              this.inviteMessage = "WhatsApp Invites to a Web Survey";
              break;
            case 4:
              this.inviteMessage = "SMS Invitations to a Web Survey";
              break;
            case 5:
              this.inviteMessage = "WhatsApp Two Way Messaging Survey";
              break;
            default:
              this.inviteMessage = "Email Invitations to a Web Survey"
              break;
          }

          this.total = 0;
          for (let i = 0; i < this.analysis.survey.Totals.length; i++) {
            switch (this.analysis.survey.Totals[i].Status) {
              case 1:
              case 2:
              case 4:
              case 7:
              case 3:
              case 5:
              case 8:
              case 10:
                this.total += this.analysis.survey.Totals[i].Total;
                break;
            }
          }


          this.invites = new StatData("", 100, this.total, "Invitations");
          this.addResult("Target", this.surveySummary.PanellistCount, "Target for a 100% completion");
          this.results.push(new StatData("Invites", this.total / (this.total == 0 ? this.surveySummary.PanellistCount : this.total) * 100, this.total, "Invitations"));
          if (this.total == 0) {
            this.total = this.surveySummary.PanellistCount;
          }

          this.addResult("Started", this.getSurveyTotal(1), "Interviews started");
          this.addResult("Bounced", this.getSurveyTotal(10), "Interviews bounced due to outbound message failure");
          this.addResult("Completed", this.getSurveyTotal(2), "Interviews completed");
          this.addResult("Stopped", this.getSurveyTotal(4), "Interviews stopped due to an inbound 'STOP' message");
          this.addResult("Screened Out", this.getSurveyTotal(7), "Interviews screened out");
          this.addResult("Quota Exceeded", this.getSurveyTotal(3), "Interviews where the quota has been exceeeded");
          this.addResult("Timed Out", this.getSurveyTotal(5), "Interview timed out, due to lack of response");
          this.addResult("Anomalies", this.getSurveyTotal(8), "Interviews that are paused due to an invalid response");
        });

      this.rules.Question = this.analysis.interview.Questions[0];
    }
  }
  private _modelChecked: boolean = false;

  addResult(name: string, value: number, description: string) {
    this.results.push(new StatData(name, value / this.total * 100, value, description));
  }

  getSurveyTotal(index: number): number {
    for (let i = 0; i < this.analysis.survey.Totals.length; i++) {
      if (this.analysis.survey.Totals[i].Status == index) {
        return this.analysis.survey.Totals[i].Total;
      }
    }

    return 0;
  }

  calculateReminders() {
    this.caseService.getTotalResults(this.analysis.survey.SurveyID, 1, this.analysis.interview, this.rules).subscribe(results => {
      this.inviteTotal.value = results;
    });
  }
}

class StatData {
  name: string;
  percent: number;
  value: number;
  description: string;
  constructor(name: string, percent: number, value: number, description: string) {
    this.name = name;
    this.percent = percent;
    this.value = value;
    this.description = description;
  }
}
