<div class="fullWidth mt-10">
  <mat-sidenav-container>
    <mat-sidenav #leftSidenav class="reports-mat-sidenav-left" mode="side" opened [disableClose]="true">
        <div *ngIf="analysis" class="simple-row">
          &nbsp;
          <button mat-stroked-button [matMenuTriggerFor]="ioMenu">
            <mat-icon>expand_more</mat-icon>
            <span>Create</span>
          </button>&nbsp;
          <button mat-stroked-button (click)="showImportDataDefinition()">
            <span>Import</span>
          </button>&nbsp;
          <button mat-icon-button (click)="getProcesses()">
            <mat-icon>refresh</mat-icon>
          </button>
          <mat-menu #ioMenu="matMenu">
            <button mat-menu-item (click)="newProcess(true, false)">
              <span>Export Data...</span>
            </button>
            <button mat-menu-item (click)="newProcess(true, true)">
              <span>Automate Data Export...</span>
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item (click)="newProcess(false, false)">
              <span>Import Data...</span>
            </button>
            <button mat-menu-item (click)="newProcess(false, true)">
              <span>Automate Data Import...</span>
            </button>
          </mat-menu>
        </div>
        <div class="reports-leftSidebar-list custom-scroll-bar comm-list-sidebar-div">
          <div class="reports-question-sidebar-box" *ngFor="let detail of details; let i = index" cdkDrag>
            <div (click)="selectedIndex = i" [class]="selectedIndex == i ? 'comm-list-selected' : 'comm-list-notSelected'">
              <div class="reports-qnSidebar-text">
                <span>{{detail.name}}&nbsp;{{detail.processes?.length > 0 ? '(' + detail.processes?.length + ')' : ''}}</span>
              </div>
            </div>
          </div>
        </div>
    </mat-sidenav>

    <mat-sidenav-content class="comm-list-sidenav-content">
      <div class="comm-list-sidenav-div">
        <div class="margin-5">
          <h3>{{details[selectedIndex].description}}</h3>
        </div>
        <div *ngIf="details[selectedIndex].processes?.length < 1">
          There is no {{details[selectedIndex].name}} definitions
        </div>
        <div>
          <div class="custom-scroll-bar comm-list-editor-div">
            <app-process-definition *ngFor="let process of details[selectedIndex].processes" [analysis]="analysis" [process]="process" (closed)="processClosed($event)"></app-process-definition>
          </div>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
<ng-template #importDataDialog>
  <div class="fullWidth dialog-title">
    <div class="leftFloat">
      <h3>Import Data I/O Definition</h3>
    </div>
    <div class="rightFloat">
      <button mat-icon-button matDialogClose="no" matTooltip="Close" matTooltipPosition="below">
        <mat-icon class="dialog-close-icon">close</mat-icon>
      </button>
    </div>
  </div>
  <div class="clearBoth mt-10">
    <app-file-upload #dataIOImport accept=".txt" text="Import Data Definition" (uploaded)="importDataDefinition($event)"></app-file-upload>
  </div>
</ng-template>

