<div class="halfWidth auto-margin">
  <form *ngIf="tempPanelist | async; else loading" class="flex-stretch-column" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <mat-accordion>
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title class="font-wt-500">
            Panelist Details
          </mat-panel-title>
          <mat-panel-description>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <p>
          Created:{{ panelist.CreatedDate | date:'medium' }} and Last Updated:{{ panelist.LastUpdatedDate | date:'medium' }}
        </p>
        <div class="halfWidth">
          <div>
            <mat-form-field appearance="outline" class="fullWidth">
              <mat-label>Title</mat-label>
              <mat-select formControlName="Title">
                <mat-option value="1">Mr</mat-option>
                <mat-option value="2">Mrs</mat-option>
                <mat-option value="3">Miss</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field appearance="outline" class="fullWidth">
              <mat-label>First Name</mat-label>
              <input matInput placeholder="First Name" formControlName="FirstName">
            </mat-form-field>
          </div>
          <div>
            <mat-form-field appearance="outline" class="fullWidth">
              <mat-label>Last Name</mat-label>
              <input matInput placeholder="Last Name" formControlName="LastName">
            </mat-form-field>
          </div>
          <div>
            <mat-form-field appearance="outline" class="fullWidth">
              <mat-label>Email</mat-label>
              <input matInput placeholder="Email" formControlName="Email">
            </mat-form-field>
          </div>
          <div>
            <mat-form-field appearance="outline" class="fullWidth">
              <mat-label>Mobile Number</mat-label>
              <input matInput placeholder="Mobile Number" formControlName="MobileNumber">
            </mat-form-field>
          </div>
          <div>
            <mat-form-field appearance="outline" class="fullWidth">
              <mat-label>Date of birth</mat-label>
              <input matInput placeholder="Date of birth" formControlName="DOB">
            </mat-form-field>
          </div>
          <div>
            <mat-form-field appearance="outline" class="fullWidth">
              <mat-label>When</mat-label>
              <input matInput placeholder="When" formControlName="WhenTrigger">
            </mat-form-field>
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel *ngIf="responseArr.controls.length > 0">
        <mat-expansion-panel-header>
          <mat-panel-title class="font-wt-500">
            Custom Data
          </mat-panel-title>
          <mat-panel-description>

          </mat-panel-description>
        </mat-expansion-panel-header>
        <div formArrayName="array">
          <div *ngFor="let response of responseArr.controls; let i = index" [formGroupName]="i">
            <mat-form-field appearance="outline" class="fullWidth">
              <mat-label>{{responses[i].Name}}</mat-label>
              <input matInput placeholder="{{responses[i].Name}}" formControlName="response">
            </mat-form-field>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </form>
</div>

<ng-template #loading>
  <div class="loading-container">
    <span class="loading-content">
      <div class="spinner-div"><mat-spinner [diameter]="25"></mat-spinner></div>
      <div class="spinner-text"> Please Wait...</div>
    </span>
  </div>
</ng-template>

