<div>
  <h3>Users</h3>
  <div class="fieldRow">
    <div>
      <button mat-raised-button color="primary" (click)="createUser()">Create New User</button>&nbsp;&nbsp;
    </div>
    <div>
      <mat-form-field appearance="outline">
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Filter" #input>
      </mat-form-field>
    </div>
    <div *ngIf="tempUsers | async">
      &nbsp;{{Users.length}} User(s)
    </div>
    <div *ngIf="tempUsers | async">
      &nbsp;<button mat-raised-button color="primary" (click)="export()">Export</button>
    </div>
  </div>
  <div>
    <br />
  </div>
  <table *ngIf="tempUsers | async; else loading" mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="UserID">
      <th mat-header-cell *matHeaderCellDef> ID. </th>
      <td mat-cell *matCellDef="let element"> {{element.UserID}} </td>
    </ng-container>

    <ng-container matColumnDef="BusinessType">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let element">
        <div style="margin-top:10px">
          <mat-icon *ngIf="isTemplate(element)" [matBadge]="templateType(element)" matBadgeSize="small" [matBadgeColor]="templateType(element) == 'T' ? 'accent' : 'warn'">{{businessType(element)}}</mat-icon> <mat-icon *ngIf="!isTemplate(element)">{{businessType(element)}}</mat-icon>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="CreateDate">
      <th mat-header-cell *matHeaderCellDef> Created </th>
      <td mat-cell *matCellDef="let element"> {{element.CreatedDate  | date:'short' : '' : translate.currentLang}} </td>
    </ng-container>

    <ng-container matColumnDef="LastLoggedIn">
      <th mat-header-cell *matHeaderCellDef> Last Logged In </th>
      <td mat-cell *matCellDef="let element"> {{element.LastLoggedIn  | date:'medium'}} </td>
    </ng-container>

    <ng-container matColumnDef="UserName">
      <th mat-header-cell *matHeaderCellDef> Username </th>
      <td mat-cell *matCellDef="let element"> {{element.UserName}} </td>
    </ng-container>

    <ng-container matColumnDef="FirstName">
      <th mat-header-cell *matHeaderCellDef> Firstname </th>
      <td mat-cell *matCellDef="let element"> {{element.FirstName}} </td>
    </ng-container>
    <ng-container matColumnDef="LastName">
      <th mat-header-cell *matHeaderCellDef> Lastname </th>
      <td mat-cell *matCellDef="let element"> {{element.LastName}} </td>
    </ng-container>
    <ng-container matColumnDef="EmailAddress">
      <th mat-header-cell *matHeaderCellDef> Email </th>
      <td mat-cell *matCellDef="let element"> <div matBadge="!" [matBadgeHidden]="element.IsConfirmed" matBadgeSize="small" matBadgePosition="before" [matTooltip]="element.IsConfirmed ? '' : 'This account has not been verified'">{{element.EmailAddress}} </div></td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row class="users-element-row" *matRowDef="let row; columns: displayedColumns;" (click)="edit(row)" (contextmenu)="onContextMenu($event, row)"></tr>
    <tr class="users-element-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
    </tr>
  </table>
  <div [ngStyle]="{position:'fixed',top:contextMenuPosition.y,left:contextMenuPosition.x}" [matMenuTriggerFor]="contextMenu"></div>
  <mat-menu #contextMenu="matMenu">
    <ng-template matMenuContent let-user="user">
      <button mat-menu-item (click)="edit(user)">
        <mat-icon>edit</mat-icon>
        <span>Edit User</span>
      </button>
      <button mat-menu-item (click)="impersonate(user)">
        <mat-icon>people_alt</mat-icon>
        <span>Impersonate</span>
      </button>
      <button mat-menu-item (click)="signin(user)">
        <mat-icon>login</mat-icon>
        <span>Signin</span>
      </button>
    </ng-template>
  </mat-menu>

  <br />
  <br />
  <br />
  <br />
  <br />
</div>

<ng-template #loading>
  <div class="loading-container">
    <span class="loading-content">
      <div class="spinner-div"><mat-spinner [diameter]="25"></mat-spinner></div>
      <div class="spinner-text"> Loading users...</div>
    </span>
  </div>
  <br /><br />
</ng-template>

