import { Component, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { plainToClass } from 'class-transformer';
import { NetworkModel, NetworkService, RequestModel } from '../../network.Service';

@Component({
  selector: 'app-manage-network',
  templateUrl: './manage-network.component.html',
  styleUrls: ['./manage-network.component.css']
})
export class ManageNetworkComponent {

  columns: string[] = ['name', 'country', 'number', 'created', 'amended', 'whiteliststatus', 'registrationstatus', 'action'];
  requests: RequestModel[] = [];
  dataSource = new MatTableDataSource<RequestModel>();
  networks: NetworkModel[] = [];


  @ViewChild('table', { static: true }) table: any | undefined;

  constructor(public translate: TranslateService,
    private router: Router,
    private networkSerivce: NetworkService
  ) {
    this.networkSerivce.getNetworks().subscribe(result => { this.networks = result; });
    this.networkSerivce.getRequests().subscribe(result => {
      for (let i = 0; i < result.length; i++) {
        this.requests.push(plainToClass(RequestModel, result[i]));
      }

      this.dataSource = new MatTableDataSource<RequestModel>(this.requests);
    })
  }

  newRequest() {
    this.router.navigate(["/networkrequest", 0, "request"]);
  }

  edit(request: RequestModel) {
    this.router.navigate(["/networkrequest", request.RequestId, "request"]);
  }

  delete(request: RequestModel) {

  }

  getNetwork(request: RequestModel): NetworkModel {
    if (request.getNetwork() == null || request.getNetwork().NetworkId == 0) {
      for (let i = 0; i < this.networks.length; i++) {
        if (request.NetworkId == this.networks[i].NetworkId) {
          request.setNetwork(this.networks[i]);
          return request.getNetwork();
        }
      }
    }

    return request.getNetwork();
  }
}
