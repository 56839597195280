<mat-toolbar [class]="checkStyle()" class="mat-toolbar-container-noshadow">
  <a [routerLink]="'/newhome'"><img src="assets/img/logo.svg" class="toolbar-logo" (click)="scrollTo('home')" /></a>&nbsp;&nbsp;
  <button mat-button (click)="scrollTo('home')">Surveys</button>&nbsp;&nbsp;
  <button mat-button (click)="scrollTo('step2')">Buy an Audience</button>&nbsp;&nbsp;
  <button mat-button (click)="scrollTo('marketing')">Marketing Solutions</button>&nbsp;&nbsp;
  <button mat-button (click)="scrollTo('enterprise')">Enterprise</button>&nbsp;&nbsp;
  <span class="toolbar-spacer"></span>
  <div class="toolbar-header-right-container" [class]="(isHandset$ | async) ? 'noDisplay' : ''">
    <a class="a" href="mailto:hello@diysurveys.com">hello&#64;diysurveys.com</a>&nbsp;&nbsp;
    <button mat-raised-button color="primary" [routerLink]="'/newlogin'">
      LOG IN
    </button>&nbsp;&nbsp;
    <button mat-raised-button color="accent" [routerLink]="'/newsignup'">SIGN UP</button>
  </div>
</mat-toolbar>
<div class="toolbar-handset-container">
  <div class="toolbar-handset">
    <a [routerLink]="'/newhome'"><img src="assets/img/logo.svg" class="toolbar-logo" /></a>
  </div>
  <div class="login-signup-div" [class]="(isHandset$ | async) ? '' : 'noDisplay'">
    <button mat-raised-button color="primary" [routerLink]="'/newlogin'" class="signup-btn-handset">
      LOG IN
    </button>&nbsp;&nbsp;
    <button mat-raised-button color="accent" [routerLink]="'/newsignup'" class="signup-btn-handset">
      SIGN UP
    </button>
  </div>
</div>
<div #body class="body" [class]="(isHandset$ | async) ? 'noDisplay' : ''">
  <div #home class="header">
    <div class="headerContent">
      <div class="left" [@leftIn]="start">
        <h1>
          Powerful enough for Researchers...
        </h1>
        <h2>
          Simple enough for Everyone!
        </h2>
        <p>
          Access to a set of unlimited tools for survey creation and feedback analysis!
        </p>
        <p>
          <span class="strong">Get started with a Free Account</span>
        </p>
        <div [formGroup]="formGroup" class="fieldRow">
          <div>
            <mat-form-field appearance="outline" class="fullWidth">
              <mat-label>Email Address</mat-label>
              <input matInput placeholder="Email Address" formControlName="email">
            </mat-form-field>
          </div>
          &nbsp;
          <div>
            <button mat-raised-button (click)="startForFree()" color="primary">Start for Free</button>
          </div>
        </div>
      </div>
      <div class="right" [@rightIn]="start">
        <img src="assets/img/sign-in-image@2x.png" class="image picture" />
      </div>
    </div>
    <div #create class="more">
      <button mat-fab class="iconButton" aria-label="Example icon button with a bookmark icon" (click)="scrollTo('create')">
        <mat-icon>expand_more</mat-icon>
      </button>
    </div>
  </div>
  <div class="section">
    <div class="sectionContent">
      <h1>Create a survey in minutes</h1>
    </div>
  </div>
  <div class="section">
    <div class="simple-row">
      <div class="wd-25-per margin-5"></div>
      <div class="number">1</div>
      <div class="wd-25-per margin-5"><mat-divider></mat-divider></div>
      <div class="number">2</div>
      <div class="wd-25-per margin-5"><mat-divider></mat-divider></div>
      <div class="number">3</div>
      <div class="wd-25-per margin-5"></div>
    </div>
    <div>
      &nbsp;
    </div>
    <div class="simple-row">
      <div class="wd-30-per"></div>
      <div>
        <h2>Use One of our survey templates</h2>
      </div>
      <div class="wd-15-per"></div>
      <div>
        <h2>Buy respondents to meet your needs</h2>
      </div>
      <div class="wd-15-per"></div>
      <div>
        <h2>Choose the best channel for your audience</h2>
      </div>
      <div class="wd-30-per"></div>
    </div>
    <div>
    </div>
  </div>
  <div class="section">
    <div class="sectionContent">
      <div class="sectionCard">
        <mat-card class="txt-align-center sectionCardContent" (click)="scrollTo('step1')">
          <div class="margin-20 mb-2">
            <img src="../../assets/img/report1.svg" width="40" />
          </div>
          <span class="strong mb-20">Survey Templates</span>
          <mat-card-content class="mb-20">
            NPS, CES, CSAT Combination, Event Feedback, Coffee shop, Garage Service Survey…
          </mat-card-content>
        </mat-card>
        <mat-card class="txt-align-center sectionCardContent" (click)="scrollTo('step2')">
          <div class="margin-20 mb-2">
            <img src="../../assets/img/report2.svg" width="40" />
          </div>
          <span class="strong mb-20">
            Buy or Upload
          </span>
          <mat-card-content class="mb-20">
            Upload your respondents or use our - <span [countUp]="[100,10000]"></span>+ million people, <span [countUp]="[60,10000]"></span> countries, <span [countUp]="[50,10000]"></span>+ demographics
          </mat-card-content>
        </mat-card>
        <mat-card class="txt-align-center sectionCardContent" (click)="scrollTo('step3')">
          <div class="margin-20 mb-2">
            <img src="../../assets/img/report3.svg" width="40" />
          </div>
          <span class="strong mb-20">
            Choose a Channel
          </span>
          <mat-card-content class="mb-20">
            Choose from Email, SMS, WhatsApp and many other channels to get your feedback from
          </mat-card-content>
        </mat-card>
      </div>
      <div #step1>
        <div class="wa-main-container">
          <div style="display:flex;min-width:50px">
            <span class="number">4</span>
            <img src="../../assets/img/report4.svg" width="40" />
            Understand your feedback
            <button mat-icon-button aria-label="Example icon button with a bookmark icon" (click)="scrollTo('step4')">
              <mat-icon>expand_more</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <div>
        Every step can be customised to get the best results
      </div>
    </div>
  </div>
  <div style="background-color:white;margin-top:-350px;padding-top:350px">
    <div class="section">
      <div class="sectionContent" style="display: flex; width: 80%; margin: 30px auto; ">
        <div class="leftImage" style="width:80%" [@leftIn]="start && p1">
          <div class="display-flex">
            <span class="number">1</span>&nbsp;
            <h2>Use one of our survey templates</h2>
          </div>
          <h1>Rely on our Research Experience</h1>
          <p>
            Access to survey templates to save you time and let you focus on your audience.
          </p>
          <mat-divider></mat-divider>
          <br />
          <p>
            For an experienced researcher we offer a full question editor with a simple look and feel controls and drag and drop controls.
          </p>
          <div #step2></div>
        </div>
        <div class="right" [@rightIn]="start && p1" (appear)="p1 = true">
          <img class="picture" src="../../assets/img/step1.svg" />
        </div>
      </div>
    </div>
    <div class="section">
      <div class="sectionContent" style="display: flex; width: 80%; margin: 30px auto; ">
        <div class="left" [@leftIn]="start && p2">
          <img class="picture" src="../../assets/img/landing-page-image.png" />
        </div>
        <div class="rightImage" style="width:80%" [@rightIn]="start && p2" (appear)="p2 = true">
          <div style="display:flex;">
            <span class="number">2</span>&nbsp;
            <h2>
              Buy respondents to meet your needs
            </h2>
          </div>
          <h1>
            Reach the right people
          </h1>
          <p>
            100+ million people, 60 countries, 50+ demographics
            Select target audiences quickly, easily and at a low price
            Choose the demographics that you require: age, gender, region, income and much much more (over 50+ attributes)
          </p>
          <mat-divider></mat-divider>
          <br />
          <p>
            For an experienced researcher we offer a full question editor with a simple look and feel controls and drag and drop controls.
          </p>
          <div #step3></div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="sectionContent" style="display: flex; width: 80%; margin: 30px auto; ">
        <div class="leftImage" style="width:80%" [@leftIn]="start && p3">
          <div style="display:flex;">
            <span class="number">3</span>&nbsp;
            <h2>
              Choose the best channel for your audience
            </h2>
          </div>
          <h1>
            We are Connected!
          </h1>
          <p>
            DIY Surveys offer the only service that connects into:
            Email
            SMS
            Chat Solutions – WhatsApp, Facebook Messaging
          </p>
          <mat-divider></mat-divider>
          <br />
          <p>
            For an experienced researcher we offer a full question editor with a simple look and feel controls and drag and drop controls.
          </p>
        </div>
        <div class="right" [@rightIn]="start && p3" (appear)="p3 = true">
          <img class="picture" style="width:100%" src="../../assets/img/AllChannels.png" />
        </div>
      </div>
    </div>
    <div #step4 class="section">
      <div class="sectionContent" style="display: flex; width: 80%; margin: 30px auto; ">
        <div class="left" [@leftIn]="start && p2">
          <img class="picture" src="../../assets/img/Reporting.png" />
        </div>
        <div class="rightImage" style="width:80%" [@rightIn]="start && p2" (appear)="p2 = true">
          <div style="display:flex;">
            <span class="number">4</span>&nbsp;
            <h2>
              Understand understand your feedback
            </h2>
          </div>
          <h1>
            Integrated Reports
          </h1>
          <p>
            Use our fully integrated reports to create and export your presentations quickly and painlessly.
          </p>
          <mat-divider></mat-divider>
          <br />
          <p>
            For an experienced researcher we offer full integration with real time updates to schedules batch updates in any format to any platform.
          </p>
          <div #step3></div>
        </div>
      </div>
    </div>
    <div #marketing class="altSection">
      <div class="sectionContent" style="display: flex; width: 80%; margin: 30px auto; ">
        <div><img src="assets/img/icon-sms-tools.png" /></div>&nbsp;&nbsp;
        <h2>
          Marketing Solutions
        </h2>
        <p>
          Surveys may be too complicated for you and all you want is to send a message to your audience. We can send it via, email, SMS or WhatsApp in as simple a process as a 'Mail Merge'.
        </p>
      </div>
    </div>
    <div #enterprise class="section">
      <div class="sectionContent" style="display: flex; width: 80%; margin: 30px auto; ">
        <h2>
          Enterprise Solutions
        </h2>
        <p>
          DIY Surveys offers fast solutions to get your demonstrations looking good, with extended services to make your solution sing!
        </p>
      </div>
    </div>
    <div class="section footer">
      <div class="sectionContent" style="width:80%;margin: 15px auto 0 auto">
        <!--div style="display:flex;flex-flow:column;width:100%">
        <div style="display:flex;flex-flow:column;text-align:left">
          <h3>About Us</h3>
          <div>About</div>
          <div></div>
          <div></div>
        </div>
      </!--div>
      <mat-divider></mat-divider-->
        <div style="display:flex;flex-flow:row;margin:15px 0 15px 0">
          <div style="margin-right:15px;font-size:0.75rem;vertical-align:middle">
            <span>&#169;{{ currentYear }} DIY Surveys Ltd </span>
          </div>
          <div style="margin-right:15px;font-size:0.75rem;vertical-align:middle">
            {{currentVersion}}
          </div>
          <div style="margin-right:15px;font-size:0.75rem;vertical-align:middle">
            <span><a (click)="openTerms()" [innerHTML]="(isHandset$ | async) ? 'Terms' : 'Terms of Service'" style="cursor: pointer;"></a></span>
          </div>
          <div style="margin-right:15px;font-size:0.75rem;vertical-align:middle">
            <span><a (click)="openPrivacy()" style="cursor: pointer;" [innerHTML]="(isHandset$ | async) ? 'Privacy' : 'Privacy Statement'"></a></span>
          </div>
          <div style="margin-right:15px">
            <a (click)="goToLink('https://www.linkedin.com/company/diy-surveys/')" style="cursor: pointer;"><img src="assets/img/linkedin-png-linkedin-icon-1600.webp" style="height: 20px; padding-right: 15px;" /></a>
          </div>
        </div>
        <div style="height:0px">&nbsp;</div>
      </div>
    </div>
    <div #body class="body" [class]="(isHandset$ | async) ? '' : 'noDisplay'">
      <div class="section-mobile">
        <div class="sectionContent-mobile column-flex">
          <div class="left padding-0" [@leftIn]="start">
            <img src="assets/img/sign-in-image@2x.png" class="fullWidth" />
          </div>
          <div class="rightImage fullWidth" [@rightIn]="start">
            <span style="color: #09215c; font-size: 2.5rem; font-weight: 400;">
              Powerful enough for Researchers...
            </span>
            <h2>
              Simple enough for Everyone!
            </h2>
            <p>
              Access to a set of unlimited tools for survey creation and feedback analysis!
            </p>
            <p>
              <span class="strong">Get started with a Free Account</span>
            </p>
            <div [formGroup]="formGroup" class="fieldRow">
              <div>
                <mat-form-field appearance="outline" class="fullWidth">
                  <mat-label>Email Address</mat-label>
                  <input matInput placeholder="Email Address" formControlName="email">
                </mat-form-field>
              </div>
              &nbsp;
              <div>
                <button mat-raised-button (click)="startForFree()" color="primary">Start for Free</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div class="section-mobile">
        <div class="sectionContent-mobile">
          <h1 style="font-size: 2.5rem; font-weight: 500; text-align: center;">Create a survey in minutes</h1>
        </div>
      </div>
      <div class="section-mobile">
        <div class="sectionContent-mobile">
          <div class="sectionCard-mobile">
            <div style="display:flex; align-items: center;">
              <span class="number">1</span>&nbsp;
              <div>
                <h2 style="font-size: 1.5rem !important; font-weight: 400;">Use One of our survey templates</h2>
              </div>
            </div>
            <mat-card class="txt-align-center margin-10" (click)="scrollTo('step1')">
              <div style="margin:20px;margin-bottom:2px;">
                <img src="../../assets/img/report1.svg" width="40" />
              </div>
              <span class="strong" style="margin-bottom:20px">Survey Templates</span>
              <mat-card-content style="margin-bottom:20px">
                NPS, CES, CSAT Combination, Event Feedback, Coffee shop, Garage Service Survey…
              </mat-card-content>
            </mat-card>
            <div style="display:flex; align-items: center;">
              <span class="number">2</span>&nbsp;
              <div>
                <h2 style="font-size: 1.5rem !important; font-weight: 400;">Buy respondents to meet your needs</h2>
              </div>
            </div>
            <mat-card style="text-align: center; margin: 10px;">
              <div style="margin:20px;margin-bottom:2px;">
                <img src="../../assets/img/report2.svg" width="40" />
              </div>
              <span class="strong mb-20">
                Buy or Upload
              </span>
              <mat-card-content class="mb-20">
                Upload your respondents or use our - <span [countUp]="[100,50000]"></span>+ million people, 60 countries, 50+ demographics
              </mat-card-content>
            </mat-card>
            <div style="display:flex; align-items: center;">
              <span class="number">3</span>&nbsp;
              <div>
                <h2 style="font-size: 1.5rem !important; font-weight: 400;">Choose the best channel for your audience</h2>
              </div>
            </div>
            <mat-card style="text-align:center; margin: 10px;">
              <div style="margin:20px;margin-bottom:2px;">
                <img src="../../assets/img/report3.svg" width="40" />
              </div>
              <span class="strong mb-20">
                Survey Templates
              </span>
              <mat-card-content class="mb-20">
                Choose from Email, SMS, WhatsApp and many other channels to get your feedback from
              </mat-card-content>
            </mat-card>
          </div>
          <div #step1>
            <div style="display: flex; flex-flow: column; align-items: center">
              <div style="display:flex; align-items: center;">
                <span class="number">4</span>
                <img src="../../assets/img/report4.svg" width="40" />
                Understand your feedback
              </div>
            </div>
          </div>
          <div style="text-align: center;">
            Every step can be customised to get the best results
          </div>
        </div>
      </div>
      <div style="background-color:white; margin-top:-350px; padding-top:350px">
        <div class="section-mobile">
          <div class="sectionContent-mobile" style="display: flex; flex-direction: column;">
            <div class="left" [@leftIn]="start && p1" (appear)="p1 = true" style="padding: 0;">
              <img src="../../assets/img/step1.svg" style="width:100%;" />
            </div>
            <div class="rightImage" style="width:100%" [@rightIn]="start && p1" (appear)="p1 = true">
              <div style="display: flex; align-items: center;">
                <span class="number">1</span>&nbsp;
                <h2>Use one of our survey templates</h2>
              </div>
              <h1>Rely on our Research Experience</h1>
              <p>
                Access to survey templates to save you time and let you focus on your audience.
              </p>
              <mat-divider></mat-divider>
              <br />
              <p>
                For an experienced researcher we offer a full question editor with a simple look and feel controls and drag and drop controls.
              </p>
            </div>
          </div>
        </div>
        <div class="section-mobile">
          <div class="sectionContent-mobile">
            <div class="left" [@leftIn]="start && p2">
              <img src="../../assets/img/landing-page-image.png" style="width: 100%;" />
            </div>
            <div class="rightImage" style="width:100%" [@rightIn]="start && p2" (appear)="p2 = true">
              <div style="display: flex; align-items: center;">
                <span class="number">2</span>&nbsp;
                <h2>
                  Buy respondents to meet your needs
                </h2>
              </div>
              <h1>
                Reach the right people
              </h1>
              <p>
                100+ million people, 60 countries, 50+ demographics
                Select target audiences quickly, easily and at a low price
                Choose the demographics that you require: age, gender, region, income and much much more (over 50+ attributes)
              </p>
              <mat-divider></mat-divider>
              <br />
              <p>
                For an experienced researcher we offer a full question editor with a simple look and feel controls and drag and drop controls.
              </p>
            </div>
          </div>
        </div>
        <div class="section-mobile">
          <div class="sectionContent-mobile">
            <div class="left" [@leftIn]="start && p3" style="padding: 0;">
              <img src="../../assets/img/AllChannels.png" style="width: 100%;" />
            </div>
            <div class="rightImage" style="width: 100%; padding-bottom: 100px;" [@rightIn]="start && p3" (appear)="p3 = true">
              <div style="display: flex; align-items: center;">
                <span class="number">3</span>&nbsp;
                <h2>
                  Choose the best channel for your audience
                </h2>
              </div>
              <h1>
                We are Connected!
              </h1>
              <p>
                DIY Surveys offer the only service that connects into:
                Email
                SMS
                Chat Solutions – WhatsApp, Facebook Messaging
              </p>
              <mat-divider></mat-divider>
              <br />
              <p>
                For an experienced researcher we offer a full question editor with a simple look and feel controls and drag and drop controls.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="handset-footer" [style]="(isHandset$ | async) ? '' : 'display:none;'">
        <div class="handset-footer-email-container">
          <div>
            <a href="mailto:hello@diysurveys.com" class="handset-footer-email"><span>hello&#64;diysurveys.com</span></a>
          </div>&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
        <div class="handset-footer-bottom-container">
          <div>
            <span>&#169;{{ currentYear }} DIY Surveys Ltd </span>
          </div>
          <div>
            <span><a (click)="openTerms()" class="cursor-pt">Terms</a></span>
          </div>
          <div>
            <span><a (click)="openPrivacy()" class="cursor-pt">Privacy</a></span>
          </div>
        </div>
      </div>
    </div>
