<div>
  <h3>WhatsApp Applications</h3>
  <table *ngIf="tempApplications | async; else loading" mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="ApplicationId">
      <th mat-header-cell *matHeaderCellDef> ID. </th>
      <td mat-cell *matCellDef="let element"> {{element.ApplicationId}} </td>
    </ng-container>

    <ng-container matColumnDef="Status">
      <th mat-header-cell *matHeaderCellDef> Status </th>
      <td mat-cell *matCellDef="let element"> {{status(element)}} </td>
    </ng-container>

    <ng-container matColumnDef="Created">
      <th mat-header-cell *matHeaderCellDef> Created </th>
      <td mat-cell *matCellDef="let element"> {{element.Created  | date:'short' : '' : translate.currentLang}} </td>
    </ng-container>

    <ng-container matColumnDef="Amended">
      <th mat-header-cell *matHeaderCellDef> Amended </th>
      <td mat-cell *matCellDef="let element"> {{element.Amended  | date:'medium'}} </td>
    </ng-container>

    <ng-container matColumnDef="CompanyName">
      <th mat-header-cell *matHeaderCellDef> Company Name </th>
      <td mat-cell *matCellDef="let element"> {{element.CompanyName}} </td>
    </ng-container>

    <ng-container matColumnDef="BrandName">
      <th mat-header-cell *matHeaderCellDef> Brand </th>
      <td mat-cell *matCellDef="let element"> {{element.Data.BrandName}} </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row class="users-element-row" *matRowDef="let row; columns: displayedColumns;" (click)="edit(row)"></tr>
  </table>
  <br />
  <br />
  <br />
  <br />
  <br />
</div>

<ng-template #loading>
  <div class="loading-container">
    <span class="loading-content">
      <div class="spinner-div"><mat-spinner [diameter]="25"></mat-spinner></div>
      <div class="spinner-text"> Loading applications...</div>
    </span>
  </div>
  <br /><br />
</ng-template>

