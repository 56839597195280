import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { fadeInLeftAnimation, fadeInRightAnimation, rubberBandAnimation } from 'angular-animations';
import { fromEvent, takeUntil, Subject, Observable } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { version } from "../../environments/environment";

@Component({
  selector: 'app-newhome',
  templateUrl: './newhome.component.html',
  styleUrls: ['./newhome.component.css'],
  animations: [
    fadeInLeftAnimation({anchor: 'leftIn'}),
    fadeInRightAnimation({ anchor: 'rightIn' }),
    rubberBandAnimation({ anchor: 'rubberBand'})
    ]
})
export class NewhomeComponent implements OnInit, AfterViewInit, OnDestroy {
  start: boolean = false;
  fadeInLeftState = false;
  formGroup: FormGroup;
  p1: boolean = false;
  p2: boolean = false;
  p3: boolean = false;

  destroy = new Subject();

  destroy$ = this.destroy.asObservable();
  top: number = 0;

  currentVersion: string;
  currentYear = new Date().getFullYear();

  @ViewChild("home") public home: ElementRef;
  @ViewChild("create") public create: ElementRef;
  @ViewChild("step1") public step1: ElementRef;
  @ViewChild("step2") public step2: ElementRef;
  @ViewChild("step3") public step3: ElementRef;
  @ViewChild("step4") public step4: ElementRef;
  @ViewChild("body") public body: ElementRef;
  @ViewChild("marketing") public marketing: ElementRef;
  @ViewChild("enterprise") public enterprise: ElementRef;

  constructor(private breakpointObserver: BreakpointObserver, private router: Router) {
    this.currentVersion = version;

    this.formGroup = new FormGroup({
      email: new FormControl("", [Validators.required])
    })
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));

  ngOnDestroy(): void {
    this.destroy.next(this.destroy$);
    }

  ngAfterViewInit(): void {
    fromEvent(window, 'scroll').pipe(takeUntil(this.destroy$)).subscribe((e: Event) => {
      this.top = this.getYPosition(e);
    });

    Promise.resolve(null).then(() => {
      this.start = true;
    });
  }

  checkStyle(): string {
    if (this.top > 5) {
      return 'mat-elevation-z2';
    }

    return '';
  }

  ngOnInit(): void {
  }

  openTerms() {
    const url = this.router.createUrlTree(['/termsofservice'])
    window.open(url.toString(), '_blank')
  }

  openPrivacy() {
    const url = this.router.createUrlTree(['/privacystatement'])
    window.open(url.toString(), '_blank')
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }

  getYPosition(e): number {
    return e.target.scrollingElement.scrollTop;
  }

  startForFree() {
    this.start = !this.start;
  }

  onAppear() {
    let a = 0;
  }

  scrollTo(section: string) {
    switch (section) {
      case "home":
        this.home.nativeElement.scrollIntoView({ behavior: 'smooth', clock: 'end', inline: 'start' });
        break;
      case "create":
        this.create.nativeElement.scrollIntoView({ behavior: 'smooth', clock: 'end', inline: 'start' });
        break;
      case "step1":
        this.step1.nativeElement.scrollIntoView({ behavior: 'smooth', clock: 'end', inline: 'start' });
        break;
      case "step2":
        this.step2.nativeElement.scrollIntoView({ behavior: 'smooth', clock: 'end', inline: 'start' });
        break;
      case "step3":
        this.step3.nativeElement.scrollIntoView({ behavior: 'smooth', clock: 'end', inline: 'start' });
        break;
      case "step4":
        this.step4.nativeElement.scrollIntoView({ behavior: 'smooth', clock: 'end', inline: 'start' });
        break;
      case "marketing":
        this.marketing.nativeElement.scrollIntoView({ behavior: 'smooth', clock: 'end', inline: 'start' });
        break;
      case "enterprise":
        this.enterprise.nativeElement.scrollIntoView({ behavior: 'smooth', clock: 'end', inline: 'start' });
        break;
    }
  }
}
