<div cdkDropList (cdkDropListDropped)="drop($event)">
  <div *ngFor="let field of fieldList(); let i = index" class="fieldRowWithPadding" cdkDrag>
    <div class="question-sidebar-custom-placeholder" *cdkDragPlaceholder></div>
    <app-field-definition [field]="field" [format]="format" [task]="task" [questions]="questions" [isLast]="i + 1 == fieldList().length" [templateProfile]="templateProfile" (add)="onAdd(i, $event)" (fieldUpdated)="onChange(i, $event)"></app-field-definition>
    <button *ngIf="i + 1 < fieldList().length" mat-icon-button matTooltip="Delete Field" matTooltipPosition="above"
            (click)="onDelete(i)">
      <mat-icon>remove</mat-icon>
    </button>
  </div>
</div>
