import { Component, OnInit, ViewChild } from '@angular/core';
import { tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { Router } from "@angular/router";
import { UserService, UserSummary } from '../user.Service';
import {MatTableDataSource} from '@angular/material/table';
import { Observable } from "rxjs";
import { ChangeUsernameComponent } from '../user-details/change-username/change-username.component';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../authentication.Service';
import { SurveyService } from '../survey.Service';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { JsonCyclic } from '../utils';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  sb: MatSnackBarRef<any> | undefined;
  tempUsers: Observable<any>;
  Users: UserSummary[] = [];
  dataSource = new MatTableDataSource(this.Users);
  displayedColumns: string[] = ['UserID', 'BusinessType', 'CreateDate', 'LastLoggedIn', 'UserName', 'FirstName', 'LastName', 'EmailAddress'];
  user: UserSummary;
  contextSelected: boolean = false;

  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;

  constructor(public dialog: MatDialog,
    public translate: TranslateService,
    private snackbar: MatSnackBar,
    private userService: UserService,
    private surveyService: SurveyService,
    private authenticationService: AuthenticationService,
    private router: Router) {
    this.tempUsers = userService.getUsers().pipe(tap<UserSummary[]>(result => {
      this.Users = result;
      this.dataSource = new MatTableDataSource(this.Users);
    }));
  }

  ngOnInit(): void {
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  businessType(user: UserSummary): string {
    if (UserService.hasRole(user.Roles, "Cash")) {
      return "monetization_on";
    }

    return "credit_card";
  }

  isTemplate(user: UserSummary): boolean {
    return UserService.hasRole(user.Roles, "template") || UserService.hasRole(user.Roles, "Admin");
  }

  templateType(user: UserSummary): string {
    return UserService.hasRole(user.Roles, "Admin") ? "A" : "T";
  }

  contextMenuPosition = { x: '0px', y: '0px' };
  onContextMenu(event: MouseEvent, user: UserSummary) {
    event.preventDefault();
    this.contextSelected = true;
    this.user = user;
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { 'user': user };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  export() {
    this.sb = this.snackbar.open("Exporting...", "");
    this.userService.exportUsers().subscribe(
        response => {
        this.downLoadFile(response, "text/plain", ".diy")
        },
        error => {
          this.sb?.dismiss();
        });
    return false;

 }


  downLoadFile(data: any, type: string, ext: string) {
    let blob = new Blob([JsonCyclic.toJson(data)], { type: "text/plain" });
    let url = window.URL.createObjectURL(blob);
    //Download by dynamically creating a tag
    const a = document.createElement('a');
    const fileName = "users";
    a.href = url;
    // a.download = fileName;
    a.download = fileName + ext;
    a.click();
    window.URL.revokeObjectURL(url);
    this.sb?.dismiss();
  }

  edit(user: UserSummary) {
    this.router.navigate(["/edituser", user.UserID, "user"]);
  }

  impersonate(user: UserSummary): boolean {
    if (UserService.hasRole(user.Roles, "Admin") || UserService.hasRole(user.Roles, "sa")) {
      event.preventDefault();
      this.authenticationService.impersonateAs(user.UserName)
        .subscribe(result => {
          const token = (result as any).Token;
          this.authenticationService.setAccessToken(token);
          this.surveyService.getSurveyCount()
            .subscribe(response => {
              if (response > 0) {
                this.router.navigate(['../surveydashboard']);
              } else {
                this.router.navigate(['../startfromtemplate']);
              }
            },
              error => {
                if (error === 401) {
                  this.router.navigate(['../']);
                }
              });
        },
          error => {
            console.error(error);
          });
    }

    return false;
  }

  signin(user: UserSummary) {
    if (UserService.hasRole(user.Roles, "Admin") || UserService.hasRole(user.Roles, "sa")) {
      event.preventDefault();
      this.authenticationService.loginAs(user.UserName)
        .subscribe(result => {
          const token = (result as any).Token;
          this.authenticationService.setAccessToken(token);
          this.surveyService.getSurveyCount()
            .subscribe(response => {
              if (response > 0) {
                this.router.navigate(['../surveydashboard']);
              } else {
                this.router.navigate(['../startfromtemplate']);
              }
            },
              error => {
                if (error === 401) {
                  this.router.navigate(['../']);
                }
              });
        },
          error => {
            console.error(error);
          });
    }

    return false;
  }

  createUser() {
    let dialogRef = this.dialog.open(ChangeUsernameComponent, {
      data: { id: 0 }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.userid) {
        this.router.navigate(["/edituser", result.userid, "user"]);
      }
    });
  }
}
