import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'diy-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.css']
})
export class LabelComponent implements OnInit {

  internalLabels: any;

  constructor() { }

  ngOnInit(): void {
  }

  @Input()
  get labels(): any {
    return this._labels;
  }
  set labels(value: any) {
    this._labels = value;
    this.internalLabels = value;
  }
  private _labels: any;

  @Input()
  get label(): any {
    return this._label;
  }
  set label(value: any) {
    this._label = value;
  }
  private _label: any;

  @Input()
  get preview(): boolean {
    return this._preview;
  }
  set preview(value: boolean) {
    this._preview = value;
  }
  private _preview: boolean = false;
}
