<div>
  <mat-toolbar class="nav-toolbar-container mat-elevation-z2">
    <button mat-icon-button #backButton (click)="onClick()">
      <mat-icon>reply</mat-icon>
    </button>
    <div class="ml-mr-5">
      <a (click)="goToHome()" class="cursor-pt"><img src="assets/img/logo.svg" class="toolbar-logo" /></a>
    </div>
    <div class="ml-10" *ngIf="displayName">
      <div class="edit-toolbar-name-div">{{nameType}}</div>
      <div class="edit-toolbar-edit-div" [hidden]="!displayName">
        <button (click)="openBasicDialog()" mat-flat-button>{{name}}<mat-icon class="edit-toolbar-edit-icon">create</mat-icon></button>
      </div>
    </div>
    <span class="toolbar-spacer"></span>

    <div class="nav-header-right-container" [class]="(isHandset$ | async) ? 'noDisplay' : ''">
      <div><button mat-button target="_blank">Pricing</button></div>
      <button mat-button class="signup-btn" [matMenuTriggerFor]="resourcesMenu">Resources</button>
      <mat-menu #resourcesMenu="matMenu">
        <button mat-menu-item (click)="goToLink('https://diysurveys.zendesk.com/')">
          <span>User Interface Documentation...</span>
        </button>
        <button mat-menu-item (click)="goToLink('https://apidocs.diysurveys.com/')">
          <span>API Documentation...</span>
        </button>
      </mat-menu>
      <div *ngIf="isTester()"><button mat-button><span [matBadge]="messages" matBadgeColor="warn" matBadgeOverlap="false" [matBadgeHidden]="messages === 0" (click)="gotoInbox()">Inbox</span></button></div>
      <div><app-user-menu></app-user-menu></div>
    </div>
  </mat-toolbar>
  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav-content class="sidenav-content">
      <div class="nav-main-content">
        <router-outlet></router-outlet>
      </div>
      <footer>
        <div class="nav-footer" [class]="(isHandset$ | async) ? 'noDisplay' : ''">
          <div class="footer-container">
            <div class="footer-left-content">
              <div class="footer-version-div">
                <span>&#169;{{ currentYear }} DIY Surveys Ltd </span>&nbsp;<span>{{currentVersion}}</span>
              </div>
              <div class="footer-terms-div">
                <span><a (click)="openTerms()" class="cursor-pt" [innerHTML]="(isHandset$ | async) ? 'Terms' : 'Terms of Service'"></a></span>
              </div>
              <div class="footer-terms-div">
                <span><a (click)="openPrivacy()" class="cursor-pt" [innerHTML]="(isHandset$ | async) ? 'Privacy' : 'Privacy Statement'"></a></span>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
