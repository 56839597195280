import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription, Observable } from "rxjs";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MissionService } from "../../MissionService";
import { AuthenticationService, Metadata, SecurityKey, MessageWindow, WindowModel } from "../../authentication.Service";
import { Router, ActivatedRoute } from "@angular/router";
import { tap } from 'rxjs/operators';
import { MetadataTableComponent } from '../metadata-entrytable/metadata-table.component';
import { WindowComponent } from '../window-entry/window.component';
import { plainToClass } from 'class-transformer';
import { TranslateService } from '@ngx-translate/core';
import { UserTinyUrl, TinyUrlService } from "../../tinyUrl.Service";
import { MessageQueue, NetworkService, SpeedDefinition } from '../../network.Service';
import { JsonCyclic } from '../../utils';

@Component({
  selector: 'app-key-details',
  templateUrl: './key-details.component.html',
  styleUrls: ['./key-details.component.css']
})
export class KeyDetailsComponent implements OnInit {
  subscription: Subscription;
  keyId: string = "";
  tempKey?: Observable<any>;
  key: SecurityKey = new SecurityKey();
  formGroup: FormGroup = new FormGroup({});
  tinyUrlForm: FormGroup = new FormGroup({});
  metadata: Metadata[] = [];
  oldMetadata: Metadata[] = [];
  windows: MessageWindow[] = [];
  oldWindows: WindowModel[] = [];
  tinyUrls: UserTinyUrl[] = [];
  isHmac: boolean = false;
  isWhatsApp: boolean = false;
  back: string = "profile";
  keyType: string = "";
  keyTitle: string = "Basic";
  userId: number = -1;
  queue: MessageQueue = null;
  queueForm: FormGroup = new FormGroup({});
  requestMade: boolean = false;
  errorWithRequest: boolean = false;

  @ViewChild('metadataTable') metadataTable: MetadataTableComponent | undefined;
  @ViewChild('messageWindows') messageWindows: WindowComponent | undefined;

  constructor(
    private missionService: MissionService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private actRoute: ActivatedRoute,
    private translate: TranslateService,
    private tinyUrlService: TinyUrlService,
    private networkService: NetworkService
  ) {
    this.queueForm = new FormGroup({
      Speed: new FormControl()
    });
    this.keyType = this.actRoute.snapshot.params.type;
    this.userId = Number(this.actRoute.snapshot.params.userid);
    this.networkService.getMessageQueueDefinitionForUser(this.userId).subscribe(
      result => {
        this.queue = plainToClass(MessageQueue, result);
        this.queue.MessageCharacteristics.DefaultSpeed = plainToClass(SpeedDefinition, this.queue.MessageCharacteristics.DefaultSpeed);
        for (let i = 0; i < this.queue.MessageCharacteristics.AllowedSpeeds.length; i++) {
          this.queue.MessageCharacteristics.AllowedSpeeds[i] = plainToClass(SpeedDefinition, this.queue.MessageCharacteristics.AllowedSpeeds[i]);
        }
      });
    this.back = this.actRoute.snapshot.params.actionType;
    switch (this.keyType) {
      case "basic":
        this.keyTitle = "Basic";
        break;
      case "hmac":
        this.keyTitle = "HMAC";
        this.isHmac = true;
        break;
      case "whatsapp":
        this.keyTitle = "WhatsApp";
        this.isWhatsApp = true;
    }
    this.subscription = missionService.missionAnnounced$.subscribe(
      mission => {
        if (mission === "Back") {
          this.onSubmit();
        }
      });
    this.tinyUrlForm = new FormGroup({
      defaultTinyUrl: new FormControl()
    })

  }

  get isAdminUser(): boolean {
    return this.authenticationService.isAuthorized(['Admin']);
  }

  get isBetaTester(): boolean {
    return this.authenticationService.isAuthorized(['beta']);
  }

  ngOnInit(): void {
    this.keyId = this.actRoute.snapshot.params.id;
    if (this.keyId == "0") {
      if (this.keyType == "whatsapp") {
        this.tempKey = this.authenticationService.getSecurityKeysForAccount('whatsapp', this.userId).pipe(tap<SecurityKey[]>(
          result => {
            if (result.length > 0) {
              this.key = plainToClass(SecurityKey, result[0]);
              this.mapKey();
            }
            else {
              this.getDetails();
            }
          }));
        return;
      }
    }

    this.getDetails();
  }

  getDetails() {
    if (this.keyId === "0") {
      this.tempKey = this.authenticationService.newSecurityKeyForAccount(this.userId).pipe(tap<SecurityKey>(
        result => {
          this.key = plainToClass(SecurityKey, result);
          if (!this.isHmac) {
            if (this.isWhatsApp) {
              this.key.AppId = "";
              this.key.KeyString = "";
            }
            else {
              if (this.key.AppId.startsWith(this.keyType + ":")) {
                let temp = this.key.AppId.split(':');
                this.key.AppId = temp[temp.length - 1];
              }
            }
          }

          this.getTinyUrls();
          this.initForm();
          this.formGroup.patchValue(this.key);
          this.metadata = this.getMetadata();
        }));
    } else {
      this.tempKey = this.authenticationService.getSecurityKeyForAccount(this.keyId, this.userId).pipe(tap<SecurityKey>(
        result => {
          this.key = plainToClass(SecurityKey, result);
          this.mapKey();
        }));
    }

  }

  mapKey() {
    if (!this.isHmac) {
      if (this.key.AppId.startsWith(this.keyType + ":")) {
        let temp = this.key.AppId.split(':');
        this.key.AppId = temp[temp.length - 1];
      }
    }

    this.getTinyUrls();
    this.initForm();
    this.formGroup.patchValue(this.key);
    this.metadata = this.getMetadata();
    this.windows = this.getWindows();
    if (this.key.SpeedCharacteristics != "") {
      this.key.Speed = plainToClass(SpeedDefinition, this.key.Speed);
      this.queueForm.controls.Speed.setValue(this.key.Speed.description);
    }

    this.oldMetadata = [];
    if (this.metadata !== null && this.metadata !== undefined) {
      for (let i = 0; i < this.metadata.length; i++) {
        let data = new Metadata();
        data.MetaId = this.metadata[i].MetaId;
        data.Created = this.metadata[i].Created;
        data.DataType = this.metadata[i].DataType;
        data.Name = this.metadata[i].Name;
        data.Sequence = this.metadata[i].Sequence;
        data.Value = this.metadata[i].Value;
        this.oldMetadata.push(data);
      }
    }

    this.oldWindows = [];
    if (this.windows !== null && this.windows !== undefined) {
      for (let i = 0; i < this.windows.length; i++) {
        this.oldWindows.push(this.key.Windows[i]);
      }
    }
  }



  getTinyUrls() {
    this.tinyUrlService.getTinyUrlsForAccount(this.userId).subscribe(result => {
      this.tinyUrls = result;
      this.tinyUrlForm.controls.defaultTinyUrl.setValue(this.key.DefaultTinyUrlId);
    });
  }

  initForm() {
    this.formGroup = new FormGroup({
      Name: new FormControl("", [Validators.required]),
      KeyString: new FormControl("", [Validators.required]),
      AppId: new FormControl("", [Validators.required]),
      IsDeleted: new FormControl(),
      Created: new FormControl(),
      Amended: new FormControl(),
      Expires: new FormControl(),
      SuspendedFlag: new FormControl(),
    });
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
  }

  message() {
    return;
  }

  showMetadata() {
    return this.authenticationService.isAuthorized(["Admin"]);
  }

  getMetadata() {

    if (this.key === undefined || this.key === null) {
      const metadata: Metadata[] = [];
      return metadata;
    }

    return this.key.MetadataList;
  }

  getWindows() {
    if (this.key === undefined || this.key === null) {
      return [];
    }

    return this.key.GetMessageWindows();
  }

  goBack() {
    switch (this.back) {
      case "profile":
        this.router.navigate(['profile', this.userId]);
        return;
      case "whatsapp":
        this.router.navigate(['manageapplications']);
        return;
    }

    this.router.navigate(['edituser', this.userId, 'user']);
  }

  onSubmit() {
    if (this.formGroup.invalid) {
      if (this.keyId === "0") {
        this.goBack();
      }

      return;
    }

    let modified = false;
    this.key.KeyString = this.formGroup.value.KeyString;
    this.key.DefaultTinyUrlId = this.tinyUrlForm.value.defaultTinyUrl;
    if (this.key.Name !== this.formGroup.value.Name) {
      modified = true;
      this.key.Name = this.formGroup.value.Name;
    }
    this.key.AppId = (!this.isHmac ? this.keyType + ":" : "") + this.formGroup.value.AppId;
    if (this.formGroup.value.SuspendedFlag !== null) {
      let suspended = false;
      if (this.formGroup.value.SuspendedFlag === "2") {
        suspended = true;
      }
      if (this.key.Suspended !== suspended) {
        modified = true;
        this.key.Suspended = suspended;
      }
    }

    const newMetadata = this.metadataTable?.metadata ?? null;
    if (newMetadata != null) {
      if (this.oldMetadata.length == 0) {
        if (newMetadata.length > 0) {
          this.key.MetadataList = newMetadata;
          modified = true;
        }
      }
      else if (this.oldMetadata.length !== newMetadata.length) {
        this.key.MetadataList = newMetadata;
        modified = true;
      }
      else {
        for (let i = 0; i < this.oldMetadata.length; i++) {
          if (this.oldMetadata[i].DataType !== newMetadata[i].DataType || this.oldMetadata[i].Name !== newMetadata[i].Name || this.oldMetadata[i].Value !== newMetadata[i].Value) {
            modified = true;
            this.key.MetadataList = newMetadata;
            break;
          }
        }
      }
    }

    let windowsModified = false;
    if (this.messageWindows != undefined) {
      this.key.SetMessageWindows(this.messageWindows.window);
    }
    
    if (this.oldWindows === undefined || this.oldWindows === null) {
      if (this.key.Windows.length > 0) {
        modified = true;
        windowsModified = true;
      }
    }
    else if (this.oldWindows.length !== this.key.Windows.length) {
      modified = true;
      windowsModified = true;
    }
    else {
      for (let i = 0; i < this.oldWindows.length; i++) {
        if (this.oldWindows[i].FromTime !== this.key.Windows[i].FromTime || this.oldWindows[i].ToTime !== this.key.Windows[i].ToTime || this.oldWindows[i].Days !== this.key.Windows[i].Days) {
          modified = true;
          windowsModified = true;
          break;
        }
      }
    }

    let speed = this.queueForm.controls.Speed.value;
    if (speed == null || speed == '' || this.queue == null) {
      if (this.key.SpeedCharacteristics != '') {
        modified = true;
      }

      this.key.SpeedCharacteristics = ''
      this.key.Speed = new SpeedDefinition();
    }
    else {
      for (let i = 0; i < this.queue.MessageCharacteristics.AllowedSpeeds.length; i++) {
        if (this.queue.MessageCharacteristics.AllowedSpeeds[i].description == speed) {
          if (this.key.Speed.description != speed) {
            modified = true;
          }

          this.key.Speed = this.queue.MessageCharacteristics.AllowedSpeeds[i];
          this.key.SpeedCharacteristics = JsonCyclic.toJson(this.key.Speed);
          break;
        }
      }
    }

    if (!windowsModified) {
      this.key.Windows = this.oldWindows;
    }

    this.key.OwnerId = this.userId;
    if (modified) {
      this.authenticationService.setSecurityKey(this.key).subscribe(
        results => {
          this.goBack();
        });
    } else {
      this.goBack();
    }
  }

  deleteKey() {
    if (this.key == null || this.key.KeyId == 0) {
      return;
    }

    this.key.IsDeleted = true;
    this.authenticationService.setSecurityKey(this.key).subscribe(
      results => {
        this.goBack();
      });
  }

  requestQueueManagement() {
    this.authenticationService.requestSpeedManagement(this.key.KeyId).subscribe(
      result => {
        this.requestMade = true;
      },
      error => {
        this.errorWithRequest = true;
      });
  }
}
