import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, Input, ElementRef, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { Subscription, Observable, timer } from "rxjs";
import { MissionService } from "../MissionService";
import { RandomiseQuestionsComponent } from '../randomise-questions/randomise-questions.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { HelpComponent } from '../help/help.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { QuestionService, InterviewModel, InterviewService } from '../question.service';
import { ScriptModel } from '../script.Service';
import { QuestionsComponent } from '../questions/questions.component';
import { QuestionnaireSidebarComponent } from '../questionnaire-sidebar/questionnaire-sidebar.component';
import { QuestionPropertiesComponent } from '../question-properties/question-properties.component';
import { QuestionLibraryComponent } from '../question-library/question-library.component';
import { plainToClass } from 'class-transformer';
import { InterviewPreviewComponent } from '../interview-preview/interview-preview.component';
import { SurveyFlowComponent } from '../questions/survey-flow/survey-flow.component';
import { SurveyModel, SurveyService } from '../survey.Service';
import { InterviewTestComponent } from '../interview-test/interview-test.component';
import { RunByQrcodeComponent } from '../interview-preview/run-by-qrcode/run-by-qrcode.component';
import { JoyrideService } from 'ngx-joyride';
import { JsonCyclic } from '../utils';
import { AddQuestionComponent } from '../questions/add-question/add-question.component';
import { UserFeatures, UserService } from '../user.Service';

@Component({
  selector: 'question-editor',
  templateUrl: './question-editor.component.html',
  styleUrls: ['./question-editor.component.css']
})
export class QuestionEditorComponent implements OnInit, AfterViewInit, OnDestroy {
  private savedInterview = "";
  inTimer = false;
  waiting?: Observable<any>;
  waitTime: Observable<any> = timer(0, 1000);

  interview: InterviewModel = new InterviewModel();
  modelChecked = false;
  index: number = -1;
  parentIndex: number = -1;
  script: ScriptModel = new ScriptModel();
  formGroup: FormGroup = new FormGroup({});
  subscription: Subscription;
  addCompSubscription: Subscription;
  userFeatures: UserFeatures = new UserFeatures();
  surveyId: string = "";
  id: string = "";
  inSurvey: boolean;
  showQuestionList = true;
  timeout = null;
  errors: string[] = [];
  errorsExpanded = false;
  errorString: string = "";

  qList = true;
  qLibrary = false;
  help = false;
  qProp = true;
  surveyRoute = false;
  surveyPvw = false;
  surveyStyle = false;

  @ViewChild('rightSidenav') public rightSidenav: any;
  @ViewChild('leftSidenav') public leftSidenav: MatSidenav | undefined;
  @ViewChild('questions') public questions: QuestionsComponent | undefined;
  @ViewChild('properties') public properties: QuestionPropertiesComponent | undefined;
  @ViewChild('sorter') public sorter: QuestionnaireSidebarComponent | undefined;
  @ViewChild('routing') public routing: SurveyFlowComponent | undefined;
  @ViewChild('library') public library: QuestionLibraryComponent | undefined;
  @ViewChild('scrollMe') private myScrollContainer: ElementRef | undefined;

  constructor(public dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
    private snackBar: MatSnackBar,
    private interviewService: InterviewService,
    private questionService: QuestionService,
    private userService: UserService,
    private surveyService: SurveyService,
    private _bottomSheet: MatBottomSheet,
    private breakpointObserver: BreakpointObserver,
    private missionService: MissionService,
    private snackbar: MatSnackBar,
    private router: Router,
    private actRoute: ActivatedRoute,
    private readonly joyrideService: JoyrideService) {
    this.userService.getUserFeatures().subscribe(result => {
       this.userFeatures = result;
    });
    this.addCompSubscription = this.questionService.getClickEvent().subscribe((result) => {
      if (result.displayParentIndex === undefined) {
        this.parentIndex = -1;
      }
      else {
        this.parentIndex = result.parentIndex;
      }

      if (result.displayIndex === undefined) {
        this.index = -1;
      }
      else {
        this.index = result.index;
      }

      if (this.properties !== undefined) {
        this.properties.parentIndex = result.parentIndex;
        this.properties.index = result.index;
      }

      switch (result.action) {
        case "after":
        case "before":
          this.addNewQuestionnaireDialogWithAction(result);
          break;
        case "copy":
        case "delete":
          this.questionService.onAddComponentButtonClick({
            questionType: result.questionType,
            action: result.action,
            index: result.index,
            displayIndex: result.displayIndex,
            parentIndex: result.parentIndex,
            displayParentIndex: result.displayParentIndex
          });
          break;
        case "select":
          if (this.questions !== undefined) {
            this.questions.selectQuestion(result.index, result.parentIndex, result.displayIndex, result.displayParentIndex);
          }

          break;
      }
    });
    this.inTimer = true;
    this.surveyId = this.actRoute.snapshot.params.id;
    this.inSurvey = this.actRoute.snapshot.params.type === "survey";
    this.snackbar.open("Loading Questionnaire...", "");
    if (this.inSurvey) {
      this.surveyService.getSurveyDetail(Number(this.surveyId)).subscribe({next: result => {
        this.survey = result;
        this.id = this.survey.ScriptID.toString();
        if (result.ScriptID == null || result.ScriptID == 0) {
          this.interview = new InterviewModel();
          this.modelChecked = true;
          this.survey.QuestionCount = 0;
          this.interviewService.createEmptyInterview(this.survey.SurveyID).subscribe(
            result => {
              this.survey.ScriptID = result.ScriptID;
              this.id = result.ScriptID;
            });
          this.snackbar.dismiss();
        }
        else {
          this.interviewService.getInterview(this.survey.ScriptID).subscribe({
            next: result => {
              if (result == null) {
                this.survey.QuestionCount = 0;
                this.interview = new InterviewModel();
                this.modelChecked = true;
                this.inTimer = false;
              }
              else {
                this.interview = plainToClass(InterviewModel, result);
                this.interview.checkModel();
                this.modelChecked = true;
                this.inTimer = false;
                this.survey.QuestionCount = this.interview.Questions.length;
                if (this.questions != null) {
                  this.questions.editor = this;
                  this.questions.populate();
                }

                this.saveInterview();
              }

              this.snackbar.dismiss();
            },
            error: err => {
              this.interview = new InterviewModel();
              this.snackbar.dismiss();
            }
          });
        }
      }});
    }
    else {
      this.id = this.surveyId;
      this.interviewService.getInterview(Number(this.id)).subscribe({
        next: result => {
          if (result === null) {
            this.interview = new InterviewModel();
            this.inTimer = false;
          }
          else {
            this.interview = plainToClass(InterviewModel, result);
            this.interview.checkModel();
            this.survey.QuestionCount = this.interview.Questions.length;
            if (this.questions != null) {
              this.questions.editor = this;
              this.questions.populate();
            }

            this.saveInterview();
            this.inTimer = false;
            this.snackbar.dismiss();
          }
        },
        error: err => {
          this.interview = new InterviewModel();
          this.inTimer = false;
          this.snackbar.dismiss();
        }
      });
    }
    this.subscription = missionService.missionAnnounced$.subscribe(
      mission => {
        if (mission === "Back") {
          this.onSubmit();
        }
      });


    this.subscription = this.waitTime.subscribe(() => {
      if (this.inTimer) {
        return;
      }

      if (this.interview === undefined || this.interview === null) {
        return;
      }

      this.inTimer = true;
      this.saveInterview();
      this.inTimer = false;
    });
  }

  uploadScript(script: string) {
    this.inTimer = true;
    this.modelChecked = false;
    this.interviewService.saveInterviewFromFile(this.id, script).subscribe(result => {
      var a = result;
      this.errors = a.MetadataModel.Errors;
      this.interview = plainToClass(InterviewModel, a.InterviewModel);
      this.interview.checkModel();
      this.modelChecked = true;
      this.survey.QuestionCount = this.interview.Questions.length;
      if (this.questions != null) {
        this.questions.editor = this;
        this.questions.reload();
      }

      this.inTimer = false;
    },
      error => {
        var a = error;
        this.inTimer = false;
      });
  }

  get hasScript() {
    if (!this.survey) {
      return false;
    }
    return this.survey.QuestionCount > 0;
  }

  @Input()
  get survey(): SurveyModel {
    return this._survey;
  }
  set survey(value: SurveyModel) {
    this._survey = value;
  }
  private _survey: SurveyModel = new SurveyModel();

  @Output()
  surveyChange = new EventEmitter<SurveyModel>();

  refresh() {
    if (this.sorter) {
      this.sorter.refresh();
    }

    if (this.routing) {
      this.routing.refresh();
    }

    if (this.properties) {
      this.properties.index = this.index;
      this.properties.parentIndex = this.parentIndex;
      this.properties.CalculateModel();
    }
  }

  refreshComponent(data: any) {
    if (this.questions) {
      this.questions.refreshComponent(data);
    }
  }

  saveInterview() {
    let temp = JsonCyclic.toJson(this.interview);
    if (temp !== this.savedInterview) {
      this.savedInterview = temp;
      if (this.survey) {
        this.survey.QuestionCount = this.interview.Questions.length;
        this.surveyChange.emit(this.survey);
      }
      this.interviewService.saveInterview(this.id, temp).subscribe(result => {
        var a = result;
        this.errors = a?.Errors ?? [];
      },
        error => {
          var a = error;
        });
    }
  }

  get questionListIcon(): string {
    if (this.showQuestionList) {
      return "fact_check";
    }

    return "fact_check_outline";
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));

  ngOnInit(): void {
    this.initForm();
    this.scrollToBottom();
  }

  ngAfterViewInit(): void {
    if (this.questions != undefined) {
      this.questions.editor = this
    }

    if (this.routing != undefined) {
      this.routing.editor = this;
    }

    Promise.resolve(null).then(() => {
      this.toggleLeftSidenav("qList");
      this.togglerightSidenav("qProp");
    });
  }

  initForm() {
    this.formGroup = new FormGroup({
      name: new FormControl("", [Validators.required]),
      description: new FormControl("", [Validators.required]),
    });
  }

  scrollToBottom(): void {
    try {
      this.myScrollContainer?.nativeElement.scroll({
        top: this.myScrollContainer.nativeElement.scrollHeight,
        left: 0,
        behavior: 'smooth'
      });
    } catch (err) { }
  }


  editRespondent(UserId: number): void {
    this.router.navigate(['../']);
  }

  onSubmit() {
    this.saveInterview();
    if (this.inSurvey) {
      this.router.navigate(['/surveys']);
    } else {
      this.router.navigate(['/questionnaires']);
    }
  }


  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
    this.addCompSubscription.unsubscribe();
  }

  openRandomiseQuestionsDialog(): void {
    const dialogRef = this.dialog.open(RandomiseQuestionsComponent, {
      width: '80%'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  addNewQuestionnaireDialog(): void {
    this.addNewQuestionnaireDialogWithAction({ action: "after", index: -1, channel: this.interview.Channel });
  }

  addNewQuestionnaireDialogWithAction(data: any): void {

    let addEnd = data && data.action === "after" && data.index === -1;
    if (data && data.questionType && data.questionType !== "unknown") {
      this.questionService.onAddComponentButtonClick(data);
      return;
    }

    const dialogRef = this.dialog.open(AddQuestionComponent, {
      data: { data },
      maxWidth: '100%'
    });

    dialogRef.afterClosed().subscribe(result => {
      const temp = this.interview.findQuestionByIndex(this.parentIndex, this.index);
      let tempIndex = this.index;
      let tempParentIndex = this.parentIndex;
      if (temp != undefined) {
        tempIndex = temp.DisplayIndex;
        tempParentIndex = temp.DisplayParentIndex;
      }

      if (this.questions) {
        this.questions.selectQuestion(this.index, this.parentIndex, tempIndex, tempParentIndex);
      }

      this.changeDetectorRef.detectChanges();
      if (temp && addEnd) {
        if (this.sorter != undefined) {
          this.sorter.selectQuestion(this.index, this.parentIndex);
        }

        this.myScrollContainer?.nativeElement.scroll({
          top: this.myScrollContainer.nativeElement.scrollHeight,
          left: 0,
          behavior: 'smooth'
        });
      }
    });
  }

  previewInterview(): void {
    let model = this.interview;
    const dialogRef = this.dialog.open(InterviewPreviewComponent, {
      data: { model },
      width: '80%'
    });
  }

  testOnOtherDevices(): void {
    const SurveyID = this.survey.SurveyID;
    const dialogRef = this.dialog.open(RunByQrcodeComponent, {
      data: { SurveyID },
      width: '50%'
    });
  }

  testInterview(): void {
    let survey = this.survey;
    const dialogRef = this.dialog.open(InterviewTestComponent, {
      data: { survey },
      width: '80%'
    });
  }

  openRightSideNav() {
    this.rightSidenav.open();
  }

  closeRightSidenav() {
    this.rightSidenav.close();
    this.qProp = false;
    this.surveyRoute = false;
    this.surveyPvw = false;
  }

  openLeftSideNav() {
    this.leftSidenav?.open();

  }

  closeLeftSidenav() {
    this.leftSidenav?.close();
    this.qList = false;
    this.help = false;
    this.qLibrary = false;
    this.surveyStyle = false;
  }

  openBottomSheet(): void {
    this._bottomSheet.open(HelpComponent);
  }

  toggleLeftSidenav(div: string) {

    if (div == 'qList') {
      this.qList = true;
      this.help = false;
      this.qLibrary = false;
      this.surveyStyle = false;
      this.leftSidenav?.open();
    }
    else if (div == 'help') {
      this.qList = false;
      this.help = true;
      this.qLibrary = false;
      this.surveyStyle = false;
      this.leftSidenav?.open();
    }

    else if (div == 'qLibrary') {
      this.qList = false;
      this.help = false;
      this.qLibrary = true;
      this.surveyStyle = false;
      this.leftSidenav?.open();
    }

    else if (div == 'surveyStyle') {
      this.qList = false;
      this.help = false;
      this.qLibrary = false;
      this.surveyStyle = true;
      this.leftSidenav?.open();
    }

  }

  togglerightSidenav(div: string) {
    switch (div) {
      case 'qProp':
        this.qProp = true;
        this.surveyRoute = false;
        this.surveyPvw = false;
        this.rightSidenav?.open();
        this.interview.State.Properties = true;
        if (this.properties !== undefined) {
          this.properties.parentIndex = this.parentIndex;
          this.properties.index = this.index;
        }

        break;
      case 'surveyRoute':
        this.qProp = false;
        this.surveyRoute = true;
        this.surveyPvw = false;
        this.rightSidenav?.open();
        this.interview.State.Editor = false;
        break;
    }
  }
}

