<div class="start-frm-temp-heading">
  <span>Select a Template</span>
</div>
<div class="fieldRow">
  <div>
    <mat-form-field appearance="outline">
      <mat-label>Template Categories</mat-label>
      <mat-select [(ngModel)]="optionValue" (selectionChange)="categoryChanged()">
        <mat-option value="All">All</mat-option>
        <mat-option *ngFor="let category of categories; let i = index;" [value]="category">{{category}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="ml-5">
    <mat-button-toggle-group #group="matButtonToggleGroup" [value]="toggle" (change)="toggleView($event)">
      <mat-button-toggle value="card" aria-label="Grid View">
        <mat-icon>grid_view</mat-icon>
      </mat-button-toggle>
      <mat-button-toggle value="list" aria-label="List View">
        <mat-icon>list</mat-icon>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</div>
<div *ngIf="surveys && toggle == 'card'">
  <div class="start-frm-temp-template-container custom-scroll-bar">
    <div *ngFor="let survey of surveys; let i = index;" class="start-frm-temp-template-content">
      <mat-card class="start-frm-temp-cards">
        <div *ngIf="survey.getMediaForSurvey() | async; else loading">
          <div class="start-frm-temp-title-container">
            <div class="start-frm-temp-title-content">
              <span>{{survey.Name}}</span>
            </div>
            <div>
              <img [src]="survey.Media | safe: 'resourceUrl'" alt="Template Image" class="start-frm-temp-img">
            </div>
          </div>
          <div class="start-frm-temp-description-container">
            <div class="start-frm-temp-description-content">
              <span>{{survey.Description}}</span>
            </div>
            <div class="start-frm-temp-channel-preview-container">
              <div class="start-frm-temp-chnl-container">
                <div *ngIf="hasChannel(survey, 5)" class="start-frm-temp-channelPvw-content">
                  <button mat-button matTooltip=" Use " matTooltipPosition="above" (click)="onSelectedAction(survey, 5)"><i class="bi bi-whatsapp"></i>&nbsp;<span class="start-frm-temp-channel-text">WhatsApp</span></button>
                  <button mat-icon-button matTooltip=" Preview " matTooltipPosition="above" (click)="previewInterview(survey, 5)"><mat-icon>play_arrow</mat-icon></button>
                </div>
                <div *ngIf="hasChannel(survey, 1)" class="start-frm-temp-channelPvw-content">
                  <button mat-button matTooltip=" Use " matTooltipPosition="above" (click)="onSelectedAction(survey, 1)"><i class="bi bi-chat-left-dots"></i>&nbsp;<span class="start-frm-temp-channel-text">SMS 2-Way</span></button>
                  <button mat-icon-button matTooltip=" Preview " matTooltipPosition="above" (click)="previewInterview(survey, 1)"><mat-icon>play_arrow</mat-icon></button>
                </div>
                <div *ngIf="hasChannel(survey, 0)" class="start-frm-temp-channelPvw-content">
                  <button mat-button matTooltip=" Use " matTooltipPosition="above" (click)="onSelectedAction(survey, 0)"><i class="bi bi-globe"></i>&nbsp;<span class="start-frm-temp-channel-text">Web</span></button>
                  <button mat-icon-button matTooltip=" Preview " matTooltipPosition="above" (click)="previewInterview(survey, 0)"><mat-icon>play_arrow</mat-icon></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>
<div *ngIf="surveys && toggle == 'list'">
  <div class="start-frm-temp-template-container-list custom-scroll-bar">
    <div *ngFor="let survey of surveys; let i = index;" class="start-frm-temp-container-content-list">
      <mat-card class="start-frm-temp-cards">
        <div class="display-flex">
          <div *ngIf="survey.getMediaForSurvey() | async; else loading" class="halfWidth">
            <div>
              <div class="start-frm-temp-title-content-list">
                <span>{{survey.Name}}</span>
              </div>
            </div>
            <div>
              <div class="start-frm-temp-description-content">
                <span>{{survey.Description}}</span>
              </div>
            </div>
          </div>
          <div class="start-frm-temp-preview-container-list">
            <div class="start-frm-temp-channel-container-list">
              <div *ngIf="hasChannel(survey, 5)" class="start-frm-temp-channelPvw-list">
                <button mat-button matTooltip=" Use " matTooltipPosition="above" (click)="onSelectedAction(survey, 5)"><i class="bi bi-whatsapp"></i>&nbsp;<span class="start-frm-temp-channel-text">WhatsApp</span></button>
                <button mat-icon-button matTooltip=" Preview " matTooltipPosition="above" (click)="previewInterview(survey, 5)"><mat-icon>play_arrow</mat-icon></button>
              </div>
              <div *ngIf="hasChannel(survey, 1)" class="start-frm-temp-channelPvw-list">
                <button mat-button matTooltip=" Use " matTooltipPosition="above" (click)="onSelectedAction(survey, 1)"><i class="bi bi-chat-left-dots"></i>&nbsp;<span class="start-frm-temp-channel-text">SMS 2-Way</span></button>
                <button mat-icon-button matTooltip=" Preview " matTooltipPosition="above" (click)="previewInterview(survey, 1)"><mat-icon>play_arrow</mat-icon></button>
              </div>
              <div *ngIf="hasChannel(survey, 0)" class="start-frm-temp-channelPvw-list">
                <button mat-button matTooltip=" Use " matTooltipPosition="above" (click)="onSelectedAction(survey, 0)"><i class="bi bi-globe"></i>&nbsp;<span class="start-frm-temp-channel-text">Web</span></button>
                <button mat-icon-button matTooltip=" Preview " matTooltipPosition="above" (click)="previewInterview(survey, 0)"><mat-icon>play_arrow</mat-icon></button>
              </div>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>
<ng-template #loading>
  <div class="display-flex align-center-center-flex auto-flex">
    <div><mat-spinner [diameter]="25"></mat-spinner></div>
  </div>
</ng-template>
