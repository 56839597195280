<div class="leftFloat">
  <h2>{{title}}</h2>
</div>
<div class="rightFloat">
  <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="clearBoth fullWidth">
  <div class="clearBoth fullWidth">
    <div class="exprt-autmn-content custom-scroll-bar" #scrollMe>
      <mat-stepper orientation="vertical" [linear]="true" #stepper>
        <mat-step [stepControl]="firstFormGroup">
          <div [formGroup]="firstFormGroup">
            <ng-template matStepLabel>{{isExport ? 'Destination' : 'Source'}}</ng-template>
            <div>
              &nbsp;
            </div>
            <div>
              <mat-form-field appearance="outline" class="fullWidth">
                <mat-label>Name</mat-label>
                <input matInput placeholder="Name" formControlName="Name">
              </mat-form-field>&nbsp;
            </div>
            <p *ngIf="isExport">Choose where you want to send the data</p>
            <p *ngIf="!isExport">Choose where you want to get the data from</p>
            <mat-button-toggle-group #group="matButtonToggleGroup" (change)="updateTransferFormat($event)" formControlName="TransferFormat">
              <mat-button-toggle value="SFTP">SFTP</mat-button-toggle>
              <mat-button-toggle value="FTPS">FTPS</mat-button-toggle>
              <mat-button-toggle *ngIf="isExport" value="SPECIFIC">DOWNLOAD</mat-button-toggle>
              <mat-button-toggle *ngIf="!isExport && !automate" value="SPECIFIC">UPLOAD</mat-button-toggle>
              <mat-button-toggle *ngIf="isExport" value="EMAIL">EMAIL</mat-button-toggle>
            </mat-button-toggle-group>
            <div>
              &nbsp;
            </div>
            <div *ngIf="transfer('SFTP') || transfer('FTPS')">
              <app-ftp-setup #ftpSetup [process]="process"></app-ftp-setup>
            </div>
            <div *ngIf="transfer('EMAIL')">
              <h3>Email Data</h3>
              <div class="alert alert-warning" role="alert">
                Emailing data is not a recommended approach to share what might be confidential data.
              </div>
            </div>
            <div *ngIf="!isExport && transfer('SPECIFIC')" class="mt-5">
              <app-file-upload #fileUpload accept=".*" text="Import File" (uploaded)="onFileUploaded($event)"></app-file-upload>
            </div>
          </div>
        </mat-step>
        <mat-step>
          <div [formGroup]="secondFormGroup">
            <ng-template matStepLabel>What data should you {{ isExport ? 'export' : 'import' }}?</ng-template>
            <mat-button-toggle-group #group="matButtonToggleGroup" formControlName="DataType">
              <mat-button-toggle *ngIf="hasCompleted()" value="Completed">Completed</mat-button-toggle>
              <mat-button-toggle *ngIf="hasCompleted()" value="Partial">Partial</mat-button-toggle>
              <mat-button-toggle *ngIf="hasInvites() || isOneWay()" value="Invites">{{ isOneWay() ? 'Invites' : 'Messages'}}</mat-button-toggle>
              <mat-button-toggle *ngIf="hasAnomalies()" value="Anomalies">Anomalies</mat-button-toggle>
              <mat-button-toggle *ngIf="hasStops()" value="Stops">Stops</mat-button-toggle>
              <mat-button-toggle *ngIf="!isExport" value="SurveyPanel">Panel</mat-button-toggle>
              <mat-button-toggle *ngIf="!isExport" value="Results">Results</mat-button-toggle>
            </mat-button-toggle-group>
            <div>
              &nbsp;
            </div>
            <div *ngIf="dataSource('Invites') && isOneWay()">
              <h3>Invitation Only Surveys</h3>
              <p>We are sending out your invitations to surveys.</p>
            </div>
            <div *ngIf="dataSource('Invites') && !isOneWay()">
              <h3>Messages Data</h3>
              <p>Inbound and outbound messages that  make up the surveys questions and answers together with the statuses</p>
            </div>
            <div *ngIf="dataSource('Completed')">
              <h3>Completed Survey Data</h3>
              <p>Completed survey data only, leaving partially completed surveys untouched.</p>
            </div>
            <div *ngIf="dataSource('Partial')">
              <h3>Partial Survey Data</h3>
              <p>Partial Survey data can be refined by applying a filter.</p>
              <app-advanced-routing [display]="false" [rules]="rule" [showAddRemove]="false" name="Export Filter " class="fullWidth"></app-advanced-routing>
              <br />
            </div>
            <div *ngIf="dataSource('Anomalies')">
              <h3>Anomalies</h3>
              <p>Answers to SMS surveys can be anomallies that you can review and improve on your scripting to provide the respondent with a better experience.</p>
            </div>
            <div *ngIf="dataSource('Stops')">
              <h3>Stopped Surveys</h3>
              <p>SMS surveys can be stopped be the respondent. Get a list of those that have stopped you sending further surveys.</p>
            </div>
            <div *ngIf="dataSource('SurveyPanel')">
              <h3>Panel Data</h3>
              <p>Panel data can be imported into a running survey to continue to boost the feedback data you are looking for.</p>
            </div>
            <div *ngIf="dataSource('Results')">
              <h3>Results Data</h3>
              <p>Results data from another survey can be imported and merged with your survey's results for further analysis.</p>
              <br />
            </div>
            <mat-button-toggle-group #group="matButtonToggleGroup" (change)="updateFormat($event)" formControlName="Format">
              <mat-button-toggle value="CSV">CSV</mat-button-toggle>
              <mat-button-toggle value="TSV">TSV</mat-button-toggle>
              <mat-button-toggle value="EXCEL">Excel</mat-button-toggle>
              <mat-button-toggle value="PIPE">PIPE</mat-button-toggle>
              <mat-button-toggle value="FIXED">Fixed</mat-button-toggle>
              <mat-button-toggle value="SPSS">SPSS</mat-button-toggle>
            </mat-button-toggle-group>
            <div>
              &nbsp;
            </div>
            <div *ngIf="format('CSV')">
              <h3>Comma separated values</h3>
              <p>
                A Comma Separated Values (CSV) file is a plain text file that contains a list of data. These files are often used for exchanging data between different applications. For example, databases and contact managers often support CSV files.
                These files may sometimes be called Character Separated Values or Comma Delimited files. They mostly use the comma character to seperate (or delimit) data, but sometimes use other characters, like semicolons. The idea is that you can export complex data from one application to a CSV file, and then import the data in that CSV file into another application.
              </p>
            </div>
            <div *ngIf="format('TSV')">
              <h3>Tab separated values</h3>
              <p>
                TSV stands for Tab Separated Values. Files with the .tsv extension store information in data tables, with each column of data being separated by tabs. The TSV file format allows the data tables to be imported into most spreadsheet applications, allowing for the interchange of spreadsheets between different software applications. The TSV files are similar to CSV files, but tabs instead of commas seperate the data.
              </p>
            </div>
            <div *ngIf="format('EXCEL')">
              <h3>Microsoft Excel</h3>
              <p>
                Microsoft Excel Open XML Spreadsheet (XLSX) file created by Microsoft Excel. You can also open this format in other spreadsheet apps, such as Apple Numbers, Google Docs, and OpenOffice. They are stored as a compressed Zip file, which contains a bunch of other files used to open the document.
              </p>
            </div>
            <div *ngIf="format('XML')">
              <h3>XML</h3>
              <p>
                An XML file is an XML (Extensible Markup Language) data file. It contains a formatted dataset that is intended to be processed by a website, web application, or software program. XML files can be thought of as text-based databases.
              </p>
            </div>
            <div *ngIf="format('PIPE')">
              <h3>Pipe separated values</h3>
              <p>
                A PIPE file is sometimes required where comma separated files do not work.
              </p>
            </div>
            <div *ngIf="format('FIXED')">
              <h3>Fixed File Format</h3>
              <p>
                A fixed file format is a custom format that must be clearly defined using our file format editor.
              </p>
            </div>
            <div *ngIf="format('SPSS')">
              <h3>SPSS (SAV) Format</h3>
              <p>
                SPSS (SAV) is capable of representing all the data types used in SPSS, a widely used software system for statistical analysis.
              </p>
            </div>
            <mat-accordion *ngIf="isExport">
              <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    More Options
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div>
                  <div>
                    <mat-form-field appearance="outline">
                      <mat-label>Date Filter</mat-label>
                      <mat-select (selectionChange)="updateDateRange($event)" formControlName="AllData">
                        <mat-option value="all">Export all data</mat-option>
                        <mat-option value="between">Export data between two times</mat-option>
                        <mat-option value="from">Export data from a certain time</mat-option>
                      </mat-select>
                    </mat-form-field>&nbsp;
                    <div *ngIf="!process.Task.AllData" class="row-flex">
                      <app-advanced-timings placeholder="From" [(value)]="startDate"></app-advanced-timings>
                      <app-advanced-timings *ngIf="!process.Task.From" placeholder="To" label="-" [(value)]="endDate"></app-advanced-timings>
                    </div>
                  </div>
                  <div *ngIf="hasCompleted()">
                    <mat-radio-group aria-label="Select an option" formControlName="Options">
                      <mat-radio-button value="1">Use numeric values</mat-radio-button>&nbsp;&nbsp;
                      <mat-radio-button value="2">Use choice text</mat-radio-button>
                    </mat-radio-group>
                  </div>
                  <div>
                    <mat-checkbox class="example-margin" formControlName="Compress">Compress data into zip file</mat-checkbox>
                  </div>
                  <div>
                    <mat-checkbox class="example-margin" formControlName="LineBreaks">Remove line breaks</mat-checkbox>
                  </div>
                  <div *ngIf="hasCompleted()">
                    <mat-checkbox class="example-margin" formControlName="Split">Split multi-value fields into columns</mat-checkbox>
                  </div>
                  <div>
                    <mat-checkbox class="example-margin" formControlName="System">Include system data</mat-checkbox>
                  </div>
                  <div *ngIf="hasCompleted()">
                    <mat-checkbox class="example-margin" formControlName="Timings">Include data collection timings</mat-checkbox>
                  </div>
                  <div>
                    <mat-checkbox class="example-margin" formControlName="Header" (change)="updateHeader($event)">Include column headings</mat-checkbox>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
            <mat-accordion *ngIf="!isExport">
              <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    More Options
                  </mat-panel-title>
                  <mat-panel-description>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div>
                  <div>
                    <mat-checkbox class="example-margin" formControlName="Compress">Uncompress data from zip file</mat-checkbox>
                  </div>
                  <div>
                    <mat-checkbox class="example-margin" formControlName="Header" (change)="updateHeader($event)">Headings included</mat-checkbox>
                  </div>
                  <div>
                    <mat-form-field appearance="outline">
                      <mat-label>Number Lookup Settings</mat-label>
                      <mat-select (selectionChange)="updateDateRange($event)" formControlName="NumberLookup">
                        <mat-option value="none">Do not check any numbers</mat-option>
                        <mat-option value="valid">Check that the number is valid</mat-option>
                        <mat-option value="mobile">Check that the number is mobile</mat-option>
                      </mat-select>
                    </mat-form-field>&nbsp;
                  </div>
                  <div>
                    <mat-checkbox class="example-margin" formControlName="Dedupe">Apply Dedeuplication Rules</mat-checkbox>
                  </div>
                  <div *ngIf="isDedupe()">
                    Dedupe settings
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>

            <p></p>
            <mat-button-toggle-group #group="matButtonToggleGroup" (change)="updateStandard($event)" formControlName="Standard">
              <mat-button-toggle value="Standard">Standard</mat-button-toggle>
              <mat-button-toggle value="Custom">Custom</mat-button-toggle>
            </mat-button-toggle-group>
            <div *ngIf="custom()" class="mt-5">
              <app-custom-format [(format)]="customFormat" [task]="process.Task" [questions]="dataSource('Invites') ? [] : questions" [templateProfile]="templateProfile"></app-custom-format>
            </div>
          </div>
        </mat-step>
        <mat-step *ngIf="automate">
          <div [formGroup]="thirdFormGroup">
            <ng-template matStepLabel>When should you {{isExport ? 'export' : 'import'}} it?</ng-template>
            <p>
              Set up a schedule for this automated {{isExport ? 'export' : 'import'}}.
            </p>
            <app-scheduler [schedule]="schedule" (scheduleChange)="scheduleChanged($event)"></app-scheduler>
          </div>
        </mat-step>
        <mat-step>
          <div>
            <ng-template matStepLabel>Summary</ng-template>
            <p>
              Check your settings and save them to review, cancel your changes or launch your automated data {{isExport ? 'export' : 'import'}}.
            </p>
            <app-task-summary *ngIf="process" [process]="process"></app-task-summary>
            <br />
          </div>
        </mat-step>
      </mat-stepper>
      <div *ngIf="export">
        <div class="margin-20">
          <app-file-process [process]="tempProcess" [showErrors]="true" (completed)="onExportDataCompleted($event)"></app-file-process>
        </div>
      </div>
      <button mat-raised-button color="primary" (click)="saveProcess()">Save</button>&nbsp;
      <button mat-raised-button color="primary" (click)="isExport ? exportData(): importData()">{{ automate ? 'Test' : (isExport ? 'Export' : 'Import') }}</button>&nbsp;
      <button *ngIf="automate" mat-raised-button color="primary" (click)="launchProcess()">Launch</button>
    </div>
  </div>
</div>
