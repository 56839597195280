import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-add-question-button',
  templateUrl: './add-question-button.component.html',
  styleUrls: ['./add-question-button.component.css']
})
export class AddQuestionButtonComponent {

  toolTip: string = "Add Question Before";

  @Input()
  get action(): string {
    return this._action;
  }
  set action(value: string) {
    this._action = value;
    if (this._action == "after") {
      this.toolTip = "Add Question After";
    }
  }
  private _action: string = "";

  @Input()
  get isBlock(): boolean {
    return this._isBlock;
  }
  set isBlock(value: boolean) {
    this._isBlock = value;
  }
  private _isBlock: boolean = false;

  performAction() {
    this.doAction.emit(this.action);
  }

  deleteAction() {
    this.doAction.emit("delete");
  }

  @Output() doAction = new EventEmitter<string>();

}

