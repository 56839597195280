import { DatePipe } from '@angular/common';
import { Component, Inject, LOCALE_ID, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MessageWindow, WindowModel } from '../../authentication.Service';

@Component({
  selector: 'app-window',
  templateUrl: './window.component.html',
  styleUrls: ['./window.component.css']
})
export class WindowComponent implements OnInit, OnDestroy {

  formGroup: FormGroup;
  subscription: Subscription[] = [];

  constructor(@Inject(LOCALE_ID) public locale: string, private fb: FormBuilder, private datePipe: DatePipe) {
    this.formGroup = this.fb.group({
      element: this.fb.array([this.newRow()])
    });
  }

  ngOnDestroy(): void {
    this.subscription.forEach(sub => sub.unsubscribe());
  }

  ngOnInit(): void {
  }

  get optionArr(): FormArray {
    return this.formGroup.get('element') as FormArray;
  }

  newRow() {
    return this.newRowWithData(new MessageWindow());
  }

  newRowWithData(data: MessageWindow) {
    let form = this.fb.group({
      From: [this.datePipe.transform(data.From, 'HH:mm', '+00:00')],
      To: [this.datePipe.transform(data.To, 'HH:mm', '+00:00')],
      Days: [data.Days]
    });

    let sub = form.valueChanges.subscribe(x => {
      this.generateChange();
    });

    this.subscription.push(sub);
    return form;
  }

  addNewRow(index: number) {
    let data = new MessageWindow();
    if (index + 1 === this._window.length) {
      this._window.push(data);
      this.optionArr.controls.push(this.newRowWithData(data));
    }
    else {
      this._window.splice(index + 1, 0, data);
      this.optionArr.controls.splice(index + 1, 0, this.newRowWithData(data));
    }

    this.generateChange();
    return false;
  }

  deleteRow(index: number) {
    if (index < this._window.length) {
      this._window.splice(index, 1);
      this.optionArr.controls.splice(index, 1);
    }

    if (this._window.length === 0) {
      this._window.push(new MessageWindow());
      this.optionArr.controls.push(this.newRow());
    }

    this.generateChange();
    return false;
  }

  generateChange() {
    this.onChange.emit(this.model);
  }

  loadMessageWindow() {
    this.optionArr.clear();
    for (let i = 0; i < this._window.length; i++) {
      this.optionArr.controls.push(this.newRowWithData(this._window[i]));
    }

    if (this._window.length === 0) {
      this._window.push(new MessageWindow());
      this.optionArr.controls.push(this.newRow());
    }
  }

  @Output()
  onChange: EventEmitter<WindowModel[]> = new EventEmitter<WindowModel[]>();

  @Input()
  get model(): WindowModel[] {
    let w = this.window;
    let m = [];
    for (let i = 0; i < w.length; i++) {
      m.push(new WindowModel(w[i]));
    }

    return m;
  }
  set model(value: WindowModel[]) {
    let w = [];
    if (value == undefined || value == null) {
      this.window = [];
      return;
    }

    for (let i = 0; i < value.length; i++) {
      w.push(value[i].GetMessageWindow());
    }

    this.window = w;
  }

  @Input()
  get window(): MessageWindow[] {
    for (let i = 0; i < this._window.length; i++) {
      let form = this.optionArr.controls[i] as FormGroup;
      this._window[i].From = new Date(this.datePipe.transform(new Date(), 'shortDate') + " " + form.controls.From.value + ' UTC+0000');
      this._window[i].To = new Date(this.datePipe.transform(new Date(), 'shortDate') + " " + form.controls.To.value + ' UTC+0000');
      this._window[i].Days = form.controls.Days.value;
    }
    return this._window;
  }
  set window(value) {
    this._window = value;
    if (this._window === undefined || this._window === null) {
      this._window = [];
    }

    this.loadMessageWindow();
  }
  private _window: MessageWindow[] = [];

}
