<div class="fullWidth dialog-title">
  <div class="leftFloat">
    <h3>Generate Test Results</h3>
  </div>
  <div class="rightFloat">
    <button mat-icon-button matDialogClose="no" matTooltip="Close" matTooltipPosition="below">
      <mat-icon class="dialog-close-icon">close</mat-icon>
    </button>
  </div>
</div>
  <div class="clearBoth"></div>
  <div mat-dialog-content>
    <form class="new-communication-form flex-stretch-column" [formGroup]="formGroup">
      <p>
        Generate test results so you can review your reporting without having to send out invitations.
      </p>
      <div class="wrapFlex mt-5">
        <mat-form-field appearance="outline" class="intrvw-test-response-field">
          <mat-label>Responses</mat-label>
          <input matInput placeholder="Responses" formControlName="Responses">
        </mat-form-field>&nbsp;&nbsp;
      </div>
      <div>
        <button mat-raised-button (click)="runTest()" color="primary">Run Tests</button>
      </div>
      <div *ngIf="isProcessing">
        <app-file-process [process]="process" (completed)="onTestCompleted($event)"></app-file-process>
      </div>
      <div *ngIf="viewReport || testDataCount > 0">
        <button mat-raised-button class="mt-5" (click)="viewResults()" color="primary">View Results</button>
      </div>
    </form>
  </div>
