import { HttpHeaders } from "@angular/common/http";
export const environment = {
  production: true
};
export const baseUrl = "https://diysurveys.com/api/";
export const options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
export const cintUrl = "https://api.cintworks.net/";

export const version = "6.3.002.001";

export const SQUARE_APPLICATION_ID = "sandbox-sq0idb-jAeCFLLBCI2yNAtoLSP0aA";
export const SQUARE_ACCESS_TOKEN = "EAAAEEJl8yjySR1TMf-20DNWvw4g-LBQZXZMBe4OhdbNJGqDM_KB-DcvB39WuUHd";
export const SQUARE_LOCATION_ID = "LNR8K43REQ9HJ";
