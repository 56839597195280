import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Transaction } from '../../authentication.Service';

@Component({
  selector: 'app-fin-tran-detail',
  templateUrl: './fin-tran-detail.component.html',
  styleUrls: ['./fin-tran-detail.component.css']
})
export class FinTranDetailComponent implements OnInit {

  formGroup: FormGroup;
  transaction: Transaction;
  transactionType = "New Transaction";

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<FinTranDetailComponent>) {
    this.transaction = data.transaction;
    this.formGroup = new FormGroup({
      tranDate: new FormControl(),
      unit: new FormControl(),
      tranType: new FormControl(),
      title: new FormControl(),
      description: new FormControl(),
      yourRef: new FormControl(),
      confirmed: new FormControl(),
      vatRate: new FormControl(),
      net: new FormControl(),
      value: new FormControl(),
      unitText: new FormControl(),
      reference: new FormControl()
    });
  }

  ngOnInit(): void {
  }

  save() {
  }
}
