<div *ngIf="tempTinyUrl | async; else loading" class="margin-10 flex-stretch-column">
  <h3>
    Welcome to your Own Tiny URL Service.
  </h3>
  <p>
    In just a few steps, you'll be set up to use your own Tiny URL Service.
  </p>
  <mat-vertical-stepper linear="true" #stepper>
    <mat-step [stepControl]="firstFormGroup">
      <form [formGroup]="firstFormGroup">
        <ng-template matStepLabel>Enter the Domain Name</ng-template>
        <div class="mt-10">
          <mat-form-field mat-line appearance="outline">
            <mat-label>Domain Name</mat-label>
            <input matInput placeholder="Domain Name" formControlName="Url" (keyup)="checkUrl($event)" [readonly]>
          </mat-form-field>
          <span mat-line>&nbsp;</span>
          <span mat-line *ngIf="errorMsg !== ''" class="alert alert-danger tUrl-status-span-width">{{errorMsg}}</span>
          <span mat-line *ngIf="success === true" class="alert alert-success tUrl-status-span-width">This Tiny URL is correctly configured</span>
        </div>
        <div>
          <button mat-raised-button class="mt-5" color="primary" (click)="verifyDomain(stepper);$event.preventDefault()">Verify</button>&nbsp;
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="secondFormGroup">
      <form [formGroup]="secondFormGroup">
        <ng-template matStepLabel>Verify Your Domain Name</ng-template>
        <h3>IP Address</h3>
        <p>
        The IP Address for our Tiny URL is {{ipAddress}}. Make sure you are using this in your settings.
          </p>
        <h3>TXT Verification</h3><br />
        <p>Add verification code to your domain settings</p>
        <mat-form-field mat-line appearance="outline" class="fullWidth">
          <mat-label>Txt String</mat-label>
          <input matInput placeholder="Txt String" formControlName="ValidationCode" readonly>
          <button matSuffix mat-icon-button aria-label="Copy" [cdkCopyToClipboard]="copyFromCode()">
            <mat-icon>content_copy</mat-icon>
          </button>
        </mat-form-field>
        <h3>Digital Certificate</h3>
        <p>For secure access to your TinyURL service you will need to share with us a digital certificate to apply to our hosted service. Please contact clientserviceteam&#64;diysurveys.com for this to take place.</p>
        <mat-checkbox formControlName="IsHttps" >Apply a Certificate</mat-checkbox>
      </form>
    </mat-step>
  </mat-vertical-stepper>
</div>

<ng-template #loading>
  <div class="loading-container">
    <span class="loading-content">
      <div class="spinner-div"><mat-spinner [diameter]="25"></mat-spinner></div>
      <div class="spinner-text"> Please Wait...</div>
    </span>
  </div>
</ng-template>

