import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'diy-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit {

  label: string = "";

  constructor() { }

  ngOnInit(): void {
  }

  @Input()
  get question(): any {
    return this._question;
  }
  set question(value: any) {
    this._question = value;
    if (this._question === undefined) {
      return;
    }

    this.label = this._question.Label.Text;
  }
  private _question: any;

}
