<ngx-charts-bar-vertical [animations]="false"
                          [view]="view"
                         [scheme]="colorSets"
                         [results]="results"
                         [gradient]="gradient"
                         [xAxis]="showXAxis"
                         [yAxis]="showYAxis"
                         [legend]="showLegend"
                         [legendPosition]="legendPosition"
                         [showXAxisLabel]="showXAxisLabel"
                         [showYAxisLabel]="showYAxisLabel"
                         [xAxisLabel]="xAxisLabel"
                         [yAxisLabel]="yAxisLabel"
                         [legendTitle]="legendTitle"
                         (select)="onSelect($event)">
</ngx-charts-bar-vertical>

