import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'diy-terminate',
  templateUrl: './terminate.component.html',
  styleUrls: ['./terminate.component.css']
})
export class TerminateComponent implements OnInit {

  redirect: string = "";

  constructor() { }

  ngOnInit(): void {
  }

  @Input()
  get question(): any {
    return this._question;
  }
  set question(value: any) {
    this._question = value;
    if (this._question === undefined) {
      return;
    }

    this.redirect = "";
    if (this.getParameter("redirect") !== "") {
      setTimeout(() => { window.location.href = this.getParameter("redirect") }, 5000);
    }
  }
  private _question: any;

  get valid(): boolean {
    return true;
  }

  private getParameter(name: string): string {
    for (let i = 0; i < this._question.Properties.Items.length; i++) {
      if (this._question.Properties.Items[i].Name === name) {
        return this._question.Properties.Items[i].Value;
      }
    }

    return "";
  }
}
