import { options, baseUrl } from "../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CintFeasibility } from "./cint.Service";

@Injectable()
export class PanelService {

  constructor(private client: HttpClient) {}

  getPanels() {
    return this.client.get<PanelModel[]>(baseUrl + 'Panel/GetPanels', options);
  }

  panelCount() {
    return this.client.get<number>(baseUrl + 'Panel/PanelCount', options);
  }

  panelCountForUser(userid: string) {
    return this.client.get<number>(baseUrl + 'Panel/PanelCountForUser?userid=' + userid, options);
  }

  getTemplateProfile(panelId: number): Observable<any> {
    return this.client.get<any>(baseUrl + 'Panellist/GetTemplateProfile?panelID=' + panelId, options);
  }
  getPanelTemplates() {
    return this.client.get<PanelModel[]>(baseUrl + 'Panel/GetPanelTemplates', options);
  }

  getPanel(id: string): Observable<PanelModel> {
    return this.client.get<PanelModel>(baseUrl + 'Panel/GetPanel?id=' + id, options);
  }

  getLatestPanel(userid: string) {
    return this.client.get<PanelModel>(baseUrl + 'Panel/GetLatestPanelForUser?userid=' + userid, options);
  }

  getPanelists(id: string, searchKey: string, page: number, pageSize: number): Observable<PanelistProfile[]> {
    return this.client.get<PanelistProfile[]>(baseUrl + 'Panel/GetPanelists?id=' + id + "&page=" + page + "&pageSize=" + pageSize, options);
  }

  getPanelist(id: string): Observable<PanelistProfile> {
    return this.client.get<PanelistProfile>(baseUrl + 'Panel/GetPanelist?id=' + id, options);
  }

  getPanelistByReferenceId(id: string): Observable<PanelistProfile> {
    return this.client.get<PanelistProfile>(baseUrl + 'Panellist/GetByReferenceId?id=' + id, options);
  }

  setPanelist(panelId: string, model: PanelistProfile) {
    return this.client.post(baseUrl + 'Panel/SetPanelist?panelid=' + panelId, model, options);
  }

  addPanelist(model: PanelistProfile) {
    return this.client.post(baseUrl + 'Panellist/AddPanellist', model, options);
  }

  addPanellistToNewPanel(model: PanelistProfile, name: string) {
    return this.client.post(baseUrl + 'Panellist/AddPanellistToNewPanel?name=' + name, model, options);
  }

  addUserPanel(name: string) {
    return this.client.post(baseUrl + 'Panel/AddUserPanel', { name: name }, options);
  }

  addPanel(model: PanelModel) {
    return this.client.post(baseUrl + 'Panel/Add', model, options);
  }

  updatePanel(model: PanelModel) {
    return this.client.patch(baseUrl + 'Panel/Update', model, options);
  }

  copyTemplatePanel(id: number) {
    return this.client.get(baseUrl + 'Panel/CopyTemplate?id=' + id, options);
  }

  copyPanel(id: number) {
    return this.client.get(baseUrl + 'Panel/Copy?id=' + id, options);
  }

  delete(id: number) {
    return this.client.delete(baseUrl + 'Panel/Delete?id=' + id, options);
  }

  deletePanellist(id: string, panellists: string) {
    return this.client.delete(baseUrl + 'Panellist/DeletePanellists?panelID=' + id + '&panellistIDs=' + panellists, options);
  }

  uploadPanel(id: number, filename: string) {
    return this.client.patch(baseUrl + 'Panel/FileUpload?id=' + id + "&filename=" + filename, options);
  }

  updateQuota(id: number, model: CintFeasibility): Observable<any> {
    return this.client.patch(baseUrl + 'Panel/UpdateQuota?id' + id, model, options);
  }
}

export class PanelModel {
  PanelID: number = 0;
  ThemeTemplateID: number = 0;
  Name: string = "";
  Description: string = "";
  PanelType: number = 0;
  SearchTag: string = "";
  Quota: string = "";
  Remark: string = "";
  IsDeleted: boolean = false;
  CreatedDate: Date = new Date();
  LastUpdatedDate: Date = new Date();
  UserID: number = 0;
  MediaID: number = 0;
  LogoID: number = 0;
  PanellistCount: number =0;
  Summary: PanelSummaryModel = new PanelSummaryModel();
  IsCint: boolean = false;
}

export class PanelSummaryModel {
  Emails: number = 0;
  Devices: number = 0;
  Countries: CountryProfileModel[] = [];
}

export class CountryProfileModel {
  CountryId: number = 0;
  Count: number = 0;
  Country: CountryModel = new CountryModel();
}

export class CountryModel {
  CountryID: number = 0;
  Name: string = "";
  CountryCode: string = "";
  Std: string = "";
  Gmt: Date = new Date();
  CreditRate: number = 0;
  IsDeleted: boolean = false;
}

export class EstimateModel {
  CompletionRate: number;
  Respondents: number;
  Rate: number;
  Total: number = 0;
  OutboundSms: number = 0;
  InboundSms: number = 0;
  constructor() {
    this.Rate = 1.2;
    this.CompletionRate = 100;
    this.Respondents = 100;
  }
}

export class PanelistProfile {
  PanelID: number = 0;
  PanellistID: number = 0;
  Title: string = "";
  FirstName: string = "";
  LastName: string = "";
  UserName: string = "";
  Email: string = "";
  MobileNumber: string = "";
  Dob: Date = new Date();
  Website: string = "";
  Address1: string = "";
  Address2: string = "";
  PostalCode: string = "";
  GeoLocation: string = "";
  CreatedDate: Date = new Date();
  LastUpdatedDate: Date = new Date();
  ProfileVariables: ProfileVariable[] = [];
  WhenTrigger: Date = new Date();
}

export class ProfileVariable {
  Id: number = 0;
  Name: string = "";
  TypeId: number = 0;
  TextVal: string = "";
  DateVal: Date = new Date();
  RealVal: number = 0;
  ObjectVal: object = null;
}
