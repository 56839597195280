import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, ComponentFactoryResolver, ComponentRef, Inject, NgZone, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { single, multi } from '../data.service';
import { Observable, Subscription, timer } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { SurveyModel, SurveyService } from '../survey.Service';
import { InterviewModel, InterviewService, ReportData, ReportModel, ReportingQuestionModel, QuestionModel, ReportDataMatrix, SeriesMatrix, ReportInterviewModel, QuestionsModel, CategoryModel, GroupReportData } from '../question.service';
import { plainToClass } from 'class-transformer';
import { CaseDataService, ChartData } from '../casedata.Service';
import { HorizontalChartComponent } from '../charts/horizontal-chart/horizontal-chart.component';
import { VerticalChartComponent } from '../charts/vertical-chart/vertical-chart.component';
import { TableChartComponent } from '../charts/table-chart/table-chart.component';
import { PieChartComponent } from '../charts/pie-chart/pie-chart.component';
import { LineChartComponent } from '../charts/line-chart/line-chart.component';
import { colorSets } from '../charts/color-sets.service';
import pptxgen from "pptxgenjs";
import { FormControl, FormGroup } from '@angular/forms';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MissionService } from '../MissionService';
import { GroupedHorizontalChartComponent } from '../charts/grouped-horizontal-chart/grouped-horizontal-chart.component';
import { GroupedVerticalChartComponent } from '../charts/grouped-vertical-chart/grouped-vertical-chart.component';
import { GaugeChartComponent } from '../charts/gauge-chart/gauge-chart.component';
import { SelectedQuestionsComponent } from './selected-questions/selected-questions.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StackedVerticalChartComponent } from '../charts/stacked-vertical-chart/stacked-vertical-chart.component';
import { StackedHorizontalChartComponent } from '../charts/stacked-horizontal-chart/stacked-horizontal-chart.component';
import { DownloadOptionsComponent } from './download-options/download-options.component';
import { DialogService } from '../shared/dialog.service';
import { DeleteDataComponent } from './delete-data/delete-data.component';
import html2pdf from 'html2pdf.js';
import * as moment from 'moment-timezone';
import { WordCloudChartComponent } from '../charts/word-cloud-chart/word-cloud-chart.component';
import { JsonCyclic } from '../utils';
import { MatrixTableComponent } from '../charts/matrix-table/matrix-table.component';
import { forEach } from 'angular';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { DeleteLiveDataComponent } from './delete-live-data/delete-live-data.component';
import { jsPDF } from 'jspdf';
import domtoimage from 'dom-to-image';
import { AuthenticationService } from '../authentication.Service';
import { TableTerminateComponent } from '../charts/table-terminate/table-terminate.component';
import { mode } from 'd3';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit, OnDestroy {
  myModel = true;
  defaultScheme = "cool";
  formGroup: FormGroup;
  private inTimer = false;
  waitTime: Observable<any> = timer(0, 1000);
  timerSubscription: Subscription;
  subscription: Subscription;
  timezone: string = moment.tz.guess();
  selectedQuestion: number = -1;
  questionData: QuestionReportData = new QuestionReportData();
  report: ReportModel = new ReportModel();
  savedReport: string = "";
  summaryamp: string = "";
  id: number = 0;
  interview: InterviewModel = new InterviewModel();
  ReportInterview: ReportInterviewModel = new ReportInterviewModel();
  currentQuestion = -1;
  standardChartTypes: ChartType[] = [];
  chartTypes: ChartType[] = [];
  survey: SurveyModel = new SurveyModel();
  isReadyToShow = false;
  chartDescriptions = [
    { value: "horizontal", description: "Horizontal", icon: "align_horizontal_left" },
    { value: "vertical", description: "Vertical", icon: "align_vertical_bottom" },
    { value: "table", description: "Table", icon: "table_rows" },
    { value: "tableMatrix", description: "Table", icon: "table_rows" },
    { value: "tableTerminate", description: "Table", icon: "table_rows" },
    { value: "pie", description: "Pie", icon: "pie_chart" },
    { value: "line", description: "Line", icon: "show_chart" },
    { value: "groupedhorizontal", description: "Grouped Horizontal", icon: "horizontal_split" },
    { value: "groupedvertical", description: "Grouped Vertical", icon: "vertical_split" },
    { value: "gauge", description: "gauge", icon: "" },
    { value: "stackedhorizontal", description: "Stacked Horizontal", icon: "horizontal_split" },
    { value: "stackedvertical", description: "Stacked Vertical", icon: "vertical_split" },
    { value: "cloud", description: "Cloud", icon: "cloud" }
  ];

  surveyName: string = "";
  surveyDesc: string = "";
  surveyPanel: string = "";
  createdDate: string = "";
  rows = new Array<any>();
  dataSource: any;
  displayedColumns = [];
  title = 'angular-export-to-excel';
  dataForExcel: any[] = [];
  @ViewChild('chartContainer', { static: false, read: ViewContainerRef }) chart: ViewContainerRef | undefined;
  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  subscribeFormChange: boolean = false;

  colorScheme = {
    domain: ['#5AA454', '#C7B42C', '#AAAAAA']
  };
  colorSets: any;
  selectedColorScheme: string = "";
  componentRef: ComponentRef<any> | null = null;
  isTestData = false;
  Groupingby_Value = 'None';

  public dataMessageResults: any;

  constructor(
    private resolver: ComponentFactoryResolver,
    private dialogService: DialogService,
    private surveyService: SurveyService,
    private interviewService: InterviewService,
    private caseDataService: CaseDataService,
    private actRoute: ActivatedRoute,
    private router: Router,
    private _ngZone: NgZone,
    public dialog: MatDialog,
    private missionService: MissionService,
    private auth: AuthenticationService,
    private breakpointObserver: BreakpointObserver) {
    this.standardChartTypes = this.chartDescriptions;
    this.id = Number(this.actRoute.snapshot.params.id);
    this.isTestData = this.actRoute.snapshot.params.type === "testreport";
    this.surveyService.getSurveyDetail(this.id).subscribe(result => {
      this.survey = result as SurveyModel;
      this.interviewService.getInterview(this.survey.ScriptID).subscribe(result => {
        this.interview = plainToClass(InterviewModel, result);
        this.interview.checkModel();
        if (this.survey.ReportModel === "") {
          this.report = new ReportModel(this.survey, this.interview);
        }
        else {
          try {
            this.report = plainToClass(ReportModel, JSON.parse(this.survey.ReportModel));
            this.report.checkModel(this.survey, this.interview);
          }
          catch {
            this.report = new ReportModel(this.survey, this.interview);
          }
        }

        this.ReportInterview = new ReportInterviewModel(this.report, this.interview);
        this.selectQuestion(0);
        this.savedReport = JSON.stringify(this.report);
        this.report.TestData = this.isTestData;
        this.timerSubscription = this.waitTime.subscribe(() => {
          if (this.inTimer) {
            return;
          }

          if (this.interview === undefined || this.interview === null) {
            return;
          }

          this.inTimer = true;
          this.saveModel();
          this.inTimer = false;
        });
      });
    },
      error => {
        let a = error;
      });

    Object.assign(this, { multi, colorSets });
    this.subscription = missionService.missionAnnounced$.subscribe(
      mission => {
        if (mission.data == undefined) {
          return;
        }

        if (mission.data.action === "back") {
          this.onSubmit();
          return;
        }

        this.report.Title = mission.data.result.name;
        this.report.Description = mission.data.result.description;
      });

    this.formGroup = new FormGroup({
      questionLabel: new FormControl(),
    });
  }

  resetReport(): void {
    this.report = new ReportModel(this.survey, this.interview);
    this.ReportInterview = new ReportInterviewModel(this.report, this.interview);
    this.selectQuestion(0);
    this.report.TestData = this.isTestData;
  }

  ngOnDestroy(): void {
    this.timerSubscription?.unsubscribe();
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));

  ngOnInit() {
    this.formGroup.valueChanges.subscribe(x => {
      if (!this.report) {
        return;
      }
      if (this.subscribeFormChange && this.questionData.reportingQuestion) {
        this.report.Questions[this.currentQuestion].Title = this.formGroup.controls.questionLabel.value;
      }
    });

    this.setColorScheme("vivid");
    this.id = this.actRoute.snapshot.params.id;
    this.getSurveyDetails();
  }

  onSubmit() {
    if (this.isTestData) {
      this.router.navigate(["/surveyquestioneditor", this.survey.SurveyID, "survey"]);
    }
    else {
      this.router.navigate(["/surveydashboard"]);
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    this.array_move(this.report.Questions, event.previousIndex, event.currentIndex);
  }

  array_move(arr: any, old_index: any, new_index: any) {
    while (old_index < 0) {
      old_index += arr.length;
    }
    while (new_index < 0) {
      new_index += arr.length;
    }
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing purposes
  }

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
  }

  isDiyTester() {
    return this.auth.isAuthorized(['diy'])
  }

  deleteTestData() {
    let survey = this.survey;
    const dialogRef = this.dialog.open(DeleteDataComponent, {
      data: { survey },
      width: '50%'
    });
  }

  deleteLiveData() {
    let survey = this.survey;
    const dialogRef = this.dialog.open(DeleteLiveDataComponent, {
      data: { survey },
      width: '50%'
    });
  }

  toggle(index: number, event: MatCheckboxChange) {
    this.report.Questions[index].Selected = event.checked;
  }

  questionIcon(question: ReportingQuestionModel): string {
    try {
      return this.interview.findQuestion(question.QuestionId)?.Icon ?? "";
    }
    catch {
      let a = 0;
      return "";
    }
  }

  questionSummary(question: ReportingQuestionModel): string {
    try {
      return this.interview.findQuestion(question.QuestionId)?.FullSummary ?? "";
    }
    catch {
      let a = 0;
      return "";
    }
  }

  isChecked(index: number) {
    if (index === -1) {
      for (let i = 0; i < this.report.Questions.length; i++) {
        if (!this.report.Questions[i].Selected) {
          return false;
        }
      }

      return true;
    }

    return this.report.Questions[index].Selected;
  };

  toggleAll(event: MatCheckboxChange) {
    for (let i = 0; i < this.report.Questions.length; i++) {
      this.report.Questions[i].Selected = event.checked;
    }
  }

  get testData(): boolean {
    return this.report && this.report.TestData;
  }

  setTestData() {
    if (this.testData) {
      this.report.TestData = false;
      this.selectQuestion(this.currentQuestion);
      return;
    }

    if (this.report) {
      this.report.TestData = true;
      this.selectQuestion(this.currentQuestion);
    }
  }

  export() {
    let count = 0;
    for (let i = 0; i < this.report.Questions.length; i++) {
      if (!this.report.Questions[i].Selected) {
        count = count + 1;
      }

      else {
        count = count + 5;
      }
    }

    if (count == this.report.Questions.length) {
      this.dialogService.openConfirmDialog('Please select at least one question or select all to Export Results!')
        .afterClosed().subscribe(res => {
          if (res) {
            console.log();
          }
        });
    }

    else {
      const dialogRef = this.dialog.open(DownloadOptionsComponent, {
        data: { component: this }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result && result.exportType === "pdf") {
          this.openDialog();
        }

        else if (result && result.exportType === "excel") {
          let temp = JsonCyclic.toJson(this.ReportInterview);
          this.caseDataService.getExcel(this.id, temp, this.testData)
            .subscribe(response => {
              this.downLoadFile(response, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", ".xlsx")
            }
            );
        }
        else (result && result.exportType === "")
        {
          dialogRef.close();
        }

        return false;
      });

    }
  }

  onNoClick(): void {
    const dialogRef = this.dialog.open(DownloadOptionsComponent, {
      data: { component: this }
    });
    dialogRef.close();
  }

  onChangeDD(value: any) {
    this.Groupingby_Value = value.target.value;
  }

  downLoadFile(data: any, type: string, ext: string) {
    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    const fileName = "Report";
    a.href = url;
    a.download = fileName + ext;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  openDialog() {
    const dialogRef = this.dialog.open(SelectedQuestionsComponent, {
      data: { component: this }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  getSurveyDetails() {
    this.surveyService.getSurveyDetail(this.id)
      .subscribe(result => {
        // console.log(result);
        this.survey = result;
        this.surveyName = result.Name;
        this.surveyDesc = result.Description;
        this.surveyPanel = "Panellist :" + result.PanelSize;
        this.createdDate = "Created Date :" + result.CreatedDate;
      });
  }

  exportToExcel() {

    this.questionData.data.forEach((row: any) => this.dataForExcel.push(Object.values(row)))

    let reportData = {
      title: 'Results Report',
      data: this.dataForExcel,
      headers: Object.keys(this.questionData.data[0])
    }
  }

  public exportToPDF() {

    const dashboard = document.getElementById('dashboard');

    const dashboardHeight = dashboard.clientHeight;
    const dashboardWidth = dashboard.clientWidth;
    const options = { background: 'white', width: dashboardWidth, height: dashboardHeight };

    domtoimage.toPng(dashboard, options).then((imgData) => {
      const doc = new jsPDF(dashboardWidth > dashboardHeight ? 'l' : 'p', 'mm', [dashboardWidth, dashboardHeight]);
      const imgProps = doc.getImageProperties(imgData);
      const pdfWidth = doc.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      doc.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      doc.save('ExportCharts.pdf');
    });
  }

  public exportToPPTX() {
  }

  onSelect($event: any) {
  }

  onActivate($event: any) {
  }

  onDeactivate($event: any) {
  }

  setColorScheme(name: string) {
    this.selectedColorScheme = name;
    if (this.questionData.reportingQuestion) {
      this.questionData.reportingQuestion.ColourScheme = name;
    }

    this.colorScheme = this.colorSets.find((s: any) => s.name === name);
    if (this.componentRef != null) {
      this.componentRef.instance.scheme = this.colorScheme;
    }
  }

  selectQuestion(index: number) {
    this.currentQuestion = index;
    if (this.report.Questions.length == 0) {
      return;
    }

    this.isReadyToShow = false;
    if (this.chart) {
      this.chart.clear();
    }

    this.subscribeFormChange = false;
    this.formGroup.controls.questionLabel.setValue(this.report.Questions[index].Title);
    this.subscribeFormChange = true;

    this.questionData.setQuestion(this.id, this.caseDataService, this.report.Questions[index], this.interview, this.defaultScheme, this.report.TestData);

    this.setColorScheme(this.questionData.reportingQuestion.ColourScheme);
    this.populateChartTypes(this.questionData.question);
  }

  get selectedChartType() {
    if (this.questionData.reportingQuestion) {
      if (!this.questionData.reportingQuestion.ReportType) {
        if (this.chartTypes && this.chartTypes.length > 0) {
          this.questionData.reportingQuestion.ReportType = this.chartTypes[0].value;
        }
      }

      return this.questionData.reportingQuestion.ReportType;
    }

    return "";
  }

  chartChanged(index: number) {
    this.questionData.reportingQuestion.ReportType = this.chartTypes[index].value;
    if (this.chart) {
      this.chart.clear();
      this.componentRef = this.selectChartType(this.resolver, this.chart, this.questionData);
    }
  }

  isReady(): boolean {
    if (this.chart && this.questionData.isReady) {
      if (!this.isReadyToShow) {
        this.chart.clear();
        this.componentRef = this.selectChartType(this.resolver, this.chart, this.questionData);
        this.isReadyToShow = true;
      }

      return true;
    }

    return false;
  }

  selectChartType(resolver: ComponentFactoryResolver, chartRef: ViewContainerRef, data: QuestionReportData): ComponentRef<any> | null {
    const component = data.chartComponent(resolver);
    if (component === undefined) {
      return null;
    }

    const temp = chartRef.createComponent(component) as ComponentRef<any>;
    temp.instance.model = data.reportingQuestion;
    temp.instance.results = data.isMatrix || data.isBucket ? data.matrixResults : data.results;
    if (data.isRank) {
      temp.instance.groupResults = data.groupResults;
    }

    temp.instance.scheme = data.reportingQuestion.ColourScheme;
    temp.instance.data = data.table;
    temp.instance.words = data.data;
    return temp;
  }

  private populateChartTypes(question: QuestionModel) {
    this.chartTypes = [];
    for (let i = 0; i < question.Charts.length; i++) {
      for (let j = 0; j < this.standardChartTypes.length; j++) {
        if (question.Charts[i] === this.standardChartTypes[j].value) {
          this.chartTypes.push(this.standardChartTypes[j]);
          break;
        }
      }
    }
  }

  exportAsXLSX(): void {

  }

  saveModel(): void {
    let temp = JSON.stringify(this.report);
    if (temp !== this.savedReport) {
      this.savedReport = temp;
      this.surveyService.updateReportModel(this.id, this.report, false).subscribe(result => {
        var a = result;

      },
        error => {
          var a = error;
        });
    }

  }
}

export class ChartType {
  value: string = "";
  description: string = "";
  icon: string = "";
}

export class QuestionReportData {
  reportingQuestion: ReportingQuestionModel = new ReportingQuestionModel();
  question: QuestionModel = new QuestionModel();
  results: ReportData[] = [];
  groupResults: GroupReportData[] = [];
  matrixResults: ReportDataMatrix[] = [];
  data: ChartData[] = [];
  table: ChartData[] = [];
  isDate: boolean = false;
  isMatrix: boolean = false;
  isMaxDiff: boolean = false;
  isBucket: boolean = false;
  isRank: boolean = false;
  isMcsaGroup: boolean = false;
  isTerminate: boolean = false;
  isInformation: boolean = false;
  component: any;
  isReady: boolean = false;
  isMatrixTextbox: boolean = false;
  OffsetTime: number = 0;
  chartComponent(resolver: ComponentFactoryResolver): any {
    switch (this.reportingQuestion.ReportType) {
      case "horizontal":
        return resolver.resolveComponentFactory(HorizontalChartComponent);
      case "vertical":
        return resolver.resolveComponentFactory(VerticalChartComponent);
      case "pie":
        return resolver.resolveComponentFactory(PieChartComponent);
      case "line":
        return resolver.resolveComponentFactory(LineChartComponent);
      case "tableMatrix":
        return resolver.resolveComponentFactory(MatrixTableComponent);
      case "tableTerminate":
        return resolver.resolveComponentFactory(TableTerminateComponent);
      case "table":
        return resolver.resolveComponentFactory(TableChartComponent);
      case "groupedhorizontal":
        return resolver.resolveComponentFactory(GroupedHorizontalChartComponent);
      case "groupedvertical":
        return resolver.resolveComponentFactory(GroupedVerticalChartComponent);
      case "gauge":
        return resolver.resolveComponentFactory(GaugeChartComponent);
      case "stackedhorizontal":
        return resolver.resolveComponentFactory(StackedHorizontalChartComponent);
      case "stackedvertical":
        return resolver.resolveComponentFactory(StackedVerticalChartComponent);
      case "cloud":
        return resolver.resolveComponentFactory(WordCloudChartComponent);
    }

    return null;
  }

  setQuestion(surveyId: number,
    caseDataService: CaseDataService,
    reportingQuestion: ReportingQuestionModel,
    interview: InterviewModel,
    defaultScheme: string,
    test: boolean
  ) {
    this.isReady = false;
    this.reportingQuestion = reportingQuestion;
    this.question = interview.findQuestion(this.reportingQuestion.QuestionId) ?? new QuestionModel();

    // Set default chart type if not already set.
    if (!reportingQuestion.ReportType) {
      const charts = this.question.Charts;
      if (charts.length > 0) {
        this.reportingQuestion.ReportType = charts[0];
      }
      else {
        this.reportingQuestion.ReportType = "table";
      }
    }

    // Set default colour scheme if not already set.
    if (!reportingQuestion.ColourScheme) {
      this.reportingQuestion.ColourScheme = defaultScheme;
    }
    let name = this.question.FullName;
    this.OffsetTime = new Date().getTimezoneOffset();
    let time = this.OffsetTime;

    this.isMcsaGroup = false;
    if (this.question.QuestionType == "mcsagroup") {
      this.isMcsaGroup = true;
    }


    this.isDate = false;
    if (this.question.QuestionType === "date") {
      name = this.question.FullName + "%date" + "." + this.OffsetTime;
      time = this.OffsetTime;
      this.isDate = true;
    }
    this.isBucket = false;
    if (this.question.QuestionType === "bucket") {
      name = this.question.FullName + "%bucket%";
      this.isBucket = true;
    }

    this.isMaxDiff = false;
    if (this.question.QuestionType === "maxDiff") {
      name = this.question.FullName + "%set%importance%";
      this.isMaxDiff = true;
    }

    this.isRank = false;
    if (this.question.QuestionType === "ranking") {
      name = this.question.FullName + "%.Rank";
      this.isRank = true;
    }

    this.isMatrixTextbox = false;
    if (this.question.QuestionType === "matrix" && this.question.Control === "textbox") {
      name = this.question.FullName + "%.%";
      if (this.reportingQuestion.ReportType == "table") {
        this.reportingQuestion.ReportType = "tableMatrix";
      }

      this.isMatrixTextbox = true;
    }

    this.isMatrix = false;
    if (this.question.QuestionType === "matrix" && this.question.Control !== "textbox") {
      name = this.question.FullName + "%.matrix";
      this.isMatrix = true;
    }

    this.isTerminate = false;
    if (this.question.QuestionType === "terminate") {
      this.reportingQuestion.ReportType = "tableTerminate";
      this.isTerminate = true;
    }

    this.isInformation = false;
    if (this.question.QuestionType === "information") {
      this.reportingQuestion.ReportType = "tableTerminate";
      this.isInformation = true;
    }

    const isRating = this.question.QuestionType === "rating";

    const isBoolean = this.question.QuestionType === "boolean";
    this.data = [];
    this.results = [];
    this.matrixResults = [];
    if (this.reportingQuestion.ReportType === 'cloud') {
      caseDataService.getWordCloud(surveyId, name, test).subscribe(
        results => {
          this.data = results;
          caseDataService.getTableData(surveyId, name, test).subscribe(
            results => {
              this.table = results;
              this.isReady = true;
            });
        }
      );
    }
    else {
      caseDataService.getMultiplateChoiceData(surveyId, name, test).subscribe(
        results => {
          this.data = results;
          if (this.isMatrix) {
            this.matrixResults = this.buildMatrixChartData(this.question, results);
          }          
          if (this.isBucket) {
            this.matrixResults = this.buildGroupingChartData(this.question, results);
          }
          if (isRating) {
            this.results = this.buildRatingChartData(this.question, results);
          }
          else if (this.isRank) {
            this.groupResults = this.buildRankingChartData(this.question, results);
          }
          else if (isBoolean) {
            this.results = this.buildBooleanChartData(this.question, results);
          }
          else if (this.isMaxDiff) {
            this.results = this.buildMaxDiffChartData(this.question, results);
          }
          else {
            this.results = this.buildMultipleChoiceChartData(this.question, results);
          }

          if (this.reportingQuestion.ReportType !== "table" && this.reportingQuestion.ReportType !== "tableMatrix") {
            this.isReady = true;
          }

          if (this.isMcsaGroup)
          {
            caseDataService.getMultiplateChoiceDataGrouping(surveyId, name, test).subscribe(
              results => {
                this.data = results;
                this.matrixResults = this.buildMatrixChartData(this.question, results);
              });
          }

          if (this.isMatrixTextbox) {
            caseDataService.getMatrixTableData(surveyId, name, this.question.Categories.List.length, test).subscribe(
              results => {
                this.table = results;
                for (let j = 0; j < this.table.length; j++) {
                  for (let i = 0; i < this.question.Parameters.length; i++) {
                    if (this.table[j].VariableName.endsWith(this.question.Parameters[i].Name)) {
                      this.table[j].VariableName = this.question.Parameters[i].Value;
                    }
                  }
                }
                if (this.reportingQuestion.ReportType == "tableMatrix") {
                  this.isReady = true;
                }
              });
          }

          if (this.isTerminate) {
            caseDataService.getTableData(surveyId, name, test).subscribe(
              results => {
                this.table = results;
                if (this.reportingQuestion.ReportType == "tableTerminate") {
                  this.isReady = true;
                }
              },
              error => {
              });

          }

          if (this.isInformation) {
            caseDataService.getTableData(surveyId, name, test).subscribe(
              results => {
                this.table = results;
                if (this.reportingQuestion.ReportType == "tableTerminate") {
                  this.isReady = true;
                }
              },
              error => {
              });

          }

          else {
            caseDataService.getTableData(surveyId, name, test).subscribe(
              results => {
                this.table = results;
                if (this.reportingQuestion.ReportType === "table") {
                  this.isReady = true;
                }
              },
              error => {
              });
          }
        });
    }
  }

  private buildRatingChartData(model: QuestionModel, chartData: ChartData[]): ReportData[] {
    const data: ReportData[] = [];
    let step = model.Step === -1 ? 1 : model.Step;
    let found = false;
    if (model.NotApplicable == true) {
      let label = "NA";
      if (model.Categories.List?.length > 0) {
        label = model.Categories.List[0].Label;
      }

      for (let i = 0; i < chartData.length; i++) {
        if (chartData[i].Response == "NA") {
          data.push(new ReportData(label, chartData[i].Answer));
          found = true;
          break;
        }
      }

      if (!found) {
        data.push(new ReportData("NA", 0));
      }
    }

    for (let i = model.Min; i <= model.Max; i += step) {
      found = false;
      for (let j = 0; j < chartData.length; j++) {
        if (Number(chartData[j].Response) === i) {
          data.push(new ReportData(i.toString(), chartData[j].Answer));
          found = true;
          break;
        }
      }

      if (!found) {
        data.push(new ReportData(i.toString(), 0));
      }
    }

    if (model.LowLabel || model.HighLabel != "")
    {
      let i = 0;
      if (model.NotApplicable == true) {
        i++;
      }

      data[i].name = data[i].name + " - " + model.LowLabel;

      var objlast = data.length - 1;
      data[objlast].name = data[objlast].name + " - " + model.HighLabel;
    }

    return data;
  }

  private buildRankingChartData(model: QuestionModel, chartData: ChartData[]): GroupReportData[] {
    const data: GroupReportData[] = [];
    const rankings = [ '1st', '2nd', '3rd', '4th', '5th', '6th', '7th', '8th', '9th', '10th', '11th', '12th', '13th', '14th', '15th', '16th'  ]
    for (let i = 0; i < model.Categories.List.length; i++) {
      let rank = new GroupReportData();
      rank.name = i >= rankings.length ? (i + 1).toString() + "th" : rankings[i];
      data.push(rank);
    }

    for (let i = 0; i < chartData.length; i++) {
      let element = chartData[i];
      let rank = Number(element.Response) - 1;
      let position = data[rank];
      let category: CategoryModel = null;
      let label = element.VariableName;
      for (let j = 0; j < model.Categories.List.length; j++) {
        if (model.Categories.List[j].Name == element.VariableName) {
          category = model.Categories.List[j];
          label = category.Name + " - " + category.Label
          break;
        }
      }


      if (position.series.length == 0) {
        position.series.push({ "name": label, "value": element.Answer });
      }
      else {
        let inserted = false;
        for (let j = 0; j < position.series.length; j++) {
          if (position.series[j].value < element.Answer) {
            position.series.splice(j, 0, { "name": label, "value": element.Answer });
            inserted = true;
            break;
          }
        }

        if (!inserted) {
          position.series.push({ "name": label, "value": element.Answer });
        }
      }
    }

    return data;
  }

  private buildBooleanChartData(model: QuestionModel, chartData: ChartData[]): ReportData[] {
    const data: ReportData[] = [];
    for (let k = 0; k < 2; k++) {
      let found = false;
      let label = k;
      if (model.Control == "checkboxes") {
        for (let j = 0; j < chartData.length; j++) {
          if (Number(chartData[j].Response) === k) {
            data.push(new ReportData("Agree", chartData[j].Answer));
            found = true;
            break;
          }
        }

        if (!found) {
          data.push(new ReportData("Agree", 0));
        }
      }

      else {
        for (let i = 0; i < model.Categories.List.length; i++) {
          let found = false;
          for (let j = 0; j < chartData.length; j++) {
            switch (chartData[j].Response) {
              case "0":
                if (model.Categories.List[i].Name == "false") {
                  data.push(new ReportData(model.Categories.List[i].Label, chartData[j].Answer));
                  found = true;
                }

                break;
              case "1":
                if (model.Categories.List[i].Name == "true") {
                  data.push(new ReportData(model.Categories.List[i].Label, chartData[j].Answer));
                  found = true;
                }

                break;
            }
          }

          if (!found) {
            data.push(new ReportData(model.Categories.List[i].Label, 0));
          }
        }
      }
    }

    return data;
  }

  private buildMultipleChoiceChartData(model: QuestionModel, chartData: ChartData[]): ReportData[] {
    const data: ReportData[] = [];
    for (let i = 0; i < model.Categories.List.length; i++) {
      let found = false;
      for (let j = 0; j < chartData.length; j++) {
        if (chartData[j].Response === model.Categories.List[i].Name || chartData[j].Response === "_" + model.Categories.List[i].Name) {
          data.push(new ReportData(model.Categories.List[i].Label, chartData[j].Answer));
          found = true;
          break;
        }
      }

      if (!found) {
        data.push(new ReportData(model.Categories.List[i].Label, 0));
      }
    }

    return data;
  }

  private buildMatrixChartData(model: QuestionModel, chartData: ChartData[]): ReportDataMatrix[] {
    const data: ReportDataMatrix[] = [];

    for (let i = 0; i < model.Categories.List.length; i++) {
      if (data.length < i + 1) {
        data.push(new ReportDataMatrix(model.Categories.List[i].Label));
      }

      for (let j = 0; j < model.Parameters.length; j++) {
        let found = false;
        for (let k = 0; k < chartData.length; k++) {

          if (chartData[k].VariableName === model.Categories.List[i].Name && (chartData[k].Response === model.Parameters[j].Name || chartData[k].Response === "_" + model.Parameters[j].Name)) {
            found = true;
            data[i].series?.push(new SeriesMatrix(model.Parameters[j].Value, chartData[k].Answer));
            break;
          }
        }

        if (!found) {
          data[i].series?.push(new SeriesMatrix(model.Parameters[j].Value, 0));
        }
      }
    }
    return data;
  }

  private buildGroupingChartData(model: QuestionModel, chartData: ChartData[]): ReportDataMatrix[] {
    const data: ReportDataMatrix[] = [];
    for (let i = 0; i < chartData.length; i++) {
      var splitted = chartData[i].VariableName.split('.')
      var label_index = model.Categories.List.map(function (e) { return e.Name; }).indexOf(splitted[0]);
      var parameter_index = splitted[1].match(/\d+/g)[0];
      if (data.length > 0) {
        var data_index = data.map(function (e) { return e.name; }).indexOf(model.Categories.List[label_index].Label);
        const found = data.some(el => el.name === model.Categories.List[label_index].Label);
        if (found) {
          data[data_index].series.push(new SeriesMatrix(model.Parameters[parameter_index].Value, chartData[i].Answer));
        }
        else {
          data.push(new ReportDataMatrix(model.Categories.List[label_index].Label))
          var data_index = data.map(function (e) { return e.name; }).indexOf(model.Categories.List[label_index].Label);
          data[data_index].series.push(new SeriesMatrix(model.Parameters[parameter_index].Value, chartData[i].Answer));
        }
      }
      else {
        data.push(new ReportDataMatrix(model.Categories.List[label_index].Label));
        data[i].series.push(new SeriesMatrix(model.Parameters[parameter_index].Value, chartData[i].Answer));
      }
    }

    return data;
  }

  private buildMaxDiffChartData(model: QuestionModel, chartData: ChartData[]): ReportData[] {
    const data: ReportData[] = [];
    for (let i = 0; i < model.Categories.List.length; i++) {
      let found = false;
      for (let j = 0; j < chartData.length; j++) {
        if (chartData[j].VariableName === model.Categories.List[i].Name) {
          data.push(new ReportData(model.Categories.List[i].Label, Number(chartData[j].Response)));
          found = true;
          break;
        }
      }

      if (!found) {
        data.push(new ReportData(model.Categories.List[i].Label, 0));
      }
    }

    return data;
  }
}
