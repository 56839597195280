import { Component, ViewChild, OnInit, TemplateRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService } from "../authentication.Service";
import { InvitePeopleComponent } from "../invite-people/invite-people.component";
import { UserService } from '../user.Service';

@Component({
  selector: 'app-new-organization',
  templateUrl: './new-organization.component.html',
  styleUrls: ['./new-organization.component.css']
})
export class NewOrganizationComponent implements OnInit {
  errorMsg: string;
  success: boolean;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  timeout: any | null = null;
  dialogRef: MatDialogRef<any, any>;
  pattern = '^[a-zA-Z0-9_.+-]+[a-zA-Z0-9-]+$'

  @ViewChild('invites') invites: InvitePeopleComponent | undefined;
  @ViewChild('invitationDialog') invitationDialog: TemplateRef<any>;

  constructor(private authService: AuthenticationService,
    private userService: UserService,
    private router: Router,
    public dialog: MatDialog
  )
  {
    this.errorMsg = "";
    this.success = false;
    this.firstFormGroup = new FormGroup({
      Name: new FormControl("", [Validators.required, Validators.pattern(this.pattern)]),
      Description: new FormControl()
    });
    this.secondFormGroup = new FormGroup({
      Temp: new FormControl()
    });
  }

  ngOnInit(): void {
  }

  checkName($event: any) {
    if (this.timeout != null) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      this.errorMsg = "";
      this.success = false;

      const name = this.firstFormGroup.controls.Name.value;
      if (!name) {
        return;
      }
      if (name.trim().length === 0) {
        return;
      }

      this.testUrl(name);
    }, 1000);
  }

  testUrl(name: string) {
    if (!this.firstFormGroup.controls.Name.valid) {
      this.errorMsg = "This is an invalid name";
      this.success = false;
      return;
    }

    this.authService.findOrganizations(name).subscribe(result => {
      this.errorMsg = "This name is currently in use";
      this.success = false;
    },
      error => {
        this.userService.checkUsername(name).subscribe(result => {
          this.success = false;
          this.errorMsg = "This name is currently in use";
        },
        error => {
          this.success = true;
        });
    });
    return;
  }

  create() {
    this.dialogRef = this.dialog.open(this.invitationDialog, {});
    this.dialogRef.afterClosed().subscribe(result => {
      if (result == undefined || result == 'no') {
        return;
      }

      this.authService.saveOrganization("0", this.firstFormGroup.value.Name, this.firstFormGroup.value.Description, this.invites?.list ?? [], 0).subscribe(result => {
        this.authService.setAccessToken(result.Token);
        this.router.navigate(['/profile', this.authService.getUserId()]);
      });
    });
  }
}
