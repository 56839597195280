<div class="mt-10 mb-10 row-flex">
  <div>
    <button mat-stroked-button [matMenuTriggerFor]="ioMenu">
      <mat-icon>file_download</mat-icon>
      <span>Import/Export</span>
    </button>
    <mat-menu #ioMenu="matMenu">
      <button mat-menu-item (click)="exportData(false, null)">
        <span>Export Data...</span>
      </button>
      <button mat-menu-item (click)="exportData(true, null)">
        <span>Automate Data Export...</span>
      </button>
      <mat-divider></mat-divider>
      <button mat-menu-item (click)="importData(false, null)">
        <span>Import Data...</span>
      </button>
      <button mat-menu-item (click)="importData(true, null)">
        <span>Automate Data Import...</span>
      </button>
      <mat-divider></mat-divider>
      <button mat-menu-item (click)="showImportDataDefinition()">
        <span>Import Data I/O Definition...</span>
      </button>
    </mat-menu>
  </div>
  <div>
    <button mat-icon-button color="primary" (click)="getProcesses()">
      <mat-icon>refresh</mat-icon>
    </button>
  </div>
</div>
<div *ngIf="liveProcesses.length > 0">
  <div>
    <h3>Active/Completed Definitions</h3>
  </div>
  <app-configured-processes (select)="checkData(true, $event)" [(processes)]="liveProcesses"></app-configured-processes>
  <div>
    &nbsp;
  </div>
</div>
<div *ngIf="importProcesses.length > 0">
  <div>
    <h3>Import Definitions</h3>
  </div>
  <app-configured-processes (select)="importData(true, $event)" [(processes)]="importProcesses"></app-configured-processes>
  <div>
    &nbsp;
  </div>
</div>
<div *ngIf="exportProcesses.length > 0">
  <div>
    <h3>Export Definitions</h3>
  </div>
  <app-configured-processes (select)="exportData(true, $event)" [(processes)]="exportProcesses"></app-configured-processes>
</div>
<ng-template #importDataDialog>
  <div class="fullWidth dialog-title">
    <div class="leftFloat">
      <h3>Import Data I/O Definition</h3>
    </div>
    <div class="rightFloat">
      <button mat-icon-button matDialogClose="no" matTooltip="Close" matTooltipPosition="below">
        <mat-icon class="dialog-close-icon">close</mat-icon>
      </button>
    </div>
  </div>
  <div class="clearBoth mt-10">
    <app-file-upload #dataIOImport accept=".txt" text="Import Data Definition" (uploaded)="importDataDefinition($event)"></app-file-upload>
  </div>
 </ng-template>
