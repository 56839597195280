import { Component, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NetworkModel, NetworkService, RequestModel } from '../../network.Service';
import { FileUploadComponent } from '../../file-upload/file-upload.component';
import { plainToClass } from 'class-transformer';

@Component({
  selector: 'app-network-management',
  templateUrl: './network-management.component.html',
  styleUrls: ['./network-management.component.css']
})
export class NetworkManagementComponent {

  columns: string[] = ['name', 'description', 'number', 'created', 'amended', 'action'];
  dataSource = new MatTableDataSource<NetworkModel>();
  networks: NetworkModel[] = [];
  network: NetworkModel = new NetworkModel();
  inTimer = false;
  modelChecked = false;
  timeout = null;
  errors: string[] = [];
  errorsExpanded = false;
  errorString: string = "";

  @ViewChild('countryUpload') countryupload: FileUploadComponent | undefined;

  @Output() uploadCountries = new EventEmitter();

  constructor(public translate: TranslateService,
    private networkService: NetworkService,
    private router: Router,
  ) {
    this.networkService.getNetworks().subscribe(
      result => {
        this.networks = result;
        this.dataSource = new MatTableDataSource<NetworkModel>(this.networks);
    });
  }

  newNetwork() {
    this.router.navigate(["/networkdetail", 0, "network"]);
  }

  exportNetwork() {
    this.networkService.exportCountriesToFile()
      .subscribe(response => {
        this.downLoadFile(response, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", ".xlsx")
      }
      );
  }

  downLoadFile(data: any, type: string, ext: string) {
    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    const fileName = "NetworkExport";
    a.href = url;
    a.download = fileName + ext;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  onFileUploaded(event: any) {
    this.uploadCountries.emit(event.name);
    this.uploadCountriesName(event.name);
    if (this.countryupload == undefined) {
      return;
    }

    this.networkService.getNetworks().subscribe(
      result => {
        this.networks = result;
        this.dataSource = new MatTableDataSource<NetworkModel>(this.networks);
      });

    this.countryupload.progress = 0;
    this.countryupload.message = "Your countries network details has been successfully imported";    
  }

  uploadCountriesName(filename: string) {
    this.inTimer = true;
    this.modelChecked = false;
    this.networkService.saveCountriesFromFile(filename).subscribe(result => {
      var a = result;
      this.errors = a.MetadataModel.Errors;
      this.network = plainToClass(NetworkModel, a.NetworkModel);
      //this.network.checkModel();
      this.modelChecked = true;

      this.inTimer = false;
    },
      error => {
        var a = error;
        this.inTimer = false;
      });
  }


  edit(network: NetworkModel) {
    this.router.navigate(["/networkdetail", network.NetworkId, "network"]);
  }

  delete(network: NetworkModel) {
    this.networkService.deleteNetwork(network).subscribe();
    for (let i = 0; i < this.networks.length; i++) {
      if (this.networks[i].NetworkId == network.NetworkId) {
        this.networks.splice(i, 1);
        return;
      }
    }
  }
}
