<mat-toolbar [class]="checkStyle()" class="mat-toolbar-container-noshadow">
  <a [routerLink]="'/newhome'"><img src="assets/img/logo.svg" class="toolbar-logo" /></a>
  <span class="toolbar-spacer"></span>
  <div class="toolbar-header-right-container" [class]="(isHandset$ | async) ? 'noDisplay' : ''">
    Already have an account?&nbsp;&nbsp;
    <button mat-raised-button color="primary" [routerLink]="'/newlogin'">LOG IN</button>
  </div>
</mat-toolbar>
<div class="toolbar-handset-container">
  <div class="toolbar-handset">
    <a [routerLink]="'/newhome'"><img src="assets/img/logo.svg" class="toolbar-logo" /></a>
  </div>
  <div class="login-signup-div" [class]="(isHandset$ | async) ? '' : 'noDisplay'">
    <button mat-raised-button color="primary" [routerLink]="'/signup'" class="signup-btn-handset">
      LOGIN
    </button>
  </div>
</div>
<div #body class="body" [class]="(isHandset$ | async) ? 'noDisplay' : ''">
  <div class="header">
    <div class="headerContent" style="width: 40%;margin:auto;text-align:center">
      <div style="display:flex;flex-flow:column">
        <h1><span style="font-size:2rem">Welcome to DIY Surveys</span></h1>
        <p>
          Complete the easy sign-up form and start using DIY Surveys today!
        </p>
        <div [formGroup]="formGroup">
          <div>
            <mat-form-field appearance="outline" class="halfWidth">
              <mat-label>Email Address</mat-label>
              <input matInput placeholder="Email Address" formControlName="email">
            </mat-form-field>
          </div>
          <div>
            <mat-form-field appearance="outline" class="halfWidth">
              <mat-label>Name</mat-label>
              <input matInput placeholder="Name" formControlName="email">
            </mat-form-field>
          </div>
          <div>
            <mat-form-field appearance="outline" class="halfWidth">
              <mat-label>Password</mat-label>
              <input matInput placeholder="Password" formControlName="email">
            </mat-form-field>
          </div>
          <div>
            <mat-form-field appearance="outline" class="halfWidth">
              <mat-label>Confirm Password</mat-label>
              <input matInput placeholder="Confirm Password" formControlName="email">
            </mat-form-field>
          </div>
        </div>
        <div>
          <button mat-raised-button color="accent" [routerLink]="'/signup'">SIGN UP</button>
        </div>
        <div style="margin:15px auto;width:50%;">
          <div class="register-or-separator">
            <span>Or Sign up with</span>
          </div>
          <div style="width: 100%; margin-top:-20px; height: 50px; display: flex; flex-flow: row; align-items: center;">
            <div style="width: 50%; margin: 0 -40px 0 18%;">
              <asl-google-signin-button type="icon" size="medium"></asl-google-signin-button>
            </div>
            <div class="social-login-separator">
            </div>
            <a mat-stroked-button href="#" (click)="logInWithFacebook()" style="width: 50%; border: none; height: 100%; padding-top: 7px;">
              <img src="assets/img/icon-facebook.png" />
            </a>
          </div>
        </div>
        <div style="margin-top: 30px;font-size:0.75rem">
          By Creating an account you have read and agree to the terms and privacy policy.
        </div>
      </div>
    </div>
  </div>
   <div class="section footer">
      <div class="sectionContent" style="width:80%;margin: 15px auto 0 auto">
        <div style="display:flex;flex-flow:row;margin:15px 0 15px 0">
          <div style="margin-right:15px;font-size:0.75rem;vertical-align:middle">
            <span>&#169;{{ currentYear }} DIY Surveys Ltd </span>
          </div>
          <div style="margin-right:15px;font-size:0.75rem;vertical-align:middle">
            {{currentVersion}}
          </div>
          <div style="margin-right:15px;font-size:0.75rem;vertical-align:middle">
            <span><a (click)="openTerms()" [innerHTML]="(isHandset$ | async) ? 'Terms' : 'Terms of Service'" style="cursor: pointer;"></a></span>
          </div>
          <div style="margin-right:15px;font-size:0.75rem;vertical-align:middle">
            <span><a (click)="openPrivacy()" style="cursor: pointer;" [innerHTML]="(isHandset$ | async) ? 'Privacy' : 'Privacy Statement'"></a></span>
          </div>
          <div style="margin-right:15px">
            <a (click)="goToLink('https://www.linkedin.com/company/diy-surveys/')" style="cursor: pointer;"><img src="assets/img/linkedin-png-linkedin-icon-1600.webp" style="height: 20px; padding-right: 15px;" /></a>
          </div>
        </div>
        <div style="height:0px">&nbsp;</div>
      </div>
    </div>
</div>
