<div [formGroup]="formGroup" class="fieldRowWithPadding fullWidth">
  <app-field-selector [questions]="questions" [templateProfile]="templateProfile" [field]="field" (selectField)="selected($event)"></app-field-selector>&nbsp;
  <div *ngIf="header()">
    <mat-form-field appearance="outline">
      <mat-label> Title </mat-label>
      <input matInput placeholder="Title" formControlName="Title">
    </mat-form-field>&nbsp;
  </div>
  <div *ngIf="isValue()">
    <mat-form-field appearance="outline" class="width-100">
      <mat-label> Value </mat-label>
      <input matInput placeholder="Value" formControlName="Text">
    </mat-form-field>&nbsp;
  </div>
  <div *ngIf="!delimited()">
    <mat-form-field appearance="outline" class="width-100">
      <mat-label> Start </mat-label>
      <input matInput placeholder="Start" formControlName="Start">
    </mat-form-field>&nbsp;
  </div>
  <div *ngIf="!delimited()">
    <mat-form-field appearance="outline" class="width-100">
      <mat-label> Length </mat-label>
      <input matInput placeholder="Length" formControlName="Length">
    </mat-form-field>&nbsp;
  </div>
  <div *ngIf="!delimited()">
    <mat-form-field appearance="outline">
      <mat-label>Alignment</mat-label>
      <mat-select required>
        <mat-option value="left">Left</mat-option>
        <mat-option value="right">Right</mat-option>
      </mat-select>
    </mat-form-field>&nbsp;
  </div>
  <div>
    <mat-form-field appearance="outline">
      <mat-label> Format </mat-label>
      <input matInput placeholder="Format" formControlName="Format">
    </mat-form-field>&nbsp;
  </div>
  <div>
    <button *ngIf="isLast" mat-icon-button matTooltip="Add Field" matTooltipPosition="above"
            (click)="onAdd()">
      <mat-icon>add</mat-icon>
    </button>
  </div>
</div>
