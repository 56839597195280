<div>
  <mat-toolbar class="nav-toolbar-container mat-elevation-z2">
    <button mat-icon-button (click)="toggleSideMenu()" joyrideStep="menuStep" [stepContent]="menuTourContent">
      <mat-icon>menu</mat-icon>
    </button>
    <ng-template #menuTourContent>
      Open Navigation Menu
    </ng-template>
    <a (click)="goToHome()" class="cursor-pt"><img src="assets/img/logo.svg" class="toolbar-logo" /></a>
    <span class="toolbar-spacer"></span>
    <div class="nav-header-right-container" [class]="(isHandset$ | async) ? 'noDisplay' : ''">
      <div joyrideStep="pricingStep" [stepContent]="pricingTourContent"><button mat-button target="_blank">Pricing</button></div>
      <ng-template #pricingTourContent>
        Click this to check the pricing.
      </ng-template>
      <div joyrideStep="resourcesStep" [stepContent]="resourcesTourContent">
        <button mat-button class="signup-btn" [matMenuTriggerFor]="resourcesMenu">Resources</button>
        <mat-menu #resourcesMenu="matMenu">
          <button mat-menu-item (click)="goToLink('https://diysurveys.zendesk.com/')">
            <span>User Interface Documentation...</span>
          </button>
          <button mat-menu-item (click)="goToLink('https://apidocs.diysurveys.com/')">
            <span>API Documentation...</span>
          </button>
        </mat-menu>
      </div>
      <ng-template #resourcesTourContent>
        <strong> Knowledgebase </strong>
        <br />
        <br />
        Learn how to use DIY Surveys
      </ng-template>
      <div *ngIf="isTester()" joyrideStep="inboxStep" [stepContent]="inboxTourContent"><button mat-button><span [matBadge]="messages" [matBadgeHidden]="messages === 0" matBadgeColor="warn" (click)="gotoInbox()" matBadgeOverlap="false">Inbox</span></button></div>
      <ng-template #inboxTourContent>
        This will hold your notifications.
      </ng-template>
      <div joyrideStep="profileStep" [stepContent]="profileTourContent" (next)="onNext()"><app-user-menu></app-user-menu></div>
      <ng-template #profileTourContent>
        <strong> Account management </strong>
        <br />
        <br />
        Access your Account
      </ng-template>
    </div>
  </mat-toolbar>
  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav #sidenav class="nav-sidenav" [mode]="(isHandset$ | async) ? 'over' : 'over'">
      <mat-nav-list>
        <div class="nav-navlist-container"
             [style]="(isHandset$ | async) ? 'top:40px; padding: 0 15px 0 15px;' : 'top:0px;'">

          <div *ngIf="showSurveyDashboard" joyrideStep="surveyDashboardStep" [stepContent]="surveyDashboardTourContent"
               class="nav-navlist-item-div">
            <a routerLink="/surveydashboard" class="nav-navlist" routerLinkActive="nav-is-active">
              <div class="nav-first-tile-div">
                <div><img src="assets/img/icon-view-list.png" /></div>&nbsp;
                <div><span>Survey dashboard</span></div>
              </div>
            </a>
          </div>
          <ng-template #surveyDashboardTourContent>
            <strong> Survey Dashboard </strong>
            <br />
            <br />
            Manage your surveys
          </ng-template>

          <div joyrideStep="surveyTemplateStep" [stepContent]="surveyTemplateTourContent"
               class="nav-navlist-item-div">
            <a routerLink="/startfromtemplate" class="nav-navlist" routerLinkActive="nav-is-active">
              <div class="nav-first-tile-div">
                <div><img src="assets/img/icon-create-template.png" /></div>&nbsp;&nbsp;
                <div><span>Start from template</span></div>
              </div>
            </a>
          </div>
          <ng-template #surveyTemplateTourContent>
            <strong> Start from Template </strong>
            <br />
            <br />
            Use a pre-built expert survey script
          </ng-template>

          <div joyrideStep="surveyScratchStep" [stepContent]="surveyScratchTourContent"
               class="nav-navlist-item-div">
            <a [routerLink]="" (click)="newSurveyFromScratchDialog()" class="nav-navlist" routerLinkActive="nav-is-active">
              <div class="nav-first-tile-div">
                <div><img src="assets/img/icon-create.png" /></div>&nbsp;&nbsp;
                <div><span>Start from scratch</span></div>
              </div>
            </a>
          </div>
          <ng-template #surveyScratchTourContent>
            <strong> Start from Scratch </strong>
            <br />
            <br />
            Build your own survey script
          </ng-template>

          <div *ngIf="isAdmin()" joyrideStep="marketMessage" [stepContent]="marketingMessage"
               class="nav-navlist-item-div">
            <a [routerLink]="" (click)="newSurveyFromScratchDialog()" class="nav-navlist" routerLinkActive="nav-is-active">
              <div class="nav-first-tile-div">
                <div><img src="assets/img/icon-create.png" /></div>&nbsp;&nbsp;
                <div>
                  <span>

                    Send a Marketing Message
                  </span>
                </div>
              </div>
            </a>
          </div>
          <ng-template #marketingMessage>
            <strong> Send a Marketing Message </strong>
            <br />
            <br />
            Send your marketing message via our global network
          </ng-template>

          <div joyrideStep="respondentsStep" [stepContent]="respondentsTourContent" (done)="onCloseMenu()"
               class="nav-navlist-item-div">
            <a [routerLink]="'/respondents'" class="nav-navlist" routerLinkActive="nav-is-active">
              <div class="nav-first-tile-div">
                <div><img src="assets/img/icon-users.png" /></div>&nbsp;&nbsp;
                <div><span>Respondents</span></div>
              </div>
            </a>
            <ng-template #respondentsTourContent>
              <strong> Respondents </strong>
              <br />
              <br />
              Upload and manage your Respondents
            </ng-template>
          </div>
          <div *ngIf="isSms()" class="nav-navlist-item-sms-div"
               [style]="(isHandset$ | async) ? 'margin-bottom: 0px;' : 'margin-bottom: 12px;'">
            <a [routerLink]="'/smshome'" class="nav-navlist" routerLinkActive="nav-is-active">
              <div class="nav-first-tile-div">
                <div><img src="assets/img/icon-sms-tools.png" /></div>&nbsp;&nbsp;
                <div><span>Messaging tools</span></div>
              </div>
            </a>
          </div>
          <div *ngIf="isTester()" class="nav-navlist-item-sms-div"
               [style]="(isHandset$ | async) ? 'margin-bottom: 0px;' : 'margin-bottom: 12px;'">
            <a [routerLink]="'/communications'" class="nav-navlist" routerLinkActive="nav-is-active">
              <div class="nav-first-tile-div">
                <div><mat-icon style="padding-top:2px"><span style="color:#65AAFD;">view_quilt</span></mat-icon></div>&nbsp;&nbsp;
                <div><span>Communication templates</span></div>
              </div>
            </a>
          </div>
          <div *ngIf="isAdmin()" class="nav-navlist-item-sms-div"
               [style]="(isHandset$ | async) ? 'margin-bottom: 0px;' : 'margin-bottom: 12px;'">
            <button mat-button class="nav-admin-tools-btn" [matMenuTriggerFor]="menu">
              <mat-icon><span class="material-icons">admin_panel_settings</span></mat-icon>
              Admin tools
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item [routerLink]="'/system-dashboard'">System Dashboard</button>
              <button mat-menu-item [routerLink]="'/routing-dashboard'">Message Routing Dashboard</button>
              <button mat-menu-item [routerLink]="'/communications'">Communications</button>
              <button mat-menu-item [routerLink]="'/manageapplications'">WhatsApp Applications</button>
              <button mat-menu-item [routerLink]="'/smshome/global'">Global Messaging</button>
              <button mat-menu-item [routerLink]="'/dataio'">Data IO</button>
              <button mat-menu-item [routerLink]="'/users'">Users</button>
              <button mat-menu-item [routerLink]="'/system'">System</button>
              <button mat-menu-item [routerLink]="'/networkmanager'">Network Manager</button>
              <button mat-menu-item [routerLink]="'/adminorgs'">Organizations</button>
              <button mat-menu-item [routerLink]="'/cint-mapper'">CINT mapper</button>
              <button mat-menu-item [routerLink]="'/cint-pricing'">CINT pricing</button>
              <button mat-menu-item [routerLink]="'/fintrans'">Financial transactions</button>
              <button mat-menu-item [routerLink]="'/activenumber'">Check Number</button>
            </mat-menu>
          </div>
        </div>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content class="sidenav-content">
      <div class="nav-main-content">
        <router-outlet></router-outlet>
      </div>
      <footer>
        <div class="nav-footer" [class]="(isHandset$ | async) ? 'noDisplay' : ''">
          <div class="footer-container">
            <div class="footer-left-content">
              <div class="footer-version-div">
                <span>&#169;{{ currentYear }} DIY Surveys Ltd </span>&nbsp;<span>{{currentVersion}}</span>
              </div>
              <div class="footer-terms-div">
                <span><a (click)="openTerms()" class="cursor-pt" [innerHTML]="(isHandset$ | async) ? 'Terms' : 'Terms of Service'"></a></span>
              </div>
              <div class="footer-terms-div">
                <span><a (click)="openPrivacy()" class="cursor-pt" [innerHTML]="(isHandset$ | async) ? 'Privacy' : 'Privacy Statement'"></a></span>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
