<div class="comm-list-main-container">
  <mat-sidenav-container>
    <mat-sidenav #leftSidenav class="reports-mat-sidenav-left" mode="side" opened [disableClose]="true">
      <div class="simple-row">
        &nbsp;
        <button mat-stroked-button [matMenuTriggerFor]="ioMenu">
          <mat-icon>expand_more</mat-icon>
          <span>Create</span>
        </button>&nbsp;
        <button mat-icon-button (click)="getComms()">
          <mat-icon>refresh</mat-icon>
        </button>
        <mat-menu #ioMenu="matMenu">
          <button mat-menu-item [matMenuTriggerFor]="emailMenu">
            <span>Email</span>
          </button>
          <button *ngIf="whatsAppStatus == 3" mat-menu-item [matMenuTriggerFor]="whatsAppMenu">
            <span>WhatsApp</span>
          </button>
          <button mat-menu-item [matMenuTriggerFor]="smsMenu">
            <span>SMS.</span>
          </button>
        </mat-menu>
        <mat-menu #emailMenu="matMenu">
          <button mat-menu-item *ngFor="let item of emailTypes" (click)="newComm(0, item.id)">
            <span>{{item.name}}</span>
          </button>
        </mat-menu>
        <mat-menu #whatsAppMenu="matMenu">
          <button mat-menu-item *ngFor="let item of whatsAppTypes" (click)="newComm(1, item.id)">
            <span>{{item.name}}</span>
          </button>
        </mat-menu>
        <mat-menu #smsMenu="matMenu">
          <button mat-menu-item *ngFor="let item of smsTypes" (click)="newComm(2, item.id)">
            <span>{{item.name}}</span>
          </button>
        </mat-menu>
      </div>
      <div class="reports-leftSidebar-list custom-scroll-bar comm-list-sidebar-div">
        <div class="reports-question-sidebar-box" *ngFor="let detail of details; let i = index" cdkDrag>
          <div (click)="selectedIndex = i" [class]="selectedIndex == i ? 'comm-list-selected' : 'comm-list-notSelected'">
            <div class="reports-qnSidebar-text">
              <span>{{detail.name}}&nbsp;{{detail.comms?.length > 0 ? '(' + detail.comms?.length + ')' : ''}}</span>
            </div>
          </div>
        </div>
      </div>
    </mat-sidenav>
    <mat-sidenav-content class="comm-list-sidenav-content">
      <div class="comm-list-sidenav-div">
        <div class="margin-5">
          <h3>{{details[selectedIndex].description}}</h3>
        </div>
        <div class="margin-5" *ngIf="selectedIndex == 1 && whatsAppStatus != 3">
          <h3>Welcome to the DIY Surveys' WhatsApp Business solution</h3>
          <p *ngIf="whatsAppStatus == -1">
            In order to start using WhatsApp you need to complete our <a [routerLink]="'/whatsapp/0/whatsapp/0/comms'">Application Form</a>.
          </p>
          <p *ngIf="whatsAppStatus == 0">
            You have completed the WhatsApp Application Form and it is waiting for approval.
            <a [routerLink]="'/whatsapp/0/whatsapp/0/profile'">Click here</a> to review the Application.
          </p>
          <p *ngIf="whatsAppStatus == 1">
            Your application has been approved and DIY Surveys is connecting your account to your WhatsApp Business Account.<br />
            You will be notified as soon as this complete.
          </p>
          <p *ngIf="whatsAppStatus == 2">
            Your application has been rejected with the basic feedback:<br />
            {{application?.Data.StatusMessage}}<br /><br />
            For more information please contact the Client Services Team.
          </p>
        </div>
        <div *ngIf="details[selectedIndex].comms?.length < 1 && selectedIndex == 1 && whatsAppStatus == 3">
          There are no {{details[selectedIndex].name}} definitions
        </div>
        <div *ngIf="details[selectedIndex].comms?.length < 1 && selectedIndex != 1">
          There are no {{details[selectedIndex].name}} definitions
        </div>
        <div>
          <div class="custom-scroll-bar comm-list-editor-div">
            <app-comm-editor *ngFor="let comm of details[selectedIndex].comms" [comm]="comm" (closed)="commClosed($event)"></app-comm-editor>
          </div>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
