import { AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import * as d3 from 'd3';
import * as cloud from 'd3-cloud';
import { BaseChart } from '../../question.service';
import { colorSets } from '../color-sets.service';

@Component({
  selector: 'app-word-cloud-chart',
  templateUrl: './word-cloud-chart.component.html',
  styleUrls: ['./word-cloud-chart.component.css']
})
export class WordCloudChartComponent extends BaseChart implements OnInit, AfterViewInit {

  public svg: d3.Selection<SVGGElement, unknown, null, undefined> | undefined;

  d3 = d3;
  rendered = false;
  colors = null;
  static nextId = 0;
  id = `wordcloud-${WordCloudChartComponent.nextId++}`;

  @ViewChild('wordcloud') wordcloud: ElementRef | undefined;

  constructor(private renderer: Renderer2) {
    super();
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
  }

  renderData(): boolean {
    if (this.rendered) {
      if (this.colors == this.scheme) {
        return true;
      }
    }

    this.colors = this.scheme;
    if (this.words && this.wordcloud) {
      this.renderChart();
      this.rendered = true;
      return true;
    }

    return false;
  }

  renderChart() {
    if (this.wordcloud) {
      this.renderer.setProperty(this.wordcloud.nativeElement, 'innerHTML', '');
    }

    let words = [];
    let color = null;
    for (let i = 0; i < colorSets.length; i++) {
      if (colorSets[i].name == this.scheme || colorSets[i].name == this.scheme.name) {
        color = colorSets[i];
        break;
      }
    }

    if (color == null) {
      color = this.colorScheme;
    }

    for (let i = 0; i < this.words.length; i++) {
      if (this.words[i].Response !== "") {
        words.push({
          text: this.words[i].Response, size: this.words[i].Answer, test: "haha",
          color: color.domain[Math.floor(Math.random() * color.domain.length)],
          id: this.id
        });
      }
    }

 //     .rotate(function () { return Math.floor(Math.random() * (60 - -60 + 1)) + -60; })
    var layout = cloud()
      .size(this.view)
      .words(words)
      .padding(10)
      .rotate(0)
      .font("Impact")
      .fontSize(function (d: any) { return d.size * 20; })
      .on("end", draw);
    layout.start();
    // this.svg = d3.select(document.getElementById(words[0].id)).append("svg");

    function draw(words: any) {
      d3.select(document.getElementById(words[0].id)).append("svg")
        .attr("width", layout.size()[0])
        .attr("height", layout.size()[1])
        .append("g")
        .attr("transform", "translate(" + layout.size()[0] / 2 + "," + layout.size()[1] / 2 + ")")
        .selectAll("text")
        .data(words)
        .enter().append("text")
        .style("font-size", function (d: any) { return d.size + "px"; })
        .style("font-family", "Impact")
        .style("fill", function (d: any) { return d.color })
        .attr("text-anchor", "middle")
        .attr("transform", function (d: any) {
          return "translate(" + [d.x, d.y] + ")rotate(" + d.rotate + ")";
        })
        .text(function (d: any) { return d.text; });
    }
  }
}
