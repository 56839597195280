<app-toolbar></app-toolbar>
<div class="reset-password-main-div">
  <mat-card class="reset-password-card">
    <form class="reset-password-form column-flex" [formGroup]="formGroup" (ngSubmit)="resetPassword()">
      <div>
        <h3 class="txt-align-center">Reset Password</h3>
      </div>
      <div *ngIf="displayError">
        <div class="alert alert-danger" role="alert">
          {{errorMsg}}
        </div>
      </div>
      <div *ngIf="displayForm == true" class="column-flex">
        <div>
          <mat-form-field appearance="outline">
            <mat-label>New Password</mat-label>
            <input matInput [type]="hide ? 'password' : 'text'" placeholder="Enter new password" formControlName="newPassword">
            <mat-icon class="cursor-pt" matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="outline">
            <mat-label>Confirm Password</mat-label>
            <input matInput [type]="hide ? 'password' : 'text'" placeholder="Confirm your password" formControlName="confirmPassword">
            <mat-icon class="cursor-pt" matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </mat-form-field>
        </div>
        <div>
          <button mat-raised-button type="submit" class="mt-5" color="primary">Change Password</button>
        </div>
      </div>
    </form>
  </mat-card>
</div>
