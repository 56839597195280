import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RuleList } from '../../question.service';

@Component({
  selector: 'app-export-filters',
  templateUrl: './export-filters.component.html',
  styleUrls: ['./export-filters.component.css']
})
export class ExportFiltersComponent implements OnInit {

  rules: RuleList = new RuleList();

  constructor(private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) data: any) { }

  ngOnInit(): void {
  }

  edit() {

  }

  delete() {

  }

}
