import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from "@angular/forms";
import { Subscription, Observable } from "rxjs";
import { UserTinyUrl, TinyUrlService } from "../tinyUrl.Service";
import { SystemService } from "../system.Service";
import { MissionService } from "../MissionService";
import { Router, ActivatedRoute } from '@angular/router';
import { tap, catchError } from 'rxjs/operators';


@Component({
  selector: 'app-tinyurl-detail',
  templateUrl: './tinyurl-detail.component.html',
  styleUrls: ['./tinyurl-detail.component.css']
})
export class TinyurlDetailComponent implements OnInit {
  subscription: Subscription;
  firstFormGroup: FormGroup = new FormGroup({});
  secondFormGroup: FormGroup = new FormGroup({});
  tempTinyUrl?: Observable<UserTinyUrl>;
  tinyUrl: UserTinyUrl = new UserTinyUrl();
  ipAddress: string = "";
  id: string = "";
  success: boolean = false;
  errorMsg: string = "";
  timeout: any | null = null;
  readonly: string = "";
  back: string = "";
  userId: number = 0;



  constructor(
    private missionService: MissionService,
    private router: Router,
    private actRoute: ActivatedRoute,
    private tinyUrlService: TinyUrlService,
    private systemService: SystemService
  ) {
    this.back = this.actRoute.snapshot.params.type;
    this.userId = Number(this.actRoute.snapshot.params.userid);
    this.id = this.actRoute.snapshot.params.id;
    systemService.getById("tinyUrl:ipAddress").subscribe(
      result => { this.ipAddress = result.Value; }
    );

    this.subscription = missionService.missionAnnounced$.subscribe(
      mission => {
        if (mission === "Back") {
          this.onSubmit();
        }
      });
  }

  ngOnInit(): void {
    this.success = false;
    this.errorMsg = "";
    this.initForm();
    if (this.id === "0") {
      this.readonly = "";
      this.tinyUrl = new UserTinyUrl();
      this.tinyUrlService.getVerificationCode().subscribe(result => {
        this.tinyUrl.ValidationCode = result.Code;
        this.secondFormGroup.patchValue(this.tinyUrl);
      });
      this.tempTinyUrl = new Observable((observer) => {
        observer.next(this.tinyUrl);
        observer.complete();
      });
    } else {
      this.readonly = "readonly";
      this.tempTinyUrl = this.tinyUrlService.getTinyUrl(this.id).pipe(tap<UserTinyUrl>(result => {
        this.tinyUrl = result;
        this.firstFormGroup.patchValue(this.tinyUrl);
        this.secondFormGroup.patchValue(this.tinyUrl);
        this.testUrl(this.tinyUrl.Url);
      }));
    }
  }

  initForm() {
    this.firstFormGroup = new FormGroup({
      Url: new FormControl()
    });
    this.secondFormGroup = new FormGroup({
      ValidationCode: new FormControl(),
      IsHttps: new FormControl()
    });
  }

  copyFromCode() {
    return this.secondFormGroup.controls.ValidationCode.value;
  }

  verifyDomain(stepper: any) {
    if (this.firstFormGroup.invalid) {
      return;
    }

    this.checkDomain().subscribe(result => {
        this.tinyUrl = result;
        stepper.next();
      });
  }

  checkDomain() {
    this.tinyUrl.Url = this.firstFormGroup.controls.Url.value;
    this.tinyUrl.ValidationCode = this.secondFormGroup.controls.ValidationCode.value;
    return this.tinyUrlService.setTinyUrl(this.tinyUrl);
  }

  checkUrl(event: any) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.errorMsg = "";
      this.success = false;

      const url = this.firstFormGroup.controls.Url.value;
      if (!url) {
        return;
      }
      if (url.trim().length === 0) {
        return;
      }

      this.testUrl(url);
    }, 1000);
  }

  testUrl(url: string) {
    this.tinyUrlService.checkUrl(url, this.id, this.tinyUrl.ValidationCode).subscribe(result => {
        this.errorMsg = "";
        this.success = true;
        this.tinyUrl.IsValidated = true;
      },
      error => {
        switch (error.status) {
        case 404:
          this.errorMsg = "This URL has no certificate associated with it";
          break;
        case 302:
          this.errorMsg = "This URL is in use by another account or you have set it up already";
          break;
        case 208:
          this.success = true;
          return;
        default:
          this.errorMsg = "This URL is not set up in the platform correctly";
          break;
        }

        this.success = false;
      });
  }


  onSubmit() {
    if (this.tinyUrl.Url?.length > 0) {
      this.tinyUrl.OwnerId = this.userId;
      this.tinyUrl.IsHttps = this.secondFormGroup.controls.IsHttps.value;
      this.tinyUrlService.setTinyUrl(this.tinyUrl).subscribe();
    }

    if (this.back == "profile") {
      this.router.navigate(['profile', this.userId]);
      return;
    }

    this.router.navigate(['edituser', this.userId, 'user'])

  }
}
