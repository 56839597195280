import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { baseUrl, options } from "../environments/environment";

export class UserModel {
  UserId: number = 0;
  CreateDate: Date = new Date();
  ConfirmationToken: string = "";
  IsConfirmed: boolean = false;
  LastPasswordFailureDate: Date = new Date();
  LastLoggedIn: Date = new Date();
  PasswordFailureSinceLastSuccess: number = 0;
  Password: string = "";
  PasswordChangedDate: Date = new Date();
  PasswordSalt: string = "";
  PasswordVerificationToken: string = "";
  PasswordVerificationTokenExpirationDate: Date = new Date();
  SmsAdministratorId: number = 0;
  Roles: Role[] = [];
  IsNew: boolean = false;
  IsModified: boolean = false;
  Devices: DeviceModel[] = [];
}

export class DeviceModel {
  DeviceListId: number = 0;
  UserId: number = 0;
  IPAddress: string = "";
  DeviceType: string = "";
  Created: Date = new Date();
  LastUsed: Date = new Date();
  IsDeleted: boolean = false;
  IsNew: boolean = false;
  IsModified: boolean = false;
}

export class Role {
  RoleId: number = 0;
  RoleName: string = "";
}

export class UserSummary {
  UserID: number = 0;
  UserName: string = "";
  EmailAddress: string = "";
  FirstName: string = "";
  LastName: string = "";
  CreatedDate: Date = new Date();
  LastLoggedIn: Date = new Date();
  IsConfirmed: boolean = false;
  Roles: Role[] = [];
}

export class UserProfile {
  UserProfileExtensionID: number = 0;
  UserID: number = 0;
  UserName: string = "";
  EmailAddress: string = "";
  FirstName: string = "";
  LastName: string = "";
  GMT: number = 0;
  Country: string = "";
  MobileNumber: string = "";
  Credit: number = 0;
  IsDeleted: boolean = false;
  CreatedDate: Date = new Date();
  LastUpdatedDate: Date = new Date();
  Status: number = 0;
  SharedKey: string = "";
  Member: UserModel = new UserModel();
  PanelPricingModel: number = 0;
  PanelAmount: number = 0;
  CompleteCost: number = 0;
  CintCompleteCost: number = 0;
  IsNew: boolean = false;
  IsModified: boolean = false;
  Company: string = "";
  CompanySector: number = 0;
  CompanySize: number = 0;
  JobRole: number = 0;
  Interests: string = "";
  TwoFA: number = 0;
  Features: string = "";
  UserFeatures: UserFeatures = new UserFeatures();
  Bio: string = "";
  ZendeskUsername: string = "";
  MediaId: number = 0;

  constructor() {

  }

  public static getInitial(value: string): string {
    if (value == null || value == "") {
      return "?";
    }

    return value.substring(0, 1).toUpperCase();
  }
}

export class UserFeatures {
  GeneralFeatures: GeneralFeatures = new GeneralFeatures();
  DashboardFeatures: DashboardFeatures = new DashboardFeatures();
  QuestionEditorFeatures: QuestionEditorFeatures = new QuestionEditorFeatures();
  DataAnalysisFeatures: DataAnalysisFeatures = new DataAnalysisFeatures();
  AccountFeatures: AccountFeatures = new AccountFeatures();
}

export class GeneralFeatures {
  Theme: string;
}

export class DashboardFeatures {
  SurveySummary: boolean;
  FeatureOrder: string[];
}

export class QuestionEditorFeatures {
  EditQuestionName: boolean;
  EditAnswerOptions: boolean;
  EditAnswerOptionFactors: boolean;
}

export class DataAnalysisFeatures {
  AccessToAdvancedAnalysisOptions: boolean;
}

export class AccountFeatures {
  MakeProfilePrivate: boolean;
}

export class ShortCode {
  ShortCodeId: number = 0;
  Code: string = "";
  OwnerId: number = 0;
  Created: Date = new Date();
  Amended: Date = new Date();
  IsDeleted: boolean = false;
  Description: string = "";

  constructor() {
    this.Code = "";
    this.OwnerId = 0;
    this.IsDeleted = false;
    this.Description = "";
  }
}


@Injectable()
export class UserService {

  constructor(private http: HttpClient) {
  }

  getNumbersForCurrentUser(): Observable<ShortCode[]> {
    return this.getNumbers("0");
  }

  getUsersForIds(userIds: number[]): Observable<any> {
    return this.http.patch<UserProfile[]>(baseUrl + 'User/GetUsersForIds', userIds, options);
  }

  getNumbers(userId: string): Observable<ShortCode[]> {
    return this.http.get<ShortCode[]>(baseUrl + 'User/GetNumbers?id=' + userId, options);
  }

  checkNumber(userId: string, code: number): Observable<any> {
    return this.http.get<string>(baseUrl + 'User/CheckNumber?id=' + userId + "&number=" + code, options);
  }

  setNumbers(userId: string, numbers: ShortCode[]) {
    return this.http.patch<ShortCode[]>(baseUrl + 'User/UpdateNumbers?id=' + userId, numbers, options);
  }

  getCurrentUser(): Observable<UserProfile> {
    return this.http.get<UserProfile>(baseUrl + 'User/Get', options);
  }

  getUser(userId: string): Observable<UserProfile> {
    return this.http.get<UserProfile>(baseUrl + 'User/GetById?id=' + userId, options);
  }

  setUser(user: UserProfile) {
    return this.http.patch<UserProfile>(baseUrl + 'User/Update', user, options);
  }

  updateRolesForOrganization(organizationId: number, status: number, roles: Role[]) {
    return this.http.patch(baseUrl + 'User/UpdateRolesForOrganization?organizationId=' + organizationId + "&status=" + status, roles, options);
  }

  getUsers(): Observable<UserSummary[]> {
    return this.http.get<UserSummary[]>(baseUrl + 'User/GetUsers', options);
  }

  getTopUsers(top: number): Observable<UserSummary[]> {
    return this.http.get<UserSummary[]>(baseUrl + 'User/GetTopUsers?top=' + top, options);
  }

  findUser(search: string): Observable<UserProfile[]> {
    return this.http.get<UserProfile[]>(baseUrl + 'User/Search?search=' + search, options);
  }

  findOrgUser(search: string): Observable<UserProfile[]> {
    return this.http.get<UserProfile[]>(baseUrl + 'User/SearchOrgUsers?search=' + search, options);
  }

  checkUsername(username: string): Observable<UserProfile[]> {
    return this.http.get<UserProfile[]>(baseUrl + 'User/CheckUsername?username=' + username, options);
  }

  change(username: string): Observable<any> {
    return this.http.patch(baseUrl + 'User/Change?username=' + username, options);
  }

  changeOrg(name: string): Observable<any> {
    return this.http.patch(baseUrl + 'User/ChangeOrg?name=' + name, options);
  }

  changeUsername(id: number, username: string): Observable<any> {
    return this.http.patch(baseUrl + 'User/ChangeUsername?id=' + id + '&username=' + username, options);
  }

  changeOrgName(id: number, name: string): Observable<any> {
    return this.http.patch(baseUrl + 'User/ChangeOrgName?id=' + id + '&name=' + name, options);
  }

  changeEmail(email: string): Observable<any> {
    return this.http.patch(baseUrl + 'User/ChangeEmail?email=' + email, options);
  }

  getUserFeatures(): Observable<UserFeatures> {
    return this.http.get<UserFeatures>(baseUrl + 'User/GetUserFeatures', options);
  }

  exportUsers(): Observable<any> {
    return this.http.get<any>(baseUrl + 'User/ExportUsers', options);
  }

  static hasRole(roles: Role[], name: string): boolean {
    if (roles === undefined || roles === null) {
      return false;
    }

    return roles.find(role => role.RoleName === name) !== undefined;
  }
}

