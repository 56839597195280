import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from '../../authentication.Service';
import { UserService } from '../../user.Service';

@Component({
  selector: 'app-change-email',
  templateUrl: './change-email.component.html',
  styleUrls: ['./change-email.component.css']
})
export class ChangeEmailComponent implements OnInit {

  errorMsg: string = "";
  success: boolean = false;
  formGroup: FormGroup = new FormGroup({});
  timeout: any | null = null;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ChangeEmailComponent>,
    private authService: AuthenticationService,
    private userService: UserService) { }

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      email: new FormControl("", [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')])
    });
  }

  checkName(event: any) {
    if (this.timeout != null) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      this.errorMsg = "";
      this.success = false;

      const name = this.formGroup.controls.email.value;
      if (!name) {
        return;
      }
      if (name.trim().length === 0) {
        return;
      }

      this.testName(name);
    }, 500);
  }

  testName(name: string) {
    this.success = true;
  }

  onSubmit() {
    this.userService.changeEmail(this.formGroup.controls.email.value).subscribe(
      result => {
        this.authService.setAccessToken(result.Token);
        this.dialogRef.close({ email: this.formGroup.controls.email.value });
      }
    );

    return false;
  }
}
