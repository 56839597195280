import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../../authentication.Service';
import { MissionService } from '../../MissionService';
import { NetworkModel, NetworkService, NumberModel, RequestModel } from '../../network.Service';

@Component({
  selector: 'app-manage-request',
  templateUrl: './manage-request.component.html',
  styleUrls: ['./manage-request.component.css']
})
export class ManageRequestComponent {

  subscription: Subscription;
  formGroup: FormGroup = new FormGroup({});
  networks: NetworkModel[] = [];
  numbers: NumberModel[] = [];
  request: RequestModel = new RequestModel();
  network: NetworkModel = new NetworkModel();
  number: NumberModel = new NumberModel();
  id: number;
  back: string = "smshome";

  constructor(public translate: TranslateService,
    private authenticationService: AuthenticationService,
    private missionService: MissionService,
    private snackbar: MatSnackBar,
    private networkService: NetworkService,
    private actRoute: ActivatedRoute,
    private router: Router
  ) {

    this.subscription = missionService.missionAnnounced$.subscribe(
      mission => {
        if (mission === "Back") {
          this.router.navigate([this.back]);
        }
      });
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      type: new FormControl(1, Validators.required),
      country: new FormControl(null, Validators.required),
      number: new FormControl(null, Validators.required),
      notes: new FormControl(null),
      status: new FormControl(null)
    });
    this.id = this.actRoute.snapshot.params.id;
    if (this.id > 0) {
      this.networkService.getNetworks().subscribe(result => {
        this.networks = result
        this.networkService.getRequest(this.id).subscribe(result => {
          this.request = result;
          this.number = this.request.Number;
          this.formGroup.controls.name.setValue(this.request.Name);
          this.formGroup.controls.type.setValue(this.request.TwoWay ? 2 : 1);
          for (let i = 0; i < this.networks.length; i++) {
            if (this.request.NetworkId == this.networks[i].NetworkId) {
              this.network = this.networks[i];
              if (this.request.NumberId != 0) {
                this.number.Network = this.networks[i];
              }

              this.getNumberArray();
              break;
            }
          }

          this.formGroup.controls.country.setValue(this.network.NetworkId);
          this.formGroup.controls.number.setValue(this.number.NumberId);
          this.formGroup.controls.notes.setValue(this.request.Notes);
          this.formGroup.controls.status.setValue(this.request.StatusId.toString());
        });
      });
    }
    else {
      this.networkService.getNetworks().subscribe(result => {
        this.networks = result
      });
    }

  }

  get isAdminUser(): boolean {
    return this.authenticationService.isAuthorized(['Admin']);
  }

  countryChange() {
    for (let i = 0; i < this.networks.length; i++) {
      if (this.formGroup.controls.country.value == this.networks[i].NetworkId) {
        this.network = this.number.Network = this.networks[i];
      }
    }

    this.getNumbers();
  }

  twoWayChange() {
    this.request.TwoWay = this.formGroup.controls.type.value;
    this.getNumbers();
  }

  getNumberArray() {
    this.numbers = [];
    for (let i = 0; i < this.network.Numbers.length; i++) {
      if ((this.network.Numbers[i].TwoWay && this.formGroup.controls.type.value == 2) ||
        (!this.network.Numbers[i].TwoWay && this.formGroup.controls.type.value == 1)) {
        this.numbers.push(this.network.Numbers[i]);
      }
    }
  }

  getNumbers() {
    this.getNumberArray();
    if (this.numbers.length == 1) {
      this.formGroup.controls.number.setValue(this.numbers[0].NumberId);
      this.formGroup.controls.number.setValidators(Validators.required);
      this.number = this.numbers[0];
    }
    else {
      this.formGroup.controls.number.setValue(null);
      if (this.numbers.length == 0) {
        this.formGroup.controls.number.setErrors(null);
        this.formGroup.controls.number.clearValidators();
      }
      else {
        this.formGroup.controls.number.setValidators(Validators.required);
      }

      this.number = new NumberModel();
    }
  }

  numberChange() {
    for (let i = 0; i < this.numbers.length; i++) {
      if (this.formGroup.controls.number.value == this.numbers[i].NumberId) {
        this.number = this.numbers[i];
        return;
      }
    }

    this.number = null;
  }

  store(value, type) {
    switch (type) {
      case "branding":
        this.request.Branding = value;
        break;
      case "invite":
        this.request.InviteMessage = value;
        break;
      case "reminder":
        this.request.ReminderMessage = value;
        break;
      case "survey":
        this.request.SurveyLinkFormat = value;
        break;
      case "optout":
        this.request.OptOutLink = value;
        break;
      case "tiny":
        this.request.TinyUrlExample = value;
        break;
    }
  }

  status(): string {
    switch (this.request.StatusId) {
      case 0:
        return "In Progress";
      case 1:
        return ".."
    }

    return "Unknown";
  }

  cancel() {
    this.router.navigate([this.back]);
  }

  onSubmit() {
    if (this.formGroup.invalid) {
      return;
    }

    this.request.Name = this.formGroup.controls.name.value;
    if (this.isAdminUser) {
      this.request.StatusId = Number(this.formGroup.controls.status.value);
      this.request.Notes = this.formGroup.controls.notes.value;
    }

    this.request.TwoWay = this.formGroup.controls.type.value == 2;
    this.request.NumberId = this.number == null ? 0 : this.number.NumberId;
    this.request.NetworkId = this.network.NetworkId;
    this.request.IsDeleted = false;
    this.networkService.saveRequest(this.request).subscribe(
      result => {
        this.openSnackbar("Successfully saved", "");
        this.router.navigate([this.back]);
      },
      error => {
        this.openSnackbar("There was a problem saving the request", "Cancel");
      }
    );
  }

  private openSnackbar(message: string, action: string) {
    if (action == "") {
      this.snackbar.open(message, action, { duration: 2000 });
    }
    else {
      this.snackbar.open(message, action, { duration: 7000 });
    }
  }

}
