import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NewPanelComponent } from '../new-panel/new-panel.component';
import { PanelService, PanelModel } from "../panel.Service";
import { Observable, timer } from 'rxjs';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { SnackbarDefaultComponent } from '../snackbar/snackbar-default.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { PanelSelectorComponent } from '../panel-selector/panel-selector.component';
import { plainToClass } from 'class-transformer';
import { CintFeasibility } from '../cint.Service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-panels',
  templateUrl: './panels.component.html',
  styleUrls: ['./panels.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class PanelsComponent implements OnInit {
  searchKey: string = "";
  noPanels = true;
  panelPageSize = 10;
  sb?: MatSnackBarRef<any>;
  allowUpdates: boolean;
  panelsTemp?: Observable<PanelModel[]>;
  Panels: PanelModel[] = [];

  dataSource = new MatTableDataSource<PanelModel>(this.Panels);
  expandedElement: PanelModel | null = null;

  resultsLength = 0;
  private inTimer = false;
  waiting?: Observable<any>;
  waitTime: Observable<any> = timer(0, 3000);

  @ViewChild('table', { static: true }) table: any | undefined;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | undefined;
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    if (this.dataSource) {
      this.dataSource.sort = value;
    }
  }

  get columnsToDisplay(): string[] {
    if (this.canEdit) {
      return ['name', 'created', 'modified', 'respondents', 'profile', 'action'];
    }

    return ['name', 'created', 'modified', 'respondents', 'profile'];
  }

  constructor(
    public translate: TranslateService,
    private dialog: MatDialog,
    private panelService: PanelService,
    private snackbar: MatSnackBar,
    private router: Router
  ) {
    this.allowUpdates = true;
    this.panelService.panelCount().subscribe(result => {
      this.noPanels = result === 0;
      if (!this.noPanels) {
        this.getPanels();
      }
    });
  }

  ngOnInit(): void {

  }

  getPanels() {
    this.sb = this.snackbar.openFromComponent(SnackbarDefaultComponent);
    this.panelService.getPanels()
      .subscribe(result => {
        this.Panels = result;
        this.loadPanels();
        this.dismissSnackBar();
      });
  }

  loadPanels() {
    this.dataSource = new MatTableDataSource(this.Panels);
    if (this.paginator != null) {
      this.dataSource.paginator = this.paginator;
    }

    this.dataSource.sort = this.sort;
    this.dataSource.filter = this.searchKey;
    this.resultsLength = this.Panels.length;
  }

  summary(panel: PanelModel) {
    if (panel.IsCint) {
      return "Cint";
    }

    if (panel.Summary) {
      return panel.Summary.Emails + " Email Address(es) and " + panel.Summary.Devices + " Mobile Device(s)";
    }

    return panel.IsCint ? "Cint" : "Unknown";
  }

  onSearchClear(event: Event) {
    this.searchKey = "";
    this.dataSource.filter = this.searchKey;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    if (filterValue === "") {
      return;
    }

    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  private dismissSnackBar() {
    if (this.sb) {
      this.sb.dismiss();
    }
  }

  newPanelDialog(): void {
    const dialogRef = this.dialog.open(NewPanelComponent,
      {});

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.data) {
        let panel: PanelModel = result.data;
        this.Panels.splice(0, 0, panel);
        this.noPanels = false;
        this.loadPanels();
        switch (panel.PanelType) {
          case 0:
          case 2:
            this.editCint(panel);
            break;
          case 1:
            this.router.navigate(['/uploadRespondents-template', panel.PanelID, "panel"]);
            break;
        }
      }
    });
  }

  renamePanel(panel: PanelModel): void {
    const dialogRef = this.dialog.open(NewPanelComponent,
      { data: { id: panel.PanelID } });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.data) {
        let panel: PanelModel = result.data;
        for (let i = 0; i < this.Panels.length; i++) {
          if (this.Panels[i].PanelID === panel.PanelID) {
            this.Panels[i] = panel;
            this.loadPanels();
            return;
          }
        }
      }
    });
  }

  editPanel(panel: PanelModel) {
    switch (panel.PanelType) {
      case 0:
      case 2:
        this.allowUpdates = false;
        this.dismissSnackBar();
        this.editCint(panel);
        break;
      case 1:
        this.allowUpdates = false;
        this.dismissSnackBar();
        this.router.navigate(['/edit-respondents', panel.PanelID, 'panel']);
        break;
    }
  }

  editCint(panel: PanelModel) {
    let quota = plainToClass(CintFeasibility, panel.Quota);
    const audDialog = this.dialog.open(PanelSelectorComponent, { width: '80%', data: { quota: quota, questionCount: 5 } });
    audDialog.afterClosed().subscribe(result => {
      if (result && result.quota.Feasibility) {
        quota = result.quota.Feasibility;
        panel.Quota = JSON.stringify(quota);
        panel.PanellistCount = quota.Limit;
        this.panelService.updatePanel(panel).subscribe(
          result => {
            for (let i = 0; i < this.Panels.length; i++) {
              if (this.Panels[i].PanelID == panel.PanelID) {
                this.Panels[i] = panel;
                break;
              }
            }

            this.loadPanels();
          });
      }
    });
  }

  edit(id: PanelModel) {
    if (this.canEdit && id.Summary && !id.IsCint) {
      this.expandedElement = this.expandedElement === id ? null : id;
      return;
    }

    if (this.canEdit && (!(id.Summary == undefined) || id.IsCint)) {
      this.allowUpdates = false;
      this.editPanel(id);
      return;
    }

    this.onSelected(id);
  }

  deletePanel(id: PanelModel) {
    this.allowUpdates = false;
    this.dismissSnackBar();
    this.panelService.delete(id.PanelID).subscribe(
      result => {
        for (let i = 0; i < this.Panels.length; i++) {
          if (this.Panels[i].PanelID === id.PanelID) {
            this.Panels.splice(i, 1);
            this.noPanels = this.Panels.length == 0;
            this.loadPanels();
            return;
          }
        }
      });
  }

  copyPanel(panel: PanelModel) {
    this.allowUpdates = false;
    this.dismissSnackBar();
    this.panelService.copyPanel(panel.PanelID).subscribe(
      result => {
        this.getPanels();
      });
  }

  @Input()
  get canEdit(): boolean {
    return this._canEdit;
  }
  set canEdit(value: boolean) {
    this._canEdit = value;
  }
  private _canEdit = true;

  @Output()
  selected: EventEmitter<PanelModel> = new EventEmitter<PanelModel>();

  registerOnSelected(fn: any): void {
    this.onSelected = fn;;
  }

  onSelected(id: PanelModel) {
    this.selected.emit(id);
  }
}
