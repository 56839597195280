import { Component, OnInit, Input } from '@angular/core';
import { AnswerModel } from "../../models/AnswerModel";

export class MaxDiffCell {
  isLabel: boolean;
  isCategory: boolean;
  questionIndex: number = -1;
  categoryIndex: number;
  label: string = "";
  question: any;
  category: any;
  groupName: string = "";

  constructor(categoryIndex: number, label?: string, question?: any, category?: any, groupName?: string) {
    this.categoryIndex = categoryIndex;
    this.isLabel = false;
    this.isCategory = false;

    if (label !== undefined) {
      this.isLabel = true;
      this.label = label;
    }

    if (groupName !== undefined) {
      this.groupName = groupName;
    }

    if (category !== undefined) {
      this.category = category;
      this.isCategory = true;
    }

    if (question !== undefined) {
      this.question = question;
    }
  }
}

@Component({
  selector: 'diy-maxdiff',
  templateUrl: './maxdiff.component.html',
  styleUrls: ['./maxdiff.component.css']
})
export class MaxDiffComponent implements OnInit {

  cells?: MaxDiffCell[];
  tempQuestion: any;
  low: string = "";
  high: string = "";
  selected: number = -1;

  constructor() {
  }

  ngOnInit(): void {
  }

  @Input()
  get preview(): boolean {
    return this._preview;
  }
  set preview(value: boolean) {
    this._preview = value;
  }
  private _preview: boolean = false;

  @Input()
  get question(): any {
    return this._question;
  }
  set question(value: any) {
    this._question = value;
    if (this._question === undefined) {
      return;
    }

    this.buildMaxDiff();
  }
  private _question: any;

  get answers(): AnswerModel[] {
    this.selected = 0;
    const answers: AnswerModel[] = [];
    for (let i = 0; i < this._question.Item.Items.length; i++) {
      const option = this._question.Item.Items[i];
      const question = option.Item.Items[0];
      let value = "0";
      if (option.QuestionName === this.low) {
        value = "-1";
        this.selected++;
      }
      if (option.QuestionName === this.high) {
        value = "1";
        this.selected++;
      }

      answers.push(new AnswerModel(question.QuestionFullName, value));
    }
    return answers;
  }

  get valid(): boolean {
    if (this._question.MustAnswer) {
      let low = false;
      let high = true;
      for (let i = 0; i < this.answers.length; i++) {
        switch (this.answers[i].value) {
          case "-1":
            low = true;
            break;
          case "1":
            high = true;
            break;
        }
      }

      return high && low;
    }

    return true;
  }

  getProperty(name: string): string {
    for (let i = 0; i < this._question.Properties.Items.length; i++) {
      if (this._question.Properties.Items[i].Name === name) {
        return this._question.Properties.Items[i].Value;
      }
    }

    return "";
  }

  getCategory(name: string): any {
    for (let i = 0; i < this._question.Item.Items[0].Categories.Items.length; i++) {
      if (this._question.Item.Items[0].Categories.Items[i].Name === name) {
        return this._question.Item.Items[0].Categories.Items[i];
      }
    }

    return null;
  }

  buildMaxDiff() {
    Promise.resolve(null).then(() => {
    this.cells = null;
    let tempCells: MaxDiffCell[] = [];
    tempCells.push(new MaxDiffCell(-1, this.getProperty("least")));
    tempCells.push(new MaxDiffCell(-1, ""));
    tempCells.push(new MaxDiffCell(-1, this.getProperty("most")));
    for (let i = 0; i < this._question.Item.Items.length; i++) {
      const question = this._question.Item.Items[i].Item.Items[0];
      const name = this._question.Item.Items[i].QuestionName;
      this.tempQuestion = question;
      const category = this.getCategory(name);
      tempCells.push(new MaxDiffCell(0, undefined, question, category, question.QuestionName + "_low"));
      tempCells.push(new MaxDiffCell(-1, category.Label.Text));
      tempCells.push(new MaxDiffCell(1, undefined, question, category, question.QuestionName + "_high"));
      if (question.Response.Value === "-1") {
        this.low = name;
      }
      if (question.Response.Value === "1") {
        this.high = name;
      }
    }

      this.cells = tempCells;
    });
  }

  checkOtherSide(name: string, group: string) {
    if (group.endsWith('_low')) {
      if (name === this.high) {
        this.high = "";
      }
    }
    else {
      if (name === this.low) {
        this.low = "";
      }
    }
  }
}
