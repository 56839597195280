import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Transaction } from '../../authentication.Service';
import { FinancialService, FinancialTransaction } from '../../finacial.Service';
import { FindUserComponent } from '../../find-user/find-user.component';
import { UserProfile, UserService } from '../../user.Service';
import { FinTranDetailComponent } from '../fin-tran-detail/fin-tran-detail.component';
import { AuthenticationService } from "../../authentication.Service";
import { TranslateService } from '@ngx-translate/core';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-fin-trans',
  templateUrl: './fin-trans.component.html',
  styleUrls: ['./fin-trans.component.css']
})
export class FinTransComponent implements OnInit, AfterViewInit {

  transactions: FinancialTransaction[] = [];


  formGroup: FormGroup;
  userProfile: UserProfile = new UserProfile();
  dataSource = new MatTableDataSource<FinancialTransaction>(this.transactions);
  pageSize = 10;
  startDate: Date = new Date();
  columnsToDisplay = ['id', 'created', 'description', 'debit', 'credit', 'action']
  resultsLength: number = 0;
  userId: string = "0"

  @ViewChild('table', { static: true }) table: any | undefined;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | undefined;

  constructor(public dialog: MatDialog,
    private actRoute: ActivatedRoute,
    public translate: TranslateService,
    private userService: UserService,
    private financeService: FinancialService,
    private authenticationService: AuthenticationService) {
    if (this.isAdminUser && this.actRoute.snapshot.params.id) {
      this.userId = this.actRoute.snapshot.params.id;
      this.userService.getUser(this.userId).subscribe(
        result => {
          this.userProfile = result;
        });
    }
    else {
      this.userProfile = new UserProfile();
    }

    this.formGroup = new FormGroup({
      username: new FormControl(),
      range: new FormControl(),
      start: new FormControl(),
      end: new FormControl()
    })
  }

  get isAdminUser(): boolean {
    return this.authenticationService.isAuthorized(['Admin']);
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.getTransactions();
  }

  findUser($event: any) {
    $event.preventDefault();
    const dialogRef = this.dialog.open(FindUserComponent, {
      width: '80%',
      data: { id: this.userProfile.UserID }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.formGroup.controls.username.setValue(result.UserID.toString());
        this.userProfile = result;
      }
    });
  }

  addTransaction() {
    const dialogRef = this.dialog.open(FinTranDetailComponent, {
      width: '80%',
      data: { id: this.userProfile.UserID, transaction: new Transaction() }
    });
  }

    getTransactions() {
      if (this.userId === "0") {
        this.financeService.getFinancialTransactions().subscribe(
          result => {
            this.transactions = result;
            this.dataSource = new MatTableDataSource(this.transactions);
            if (this.paginator != undefined) {
              this.dataSource.paginator = this.paginator;
            }

            this.dataSource.filter = "";
            this.resultsLength = this.transactions.length;
          });
      }
      else {
        this.financeService.getFinancialTransactionsForUser(this.userId).subscribe(
          result => {
            this.transactions = result;
            this.dataSource = new MatTableDataSource(this.transactions);
            if (this.paginator != undefined) {
              this.dataSource.paginator = this.paginator;
            }

            this.dataSource.filter = "";
            this.resultsLength = this.transactions.length;
          });
      }
  }

  edit(tran: FinancialTransaction) {

  }
}
