import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { options, baseUrl } from "../environments/environment";
import { Observable } from "rxjs";

export class ZoneSummary {
  Name: string = "";
  Type: number = 0;
  Status: number = 0;
  IpAddress: string = "";
  Port: number = 0;
}

export class MessageQueue {
  QueueId: number = 0;
  Name: string = "";
  Description: string = "";
  StatusId: number = 0;
  TypeId: number = 0;
  OwnerId: number = 0;
  IsDeleted: boolean = false;
  Created: Date = new Date();
  Amended: Date = new Date();
  Characteristics: string
  MessageCharacteristics: MessageQueueCharacteristics = new MessageQueueCharacteristics();
  Users: MessageQueueUser[] = [];
}

export class MessageQueueCharacteristics {
  DefaultSpeed: SpeedDefinition;
  AllowedSpeeds: SpeedDefinition[] = [];

  constructor() {
    this.AllowedSpeeds.push(new SpeedDefinition(QueueSpeed.Fastest, -1, -1));
    this.AllowedSpeeds.push(new SpeedDefinition(QueueSpeed.PerSecond, 60, 1));
    this.AllowedSpeeds.push(new SpeedDefinition(QueueSpeed.PerMinute, 240, 1));
    this.AllowedSpeeds.push(new SpeedDefinition(QueueSpeed.PerMinute, 120, 1));
    this.AllowedSpeeds.push(new SpeedDefinition(QueueSpeed.PerMinute, 60, 1));
    this.AllowedSpeeds.push(new SpeedDefinition(QueueSpeed.PerMinute, 30, 1));
    this.AllowedSpeeds.push(new SpeedDefinition(QueueSpeed.PerMinute, 20, 1));
    this.AllowedSpeeds.push(new SpeedDefinition(QueueSpeed.PerMinute, 15, 1));
    this.AllowedSpeeds.push(new SpeedDefinition(QueueSpeed.PerMinute, 10, 1));
    this.AllowedSpeeds.push(new SpeedDefinition(QueueSpeed.PerMinute, 5, 1));
    this.AllowedSpeeds.push(new SpeedDefinition(QueueSpeed.PerHour, 60, 1));
    this.AllowedSpeeds.push(new SpeedDefinition(QueueSpeed.PerHour, 30, 1));
    this.AllowedSpeeds.push(new SpeedDefinition(QueueSpeed.PerHour, 20, 1));
    this.AllowedSpeeds.push(new SpeedDefinition(QueueSpeed.PerHour, 12, 1));
    this.AllowedSpeeds.push(new SpeedDefinition(QueueSpeed.PerHour, 10, 1));
    this.AllowedSpeeds.push(new SpeedDefinition(QueueSpeed.PerHour, 5, 1));
    this.DefaultSpeed = this.AllowedSpeeds[0];
  }
}

export enum QueueSpeed {
  Fastest = 0,
  PerSecond = 1,
  PerMinute = 2,
  PerHour = 3
}

export class MessageQueueUser {
  QueueUserId: number;
  QueueId: number;
  UserId: number;
  RoleId: number;

  constructor(userId: number) {
    this.UserId = userId;
  }
}



export class SpeedDefinition {
  Speed: number = 0;
  Messages: number = -1;
  PerSpeed: number = -1;

  constructor(speed?: number, messages?: number, perSpeed?: number) {
    if (speed) {
      this.Speed = speed;
    }

    if (messages) {
      this.Messages = messages;
    }

    if (perSpeed) {
      this.PerSpeed = perSpeed;
    }
  }

  get description(): string {
    let perString = "Second";
    switch (this.Speed) {
      case 0:
        return "Fastest";
      case 1:
        perString = "Second";
        break;
      case 2:
        perString = "Minute";
        break;
      case 3:
        perString = "hour";
        break;
    }

    return this.Messages + (this.Messages > 1 ? " Messages " : " Message ") + "Every " + this.PerSpeed + " " + perString + (this.PerSpeed > 1 ? "s" : "");
  }
}

export class NetworkModel {
  NetworkId: number = 0;
  CountryCode: number = 0;
  Name: string = "";
  Description: string = "";
  Amended: Date = new Date();
  Created: Date = new Date();
  IsDeleted: boolean = false;
  Numbers: NumberModel[] = [];
  SelectedNumbers: NumberModel[] = [];
}

export class NumberModel {
  NumberId: number = 0;
  Amended: Date = new Date();
  Created: Date = new Date();
  Description: string = "";
  NetworkId: number = 0;
  IsDeleted: boolean = false;
  Number: number = 0;
  Shareable: boolean = false;;
  TwoWay: boolean = false;
  Concatenation: boolean = false;
  GsmStandard: boolean = false;
  GsmExtended: boolean = false;
  Unicode: boolean = false;
  Brandable: boolean = false;
  WhitelistingRequired: boolean = true;
  WhitelistingBranding: boolean = true;
  WhitelistingInvite: boolean = true;
  WhitelistingReminder: boolean = true;
  WhitelistingSurveyLink: boolean = true;
  WhitelistingOptOut: boolean = true;
  WhitelistingTinyUrl: boolean = true;
  RegistrationRequired: boolean = true;
  Network: NetworkModel = new NetworkModel();
}

export class RequestModel {
  RequestId: number = 0;
  UserId: number = 0;
  Amended: Date = new Date();
  Created: Date = new Date();
  Name: string = "";
  NumberId: number = 0;
  NetworkId: number = 0;
  InviteMessage: string = "";
  ReminderMessage: string = "";
  SurveyLinkFormat: string = "";
  OptOutLink: string = "";
  TinyUrlExample: string = "";
  Branding: string = "";
  StatusId: number = 0;
  Notes: string = "";
  IsDeleted: boolean = false;
  Number: NumberModel = new NumberModel();
  TwoWay: boolean = false;

  get StatusDescription(): string {
    switch (this.StatusId) {
      case 0:
        return "Requested";
      case 1:
        return "Started";
      case 2:
        return "Completed";
      case 3:
        return "Problem";
    }

    return "Unknown";
  }

  getNetwork() {
    return this._network;
  }
  setNetwork(value: NetworkModel) {
    this._network = value;
  }
  private _network = new NetworkModel();
}


@Injectable()
export class NetworkService {
  constructor(
    private http: HttpClient) {
  }


  public DeleteMessageQueueDefinitionForUser(queueId: number) {
    return this.http.delete(baseUrl + 'Network/DeleteMessageQueueDefinitionForUser?queueid=' + queueId, options);
  }

  public getMessageQueueDefinitions(): Observable<any> {
    return this.http.get(baseUrl + 'Network/GetMessageQueueDefinitions', options);
  }

  public saveCountriesFromFile(filename: string): Observable<any> {
    return this.http.get(baseUrl + "Network/SaveCountriesFromFile?&filename=" + filename, options);
  }

  public exportCountriesToFile(): Observable<any> {
    return this.http.get(baseUrl + 'Network/exportCountriesToFile', {
      responseType: "blob",
      headers: new HttpHeaders().append("Content-Type", "application/json")
    });
  }

  public getMessageQueueDefinition(name: string): Observable<any> {
    return this.http.get(baseUrl + 'Network/GetMessageQueueDefinition?name=' + name, options);
  }

  public getMessageQueueDefinitionForCurrentUser(): Observable<any> {
    return this.http.get(baseUrl + 'Network/GetMessageQueueDefinitionForCurrentUser', options);
  }

  public getMessageQueueDefinitionForUser(userId: number): Observable<any> {
    return this.http.get(baseUrl + 'Network/GetMessageQueueDefinitionForUser?userId=' + userId, options);
  }

  public saveMessageQueueDefinition(definition: MessageQueue): Observable<any> {
    return this.http.post(baseUrl + 'Network/SaveMessageQueueDefinition', definition, options);
  }

  public getSummary(): Observable<any> {
    return this.http.get(baseUrl + "Network/GetZoneSummary", options);
  }

  public getNetworks(): Observable<any> {
    return this.http.get(baseUrl + "Network/GetNetworks", options);
  }

  public getNetwork(id: number): Observable<any> {
    return this.http.get(baseUrl + 'Network/GetNetwork?id=' + id, options);
  }

  public getAllNetworks(): Observable<any> {
    return this.http.get(baseUrl + "Network/GetAllNetworks", options);
  }

  public getRequests(): Observable<any> {
    return this.http.get(baseUrl + "network/GetRequests", options);
  }

  public getRequestForUser(userId: number, id: number): Observable<any> {
    return this.http.get(baseUrl + "network/GetRequestForUser?userId=" + userId + "&id=" + id, options);
  }

  public getRequest(id: number): Observable<any> {
    return this.http.get(baseUrl + "network/GetRequest?id=" + id, options);
  }

  public check(message: string): Observable<any> {
    return this.http.patch(baseUrl + 'Message/Check', JSON.stringify(message), options);
  }

  public saveRequest(model: RequestModel): Observable<any> {
    return this.http.post(baseUrl + "Network/SaveRequest", JSON.stringify(model), options);
  }

  public saveNetwork(model: NetworkModel): Observable<any> {
    return this.http.post(baseUrl + "Network/SaveNetwork", model, options);
  }

  public deleteNetwork(model: NetworkModel): Observable<any> {
    return this.http.patch(baseUrl + "Network/DeleteNetwork", model, options);
  }
}
