import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { plainToClass } from 'class-transformer';
import { catchError, map, Observable, startWith, Subscription, switchMap, timer, of as observableOf } from 'rxjs';
import { InterviewPreviewComponent } from '../interview-preview/interview-preview.component';
import { InterviewModel, InterviewService, QuestionService } from '../question.service';
import { DialogService } from '../shared/dialog.service';
import { SurveyService, SurveySummaryModel } from '../survey.Service';
import { SnackbarDefaultComponent } from '../snackbar/snackbar-default.component';
import { MatPaginator } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { RunByQrcodeComponent } from '../interview-preview/run-by-qrcode/run-by-qrcode.component';
import { InterviewTestComponent } from '../interview-test/interview-test.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { DeleteSurveyComponent } from './delete-survey/delete-survey.component';

@Component({
  selector: 'app-survey-dashboard',
  templateUrl: './survey-dashboard.component.html',
  styleUrls: ['./survey-dashboard.component.css']
})
export class SurveyDashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  searchKey: string = "";
  requestsStacked: number = 0;
  surveyPageSize = 10;
  sb: MatSnackBarRef<any> | undefined;
  currentProgress: number = 0;
  status: number = 1;
  allowUpdates: boolean;
  language: string = "";
  page: number = 0;
  totals: number[] = [];
  surveys: SurveySummaryModel[] = [];
  isRateLimitReached = false;
  private inTimer = false;
  private isLoadingResults = false;
  waiting?: Observable<any>;
  waitTime: Observable<any> = timer(0, 30000);
  subscription?: Subscription;
  survey: SurveySummaryModel = new SurveySummaryModel();
  contextSelected: boolean = false;

  @ViewChild('table', { static: true }) table: any | undefined;
  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;

  constructor(public translate: TranslateService,
    private router: Router,
    private snackbar: MatSnackBar,
    public dialog: MatDialog,
    private dialogService: DialogService,
    private interviewService: InterviewService,
    private surveyService: SurveyService) {
    this.allowUpdates = true;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.paginator?.page
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          this.sb = this.snackbar.openFromComponent(SnackbarDefaultComponent);
          this.page = (this.paginator?.pageIndex ?? 0) + 1;
          this.surveyService.getSurveyTotals().subscribe(
            result => {
              this.totals = result;
              this.checkTabs();
            });
          return this.surveyService.getPagedSurveySummaries(this.status, this.searchKey, this.page, this.surveyPageSize)
              .pipe(catchError(() => observableOf(null)));
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          if (data != null && data.length < this.surveyPageSize) {
            this.setTotal((this.paginator?.pageIndex ?? 0) * this.surveyPageSize + data.length);
          }

          this.isRateLimitReached = data === null;
          this.sb?.dismiss();

          if (data === null) {
            return [];
          }

          // Only refresh the result length if there is new data. In case of rate
          // limit errors, we do not want to reset the paginator to zero, as that
          // would prevent users from re-triggering requests.
          return data;
        }),
      )
      .subscribe(data => (this.surveys = data));

    this.subscription = this.waitTime.subscribe(() => {
      if (this.inTimer) {
        return;
      }

      this.inTimer = true;
      this.surveyService.getSurveyTotals().subscribe(
        result => {
          for (let i = 0; i < this.totals.length; i++) {
            if (result[i] != this.totals[i]) {
              this.totals = result;
              this.checkTabs();
              this.getSurveys();
            }
          }
        });
      this.inTimer = false;
    });

  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription?.unsubscribe();
  }

  getColumnsToDisplay(): string[] {
    if (this.status == 1) {
      return ['name', 'created', 'modified', 'channel', 'questions', 'action'];
    }

    if (this.status == 3) {
      return ['name', 'created', 'modified', 'launch', 'enddate', 'channel', 'questions', 'completed', 'progress', 'action'];
    }

    return ['name', 'created', 'modified', 'golive', 'enddate', 'channel', 'questions', 'completed', 'progress', 'action'];
  }

  onSearchClear(event: Event) {
    this.searchKey = "";
    this.getSurveys();
  }

  applyFilter(event: any) {
    if (event.key !== "Enter") {
      return;
    }

    this.getSurveys();
  }

  Description(survey: SurveySummaryModel) {
    if (survey.Name === survey.Description) {
      return '';
    } else {
      return survey.Description;
    }
  }

  percentageCompleted(survey: SurveySummaryModel): number {
    let percent = survey.CompletionCount
    if (percent > 0) {
      if (survey.PanellistCount > 0) {
        return percent / survey.PanellistCount * 100;
      }
    }

    return 0;
  }

  getTotal(): number {
    switch (this.status) {
      case 1:
        return this.totals[0];
      case 3:
        return this.totals[1];
      case 2:
        return this.totals[2];
      case 4:
        return this.totals[3];
      default:
        return 0;
    }
  }

  setTotal(value: number) {
    switch (this.status) {
      case 1:
        this.totals[0] = value;
        break;
      case 3:
        this.totals[1] = value;
        break;
      case 2:
        this.totals[2] = value;
        break;
      case 4:
        this.totals[3] = value;
        break;
      default:
        break;
    }

  }

  checkStatus(s: number[], survey: SurveySummaryModel): boolean {
    for (let i = 0; i < s.length; i++) {
      if (s[i] === survey.Status) {
        return true;
      }
    }

    return false;
  }

  edit(id: SurveySummaryModel) {
    if (this.contextSelected) {
      this.contextSelected = false;
      return;
    }

    if (this.status === 1) {
      this.editSurvey(id, false);
    }
    else {
      this.viewDelivery(id);
    }
  }

  contextMenuPosition = { x: '0px', y: '0px' };
  onContextMenu(event: MouseEvent, survey: SurveySummaryModel) {
    event.preventDefault();
    this.contextSelected = true;
    this.survey = survey;
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { 'survey': survey };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  channel(id: SurveySummaryModel): string {
    switch (id.Channel) {
      case 1:
        return "bi bi-chat-left-dots";
      case 3:
        return "bi bi-chat"
      case 4:
        return "bi bi-chat-left";
      case 5:
        return "bi bi-whatsapp";
      default:
        return "bi bi-globe";
    }
  }

  channelTooltip(id: SurveySummaryModel): string {
    switch (id.Channel) {
      case 1:
        return "SMS Survey";
      case 3:
        return "WhatsApp Invitation"
      case 4:
        return "SMS Invitation";
      case 5:
        return "WhatsApp 2-Way Survey";
      default:
        return "Web Survey"
    }
  }

  editSurvey(id: SurveySummaryModel, newWindow: boolean) {
    this.subEditSurvey(id, newWindow);
  }

  subEditSurvey(id: SurveySummaryModel, newWindow: boolean) {
    if (this.status === 1) {
      if (newWindow) {
        this.router.navigate([]).then(result => {
          window.open(`/surveyquestioneditor/${id.SurveyID}/survey`, '_blank');
        });
        return;
      }

      this.router.navigate(["/surveyquestioneditor", id.SurveyID, "survey"]);
    }
    else {
      this.allowUpdates = false;
      if (newWindow) {
        this.router.navigate([]).then(result => {
          window.open(`/delivery-summary/${id.SurveyID}/summary`, '_blank');
        });
        return;
      }

      this.router.navigate(["/delivery-summary", id.SurveyID, "summary"]);
    }
  }

  deleteSurvey(id: SurveySummaryModel) {
    this.surveyService.deleteSurvey(id.SurveyID).subscribe(
      result => {
        this.surveyService.getSurveyTotals().subscribe(
          result => {
            this.totals = result;
            this.checkTabs();
            this.getSurveys();
          });
      });
  }

  exportScript(id: SurveySummaryModel) {
    this.interviewService.getScriptFile(id.SurveyID)
      .subscribe(response => {
        this.downLoadFile(response, "text/html", ".txt")
      }
      );
  }

  downLoadFile(data: any, type: string, ext: string) {
    let blob = new Blob([data], { type: type });
    let url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    const fileName = "Script";
    a.href = url;
    a.download = fileName + ext;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  copySurvey(id: SurveySummaryModel) {
    this.allowUpdates = false;
    this.surveyService.copySurvey(id.SurveyID).subscribe(
      result => {
        this.router.navigate(["/surveyquestioneditor", result.SurveyID, "survey"]);
      });
    this.getTotals();
    this.getSurveys();
  }

  abandonSurvey(id: SurveySummaryModel) {
    this.allowUpdates = false;
    this.surveyService.abandonSurvey(id.SurveyID).subscribe(
      result => {
        this.surveyService.getSurveyTotals().subscribe(
          result => {
            this.totals = result;
            this.checkTabs();
            this.getSurveys();
          });
      });
  }

  relaunchSurvey(id: SurveySummaryModel) {
    this.allowUpdates = false;
    this.surveyService.relaunchSurvey(id.SurveyID).subscribe(
      result => {
        this.router.navigate(["/surveyquestioneditor", id.SurveyID, "survey"]);
      },
      error => {
        this.getSurveys();
        this.getTotals();
      });
  }

  viewResults(id: SurveySummaryModel) {
    this.allowUpdates = false;
    this.router.navigate(["/reports", id.SurveyID, "report"]);
  }

  viewDelivery(id: SurveySummaryModel) {
    this.allowUpdates = false;
    this.router.navigate(["/delivery-summary", id.SurveyID, "summary"]);
  }

  surveyProperties(id: SurveySummaryModel) {
    this.allowUpdates = false;
    this.router.navigate(["/survey-properties", id.SurveyID, "summary"]);
  }

  previewInterview(id: SurveySummaryModel) {
    const model = plainToClass(InterviewModel, JSON.parse(id.Script)) as InterviewModel;
    model.checkModel();
    const dialogRef = this.dialog.open(InterviewPreviewComponent, {
      data: { model },
      width: '60%'
    });
  }

  testOnOtherDevices(id: SurveySummaryModel): void {
    let SurveyID = id.SurveyID;
    const dialogRef = this.dialog.open(RunByQrcodeComponent, {
      data: { SurveyID },
      width: '50%'
    });
  }

  testInterview(id: SurveySummaryModel): void {
      this.surveyService.getSurveyDetail(id.SurveyID).subscribe(result => {
        let survey = result;
        const dialogRef = this.dialog.open(InterviewTestComponent, {
          data: { survey },
          width: '80%'
        });
      });
  }

  transfer(id: SurveySummaryModel): void {
    this.router.navigate(["/transfer", id.SurveyID, "survey"]);
  }

  getSurveysWithStatus(s: string) {
    this.status = Number(s);
    this.surveys = [];
    this.getSurveys();
  }

  getTotals() {
    this.surveyService.getSurveyTotals().subscribe(
      result => {
        this.totals = result;
        this.checkTabs();
      });
  }

  getSurveys() {
    this.page = (this.paginator?.pageIndex ?? 0) + 1;
    if (this.page > 1 && (this.paginator.pageIndex * this.paginator?.pageSize) >= this.totals[this.status - 1]) {
      this.page--;
    }

    if (this.paginator != undefined) {
      this.paginator.pageIndex = this.page - 1;
    }

    this.sb = this.snackbar.openFromComponent(SnackbarDefaultComponent);
    this.surveyService.getPagedSurveySummaries(this.status, this.searchKey, this.page, this.surveyPageSize).subscribe(
      result => {
        if (result.length == 0) {
          this.paginator?.pageIndex == 0 ? 0 : this.page;
        }
        else {
          this.surveys = result;
        }

        this.sb?.dismiss();
      });
  }

  private checkTabs() {
    let selected = true;
    switch (this.status) {
      case 1:
        if (this.totals[0] == 0) {
          selected = false;
        }
        break;
      case 3:
        if (this.totals[1] == 0) {
          selected = false;
        }
        break;
      case 2:
        if (this.totals[2] == 0) {
          selected = false;
        }
        break;
      case 4:
        if (this.totals[3] == 0) {
          selected = false;
        }

        break;
      default:
        selected = false;
    }

    if (!selected) {
      for (let i = 0; i < 4; i++) {
        if (this.totals[i] > 0) {
          this.status = i;
          break;
        }
      }
    }

  }
}


