import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PanelService } from '../../panel.Service';
import { CustomFormat, DataTask, Field, Line, ProcessService, ProcessServiceModel } from '../../process.Service';
import { QuestionModel, RuleList } from '../../question.service';
import { DataAnalysisComponent } from '../data-analysis.component';
import { Schedule } from '../../scheduler/scheduler.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { baseUrl } from '../../../environments/environment';
import { FtpSetupComponent } from '../ftp-setup/ftp-setup.component';
import { FileUploadComponent } from '../../file-upload/file-upload.component';
import { v4 as uuidv4 } from 'uuid';
import { delay } from 'rxjs';

@Component({
  selector: 'app-export-automation',
  templateUrl: './export-automation.component.html',
  styleUrls: ['./export-automation.component.css']
})
export class ExportAutomationComponent implements OnInit {

  process: ProcessServiceModel = new ProcessServiceModel();
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  forthFormGroup: FormGroup;
  customFormat: CustomFormat = new CustomFormat();
  questions: QuestionModel[] = [];
  templateProfile = [];
  analysis: DataAnalysisComponent;
  rules: RuleList[];
  schedule: Schedule = new Schedule("", new Date(), new Date(), "");
  automate: boolean;
  title: string;
  export: boolean = false;
  tempProcess: ProcessServiceModel = new ProcessServiceModel();
  isExport: boolean = true;
  startDate = new Date();
  endDate = new Date();
  isSaving: boolean = false;
  maxDate: Date;

  @ViewChild('ftpSetup') public ftpSetup: FtpSetupComponent | undefined;
  @ViewChild('fileUpload') fileUpload: FileUploadComponent | undefined;
  @ViewChild('picker') public picker: any | undefined;

  constructor(
    private panelService: PanelService,
    private processService: ProcessService,
    private snackbar: MatSnackBar,
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) data: any) {
    this.isExport = data.isExport;
    this.automate = data.automate;
    this.analysis = data.analysis;
    this.questions = this.analysis.interview.dataQuestions();
    this.rules = data.rules;
    if (data.process == undefined) {
      this.createProcess();
    }
    else {
      this.process = data.process;
      this.process.ReferenceId = this.analysis.survey.SurveyID;
      this.schedule.start = this.process.Start;
      this.schedule.end = this.process.Finish;
      this.schedule.cron = this.process.Cron;
      // this.schedule.offset = this.process.Adjustment;
      this.schedule.timezone = this.process.Timezone;
      if (this.process.Task.CustomFormat == undefined) {
        this.process.Task.CustomFormat = new CustomFormat();
      }

      this.customFormat = this.process.Task.CustomFormat;
      this.customFormat.Header = this.process.Task.Headers == undefined ? false : this.process.Task.Headers;
      if (this.process.Task.Entity == null) {
        this.process.Task.Entity = this.isExport ? this.hasInvites ? "Invites" : "Completed" : "SurveyPanel";
      }
    }

    this.panelService.getTemplateProfile(this.analysis.survey.PanelID).subscribe(
      result => { this.templateProfile = result });
    if (this.automate) {
      this.title = "Automate Data " + (this.isExport ? "Export" : "Import");
    }
    else {
      this.title = "Data " + (this.isExport ? "Export" : "Import");
    }
  }

  get rule(): RuleList {
    if (this.rules == null) {
      this.rules = [];
      this.rules.push(new RuleList());
    }

    return this.rules[0];
  }

  ngOnInit(): void {
    let standard = "Standard";
    if (this.customFormat.Lines[0].Fields.length > 0 && this.customFormat.Lines[0].Fields[0].Name != "") {
      standard = "Custom";
    }
    this.firstFormGroup = new FormGroup({
      Name: new FormControl(this.process.Name),
      TransferFormat: new FormControl(this.process.Task.FileTransfer.Type),
      Host: new FormControl(""),
      Port: new FormControl("25"),
      Username: new FormControl(""),
      Password: new FormControl(""),
    });
    this.firstFormGroup.valueChanges.subscribe(result => {
      this.process.Name = this.firstFormGroup.controls.Name.value;
      this.process.Task.FileTransfer.Type = this.firstFormGroup.controls.TransferFormat.value;
    });
    this.secondFormGroup = new FormGroup({
      DataType: new FormControl(this.process.Task.Entity),
      Format: new FormControl(this.process.Task.Format),
      Standard: new FormControl(standard),
      Options: new FormControl(this.process.Task.UseNumericValues ? "1" : "2"),
      AllData: new FormControl(this.process.Task.AllData ? "all" : this.process.Task.From ? "from" : "between"),
      Compress: new FormControl(this.process.Task.Compress),
      LineBreaks: new FormControl(this.process.Task.RemoveLineBreaks),
      Split: new FormControl(this.process.Task.SplitMultiValueFields),
      System: new FormControl(this.process.Task.SystemData),
      Timings: new FormControl(this.process.Task.Timings),
      Header: new FormControl(this.process.Task.Headers),
      Start: new FormControl(this.process.Task.Start),
      Finish: new FormControl(this.process.Task.Finish),
      Dedupe: new FormControl(false)
    });
    this.thirdFormGroup = new FormGroup({});
  }

  createProcess() {
    this.process = new ProcessServiceModel();

    // Basic details
    this.process.Cron = this.schedule.cron;
    this.process.Start = this.schedule.start;
    this.process.Finish = this.schedule.end;
    this.process.IsDeleted = false;
    this.process.Name = this.analysis.survey.Name;

    this.process.ProcessTypeId = this.isExport ? 2 : 0;

    this.process.Task.Entity = this.isExport ? this.hasCompleted() ? "Completed" : "Invites" : "SurveyPanel";

    // file Transfer Information
    this.process.Task.Type = this.isExport ? "EXPORT" : "IMPORT";
    this.process.Task.FileTransfer.Type = this.automate ? "SFTP" : "SPECIFIC";
    this.process.Task.FileTransfer.Passive = false;
    this.process.Task.FileTransfer.Ssl = false;
    this.process.Task.FileTransfer.Port = 22;

    this.process.ReferenceId = this.process.Task.ReferenceID = this.analysis.survey.SurveyID;
    this.process.Task.Rules = this.rules == null ? null : this.rules.length > 0 ? this.rules[0] : null;
    this.process.Task.SplitMultiValueFields = true;
    this.process.Task.RemoveLineBreaks = true;
    this.process.Task.Timings = false;
    this.process.Task.SystemData = false;
    this.process.Task.Format = "CSV";
    this.process.Task.UseNumericValues = false;
    this.process.Task.AllData = true;
    this.process.Task.Start = new Date();
    this.process.Task.Finish = new Date();

    // Empty custom Fields
    this.customFormat.Lines.push(new Line());
  }

  transfer(transferFormat: string): boolean {
    return this.firstFormGroup != undefined && this.firstFormGroup.controls.TransferFormat.value == transferFormat;
  }

  dataSource(dataType: string): boolean {
    return this.secondFormGroup != undefined && this.secondFormGroup.controls.DataType.value == dataType;
  }

  format(name: string) {
    return this.secondFormGroup != undefined && this.secondFormGroup.controls.Format.value == name;
  }

  custom(): boolean {
    return this.secondFormGroup != undefined && this.secondFormGroup.controls.Standard.value == "Custom";
  }

  hasAnomalies(): boolean {
    return this.analysis.survey.Channel == 1;
  }

  hasStops(): boolean {
    switch (this.analysis.survey.Channel) {
      case 1:
      case 4:
        return true;
      default:
        return false;
    }
  }

  hasCompleted(): boolean {
  if (!this.isExport) {
    return false;
    }

  switch (this.analysis.survey.Channel) {
    case 0:
    case 1:
    case 2:
    case 5:
      return true;
    default:
      return false;
    }
  }

  isOneWay(): boolean {
    switch (this.analysis.survey.Channel) {
      case 3:
      case 4:
        return true;
      default:
        return false;
    }
  }

  isDedupe(): boolean {
    return this.secondFormGroup != undefined && this.secondFormGroup.controls.Dedupe.value;
  }


  hasInvites(): boolean {
    if (!this.isExport) {
      return false;
    }

    switch (this.analysis.survey.Channel) {
      case 0:
      case 1:
      case 3:
      case 4:
      case 5:
        return true;
      default:
        return false;
    }
  }

  updateTransferFormat(event: any) {
    switch (event.value) {
      case "SFTP":
        this.process.Task.FileTransfer.Port = 22;
        if (this.ftpSetup != undefined) {
          this.ftpSetup.refresh();
        }

        break;
      case "FTPS":
        this.process.Task.FileTransfer.Port = 21;
        if (this.ftpSetup != undefined) {
          this.ftpSetup.refresh();
        }

        break;
    }
  }

  updateFormat(event: any) {
    this.process.Task.Format = event.value;
    if (this.process.Task.Format == "FIXED") {
      this.secondFormGroup.controls.Standard.setValue("Custom");
    }

    for (let i = 0; i < this.process.Task.CustomFormat?.Lines?.length; i++) {
      let line = this.process.Task.CustomFormat.Lines[i];
      for (let j = 0; line?.Fields?.length; j++) {
        switch (this.process.Task.Format) {
          case "CSV":
            line.Fields[j].EndsWith = ",";
            break;
          case "TSV":
            line.Fields[j].EndsWith = "\t";
            break;
          case "PIPE":
            line.Fields[j].EndsWith = "|";
            break;
          default:
            line.Fields[j].EndsWith = "";
            break;
        }
      }
    }
  }

  updateStandard(event: any) {
    if (event.value != "Custom") {
      this.process.Task.Format == "CSV";
      this.secondFormGroup.controls.Format.setValue("CSV");
    }
  }

  updateHeader(event: any) {
    this.customFormat.Header = event.checked;
  }

  updateDateRange(event: any) {
    this.process.Task.AllData = event.value == "all";
    this.process.Task.From = event.value == "from";
  }

  scheduleChanged(value: Schedule) {
    this.schedule = value;
  }

  updateProcess() {
    // Basic details
    this.process.Cron = this.schedule.cron;
    this.process.Start = this.schedule.start;
    this.process.Finish = this.schedule.end;
    this.process.Adjustment = this.schedule.offset;
    this.process.Timezone = this.schedule.timezone;
    this.process.IsDeleted = false;
    this.process.Name = this.firstFormGroup.controls.Name.value;

    this.process.StatusId = 5;
    this.process.ProcessTypeId = this.isExport ? 2 : 0;

    // file Transfer Information
    this.process.Task.Type = this.isExport ? "EXPORT" : "IMPORT";
    this.process.Task.FileTransfer.Type = this.firstFormGroup.controls.TransferFormat.value;
    this.process.Task.Entity = this.secondFormGroup.controls.DataType.value;
    this.process.ReferenceId = this.process.Task.ReferenceID = this.analysis.survey.SurveyID;
    this.process.Task.Rules = this.rules == null ? null : this.rules.length > 0 ? this.rules[0] : null;
    this.process.Task.SplitMultiValueFields = this.secondFormGroup.controls.Split.value;
    this.process.Task.UseNumericValues = this.secondFormGroup.controls.Options.value == "1";
    this.process.Task.RemoveLineBreaks = this.secondFormGroup.controls.LineBreaks.value;
    this.process.Task.Timings = this.secondFormGroup.controls.Timings.value;
    this.process.Task.SystemData = this.secondFormGroup.controls.System.value;
    this.process.Task.Format = this.secondFormGroup.controls.Format.value;
    this.process.Task.Compress = this.secondFormGroup.controls.Compress.value;
    this.process.Task.Headers = this.secondFormGroup.controls.Header.value;
    this.process.Task.CustomFormat = this.customFormat;
    this.process.Task.LastSuccess = true;
    this.process.Task.LastRun = new Date(2001,1,1);
    if (!this.process.Task.AllData) {
      this.process.Task.Start = this.startDate;
      this.process.Task.Finish = this.endDate;
    }
  }

  saveProcess() {
    if (this.isSaving) {
      return;
    }

    this.isSaving = true;
    this.updateProcess();
    this.processService.saveProcess(this.process).subscribe(
      result => {
        this.process = result;
        this.openSnackbar("Successfully Saved", "");
        this.isSaving = false;
      },
      error => {
        this.openSnackbar("There was a problem saving the " + (this.isExport ? "export" : "import") + " specification", "Cancel");
        this.isSaving = false;
      });
  }

  launchProcess() {
    while (this.isSaving) {
      delay(1000);
    }

    this.updateProcess();
    this.process.StatusId = 0;
    this.processService.saveProcess(this.process).subscribe(
      result => {
        this.process = result;
        this.openSnackbar("Successfully Launched", "");
        this.dialogRef.close({ new: false, process: this.process });
      },
      error => {
        this.openSnackbar("There was a problem launching the " + this.isExport ? "export" : "import", "Cancel");
      });
  }

  importData() {
    this.updateProcess();
    this.processService.saveProcess(this.process).subscribe(
      result => {
        this.process = result;
        let saveId = this.process.ProcessId;
        if (baseUrl.startsWith("https://localhost")) {
          this.process.ProcessId = 0;
          if (this.fileUpload) {
            this.process.Task.FileTransfer.File = this.fileUpload.name;
          }
          else {
            this.process.Task.FileTransfer.File = uuidv4() + ".tmp";
          }
          this.process.Task.FileTransfer.LocalPath = this.process.Task.FileTransfer.LocalFolder + "\\" + this.process.Task.FileTransfer.File;
          this.processService.saveProcess(this.process).subscribe(result => {
            this.process.ProcessId = saveId;
            this.tempProcess = result;
            this.export = true;
            this.processService.runProcess(this.tempProcess).subscribe(result => {
              this.tempProcess = result;
            });
          });
        }
        else {
          // Create a copy to use as a test
          if (this.fileUpload) {
            this.process.Task.FileTransfer.File = this.fileUpload.name;
          }
          else {
            this.process.Task.FileTransfer.File = uuidv4() + ".tmp";
          }
          this.process.Task.FileTransfer.LocalPath = this.process.Task.FileTransfer.LocalFolder + "\\" + this.process.Task.FileTransfer.File;
          this.process.ProcessId = 0;
          this.process.StatusId = 0;
          this.process.Cron = "";
          this.process.Finish = new Date();
          this.process.Finish.setDate(this.process.Finish.getDate() + 3);
          this.processService.importData(this.process).subscribe(result => {
            this.process.ProcessId = saveId;
            this.export = true;
            this.tempProcess = result;
          });
        }
      },
      error => {
        this.openSnackbar("There was a problem saving the " + (this.export ? "export" : "import") + " specification", "Cancel");
      });
  }

  exportData() {
    this.updateProcess();
    this.processService.saveProcess(this.process).subscribe(
      result => {
        this.process = result;
        let saveId = this.process.ProcessId;
        if (baseUrl.startsWith("https://localhost")) {
          this.process.ProcessId = 0;
          this.process.StatusId = 0;
          this.process.Cron = "";
          this.process.Finish = new Date();
          this.process.Task.LastRun = new Date("2020-01-01");
          this.process.Task.AllData = true;
          this.process.Finish.setDate(this.process.Finish.getDate() + 3);
          this.processService.saveProcess(this.process).subscribe(result => {
            this.process.ProcessId = saveId;
            this.tempProcess = result;
            this.export = true;
            this.processService.runProcess(this.tempProcess).subscribe(result => {
              this.tempProcess = result;
            });
          });
        }
        else {
          // Create a copy to use as a test
          this.process.ProcessId = 0;
          this.process.StatusId = 0;
          this.process.Cron = "";
          this.process.Finish = new Date();
          this.process.Task.LastRun = new Date("2020-01-01");
          this.process.Task.AllData = true;
          this.process.Finish.setDate(this.process.Finish.getDate() + 3);
          this.processService.exportData(this.process.Name, this.analysis.survey.SurveyID, this.process.Task).subscribe(result => {
            this.process.ProcessId = saveId;
            this.export = true;
            this.tempProcess = result;
          });
        }
      },
      error => {
        this.openSnackbar("There was a problem saving the " + (this.export ? "export" : "import") + " specification", "Cancel");
      });
  }

  onExportDataCompleted($event: any) {
    let process = $event.process as ProcessServiceModel;
    if (process.StatusId == 4) {
      return;
    }

    if (this.isExport && this.process.Task.FileTransfer.Type != "SPECIFIC") {
      return;
    }

    this.export = false;
    if (this.isExport) {
      this.processService.getFile(process).subscribe(
        response => {
          this.downLoadFile(response, process.Name, ProcessService.FindDownloadFromTask(process.Task));
        });
    }
    else {
      this.processService.getFile(process).subscribe(
        response => {
          this.openSnackbar("Successfully Imported", "");
        });
    }
  }

  downLoadFile(data: any, filename: string, types: string[]) {
    let blob = new Blob([data], { type: types[0] });
    let url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename + types[1];
    a.click();
    window.URL.revokeObjectURL(url);
  }

  onFileUploaded($event: any) {
    this.process.Task.FileTransfer.File = $event.name;
    if (this.fileUpload != undefined) {
      this.fileUpload.progress = 0;
      this.fileUpload.message = "Your data file uploaded to be imported";
    }
  }

  private openSnackbar(message: string, action: string) {
    if (action == "") {
      this.snackbar.open(message, action, { duration: 2000 });
    }
    else {
      this.snackbar.open(message, action, { duration: 7000 });
    }
  }
}
