import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { InterviewModel, QuestionModel, RuleGroup, RuleList } from '../../question.service';
import { QuestionEditorComponent } from '../../question-editor/question-editor.component';
import { MatDialog } from '@angular/material/dialog';
import { RoutingDialogComponent } from '../routing-dialog/routing-dialog.component';
import { EmbedDataComponent } from '../embed-data/embed-data.component';
import { RandomiseComponent } from '../randomise/randomise.component';

@Component({
  selector: 'app-survey-flow',
  templateUrl: './survey-flow.component.html',
  styleUrls: ['./survey-flow.component.css']
})
export class SurveyFlowComponent implements OnInit {

  @ViewChild('routing') public routing: ElementRef | undefined;

  dialogOpen = false;
  question: QuestionModel | null = null;
  isDelete = false;

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  onAdd(name: string, index: number, actionType: string) {
    if (this.dialogOpen) {
      return;
    }

    let before = name === "before";
    let question = new QuestionModel(this.editor?.interview);
    question.QuestionType = "advanced";
    question.Action = actionType;
    switch (actionType) {
      case "route":
        question.Rules = [];
        question.Rules.push(new RuleList());
        question.Rules[0].Question = question;
        question.Rules[0].Rules = [];
        break;
    }

    if (before) {
      this.editor?.interview.Questions.splice(index, 0, question);
    }
    else {
      if (index + 1 === this.editor?.interview.Questions.length) {
        this.editor?.interview.Questions.push(question);
      }
      else {
        this.editor?.interview.Questions.splice(index + 1, 0, question);
      }
      index++;
    }

    this.editor?.interview.reIndex(this.editor.interview.Questions[0].ParentIndex, this.editor.interview.Questions[0].DisplayParentIndex, this.editor.interview.Questions);
    this.editor?.refresh();
    if (!before) {
      this.onClick(question.DisplayIndex + 1, -1);
    }
  }

  onEdit(index: number) {
    if (this.dialogOpen) {
      return;
    }

    let question = this.editor?.interview.Questions[index];
    this.question = question == undefined ? null : question;
    let rules;
    let name;
    let dialogRef;
    this.dialogOpen = true;
    switch (question?.Action) {
      case "route":
        rules = question.Rules[0];
        name = "Branch ";
        dialogRef = this.dialog.open(RoutingDialogComponent,
          {
            hasBackdrop: false,
            data: { name: name, rules: rules },
            width: '95vw',
            maxWidth: '95vw'
          });
        break;
      case "embed":
        dialogRef = this.dialog.open(EmbedDataComponent,
          {
            hasBackdrop: false,
            data: { question: question, userFeatures: this.editor?.userFeatures },
            width: '95vw',
            maxWidth: '95vw'
          });
        break;
      case "randomise":
        dialogRef = this.dialog.open(RandomiseComponent,
          {
            hasBackdrop: false,
            data: { question: question },
            width: '95vw',
            maxWidth: '95vw'
          });
        break;
    }

    dialogRef?.afterClosed().subscribe(result => {
      this.question = null;
      this.dialogOpen = false;
    });
  }

  onDelete(index: number) {
    this.isDelete = true;

    this.editor?.interview.deleteQuestion({ index: index, parentIndex: -1 });
    this.editor?.refresh();
    this.isDelete = false;
  }

  refresh() {
  }

  isSelected(index: number) {
    return !this.isDelete && this.editor?.interview.Questions[index].Index == this.editor?.index;
  }

  displayGoto(question: QuestionModel): boolean {
    return question?.Action == "route";
  }


  onClick(index: number, parentIndex: number) {
    if (this.editor == null) {
      return;
    }

    const question = this.editor.interview.findQuestionByIndex(parentIndex, index);
    this.editor.questions?.selectQuestion(index, parentIndex, question.DisplayIndex, question.DisplayParentIndex);
    this.editor.index = index;
    this.editor.parentIndex = parentIndex;
    if (this.editor.sorter) {
      this.editor.sorter.scrollToSelection();
    }
  }

  selectQuestion(index: number, parentIndex: number) {
    this.scrollToQuestion(index, parentIndex);
  }

  scrollToQuestion(index: number, parentIndex: number) {
    let i = index;
    if (parentIndex > -1) {
      i = parentIndex;
    }

    setTimeout(() => {
      try {
        this.routing?.nativeElement.childNodes[i].scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "nearest"
        });
      }
      catch (err) {
        let a = err;
      }
    }, 0);
  }

  @Input()
  get editor(): QuestionEditorComponent | null {
    return this._editor;
  }
  set editor(value: QuestionEditorComponent | null) {
    this._editor = value;
  }
  private _editor: QuestionEditorComponent | null = null;
}
