import { Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { AuthenticationService } from "../authentication.Service";
import { version } from "../../environments/environment";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { MissionService } from '../MissionService';
import { MatDialog } from '@angular/material/dialog';
import { NameSurveyFromScratchComponent } from '../name-survey-from-scratch/name-survey-from-scratch.component';
import { SurveyService, SurveySummaryModel } from '../survey.Service';
import { Router } from '@angular/router';
import { PanelService } from '../panel.Service';
import { JoyrideService } from 'ngx-joyride';
import { ChatService } from '../chat.Service';
import { UserFeatures, UserService } from '../user.Service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css'],
  providers: [MissionService]
})
export class NavigationComponent implements OnInit {

  isExpanded = false;
  showSurveyEdit = true;
  theme = "default";
  currentVersion: string;
  showSurveyDashboard = false;
  showRespondents = false;
  currentYear = new Date().getFullYear();
  messages = 0;
  userFeatures: UserFeatures = new UserFeatures();

  public Surveys: SurveySummaryModel[] = [];

  @ViewChild('sidenav') public sidenav: any;
  @HostBinding('class') activeThemeCssClass: string
  title = 'app';
  isDarkTheme: boolean = false;

  constructor(private dialog: MatDialog,
    private overlayContainer: OverlayContainer,
    private auth: AuthenticationService,
    private breakpointObserver: BreakpointObserver,
    private surveyService: SurveyService,
    private router: Router,
    private missionService: MissionService,
    private panelService: PanelService,
    private readonly joyrideService: JoyrideService,
    private chatService: ChatService,
    private userService: UserService,
  ) {
    this.currentVersion = version;
    missionService.missionConfirmed$.subscribe(
      () => {
      });

    this.surveyService.getSurveyCount()
      .subscribe(response => {
        if (response > 0) {
          this.showSurveyDashboard = true;
        } else {
          this.showSurveyDashboard = false;
        }
      });

    this.panelService.panelCount().subscribe(response => { this.showRespondents = response > 0 });
    this.chatService.getUnreadMessageCount().subscribe(response => { this.messages = response });
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));

  isHandsetLandscape$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.HandsetLandscape)
    .pipe(map(result => result.matches));

  isHandsetPortrait$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.HandsetPortrait)
    .pipe(map(result => result.matches));

  ngOnInit(): void {
    this.overlayContainer.getContainerElement().classList.add(this.theme);
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }

  onNext() {
    this.sidenav.toggle();
  }

  onCloseMenu() {
    this.sidenav.toggle();
  }

  onThemeChange() {
    this.overlayContainer.getContainerElement().classList.add(this.theme);
  }

  changeTheme() {
    if (this.isDarkTheme) {
      this.isDarkTheme = false;
    } else {
      this.isDarkTheme = true;
    }
  }

  goToHome() {
    this.surveyService.getSurveyCount()
      .subscribe(response => {
        if (response > 0) {
          this.router.navigate(['../surveydashboard']);
        } else {
          this.router.navigate(['../startfromtemplate']);
        }
      });
  }

  gotoInbox() {
    this.router.navigate(['../inbox'])
  }

  isAdmin() {
    return this.auth.isAuthorized(['Admin']);
  }

  isSms() {
    return this.auth.isAuthorized(['sms']);
  }

  isAdvanced() {
    return this.auth.isAuthorized(['advanced']);
  }

  isDeveloper() {
    return this.auth.isAuthorized(['developer']);
  }

  isTester() {
    return this.auth.isAuthorized(['diy']);
  }

  toggleSideMenu() {
    this.sidenav.toggle();
  }
  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  openTerms() {
    const url = this.router.createUrlTree(['/termsofservice'])
    window.open(url.toString(), '_blank')
  }

  openPrivacy() {
    const url = this.router.createUrlTree(['/privacystatement'])
    window.open(url.toString(), '_blank')
  }

  newSurveyFromScratchDialog(): void {
    const dialogRef = this.dialog.open(NameSurveyFromScratchComponent,
      {

      });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(['/surveyquestioneditor', result.survey.SurveyID, "survey"]);
      }

      console.log('The dialog was closed');
    });
  }
}
