import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { version } from '../../environments/environment';
import { AuthenticationService, Organization } from '../authentication.Service';
import { ProcessService } from '../process.Service';
import { JoyrideService } from 'ngx-joyride';
import { SurveySummaryModel } from '../survey.Service';
import { Observable, Subscription, timer } from 'rxjs';
import { Location } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MediaService } from '../media.Service';

class OrgList {
  selected: boolean;
  icon: string;
  name: string;
  value: number;
  memberId: number;
  mediaId: number;
  mediaService: MediaService;

  constructor(selected: boolean, icon: string, name: string, value: number, memberId: number, mediaId: number,
    mediaService: MediaService) {
    this.selected = selected;
    this.icon = icon;
    this.name = name;
    this.value = value;
    this.memberId = memberId;
    this.mediaId = mediaId;
    this.mediaService = mediaService;
  }

  get media() {
    if (this._media != null) {
      return this._media == "" ? null : this._media;
    }

    if (this.mediaId == 0) {
      this._media = "";
      return null;
    }

    this.mediaService.getMediaById(this.mediaId).subscribe(
      result => {
        this._media = result;
      },
      error => {
        this._media = "";
      });
  }
  private _media = null;
}

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.css']
})
export class UserMenuComponent implements OnInit, OnDestroy {

  private inTimer = false;
  private config: {
    version: string
  };

  waitTime: Observable<any> = timer(0, 60000);
  subscription: Subscription;
  processCount = 0;
  currentVersion: string;
  organizations: Organization[] = [];
  orgList: OrgList[] = [];
  selectedOrg?: OrgList;
  lastLoggedIn: Date;
  surveyId: number = 0;
  isPageTour: boolean = false;
  ownOrganizations: boolean = false;

  id: SurveySummaryModel = new SurveySummaryModel();

  constructor(private http: HttpClient,
    private mediaService: MediaService,
    private snackBar: MatSnackBar,
    private authService: AuthenticationService,
    private processService: ProcessService,
    private router: Router,
    private readonly joyrideService: JoyrideService,
    private actRoute: ActivatedRoute,
    private location: Location) {
    this.currentVersion = version;
    this.lastLoggedIn = this.authService.lastLoggedIn();
    let m = new OrgList(true, 'account_circle', this.authService.getMemberName() ?? "", -1, -1, this.authService.getMediaId(), this.mediaService);
    this.selectedOrg = m;
    let userId = this.authService.getUserId();
    this.authService.getOrganizationSummariesForMember().subscribe(result => {
      this.orgList = [];
      this.organizations = result;
      this.orgList.push(m);
      this.selectedOrg = m;
      let i = this.authService.currentOrganization();
      for (let j = 0; j < this.organizations.length; j++) {
        let org = this.organizations[j];
        let o = new OrgList(false, org.OrganizationId == i ? 'supervised_user_circle' : 'supervisor_account', org.Name, org.OrganizationId, org.MemberId, org.MediaId, this.mediaService);
        if (org.RoleId == 5) {
          this.ownOrganizations = true;
        }

        if (org.OrganizationId === i) {
          o.selected = true;
          this.selectedOrg = o;
          this.orgList[0].selected = false;
          this.orgList[0].icon = 'person';
        }

        this.orgList.push(o);
      }

      this.orgList.push(new OrgList(false, 'add', 'Create an Organization', 0, -1, 0, this.mediaService));
      if (this.ownOrganizations) {
        this.orgList.push(new OrgList(false, 'business', 'Manage Organizations', -2, this.orgList[1].memberId, 0, mediaService));
      }
    });
  }

  get image(): string {
    return this.selectedOrg.media;
  }

  ngOnDestroy(): void {
    // this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.config = require('../../assets/files/config.json')

    const headers = new HttpHeaders()
      .set('Cache-Control', 'no-cache')
      .set('Pragma', 'no-cache');

    this.http.get<{ version: string }>('../../assets/files/config.json', { headers })
      .subscribe(config => {
        if (config.version !== this.config.version) {
          let temp = this.snackBar.open("New version of the platform available", "Reload", {
            horizontalPosition: "center",
            verticalPosition: "top"
            });
          temp.afterDismissed().subscribe(() => {
            this.http
              .get("", { headers, responseType: "text" })
              .subscribe(() => location.reload());
          });
        }
      });

    if ((this.router.url.includes("surveyquestioneditor")) || (this.router.url.includes("surveydashboard")) || (this.router.url.includes("startfromtemplate"))) {
      this.isPageTour = true;
    }
  }

  takeATour() {
    if ((this.router.url.includes("surveyquestioneditor"))) {
      this.surveyId = this.actRoute.snapshot.params.id;
      console.log(this.surveyId);
      this.joyrideService.startTour(
        {
          steps: [
            'designSurveyStep@surveyquestioneditor/' + this.surveyId + '/survey',
            'questionListStep@surveyquestioneditor/' + this.surveyId + '/survey',
            'surveyRoutingStep@surveyquestioneditor/' + this.surveyId + '/survey',
            'questionOrderStep@surveyquestioneditor/' + this.surveyId + '/survey',
            'newQuestionStep@surveyquestioneditor/' + this.surveyId + '/survey',
            'questionPropertiesStep@surveyquestioneditor/' + this.surveyId + '/survey',
            'questionLibraryStep@surveyquestioneditor/' + this.surveyId + '/survey',
            'testSurveyStep@surveyquestioneditor/' + this.surveyId + '/survey',
            'surveySettingsStep@surveyquestioneditor/' + this.surveyId + '/survey',
            'selectRespondentStep@surveyquestioneditor/' + this.surveyId + '/survey',
            'surveyLiveStep@surveyquestioneditor/' + this.surveyId + '/survey'
          ],
          showCounter: false,
        } // Your steps order
      );
    }
    if (this.router.url.includes("surveydashboard")) {
      this.joyrideService.startTour(
        {
          steps: [
            'resourcesStep@surveydashboard',
            'profileStep@surveydashboard',
            'menuStep@surveydashboard',
            'surveyDashboardStep@surveydashboard',
            'surveyTemplateStep@surveydashboard',
            'surveyScratchStep@surveydashboard',
            'respondentsStep@surveydashboard'
          ],
          showCounter: false,
        } // Your steps order
      );
    }

    if (this.router.url.includes("startfromtemplate")) {
      this.joyrideService.startTour(
        {
          steps: [
            'resourcesStep@startfromtemplate',
            'profileStep@startfromtemplate',
            'menuStep@startfromtemplate',
            'surveyDashboardStep@startfromtemplate',
            'surveyTemplateStep@startfromtemplate',
            'surveyScratchStep@startfromtemplate',
            'respondentsStep@startfromtemplate'
          ],
          showCounter: false,
        } // Your steps order
      );
    }
  }

  get username(): string {
    return this.authService.getMemberName() ?? "";
  }

  isImpersonating() {
    return this.authService.impersonate() ? "I" : "";
  }

  stopImpersonating() {
    this.authService.stopImpersonating();
  }

  makeSelection(i: number, $event: any) {
    this.inTimer = true;
    this.selectedOrg = this.orgList[i];
    let index = this.selectedOrg.value;
    switch (index) {
      case 0:
        this.router.navigate(["/neworganization"]);
        break;
      case -1:
        this.authService.setOrganization(this.location.path(), this.selectedOrg.value);
        this.setLocation();
        break;
      case -2:
        this.router.navigate(["/profile", this.orgList[i].memberId]);
        break;
      default:
        this.authService.setOrganization(this.location.path(), this.selectedOrg.value);
        this.setLocation();
        break;
    }
    this.inTimer = false;
  }

  isOrganization() {
    return this.authService.currentOrganization();
  }

  setLocation() {
  }

}
