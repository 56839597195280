<div *ngIf="canEdit">
  <h2>Respondents</h2>
  <div *ngIf="noPanels" class="panels-headingText-div">
    <p>
      Select Add Respondent Template, name it and then upload your list of respondents. Your respondents are then available for you to use in your surveys.
    </p>
    <p>
      <button mat-raised-button class="panels-newRespondentTemplate-btn" (click)="newPanelDialog()">Add Respondent Template</button>
    </p>
  </div>

  <div class="panels-addResp-search-div" *ngIf="!noPanels">
    <div>
      <button mat-raised-button class="panels-newRespondentTemplate-btn" (click)="newPanelDialog()">Add Respondent Template</button>
    </div>&nbsp;&nbsp;
    <div>
      <mat-form-field appearance="outline">
        <mat-label>Search</mat-label>
        <input type="text" matInput placeholder="Search" [(ngModel)]="searchKey" autocomplete="off" (keyup)="applyFilter($event)">
        <button class="search-close-btn" matSuffix mat-icon-button aria-label="Clear" *ngIf="searchKey" (click)="onSearchClear($event)">
          <mat-icon class="dialog-close-icon">close</mat-icon>
        </button>
      </mat-form-field>&nbsp;&nbsp;
    </div>
  </div>
</div>
<div *ngIf="!noPanels" class="outer-table-container mat-elevation-z8">

  <div class="txt-analysis-table-container custom-scroll-bar">
    <table mat-table #table [dataSource]="dataSource"
           multiTemplateDataRows
           matSort
           matSortActive="created"
           matSortDisableClear matSortDirection="desc">

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let panel"> {{panel.Name}} </td>
      </ng-container>

      <!-- Created Column -->
      <ng-container matColumnDef="created">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Created </th>
        <td mat-cell *matCellDef="let panel"> {{panel.CreatedDate | date:'short': '' : translate.currentLang}} </td>
      </ng-container>

      <!-- Modified Column -->
      <ng-container matColumnDef="modified">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Modified </th>
        <td mat-cell *matCellDef="let panel"> {{panel.LastUpdatedDate | date:'short': '' : translate.currentLang}} </td>
      </ng-container>

      <!-- Respondent count Column -->
      <ng-container matColumnDef="respondents">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Respondents </th>
        <td mat-cell *matCellDef="let panel"> {{panel.PanellistCount}} </td>
      </ng-container>

      <!-- Profile Element Column -->
      <ng-container matColumnDef="profileElement">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Profile Elements </th>
        <td mat-cell *matCellDef="let panel"> {{panel.ProfileElementCount}} </td>
      </ng-container>

      <!-- Profile Element Column -->
      <ng-container matColumnDef="profile">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Profile </th>
        <td mat-cell *matCellDef="let panel"> {{summary(panel)}} </td>
      </ng-container>


      <!-- Action column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td mat-cell *matCellDef="let panel">
          <div>
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="icon-button with a menu" (click)="$event.stopPropagation()">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="editPanel(panel)">
                <mat-icon>edit</mat-icon>
                <span>Edit</span>
              </button>
              <button mat-menu-item (click)="renamePanel(panel)">
                <mat-icon>settings</mat-icon>
                <span>Settings</span>
              </button>
              <button mat-menu-item (click)="deletePanel(panel)">
                <mat-icon>delete</mat-icon>
                <span>Delete</span>
              </button>
              <button mat-menu-item (click)="copyPanel(panel)">
                <mat-icon>file_copy</mat-icon>
                <span>Copy</span>
              </button>
            </mat-menu>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let panel" [attr.colspan]="columnsToDisplay.length" class="padding-0">
          <div class="panels-element-detail custom-scroll-bar"
               [@detailExpand]="panel == expandedElement ? 'expanded' : 'collapsed'">
              <app-panel-profile [summary]="panel.Summary"
                                 [showMobileDistribution]="true"></app-panel-profile>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
      <tr mat-row *matRowDef="let panel; columns: columnsToDisplay;"
          class="txt-analysis-element-row"
          [class.example-expanded-row]="expandedElement === panel"
          (click)="edit(panel)">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="panels-detail-row"></tr>
    </table>
  </div>
  <mat-paginator [pageSize]="panelPageSize" [length]="resultsLength"></mat-paginator>
</div>
