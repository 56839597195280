import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomFormat, DataTask, Field, Line } from '../../process.Service';
import { QuestionModel } from '../../question.service';

@Component({
  selector: 'app-custom-format',
  templateUrl: './custom-format.component.html',
  styleUrls: ['./custom-format.component.css']
})
export class CustomFormatComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

  @Input()
  get format(): CustomFormat {
    return this._format;
  }
  set format(value: CustomFormat) {
    this._format = value;
  }
  private _format: CustomFormat;

  @Output() formatChange = new EventEmitter<CustomFormat>();

  fieldList(): Field[] {
    if (this.fields != undefined) {
      return this.fields;
    }

    if (this._format == undefined) {
      return [];
    }

    if (this._format.Lines.length == 0) {
      this._format.Lines.push(new Line());
    }

    this.fields = [];
    for (let i = 0; i < this._format.Lines[0].Fields.length; i++) {
      this.fields.push(this._format.Lines[0].Fields[i]);
    }

    if (this.fields.length == 0) {
      this.fields.push(this.newField());
    }

    return this.fields;
  }

  @Input()
  get fields(): Field[] {
    return this._fields;
  }
  set fields(value: Field[]) {
    this._fields = value;
  }
  private _fields: Field[];

  @Input()
  get task(): DataTask {
    return this._task;
  }
  set task(value: DataTask) {
    this._task = value;
  }
  private _task: DataTask = new DataTask();

  @Input()
  get questions(): QuestionModel[] {
    return this._questions;
  }
  set questions(value: QuestionModel[]) {
    this._questions = value;
  }
  private _questions: QuestionModel[] = [];

  @Input()
  get templateProfile(): any {
    return this._templateProfile;
  }
  set templateProfile(value: any) {
    this._templateProfile = value;
  }
  private _templateProfile: any;

  onDelete(index: number) {
    this.fields.splice(index, 1);
    this.generalChange();
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.fields, event.previousIndex, event.currentIndex);
    this.generalChange();
  }

  onAdd(index: number, value: Field) {
    this.fields[index] = value;
    if (this.fields.length - 1 == index) {
      this.fields.push(this.newField());
      this.generalChange();
    }
  }

  onChange(index: number, value: Field) {
    if (this.fields.length == index) {
      return;
    }

    this.fields[index] = value;
    this.generalChange();
  }

  generalChange() {
    this.format.Lines[0].Fields = this._fields;
    this.formatChange.emit(this._format);
  }

  newField(): Field {
    let f = new Field();
    f.Alias = "";
    f.Position = -1
    switch (this.task.Format) {
      case "CSV":
        f.EndsWith = ",";
        break;
      case "TSV":
        f.EndsWith = "\t";
        break;
      case "PIPE":
        f.EndsWith = "|";
        break;
      default:
        f.EndsWith = "";
        f.Position = 0;
        f.Length = 0;
        break;
    }
    return f;
  }
}
