<div [class]="theme">
  <mat-toolbar class="mat-toolbar-container mat-elevation-z2">
    <a [routerLink]="'/'"><img src="assets/img/logo.svg" class="toolbar-logo" /></a>
    <span class="toolbar-spacer"></span>
    <div class="toolbar-header-right-container" [class]="(isHandset$ | async) ? 'noDisplay' : ''">
      <a href="mailto:hello@diysurveys.com" class="toolbar-hellodiysurveyscom">hello&#64;diysurveys.com</a>&nbsp;
      <button mat-raised-button [routerLink]="'/signup'" target="_blank" color="accent">SIGN UP</button>
    </div>
  </mat-toolbar>
</div>
