import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from "../authentication.Service";
import { Observable } from 'rxjs';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.css']
})
export class VerifyComponent implements OnInit {
  displayError: number = 0;
  wait?: Observable<any>;
  errorMsg: string = "";
  constructor(private route: ActivatedRoute, private authService: AuthenticationService) {
  }

  ngOnInit(): void {
    let token = this.route.snapshot.queryParamMap.get("token");
    if (token == null) {
      this.displayError = 1;
      this.errorMsg = "Unable to register at this time please check the email address";
    }
    else {
      this.authService.testRegisterToken(token).subscribe(result => {
        this.displayError = 0;
      },
        error => {
          if (error.status === 400) {
            // cannot register
            this.displayError = 1;
            this.errorMsg = "Unable to register at this time please check the email address";
          }
          else if (error.status === 409) {
            // Duplicate details
            this.displayError = 1;
            this.errorMsg = "You will need to register with us first before you can use this link";
          } else {
            this.displayError = 99;
            console.error(error.message);
            this.errorMsg = "Unable to register at this time please check the email address";
          }
        });
    }
  }

}
