<mat-toolbar class="mat-elevation-z2 mat-toolbar-container">
  <a [routerLink]="'/'"><img src="assets/img/logo.svg" class="toolbar-logo" /></a>
  <span class="toolbar-spacer"></span>
  <div [class]="(isHandset$ | async) ? 'noDisplay' : ''">
    <a href="mailto:hello@diysurveys.com" class="toolbar-hellodiysurveyscom">hello&#64;diysurveys.com</a>&nbsp;&nbsp;
    <button mat-raised-button color="primary" [routerLink]="'/login'">LOG IN</button>&nbsp;&nbsp;
    <button mat-raised-button color="accent" [routerLink]="'/signup'">SIGN UP</button>
  </div>
</mat-toolbar>
<div class="toolbar-handset-container">
  <div class="toolbar-handset">
    <a [routerLink]="'/'"><img src="assets/img/logo.svg" class="toolbar-logo" /></a>
  </div>
  <div class="login-signup-div-handset" [class]="(isHandset$ | async) ? '' : 'noDisplay'">
    <button mat-raised-button color="primary" [routerLink]="'/login'" class="login-btn-handset">
      LOG IN
    </button>
    <button mat-raised-button color="accent" [routerLink]="'/signup'" class="signup-btn-handset">
      SIGN UP
    </button>
  </div>
</div>
<div class="diy-landing-content-bgColor">
  <mat-grid-list [cols]="(isHandset$ | async) ? '1' : '4'" rowHeight="20vh">
    <mat-grid-tile [colspan]="(isHandset$ | async) ? '1' : '1'" [rowspan]="(isHandset$ | async) ? '4' : '4'" class="diy-landing-gridList-bgColor">
      <div class="nav-navlist-container"
           [style]="(isHandset$ | async) ? 'top:40px; padding: 0 15px 0 15px;' : 'top:0px;'">
        <div class="txt-align-left" [class]="(isHandset$ | async) ? 'mb-10' : 'diy-landing-first-tile-heading-div'">
          <span class="font-wt-500"
                [style]="(isHandset$ | async) ? 'margin-bottom: 10px; font-size: 20px;': 'font-size: 20px;'">What would you like to do?</span>
        </div>
        <div class="diy-landing-first-tile-container">
          <a>
            <div class="diy-landing-first-tile-div">
              <div><img src="assets/img/icon-create-template.png" /></div>&nbsp;&nbsp;
              <div><span class="diy-landing-first-tile-span">Start from template</span></div>
            </div>
          </a>
        </div>
        <div class="diy-landing-first-tile-container">
          <a>
            <div class="diy-landing-first-tile-div">
              <div><img src="assets/img/icon-create.png" /></div>&nbsp;&nbsp;
              <div><span class="diy-landing-first-tile-span">Start from scratch</span></div>
            </div>
          </a>
        </div>
        <div class="diy-landing-first-tile-container">
          <a>
            <div class="diy-landing-first-tile-div">
              <div><img src="assets/img/icon-users.png" /></div>&nbsp;&nbsp;
              <div><span class="diy-landing-first-tile-span">Buy / Upload survey respondents</span></div>
            </div>
          </a>
        </div>
        <div class="diy-landing-first-tile-container">
          <a>
            <div class="diy-landing-first-tile-div">
              <div><img src="assets/img/icon-sms-tools.png" /></div>&nbsp;&nbsp;
              <div><span class="diy-landing-first-tile-span">SMS tools</span></div>
            </div>
          </a>
        </div>
        <div class="diy-landing-fast-tile-bottom-container"
             [class]="(isHandset$ | async) ? 'mb-10' : 'mb-15'">
          <a>
            <div class="diy-landing-first-tile-div">
              <mat-icon class="material-icons-outlined diy-landing-chatSol-icon">question_answer</mat-icon>&nbsp;&nbsp;
              <div>
                <span class="diy-landing-first-tile-span">Chat Solutions</span>
              </div>
            </div>
          </a>
        </div>
        <div class="diy-landing-first-tile-bottom-div">
          <span class="diy-landing-first-tile-bottom-span">Our DIY platform is powerful enough <br />to meet the advanced needs of top <br />researchers, yet simple and easy <br />enough for anyone to use!</span>
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="(isHandset$ | async) ? '1' : '3'" [rowspan]="(isHandset$ | async) ? '4' : '2'" class="diy-landing-tile2" [class]="(isHandset$ | async) ? '' : 'diy-landing-grid-tile'">
      <div class="column-flex"
           [style]="(isHandset$ | async) ? 'padding: 30px;' : 'padding: 0px 0px 0px 0px; margin-top: 0px;'">
        <div class="diy-landing-tile2-span1-container">
          <span class="diy-landing-tile2-span1"
                [style]="(isHandset$ | async) ? 'font-size: 24px;' : ''">DIY Surveys - Powerful enough for Researchers, Simple enough for Everyone!</span>
        </div>
        <div class="diy-landing-tile2-span2-container">
          <span class="diy-landing-tile2-span2"
                [style]="(isHandset$ | async) ? 'font-size: 24px;' : 'font-size: 16px;'">4 Quick and Easy Steps</span>
        </div>
        <div class="diy-landing-tile2-list-div">
          <div>
            <ol>
              <li><span>Sign up for your license free account</span></li>
              <li><span>Create a survey in minutes – 23 pre-programmed question types. Not a survey expert - Use our survey templates</span></li>
              <li><span>Upload your respondents, or use ours - 306+ million people, 130+ countries, 50+ demographics</span></li>
              <li><span>Preview the costs, launch your survey, see your results in real-time</span></li>
            </ol>
          </div>

        </div>
        <div class="diy-landing-tile2-bottomSpan-container">
          <div>
            <span>* DIY Surveys doesn’t limit you like other Survey platforms. Access ALL functionality license free.</span>
          </div>
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="(isHandset$ | async) ? '1' : '0'" [rowspan]="(isHandset$ | async) ? '2' : '0'" [style]="(isHandset$ | async) ? '' : 'display: none;'">
      <div class="full-width-height txt-align-center">
        <img src="assets/img/landing-page-image@2x.png" class="full-width-height" />
      </div>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="(isHandset$ | async) ? '1' : '3'" [rowspan]="(isHandset$ | async) ? '5' : '2'" class="wrapFlex">
      <div class="halfWidth txt-align-center fullHeight pb-0" [style]="(isHandset$ | async) ? 'display: none;' : ''">
        <img src="assets/img/landing-page-image@2x.png" class="full-width-height" />
      </div>
      <div class="column-flex" [class]="(isHandset$ | async) ? 'estimate-grid-content-div-handset' : 'estimate-grid-content-div'">
        <div class="estimate-grid-heading-div">
          <span class="estimate-grid-heading-span"
                [style]="(isHandset$ | async) ? 'font-size: 24px;' : ''">Buy survey respondents - reach the right people</span>
        </div>
        <div class="estimate-grid-point-one-div">
          <div><img src="assets/img/check-icon.png" /></div>&nbsp;&nbsp;&nbsp;
          <div><span>Access 306+ million people in over 130+ countries</span></div>
        </div>
        <div class="estimate-grid-point-two-div">
          <div><img src="assets/img/check-icon.png" /></div>&nbsp;&nbsp;&nbsp;
          <div><span>Select target audiences quickly, easily and at a low price</span></div>
        </div>
        <div class="estimate-grid-point-three-div">
          <div><img src="assets/img/check-icon.png" /></div>&nbsp;&nbsp;&nbsp;
          <div><span>Choose the demographics that you require: age, gender, <br /> region, income and much more (over 50+ attributes)</span></div>
        </div>
        <div class="margin-10 txt-align-center" [style]="(isHandset$ | async) ? '' : 'padding-bottom: 30px;'">
          <button mat-raised-button [routerLink]="'/signup'"
                  target="_blank"
                  class="diy-landing-getEstimate-btn"
                  [style]="(isHandset$ | async) ? 'width:100%;' : ''">
            GET ESTIMATE
          </button>
        </div>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
  <div class="create-account-div" [style]="(isHandset$ | async) ? 'display: none;' : ''">
    <div class="create-account-content">
      <div [style]="(isHandset$ | async) ? 'display: none;' : ''" class="pr-30">
        <span class="create-account-getStarted-span"
              [style]="(isHandset$ | async) ? 'font-size: 18px; font-weight: 300;' : 'font-size: 22px; font-weight: 300; letter-spacing: 1.5px;'">
          Get started now with your FREE account
        </span>
      </div>
      <div class="diy-landing-separator">
      </div>
      <div class="pl-15">
        <button mat-button [routerLink]="'/signup'" target="_blank" class="diy-landing-createAcct-btn">
          CREATE ACCOUNT<span class="hidden-visibility">Hide</span>
        </button>
      </div>
    </div>
  </div>
  <div class="create-account-div-handset" [style]="(isHandset$ | async) ? '' : 'display: none;'">
    <div class="create-account-content-handset">
      <div class="txt-align-left">
        <span class="create-account-getStarted-span-handset">
          Get started now with your FREE account
        </span>
      </div>&nbsp;&nbsp;&nbsp;&nbsp;
      <div class="diy-landing-separator">
      </div>
      <div class="txt-align-center">
        <button mat-button [routerLink]="'/signup'" class="diy-landing-createAcct-btn-handset" target="_blank">
          CREATE ACCOUNT
        </button>
      </div>
    </div>
  </div>
  <div class="fixed-footer" [style]="(isHandset$ | async) ? 'display:none;' : ''">
    <div class="footer-container">
      <div class="footer-left-content">
        <div class="footer-version-div">
          <span>&#169;{{ currentYear }} DIY Surveys Ltd </span>&nbsp;<span>{{currentVersion}}</span>
        </div>
        <div class="footer-terms-div">
          <span><a (click)="openTerms()" [innerHTML]="(isHandset$ | async) ? 'Terms' : 'Terms of Service'" class="cursor-pt"></a></span>
        </div>
        <div class="footer-privacy-div">
          <span><a (click)="openPrivacy()" class="cursor-pt" [innerHTML]="(isHandset$ | async) ? 'Privacy' : 'Privacy Statement'"></a></span>
        </div>
      </div>
    </div>
  </div>
  <div class="handset-footer" [class]="(isHandset$ | async) ? '' : 'noDisplay'">
    <div class="handset-footer-email-container">
      <div>
        <a href="mailto:hello@diysurveys.com" class="handset-footer-email"><span>hello&#64;diysurveys.com</span></a>
      </div>&nbsp;&nbsp;&nbsp;&nbsp;
    </div>
    <div class="handset-footer-bottom-container">
      <div>
        <span>&#169;{{ currentYear }} <br />DIY Surveys Ltd </span>
      </div>
      <div>
        <span><a (click)="openTerms()" class="cursor-pt">Terms of <br />Service</a></span>
      </div>
      <div>
        <span><a (click)="openPrivacy()" class="cursor-pt">Privacy <br />Statement</a></span>
      </div>
    </div>
  </div>
</div>
