import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { AnswerModel } from "../../models/AnswerModel";
import { LoopCategoricalComponent } from '../loop-categorical/loop-categorical.component';

@Component({
  selector: 'diy-loop-numeric',
  templateUrl: './loop-numeric.component.html',
  styleUrls: ['./loop-numeric.component.css']
})
export class LoopNumericComponent implements OnInit {

  constructor() { }

  @ViewChild('loop') loop: LoopCategoricalComponent | undefined;


  ngOnInit(): void {
  }

  @Input()
  get question(): any {
    return this._question;
  }
  set question(value: any) {
    this._question = value;
    if (this._question === undefined) {
      return;
    }

  }
  private _question: any

  get answers(): AnswerModel[] {
    return this.loop?.answers ?? [];
  }

  get valid(): boolean {
    return this.loop?.valid ?? false;
  }
}
