<div [formGroup]="formGroup">
  <div class="fieldRowWithPadding">
    <div>
      <div style="width:100%">
        <app-user-org-search [users]="users" label="Search Users/Organizations"></app-user-org-search>
      </div>
      <div>
        <div class="fieldRowWithPadding">
          <div>
            <app-advanced-timings placeholder="From" [(value)]="startDate"></app-advanced-timings>
          </div>
          <div>
            <app-advanced-timings placeholder="To" label="-" [(value)]="endDate"></app-advanced-timings>
          </div>
        </div>
      </div>
      <div>
        <mat-button-toggle-group formControlName="MessageType" name="messageType" (change)="setPivotMeasure()">
          <mat-button-toggle value="Segments">Segments</mat-button-toggle>
          <mat-button-toggle value="Messages">Messages</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div>
      &nbsp;
    </div>
    <div>
      <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
        @for (aggregator of aggregators; track aggregator) {
        <div class="example-box" cdkDrag>
          <mat-checkbox [formControlName]="aggregator" (click)="setAggregators()">{{aggregator}}</mat-checkbox>
        </div>
        }
      </div>&nbsp;
    </div>
    <div>
      &nbsp;
    </div>
    <div>
      <div>
        <button mat-raised-button color="primary" (click)="searchForMessages()">Search</button>&nbsp;
      </div>
    </div>
  </div>
</div>
<div>
  &nbsp;
</div>
<div *ngIf="dataReady" class="outer-table-container">
  <app-pivot-table #pivotTable [toolbar]="true"
                    [width]="'100%'"
                    [height]="500"
                    [report]="results"
                   (beforetoolbarcreated)="checkToolbar($event)">
    WebDataRocks will appear here
  </app-pivot-table>
</div>
