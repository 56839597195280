import { Component, OnInit, ViewChild, Inject, Output, EventEmitter, AfterViewInit, OnDestroy } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { AuthenticationService, MessageWindow, WindowModel } from '../authentication.Service';
import { MissionService } from "../MissionService";
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, Subscription, timer } from "rxjs";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { NameSurveyFromScratchComponent } from '../name-survey-from-scratch/name-survey-from-scratch.component';
import { SelectRespondentService } from '../select-respondents-landing-page/services/select-respondent.service';
import { JobMasterModel, SurveyService } from '../survey.Service';
import { SurveyModel } from '../survey.Service';
import { SelectRespondentsLandingPageComponent } from '../select-respondents-landing-page/select-respondents-landing-page.component';
import { PanelPricingComponent } from '../edit-panel/panel-pricing/panel-pricing.component';
import { QuestionEditorComponent } from '../question-editor/question-editor.component';
import { InterviewModel } from '../question.service';
import { JoyrideService } from 'ngx-joyride';
import { Schedule } from '../scheduler/scheduler.component';
import * as moment from 'moment-timezone';
import { version } from "../../environments/environment";
import { EstimateModel } from '../panel.Service';
import { WindowComponent } from '../Development/window-entry/window.component';
import { plainToClass } from 'class-transformer';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-survey-details-navigation',
  templateUrl: './survey-details-navigation.component.html',
  styleUrls: ['./survey-details-navigation.component.css'],
  providers: [MissionService]
})
export class SurveyDetailsNavigationComponent implements OnInit, AfterViewInit, OnDestroy {
  isLinear = true;
  surveyId: number = 0;
  survey: SurveyModel = null;
  messages = 0;

  surveyName: string = "";
  isExpanded = false;
  theme = "default";
  displayQRTiny: boolean = false;
  audienceSelected: boolean = false;
  displayScheduler = false;
  displaySendButton = false;
  scheduleType: string = "now";
  endDate: Date = new Date();
  currentVersion: string;
  currentYear = new Date().getFullYear();
  launch: boolean = false;
  launched: boolean = false;
  windows: WindowModel[]
  displayQuestionnaire: boolean = false;

  private inTimer = false;
  waiting?: Observable<any>;
  waitTime: Observable<any> = timer(0, 5000);
  subscription?: Subscription;
  displayStep2 = false;

  sendButtonText = '';

  @ViewChild('sidenav')
  public sidenav: any;

  @ViewChild('step1', { read: QuestionEditorComponent }) public step1: QuestionEditorComponent | undefined;
  @ViewChild('stepper') public stepper: MatStepper | undefined;
  @ViewChild('step2', { read: SelectRespondentsLandingPageComponent }) public step2: SelectRespondentsLandingPageComponent | undefined;
  @ViewChild('step3Pricing') public step3Pricing: PanelPricingComponent | undefined;
  @ViewChild('messageWindows') messageWindows: WindowComponent | undefined;

  firstFormGroup: FormGroup = new FormGroup({});
  secondFormGroup: FormGroup = new FormGroup({});

  constructor(private router: Router,
    private dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private overlayContainer: OverlayContainer,
    private breakpointObserver: BreakpointObserver,
    private auth: AuthenticationService,
    private actRoute: ActivatedRoute,
    private missionService: MissionService,
    private surveyService: SurveyService,
    private distributeService: SelectRespondentService,
    private readonly joyrideService: JoyrideService) {
    this.currentVersion = version;
    missionService.missionConfirmed$.subscribe(
      () => {
      });
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });

    this.surveyId = this.actRoute.snapshot.params.id;
    this.getSurveyDetails();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));

  isHandsetLandscape$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.HandsetLandscape)
    .pipe(map(result => result.matches));

  isHandsetPortrait$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.HandsetPortrait)
    .pipe(map(result => result.matches));

  ngOnInit(): void {

    this.overlayContainer.getContainerElement().classList.add(this.theme);

    if (!localStorage.getItem('surveySetupTourDone')) {
      this.joyrideService.startTour(
        {
          steps: ['designSurveyStep',
            'questionListStep@surveyquestioneditor/' + this.surveyId + '/survey',
            'surveyRoutingStep@surveyquestioneditor/' + this.surveyId + '/survey',
            'questionOrderStep@surveyquestioneditor/' + this.surveyId + '/survey',
            'newQuestionStep@surveyquestioneditor/' + this.surveyId + '/survey',
            'questionPropertiesStep@surveyquestioneditor/' + this.surveyId + '/survey',
            'questionLibraryStep@surveyquestioneditor/' + this.surveyId + '/survey',
            'testSurveyStep@surveyquestioneditor/' + this.surveyId + '/survey',
            'surveySettingsStep@surveyquestioneditor/' + this.surveyId + '/survey',
            'selectRespondentStep',
            'surveyLiveStep'
          ],
          showCounter: false,
        } // Your steps order
      );
      localStorage.setItem('surveySetupTourDone', "Survey Setup Tour");
    }
  }


  ngAfterViewInit(): void {
  }

  getSurveyDetails() {
    this.surveyService.getSurveyDetail(this.surveyId)
      .subscribe(result => {
        this.survey = result;
        this.surveyName = result.Name;
        switch (this.survey.Channel) {
          case 3:
          case 4:
            break;
          default:
            this.displayQuestionnaire = true;
        }

        this.selectedScheduleType();
        if (this.scheduleType == "cron") {
          this.openScheduler();
        }
        else {
          this.selectNow();
        }

        this.windows = [];
        if (this.survey.Windows != undefined) {
          for (let i = 0; i < this.survey.Windows.length; i++) {
            this.windows.push(plainToClass(WindowModel, this.survey.Windows[i]));
          }
        }
      });
  }

  getChannelIcon() {

  }

  onWindowChange(value: WindowModel[]) {
    this.survey.Windows = value;
    this.surveyService.updateBasicDetails(this.survey).subscribe(
      result => {
        this.survey = result;
        this.windows = this.survey.Windows ?? [];
      });
  }

  onTimeoutChange(selection: MatSelectChange) {
    this.survey.Occurences = selection.value;
    this.surveyService.updateBasicDetails(this.survey).subscribe(
      result => {
        this.survey = result;
      });
  }

  onThemeChange() {
    this.overlayContainer.getContainerElement().classList.add(this.theme);
  }

  isAdmin() {
    return this.auth.isAuthorized(['Admin']);
  }

  isTester() {
    return this.auth.isAuthorized(['diy']);
  }

  gotoInbox() {
    this.router.navigate(['../inbox'])
  }

  openTerms() {
    const url = this.router.createUrlTree(['/termsofservice'])
    window.open(url.toString(), '_blank')
  }

  openPrivacy() {
    const url = this.router.createUrlTree(['/privacystatement'])
    window.open(url.toString(), '_blank')
  }

  panelPricingReady() {
    return this.step2 != undefined; // && this.step3Pricing != undefined;
  }

  get hasScript() {
    if (!this.survey) {
      return false;
    }
    return this.survey.QuestionCount > 0;
  }

  get hasPanel() {
    if (!this.survey) {
      return false;
    }
    return this.survey.PanelID > 0;
  }

  toggleSideMenu() {
    this.sidenav.toggle();
  }
  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }

  goToHome() {
    this.surveyService.getSurveyCount()
      .subscribe(response => {
        if (response > 0) {
          this.router.navigate(['../surveydashboard']);
        } else {
          this.router.navigate(['../startfromtemplate']);
        }
      });
  }

  openBasicDialog(): void {
    const dialogRef = this.dialog.open(NameSurveyFromScratchComponent,
      {
        data: { id: this.surveyId }
      });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.survey) {
          this.survey = result.survey;
          this.surveyName = this.survey.Name;
        }
      }
    });
  }

  moveToThirdStep(stepper: MatStepper) {
    stepper.next();
  }

  selectNow(): void {
    this.displayScheduler = false;
    this.displaySendButton = true;
    this.sendButtonText = 'Send';
    this.checkJobMaster("", true);
  }

  selectedScheduleType() {
    if (this.survey.JobMaster) {
      if (this.survey.JobMaster.CronExpression === "") { // || this.survey.JobMaster.Name === "") {
        this.scheduleType = "now";
      }
      else {
        this.scheduleType = "cron";
      }
    }
  }

  get schedule(): Schedule | null {
    return this._schedule;
  }
  set schedule(value: Schedule | null) {
    this._schedule = value;
    if (this.survey.JobMaster == undefined || this.survey.JobMaster == null) {
      this.CreateNewJobMaster();
    }

    if (this.survey.JobMaster != null) {
      this.survey.JobMaster.Name = this._schedule?.timezone ?? "";
      this.survey.JobMaster.Adjustment = this.schedule?.offset ?? 0;
      this.survey.JobMaster.Start = this._schedule?.start ?? new Date();
      this.survey.JobMaster.End = this._schedule?.end ?? new Date();
      this.survey.JobMaster.CronExpression = this._schedule?.cron ?? "";
      let offset = moment.tz(moment.tz.guess()).utcOffset();
      let endDate = new Date(new Date(new Date().getTime() + offset * 60000).getDate() + 3);
      if (this.survey.JobMaster.End < endDate) {
        this.survey.JobMaster.End = endDate;
      }
    }

    this.checkJobMaster(this._schedule?.cron ?? "", false);
  }
  private _schedule: Schedule | null = new Schedule(moment.tz.guess(), new Date(), new Date(new Date().getDate() + 3), "");

  sendSurvey() {
    this.launch = true;
    this.launched = false;
    if (this.displaySendButton) {
      this.displaySendButton = false;
      if (this.step2 != undefined) {
        this.surveyService.makePayment(this.survey, this.step2.estimate ?? new EstimateModel()).subscribe(
          result => {
            this.surveyService.makeLive(this.survey).subscribe(
              result => {
                this.inTimer = false;
                this.subscription = this.waitTime.subscribe(() => {
                  if (this.inTimer) {
                    return;
                  }

                  this.inTimer = true;
                  this.surveyService.getSurveyDetail(this.survey.SurveyID).subscribe(
                    result => {
                      if (result.Status != 1 && result.Status != 3) {
                        this.launch = false;
                        this.launched = true;
                        this.router.navigate(["delivery-summary", this.survey.SurveyID, "summary"]);
                      }
                    });
                  this.inTimer = false;
                });
              });
          });
      }
    }
  }

  backtoDashboard() {
    this.subscription?.unsubscribe();
    this.router.navigate(['/surveydashboard']);
  }

  scheduleChanged(value: Schedule) {
    this.schedule = value;
  }

  openScheduler(): void {
    this.displaySendButton = true;
    this.displayScheduler = true;
    this.sendButtonText = "Schedule";
    let temp = "";
    if (this.survey && this.survey.JobMaster) {
      temp = this.survey.JobMaster.CronExpression;
    }

    this.checkJobMaster(temp, true);
  }

  get interview(): InterviewModel | null {
    return this.step1?.interview ?? new InterviewModel();
  }

  private CreateNewJobMaster() {
    let offset = moment.tz(moment.tz.guess()).utcOffset();
    this.survey.JobMaster = new JobMasterModel();
    this.survey.JobMaster.JobMasterId = 0;
    this.survey.JobMaster.SurveyID = this.survey.SurveyID;
    this.survey.JobMaster.Name = moment.tz.guess();
    this.survey.JobMaster.Subject = "";
    this.survey.JobMaster.Content = "";
    this.survey.JobMaster.CanMissfire = false;
    this.survey.JobMaster.Adjustment = offset;
    this.survey.JobMaster.Start = new Date(new Date().getTime() + offset * 60000);
    this.survey.JobMaster.End = new Date(new Date().getTime() + offset * 60000);
    this.survey.JobMaster.CronExpression = "";
    this.survey.JobMaster.End.setDate(this.survey.JobMaster.End.getDate() + 3);
  }

  private checkJobMaster(cron: string, updateSchedule: boolean) {
    if (this.survey.JobMaster) {
      if (cron == "") {
        let offset = moment.tz(moment.tz.guess()).utcOffset();
        this.survey.JobMaster.Adjustment = offset;
        this.survey.JobMaster.Start = new Date(new Date().getTime() + offset * 60000);
        this.survey.JobMaster.End = new Date(new Date().getTime() + offset * 60000);
        this.survey.JobMaster.End.setDate(this.survey.JobMaster.End.getDate() + 3);
        this.survey.JobMaster.CronExpression = "";
        this._schedule = null; // new Schedule(moment.tz.guess(), this.survey.JobMaster.Start, this.survey.JobMaster.End, "");
      }
      else {
        let timezone = this.survey.JobMaster.Name;
        if (this.survey.JobMaster.Name.includes("|")) {
          timezone = this.survey.JobMaster.Name.split("|")[0];
        }

        let offset = moment.tz(moment.tz.guess()).utcOffset();
        let endDate = new Date(new Date(new Date().getTime() + offset * 60000).getDate() + 3);
        if (this.survey.JobMaster.End < endDate) {
          this.survey.JobMaster.End = endDate;
        }

        if (updateSchedule) {
          this._schedule = new Schedule(timezone, this.survey.JobMaster.Start, this.survey.JobMaster.End, this.survey.JobMaster.CronExpression);
        }
      }
    }
    else {
      this.CreateNewJobMaster();
    }

    if (this.survey.JobMaster != null) {
      this.survey.JobMaster.Launched = new Date();
      this.survey.JobMaster.CronExpression = cron;
      this.survey.JobMaster.JobTypeID = 1;
      if (this.survey.JobMaster.ChannelID == 0 && this.survey.Channel == 1) {
        this.survey.JobMaster.ChannelID = 2;
      }

      this.surveyService.saveJobMaster(this.survey.JobMaster).subscribe();
    }
  }
}
