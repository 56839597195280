import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription, Observable } from "rxjs";
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { PanelService, PanelistProfile, ProfileVariable } from "../panel.Service";
import { MissionService } from "../MissionService";

@Component({
  selector: 'app-edit-panelist',
  templateUrl: './edit-panelist.component.html',
  styleUrls: ['./edit-panelist.component.css']
})
export class EditPanelistComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  panelId: string;
  panelistId: string;
  tempPanelist?: Observable<PanelistProfile>;
  panelist: PanelistProfile = new PanelistProfile();
  formGroup: FormGroup = new FormGroup({});
  responses: ProfileVariable[] = [];

  constructor(
    private missionService: MissionService, 
    private router: Router,
    private panelService: PanelService,
    private actRoute: ActivatedRoute
  ) {
    this.panelId = this.actRoute.snapshot.params.id;
    this.panelistId = this.actRoute.snapshot.params.pid;
    this.subscription = missionService.missionAnnounced$.subscribe(
      mission => {
        if (mission === "Back") {
          this.onSubmit();
        }
      });
  }

  selectedFile: File | null = null;

  ngOnInit(): void {
    this.tempPanelist = this.panelService.getPanelist(this.panelistId).pipe(tap<PanelistProfile>(
      result => {
        this.panelist = result;
        this.responses = this.panelist.ProfileVariables
        this.initForm();
        this.formGroup.patchValue(this.panelist);
      }));
  }
  
  initForm() {
    this.formGroup = new FormGroup({
      Title: new FormControl(),
      UserName: new FormControl(),
      FirstName: new FormControl(),
      LastName: new FormControl(),
      Email: new FormControl(),
      MobileNumber: new FormControl(),
      DOB: new FormControl(),
      WhenTrigger: new FormControl(),
      IsDeleted: new FormControl(),
      CreatedDate: new FormControl(),
      LastUpdatedDate: new FormControl(),
      array: new FormArray([])
    });

    for (let i = 0; i < this.responses.length; i++) {
      this.responseArr.push(this.createResponseEntry(this.responses[i]));
    }
  }

  get responseArr() {
    return this.formGroup.controls.array as FormArray;
  }

  createResponseEntry(response: ProfileVariable): FormGroup {
    let value: any = null;
    switch (response.TypeId) {
      case 1:
        value = response.TextVal;
        break;
      case 2:
        value = response.RealVal;
        break;
      case 3:
        value = response.DateVal;
        break;
      case 4:
        value = response.ObjectVal;
        break;
    }

    let form = new FormGroup({
      response: new FormControl(value)
    });
    return form;
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
  }

  message() {
    return;
  }

  onSubmit() {
    if (this.formGroup.invalid) {
      return;
    }

    this.panelist.UserName = this.formGroup.value.UserName;
    this.panelist.FirstName = this.formGroup.value.FirstName;
    this.panelist.LastName = this.formGroup.value.LastName;
    this.panelist.Email = this.formGroup.value.Email;
    this.panelist.MobileNumber = this.formGroup.value.MobileNumber;
    this.panelist.Title = this.formGroup.value.Title;
    this.panelist.Dob = this.formGroup.value.DOB;

    for (let i = 0; i < this.responseArr.controls.length; i++) {
      let form = this.responseArr.controls[i] as FormGroup;
      switch (this.responses[i].TypeId) {
        case 1:
          this.panelist.ProfileVariables[i].TextVal = form.controls.response.value;
          break;
        case 2:
          this.panelist.ProfileVariables[i].RealVal = Number(form.controls.response.value);
          break;
        case 3:
          this.panelist.ProfileVariables[i].DateVal = new Date(form.controls.response.value.toString());
          break;
        case 4:
          this.panelist.ProfileVariables[i].ObjectVal = form.controls.response.value;
          break;
      }
    }

    this.panelService.setPanelist(this.panelId, this.panelist).subscribe(result => {
      this.router.navigate(['/edit-respondents', this.panelId, "panel"]);
    });
  }
}
