import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, tap } from 'rxjs';
import { Keyword, KeywordService } from '../../keyword.Service';

@Component({
  selector: 'app-keywords',
  templateUrl: './keywords.component.html',
  styleUrls: ['./keywords.component.css']
})
export class KeywordsComponent implements OnInit {

  tempKeywords: Observable<any>;
  Keywords: Keyword[] = [];
  dataSource = new MatTableDataSource(this.Keywords);
  displayedColumns: string[] = ['Name', 'Created', 'Amended'];

  constructor(private keywordService: KeywordService,
    public translate: TranslateService,
    private router: Router) {
    this.tempKeywords = keywordService.getKeywords().pipe(tap<Keyword[]>(result => {
      this.Keywords = result;
      this.dataSource = new MatTableDataSource(this.Keywords);
    }));

  }

  ngOnInit(): void {
  }

  new() {
    this.router.navigate(["/keyword", 0, "keyword"]);
  }

  edit(keyword: Keyword) {
    this.router.navigate(["/keyword", keyword.KeywordID, "keyword"]);
  }
}
