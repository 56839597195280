import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SurveyModel, SurveyService } from '../../survey.Service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageBuilderComponent } from '../../survey-distribution/message-builder/message-builder.component';
import { CommsService } from '../../comms.Service';

@Component({
  selector: 'app-send-whatsapp',
  templateUrl: './send-whatsapp.component.html',
  styleUrls: ['./send-whatsapp.component.css']
})
export class SendWhatsappComponent implements OnInit {

  formGroup: FormGroup;
  survey: SurveyModel;
  subject: string = "";
  content: string = "";
  channel: number = -1;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<MessageBuilderComponent>,
    private surveyService: SurveyService,
    private commsService: CommsService,
    private fb: FormBuilder) {
    this.formGroup = new FormGroup({
      appId: new FormControl("", [Validators.required]),
      templateName: new FormControl("", [Validators.required]),
      content: new FormControl("", [Validators.required])
    });
    this.survey = data.survey;
    this.channel = data.channel;
    if (this.survey.JobMaster && this.survey.JobMaster.Content !== "" && this.survey.JobMaster.Subject !== "") {
      this.subject = this.survey.JobMaster.Subject;
      this.content = this.survey.JobMaster.Content;
    }
    else {
      this.commsService.getCommByType(1).subscribe(
        result => {
          this.subject = result.Subject;
          this.content = result.EmailContent;
          this.ngAfterViewInit();
        }
      )
    }
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    let details = this.subject.split(':');
    this.formGroup.controls.appId.setValue(details[0]);
    this.formGroup.controls.templateName.setValue(details[1]);
    this.formGroup.controls.content.setValue(this.content);
  }

  saveWhatsApp() {

  }

  get messageType(): string {
    switch (this.channel) {
      case 1:
      case 5:
        return "web";
      default:
        return "sms";
    }
  }
}
