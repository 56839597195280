import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { plainToClass } from 'class-transformer';
import { CaseDataService } from '../../casedata.Service';
import { ProcessService, ProcessServiceModel } from '../../process.Service';
import { DataAnalysisComponent } from '../data-analysis.component';
import { ExportAutomationComponent } from '../export-automation/export-automation.component';

@Component({
  selector: 'app-inviteio',
  templateUrl: './inviteio.component.html',
  styleUrls: ['./inviteio.component.css']
})
export class InviteioComponent implements OnInit {

  importProcesses: ProcessServiceModel[] = [];
  exportProcesses: ProcessServiceModel[] = [];
  liveProcesses: ProcessServiceModel[] = [];
  dialogRef: MatDialogRef<any, any>;

  @ViewChild('importDataDialog') dataDefinitionDialog: TemplateRef<any>;

  constructor(private caseService: CaseDataService,
    private processService: ProcessService,
    private snackbar: MatSnackBar,
    public dialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  getProcesses() {
    this.processService.getExportProcesses(this.analysis?.id ?? 0).subscribe(
      result => this.exportProcesses = result);
    this.processService.getImportProcesses(this.analysis?.id ?? 0).subscribe(
      result => this.importProcesses = result);
    this.processService.getActiveProcesses(this.analysis?.id ?? 0).subscribe(
      result => this.liveProcesses = result);
  }

  @Input()
  get analysis(): DataAnalysisComponent | undefined {
    return this._analysis
  }
  set analysis(value: DataAnalysisComponent | undefined) {
    this._analysis = value;
    if (this._analysis) {
      this.getProcesses();
    }
  }
  private _analysis: DataAnalysisComponent | undefined;

  exportData(automated: boolean, process: ProcessServiceModel | null) {
    const dialogRef = this.dialog.open(ExportAutomationComponent, {
      data: { isExport: true, automate: automated, analysis: this.analysis, rules: null, process: process },
      width: '95vw',
      maxWidth: '95vw'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getProcesses();
    });
  }

  importData(automated: boolean, process: ProcessServiceModel | null) {
    const dialogRef = this.dialog.open(ExportAutomationComponent, {
      data: { isExport: false, automate: automated, analysis: this.analysis, rules: null, process: process },
      width: '95vw',
      maxWidth: '95vw'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getProcesses();
    });
  }

  showImportDataDefinition() {
    this.dialogRef = this.dialog.open(this.dataDefinitionDialog);
  }

  importDataDefinition($event) {
    this.dialogRef.close();
    this.processService.getImport($event.name).subscribe(
      result => {
        let process = plainToClass(ProcessServiceModel, result);
        const dialogRef = this.dialog.open(ExportAutomationComponent, {
          data: { isExport: process.Task.Type == "EXPORT", automate: true, analysis: this.analysis, rules: null, process: process },
          width: '95vw',
          maxWidth: '95vw'
        });
        dialogRef.afterClosed().subscribe(result => {
          this.getProcesses();
        });
      });
  }

  checkData(automated: boolean, process: ProcessServiceModel) {
    if (process.Task.Type == "EXPORT") {
      this.exportData(automated, process);
    }
    else {
      this.importData(automated, process);
    }
  }
}
