<div class="surv-summ-container">
  <mat-accordion>
    <mat-expansion-panel hideToggle [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3>General Statistics</h3>
        </mat-panel-title>
        <mat-panel-description>

        </mat-panel-description>
      </mat-expansion-panel-header>
      <div *ngIf="results.length > 0" class="surv-summ-genStat-container">
        <div class="column-flex-flow">
          <div class="surv-summ-genStat-content">
            <div class="wd-30-per">Survey Type</div><div class="surv-summ-InvMsg-div">{{inviteMessage}}</div>
          </div>
          <div *ngFor="let result of results" class="surv-summ-genStat-content">
            <div class="wd-30-per" [matTooltip]="result.description" matTooltipPosition="after">{{result.name}}</div><div class="surv-summ-InvMsg-div"><mat-progress-bar mode="determinate" [value]="result.percent"></mat-progress-bar></div><div class="surv-summ-resltVal-div">{{result.value}}</div>
          </div>
        </div>
      </div>
      <div *ngIf="dropoffRates.length > 0">
        <div class="mt-10">
          <h3>Drop Off Rate</h3>
        </div>
        <div class="column-flex-flow">
          <div class="row-flex-flow">
            <div>Q1</div><div></div><div></div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3>Reminders</h3>
        </mat-panel-title>
        <mat-panel-description>

        </mat-panel-description>
      </mat-expansion-panel-header>
      Reminders can be sent out to any receipients. You select who you want to send a reminder to by setting up a filter on the survey.
      <app-advanced-routing [display]="false" [rules]="rules" [showAddRemove]="false" name="Reminder Filter " class="fullWidth"></app-advanced-routing>
      <div class="column-flex-flow">
        <div>
          <button mat-flat-button color="primary" (click)="calculateReminders()">Calculate</button>
        </div>
      </div>
      <div>
        <div class="surv-summ-genStat-content">
          <div class="wd-30-per">Recipients Selected</div><div class="surv-summ-InvMsg-div"><mat-progress-bar mode="determinate" [value]="invites?.percent"></mat-progress-bar></div><div class="surv-summ-resltVal-div">{{invites?.value}}</div>
        </div>
      </div>
      <div>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3>Schedule</h3>
        </mat-panel-title>
        <mat-panel-description>

        </mat-panel-description>
      </mat-expansion-panel-header>
      <app-survey-schedular [survey]="analysis.survey"></app-survey-schedular>
    </mat-expansion-panel>
    <mat-expansion-panel hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h3>Interview Timeout</h3>
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <p>
        You have the ability to increase or decrease the interview time period from 3 days.
        It allows you to leave an interview open for an individual for a specified period of time after which they will be timed out and unable to complete the survey.
      </p>
      <mat-form-field appearance="outline">
        <mat-label>Timeout Value</mat-label>
        <mat-select [disabled]="true" #timeout [value]="analysis?.survey?.Occurences < 5 ? 4320 : analysis?.survey?.Occurences">
          <mat-option [value]="5">5 Minutes</mat-option>
          <mat-option [value]="10">10 Minutes</mat-option>
          <mat-option [value]="180">3 Hours</mat-option>
          <mat-option [value]="306">6 Hours</mat-option>
          <mat-option [value]="720">12 Hours</mat-option>
          <mat-option [value]="1440">1 Day</mat-option>
          <mat-option [value]="2880">2 Days</mat-option>
          <mat-option [value]="4320">3 Days</mat-option>
          <mat-option [value]="5760">4 Days</mat-option>
          <mat-option [value]="7200">5 Days</mat-option>
          <mat-option [value]="8640">6 Days</mat-option>
          <mat-option [value]="10040">1 Week</mat-option>
          <mat-option [value]="20080">2 Weeks</mat-option>
          <mat-option [value]="30120">3 Weeks</mat-option>
        </mat-select>
      </mat-form-field>
    </mat-expansion-panel>
  </mat-accordion>
</div>
