<div class="flex-flow-col">
  <div class="slctRspndnt-buy-audience-content">
    <div class="margin-5">
      <button mat-raised-button color="primary" (click)="showUploadCard()">Upload Respondent File</button>
    </div>
    <div class="slctRspndnt-or-separator">
      <span>Or</span>
    </div>
    <div class="margin-5">
      <button mat-raised-button color="primary" (click)="showIndividualCard()">Add Individual Respondent</button>
    </div>
    &nbsp;
    <div *ngIf="uploadDivCancelSection" class="margin-5">
      <button mat-raised-button color="accent" (click)="cancelAddPanellistClick()">Cancel</button>
    </div>
  </div>
  <div class="slctRspndnt-buy-audience-content">
    <div>
      <mat-icon>warning</mat-icon>
    </div>
    <div>
      Remember, the use of SMS should follow our strict <a href="">SMS Usage Guidelines</a>
    </div>
  </div>
</div>
<!-- Upload Respondent Section start -->
<div class="add-upld-upload-card mat-elevation-z2" *ngIf="uploadCard">
  <div>
    <a href="assets/files/SamplePanellistImport.xlsx" class="add-upld-download-temp-link">Click here</a> to download a template file to upload respondents.
  </div>
  <div>
    <app-file-upload *ngIf="process == undefined || process.ProcessId == 0" accept=".xlsx" (uploaded)="onFileUploaded($event)"></app-file-upload>
    <app-file-process *ngIf="process != undefined && process.ProcessId != 0" [process]="process" (completed)="onFileProcessCompleted($event)"></app-file-process>
    <div class="fullWidth" *ngIf="errors && errors.length > 0">
      <div class="fullWidth" *ngFor="error of errors; let i = index;">
        <div class="add-upld-example-element-description">
          <div>
            {{errors[i].Line}}:{{errors[i].Col}}&nbsp;&nbsp;
          </div>
          <div>
            {{errors[i].Message}}
          </div>
        </div>
      </div>
    </div>
    <div class="pt-5">
      <button mat-raised-button color="accent" (click)="onCancelUploadClick()">Cancel</button>
    </div>
    <div>
      <div *ngIf="message" class="alert alert-primary" role="alert">
        {{message}}
      </div>
    </div>
  </div>
</div>
<!-- Upload Respondent Section end -->
<!-- Add Individual Respondent Section start -->
<div class="add-upld-new-panellist-card mat-elevation-z2" *ngIf="individualCard">
  <h2>
    Enter one or both options below to define your respondent
  </h2>
  <div>
    <form [formGroup]="individualFormGroup" class="add-upld-normal-form">
      <div class="slctRspndnt-buy-audience-content">
        <div class="margin-5">
          <mat-form-field appearance="outline" class="add-upld-invdl-form-field">
            <mat-label>Email</mat-label>
            <input formControlName="email" matInput placeholder="Email">
            <mat-error class="mt-15">Invalid email address.</mat-error>
          </mat-form-field>
        </div>
        <div>
          <span>And/Or</span>
        </div>
        <div class="margin-5">
          <mat-form-field appearance="outline" class="add-upld-invdl-form-field">
            <mat-label>Mobile</mat-label>
            <input formControlName="mobile" matInput placeholder="Mobile">
            <mat-error *ngIf="individualFormGroup.controls['mobile'].errors?.required">This field is mandatory.</mat-error>
            <mat-error class="mt-15" *ngIf="individualFormGroup.controls['mobile'].errors?.minlength">Minimum 10 charactors needed.</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="add-upld-button-row">
        <button mat-raised-button color="accent" (click)="onCancelUploadClick()">Cancel</button>
        <button mat-raised-button color="primary" (click)="onSave()" [disabled]="isDisabled">Save</button>
      </div>
    </form>
  </div>
</div>
<!-- Add Individual Respondent Section end -->
