import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Field } from '../../../process.Service';
import { QuestionModel } from '../../../question.service';

@Component({
  selector: 'app-field-select-dialog',
  templateUrl: './field-select-dialog.component.html',
  styleUrls: ['./field-select-dialog.component.css']
})
export class FieldSelectDialogComponent {

  field: Field;
  fields: Field[] = [];
  questions: QuestionModel[] = [];
  templateProfile: any;

  constructor(private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) data: any) {

  }

  selected(event) {

  }
}
