<div class="full-width-height display-flex">
  <div class="qe-sidemenu-div">
    <button *ngIf="showQuestionList" mat-icon-button matTooltip=" Question List " matTooltipPosition="left" (click)="showQuestionList = true" joyrideStep="questionListStep" [stepContent]="QuestionListTourContent"><mat-icon>fact_check</mat-icon></button>
    <ng-template #QuestionListTourContent>
      <strong> Question list </strong>
      <br />
      <br />
      View questions
    </ng-template>
    <button *ngIf="!showQuestionList" mat-icon-button matTooltip=" Question List " matTooltipPosition="left" (click)="showQuestionList = true" joyrideStep="questionListStep" [stepContent]="QuestionListTourContent"><mat-icon fontSet="material-icons-outlined">fact_check</mat-icon></button>
    <button *ngIf="!showQuestionList" mat-icon-button matTooltip=" Survey Routing " matTooltipPosition="right" (click)="showQuestionList = false" joyrideStep="surveyRoutingStep" [stepContent]="SurveyRouteTourContent"><mat-icon>directions</mat-icon></button>
    <ng-template #SurveyRouteTourContent>
      <strong> Routing </strong>
      <br />
      <br />
      Add logic to create routes through survey
    </ng-template>
    <button *ngIf="showQuestionList" mat-icon-button matTooltip=" Survey Routing " matTooltipPosition="right" (click)="showQuestionList = false" joyrideStep="surveyRoutingStep" [stepContent]="SurveyRouteTourContent" [disabled]="!hasScript"><mat-icon fontSet="material-icons-outlined">directions</mat-icon></button>
    <button *ngIf="qList" mat-icon-button matTooltip=" Question Order " matTooltipPosition="right" (click)="toggleLeftSidenav('qList')" joyrideStep="questionOrderStep" [stepContent]="QuestionOrderTourContent"><mat-icon>view_list</mat-icon></button>
    <ng-template #QuestionOrderTourContent>
      <strong> Question order </strong>
      <br />
      <br />
      Change question order
    </ng-template>
    <button *ngIf="!qList" mat-icon-button matTooltip=" Question Order " matTooltipPosition="right" (click)="toggleLeftSidenav('qList')" joyrideStep="questionOrderStep" [stepContent]="QuestionOrderTourContent"><mat-icon fontSet="material-icons-outlined">view_list</mat-icon></button>
    <button mat-icon-button matTooltip="Add New Question" matTooltipPosition="right" (click)="addNewQuestionnaireDialog()" joyrideStep="newQuestionStep" [stepContent]="NewQuestionTourContent"><mat-icon>add_circle</mat-icon></button>
    <ng-template #NewQuestionTourContent>
      <strong> Add New Question </strong>
      <br />
      <br />
      Choose from 23 question types
    </ng-template>
    <button *ngIf="qProp" mat-icon-button matTooltip=" Question Properties " matTooltipPosition="right" (click)="togglerightSidenav('qProp')" joyrideStep="questionPropertiesStep" [stepContent]="QuestionPropertiesTourContent"><mat-icon>widgets</mat-icon></button>
    <ng-template #QuestionPropertiesTourContent>
      <strong> Question Properties </strong>
      <br />
      <br />
      Change question properties
    </ng-template>
    <button *ngIf="!qProp" mat-icon-button matTooltip=" Question Properties " matTooltipPosition="right" (click)="togglerightSidenav('qProp')" joyrideStep="questionPropertiesStep" [stepContent]="QuestionPropertiesTourContent"><mat-icon fontSet="material-icons-outlined">widgets</mat-icon></button>
    <button *ngIf="qLibrary" mat-icon-button matTooltip=" Question Library " matTooltipPosition="right" (click)="toggleLeftSidenav('qLibrary')" joyrideStep="questionLibraryStep" [stepContent]="QuestionLibraryTourContent"><mat-icon>local_library</mat-icon></button>
    <ng-template #QuestionLibraryTourContent>
      <strong> Question library </strong>
      <br />
      <br />
      Add your saved Questions to new Surveys
    </ng-template>
    <button *ngIf="!qLibrary" mat-icon-button matTooltip=" Question Library " matTooltipPosition="right" (click)="toggleLeftSidenav('qLibrary')" joyrideStep="questionLibraryStep" [stepContent]="QuestionLibraryTourContent"><mat-icon fontSet="material-icons-outlined">local_library</mat-icon></button>
    <button mat-icon-button matTooltip=" Test Survey " [matMenuTriggerFor]="menu" matTooltipPosition="right" joyrideStep="testSurveyStep" [stepContent]="TestSurveyTourContent"><mat-icon>monitor</mat-icon></button>
    <ng-template #TestSurveyTourContent>
      <strong> Test Survey </strong>
      <br />
      <br />
      Preview survey and generate test data
    </ng-template>
    <button *ngIf="surveyStyle" mat-icon-button matTooltip=" Survey Settings " matTooltipPosition="right" (click)="toggleLeftSidenav('surveyStyle')" joyrideStep="surveySettingsStep" [stepContent]="SurveySettingsTourContent"><mat-icon>settings</mat-icon></button>
    <ng-template #SurveySettingsTourContent>
      <strong> Survey Settings </strong>
      <br />
      <br />
      Theme your survey
    </ng-template>
    <button *ngIf="!surveyStyle" mat-icon-button matTooltip=" Survey Settings " matTooltipPosition="right" (click)="toggleLeftSidenav('surveyStyle')" joyrideStep="surveySettingsStep" [stepContent]="SurveySettingsTourContent"><mat-icon fontSet="material-icons-outlined">settings</mat-icon></button>
    <mat-menu #menu="matMenu" xPosition="after">
      <button mat-menu-item (click)="previewInterview()">
        <mat-icon>play_arrow</mat-icon>
        <span>Preview</span>
      </button>
      <button mat-menu-item (click)="testOnOtherDevices()">
        <mat-icon>qr_code_2</mat-icon>
        <span>Test On Other Devices</span>
      </button>
      <button mat-menu-item (click)="testInterview()">
        <mat-icon>bug_report</mat-icon>
        <span>Generate Test Data</span>
      </button>
    </mat-menu>
  </div>
  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav #leftSidenav class="qe-sidenav-left" [mode]="(isHandset$ | async) ? 'over' : 'side'">
      <div *ngIf="qList">
        <div class="qe-sidenav-left-container">
          <div class="qe-sidenav-left-heading">
            <span>
              {{ showQuestionList ? 'Questions' : 'Routing' }}
            </span>
          </div>
          <div>
            <button mat-icon-button (click)="closeLeftSidenav()">
              <mat-icon class="dialog-close-icon">close</mat-icon>
            </button>
          </div>
        </div>
        <app-questionnaire-sidebar #sorter [editor]="this"></app-questionnaire-sidebar>
      </div>
      <div *ngIf="qLibrary">
        <div class="qe-sidenav-left-container">
          <div>
          </div>
          <div class="qe-sidenav-left-heading">
            <span>Question Library</span>
          </div>
          <button mat-icon-button (click)="closeLeftSidenav()">
            <mat-icon class="dialog-close-icon">close</mat-icon>
          </button>
        </div>
        <app-question-library #library [editor]="this"></app-question-library>
      </div>
      <div *ngIf="help">
        <div class="qe-sidenav-left-container">
          <div class="qe-sidenav-left-heading">
            <span>Help</span>
          </div>
          <button mat-icon-button (click)="closeLeftSidenav()">
            <mat-icon class="dialog-close-icon">close</mat-icon>
          </button>
        </div>
        <app-help></app-help>
      </div>
      <div *ngIf="surveyStyle">
        <div class="qe-sidenav-left-container qe-survey-sett-bottom-border">
          <div class="qe-sidenav-left-heading">
            <span>Survey Settings</span>
          </div>
          <div>
            <button mat-icon-button (click)="closeLeftSidenav()">
              <mat-icon class="dialog-close-icon">close</mat-icon>
            </button>
          </div>
        </div>
        <div class="custom-scroll-bar qe-survey-style-div">
          <app-survey-style [editor]="this" (uploadScript)="uploadScript($event)"></app-survey-style>
        </div>
      </div>
    </mat-sidenav>
    <mat-sidenav #rightSidenav class="qe-sidenav-right" position="end" [mode]="(isHandset$ | async) ? 'over' : 'side'">
      <div *ngIf="qProp">
        <div class="qe-sidenav-right-container">
          <div class="qe-sidenav-right-heading">
            <span>Properties</span>
          </div>
          <button mat-icon-button (click)="closeRightSidenav()" class="justify-flex-start">
            <mat-icon class="qe-prop-close-icon">close</mat-icon>
          </button>
        </div>
        <app-question-properties #properties [parentIndex]="parentIndex" [editor]="this" [index]="index"></app-question-properties>
      </div>
      <div *ngIf="surveyPvw">
        <div class="qe-sidenav-right-container">
          <button mat-icon-button (click)="closeRightSidenav()" class="justify-flex-start">
            <mat-icon class="dialog-close-icon">close</mat-icon>
          </button>
          <div class="qe-sidenav-right-heading">
            <span>Question Preview</span>
          </div>
        </div>
        <div class="custom-scroll-bar qe-survey-prvw-height">
        </div>
      </div>
    </mat-sidenav>
    <mat-sidenav-content class="qe-sidenav-content">
      <div *ngIf="errors.length > 0" class="margin-10">
        <div class="alert alert-danger" role="alert">
          There are problems with the survey. <span class="qe-link" (click)="errorsExpanded = !errorsExpanded">Please click here for {{ errorsExpanded ? 'less' : 'more' }} details</span>.
          <div *ngIf="errorsExpanded">
            <div>&nbsp;</div>
            <div *ngFor="let error of errors; let i = index">
              {{ error }}
            </div>
          </div>
        </div>
      </div>
      <div class="qe-survey-flow-div custom-scroll-bar" #scrollMe>
        <div class="fullWidth">
          <div class="qe-survey-flow-content">
            <div [hidden]="!showQuestionList" class="qe-question-container">
              <app-questions #questions></app-questions>
            </div>
            <div [hidden]="showQuestionList" class="qe-question-container">
              <app-survey-flow #routing [editor]="this"></app-survey-flow>
            </div>
          </div>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
