import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MessageService, MessageModel } from "../../message.Service";
import { SecurityKey, AuthenticationService } from "../../authentication.Service";
import { SmsEditorComponent } from '../sms-editor/sms-editor.component';

@Component({
  selector: 'app-send',
  templateUrl: './send.component.html',
  styleUrls: ['./send.component.css']
})
export class SendComponent implements OnInit {
  formGroup: FormGroup;
  keys: SecurityKey[] = [];
  response: string = "";
  timeout = null;
  length = 0;
  segments = 0;
  isGsm: boolean = false;
  isUnicode: boolean = false;
  keysControl: FormControl = new FormControl();
  message: string = "";

  @ViewChild('sms') public sms: SmsEditorComponent | undefined;

  constructor(private messageService: MessageService,
    private authService: AuthenticationService) {
    this.formGroup = new FormGroup({
      Keys: this.keysControl,
      Type: new FormControl("sms"),
      Source: new FormControl(),
      Destination: new FormControl(),
      Message: new FormControl(),
      Flags: new FormControl(),
    });
  }

  ngOnInit(): void {
    this.authService.getSecurityKeys('').subscribe(result => this.keys = result);
  }

  isSms(): boolean {
    return this.formGroup.controls.Type.value == "sms";
  }

  sendMessage() {
    let model = new MessageModel();
    model.KeyId = this.keysControl.value.KeyId;
    model.Source = this.isSms() ? this.formGroup.value.Source : "DUMMY";
    model.Destination = this.formGroup.value.Destination;
    model.Message = this.sms?.text ?? "";
    model.Type = this.formGroup.value.Type;
    this.response = "";
    if (this.formGroup.value.Flags !== null && this.formGroup.value.Flags !== undefined) {
      if (this.formGroup.value.Flags.find((flag: any) => flag == "reply") !== undefined) {
        model.Reply = true;
      }
      if (this.formGroup.value.Flags.find((flag: any) => flag == "test") !== undefined) {
        model.Test = true;
      }
      if (this.formGroup.value.Flags.find((flag: any) => flag == "lookup") !== undefined) {
        model.Lookup = true;
      }
    }

    this.messageService.send(model).subscribe(result => {
      this.response = JSON.stringify(result, undefined, 2)
    });
  }
}
