<mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
  <mat-tab label="Summary">
    <app-survey-summary [analysis]="this" [modelChecked]="modelChecked"></app-survey-summary>
  </mat-tab>
  <mat-tab *ngIf="showDataAnalysis()" label="Data">
    <app-basic-data [analysis]="this" [modelChecked]="modelChecked"></app-basic-data>
  </mat-tab>
  <mat-tab *ngIf="showSmsInsights()" label="Message Insights">
    <app-message-history [survey]="survey"></app-message-history>
  </mat-tab>
  <mat-tab *ngIf="showDataAnalysis() && questionTexts.length > 0" label="Text Analysis">
    <app-text-analysis [analysis]="this"></app-text-analysis>
  </mat-tab>
  <mat-tab *ngIf="showDataAnalysis()" label="Reporting">
    <app-reports></app-reports>
  </mat-tab>
  <mat-tab label="Import/Export">
    <app-automations [analysis]="this"></app-automations>
  </mat-tab>
</mat-tab-group>
