<div class="user-details-dialog-container">
  <div class="leftFloat">
    <h3>Preview</h3>
  </div>
  <div class="rightFloat">
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
    <div class="clearBoth"></div>
    <div mat-dialog-content class="intrvw-prvw-dlg-content">
      <div *ngIf="error" class="margin-10">
        <div class="alert alert-danger row-flex align-center-start-flex" role="alert" div>
          <div><mat-icon>warning</mat-icon></div>
          <div>
            <strong>&nbsp;There was a problem running the survey.</strong>
          </div>
        </div>
        <div *ngIf="isTester()" class="alert alert-secondary column-flex align-center-start-flex" role="alert" div>
          <div>
            <br />
            <div [innerHTML]="errorMsg"></div>
          </div>
          <div>
            &nbsp;
          </div>
          <div>
            For more information <a (click)="getDetails()">click here</a>
          </div>
          <div>
            To Report it download <a (click)="download()">this file</a> and attach it to a <a href="https://github.com/OnePointGlobal/Frodo/issues" target="_blank"><img class="intrvw-prvw-github-img" src="../../assets/img/Github.png" />&nbsp;GitHub Issue</a>
          </div>
          <div>
            &nbsp;
          </div>
        </div>
        <div [@detailExpand]="edit === true ? 'expanded' : 'collapsed'">
          <div *ngIf="edit === true" class="alert alert-secondary" role="alert">
            <strong>Editor Interview Model</strong>&nbsp;<button mat-icon-button (click)="copyModel()"><mat-icon>content_copy</mat-icon></button>
            <pre class="intrvw-prvw-json-string">{{jsonString}}</pre>
          </div>
          <div *ngIf="edit === true" class="alert alert-secondary" role="alert">
            <strong>OneScript Source</strong>&nbsp;<button mat-icon-button (click)="copyScript()"><mat-icon>content_copy</mat-icon></button>
            <div [innerHTML]="errorDetail" class="intrvw-prvw-onescript-errorDtl"></div>
          </div>
        </div>
      </div>
      <div *ngIf="isEmbed && !error">
        <h3>Embedded Data</h3>
        <div [formGroup]="embedForm">
          <div *ngFor="let question of embeddedQuestions; let i = index" formArrayName="values">
            <div class="fieldRow" [formGroupName]="i">
              <div>
                {{question.QuestionName}}&nbsp;
              </div>
              <div style="margin-bottom:3px">
                <mat-form-field appearance="outline">
                  <mat-label> Value </mat-label>
                  <input matInput placeholder="Value" formControlName="value">
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="!started" style="margin-top: 5px">
          <button mat-flat-button color="primary" (click)="restartSurvey()">Start</button>&nbsp;
          <button mat-stroked-button (click)="showScript()">Show Script</button>&nbsp;
        </div>
      </div>
      <div *ngIf="!error && model" style="margin-top: 5px">
        <div class="mb-5 fullWidth row-flex align-center-center-flex">
          <div class="auto-flex">
            <button mat-flat-button color="primary" (click)="restartSurvey()">Restart</button>&nbsp;
            <button mat-stroked-button (click)="showScript()">Show Script</button>&nbsp;
          </div>
          <div *ngIf="!isReview && !isSms" class="auto-flex">
            <mat-button-toggle-group name="device" [(ngModel)]="deviceType" aria-label="Device Type">
              <mat-button-toggle value="desktop" (click)="runOnDesktop()">
                <mat-icon>desktop_windows</mat-icon>
              </mat-button-toggle>
              <mat-button-toggle value="tablet" (click)="runOnTablet()">
                <mat-icon>tablet_mac</mat-icon>
              </mat-button-toggle>
              <mat-button-toggle value="mobile" (click)="runOnMobile()">
                <mat-icon>phone_android</mat-icon>
              </mat-button-toggle>
              <mat-button-toggle value="sms" (click)="runAsSms()">
                <mat-icon>sms</mat-icon>
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div *ngIf="!isSms" class="intrvw-prvw-player-div fullWidth">
          <div class="display-flex align-center-center-flex fullWidth">
            <div class="intrvw-prvw-preview custom-scroll-bar intrvw-prvw-player-container" [ngStyle]='{ width : previewWidth, overflow : horizontalscroll}'>
              <diy-player class="scroller" #player [show]="show" [model]="model" [preview]="false" (navigate)="navigateQuestions($event)" (changeLanguage)="languageChanged($event)" [playerWidth]="width" [ngClass]="selectedTheme"></diy-player>
            </div>
          </div>
        </div>
        <div *ngIf="isSms" class="intrvw-prvw-player-div fullWidth">
          <div class="column-flex align-center-center-flex fullWidth">
            <div #scrollMe class="intrvw-prvw-messageWindow column-flex">
              <div class="intrvw-prvw-imessage" *ngFor="let message of messages; let i = index">
                <p *ngIf="whenFormatted(i) !=''" class="intrvw-prvw-messageDate">
                  {{ whenFormatted(i) }}
                </p>
                <p *ngIf="message.Type == 1" class="intrvw-prvw-from-them">
                  {{ message.Message }}
                </p>
                <p *ngIf="message.Type == 2" class="intrvw-prvw-from-me">
                  {{ message.Message }}
                </p>
                <div *ngIf="i + 1 == messages.length">{{ scrollToBottom(i) }}</div>
              </div>
            </div>
            <div [formGroup]="form" class="intrvw-prvw-txtMsg-div">
              <mat-form-field appearance="outline" class="fullWidth">
                <mat-label>Text Message</mat-label>
                <div class="row-flex">
                  <div class="wd-90-per">
                    <textarea matInput formControlName="message" class="scroll fullWidth"
                              cdkTextareaAutosize
                              #autosize="cdkTextareaAutosize"
                              cdkAutosizeMinRows="1"
                              cdkAutosizeMaxRows="10"></textarea>
                  </div>
                  <div class="display-flex align-center-start-flex">
                    <button mat-icon-button color="primary" (click)="sendSms()">
                      <mat-icon>arrow_circle_up</mat-icon>
                    </button>
                  </div>
                </div>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #scriptDialog>
      <div class="user-details-dialog-container">
        <div class="leftFloat">
          <h3>OneScript</h3>
        </div>
        <div class="rightFloat">
          <button mat-icon-button (click)="copyScript()" matTooltip="Copy to Clipboard"><mat-icon>content_copy</mat-icon></button>
          <button mat-icon-button matDialogClose="no" matTooltip="Close" matTooltipPosition="below">
            <mat-icon class="user-details-dialog-close-icon">close</mat-icon>
          </button>
        </div>
      </div>
      <div>
        <div [innerHTML]="errorDetail" class="intrvw-prvw-onescript-errorDtl" style="height:100%"></div>
      </div>
    </ng-template>

