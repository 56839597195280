<app-toolbar></app-toolbar>
<div class="forgot-pwd-main-div">
  <mat-card class="forgot-pwd-card">
    <form class="forgot-pwd-form" [formGroup]="formGroup" (ngSubmit)="onSubmit()">
      <h2>Reset your password</h2>
      <div *ngIf="displayError">
        <div class="alert alert-danger" role="alert">
          {{errorMsg}}
        </div>
      </div>
      <p class="txt-align-center">
        Enter your user account's verified email address and we will send you a password reset link.
      </p>
      <div *ngIf="displaySuccess">
        <div class="alert alert-success" role="alert">
          Please check your email for a password reset link
        </div>
      </div>
      <div class="fieldRowWithPadding">
        <div class="forgot-pwd-email-div">
          <mat-form-field appearance="outline" class="fullWidth">
            <mat-label>Enter your Email Address</mat-label>
            <input matInput placeholder="Enter your Email Address" formControlName="username">
          </mat-form-field>
        </div>
        <div>
          <button mat-raised-button type="submit" color="primary">Submit</button>
        </div>
      </div>
    </form>
  </mat-card>
</div>
