<h2>Data Import/Export Processes</h2>
<div class="mb-5">
  <button mat-raised-button color="primary" (click)="loadProcesses()">Refresh</button>&nbsp;
</div>
<div *ngIf="processes?.length > 0" class="outer-table-container mat-elevation-z8">

  <div class="inbox-table-container custom-scroll-bar">
    <table mat-table #table [dataSource]="dataSource"
           multiTemplateDataRows
           matSort
           matSortActive="created"
           matSortDisableClear matSortDirection="desc">

      <!-- Name Column -->
      <ng-container matColumnDef="icon">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
        <td mat-cell *matCellDef="let process">
          <div class="dataIO-upld-content">
            <div class="dataIO-upld-icon-div">
              <mat-icon class="dataIO-upld-icon">{{isExport(process) ? 'download' : 'upload'}}</mat-icon>
            </div>
            <div class="dataIO-schedule-div">
              <mat-icon *ngIf="automate" class="dataIO-automatedIcon">schedule</mat-icon>
            </div>
          </div>
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let process"> {{process.Name}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="survey">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let process"> {{process.surveySummary?.Name}} </td>
      </ng-container>

      <!-- Created Column -->
      <ng-container matColumnDef="amended">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Updated </th>
        <td mat-cell *matCellDef="let process"> {{process.LastUpdated | date:'shortDate': '' : translate.currentLang}} {{process.LastUpdated | date:'mediumTime': '' : translate.currentLang}} </td>
      </ng-container>

      <!-- Respondent count Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
        <td mat-cell *matCellDef="let process">
          <div><strong>{{process.StatusDescription}}</strong>&nbsp;{{process.StatusDescription == 'In progress' ?'(' + process.Progress + '%)' : ''}}</div>
        </td>
      </ng-container>

      <!-- Profile Element Column -->
      <ng-container matColumnDef="errors">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
        <td mat-cell *matCellDef="let process"> {{process.errors?.length > 0 ? process.errors.length + (process.StatusDescription == "Idle" ? " Previous" : "") + " Errors" : "There are no errors"}} </td>
      </ng-container>

      <!-- Profile Element Column -->
      <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Owner </th>
        <td mat-cell *matCellDef="let process"> {{process.userProfile?.UserName ?? process.OwnerId}} </td>
      </ng-container>


      <!-- Action column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td mat-cell *matCellDef="let process">
          <div>
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="icon-button with a menu" (click)="$event.stopPropagation()">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="login(process)">
                <mat-icon>login</mat-icon>
                <span>Log into Account</span>
              </button>
              <mat-divider></mat-divider>
              <button mat-menu-item (click)="delete(process)">
                <mat-icon>delete</mat-icon>
                <span>Delete</span>
              </button>
            </mat-menu>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let process" [attr.colspan]="columnsToDisplay.length" class="padding-0">
          <div class="dataIO-element-detail custom-scroll-bar"
               [@detailExpand]="process == expandedElement ? 'expanded' : 'collapsed'">
            <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
              <mat-tab label="Messages">
                <div class="margin-10">
                  <div *ngIf="process.messages?.length == 0">
                    <div>
                      There is no activity recorded for this definition
                    </div>
                  </div>
                  <div *ngFor="let error of process.messages; let i = index;" class="fieldRowWithPadding">
                    <div>
                      <mat-icon>check_circle</mat-icon>
                    </div>
                    <div>
                      {{error.Created | date:'short': '' : translate.currentLang}}&nbsp;&nbsp;
                    </div>
                    <div>
                      {{errorMessageDate(error.Message) == null ? '' : 'From ' + (errorMessageDate(error.Message) | date:'short': '' : translate.currentLang) + ', '}}
                      {{errorMessageValue(error.Message)}}
                    </div>
                  </div>
                </div>
              </mat-tab>
              <mat-tab label="Errors">
                <div class="margin-10">
                  <div *ngIf="process.errors?.length == 0">
                    <div>
                      There is no activity recorded for this definition
                    </div>
                  </div>
                  <div *ngFor="let error of process.errors; let i = index;" class="fieldRowWithPadding">
                    <div>
                      <mat-icon color="warn">warning</mat-icon>
                    </div>
                    <div>
                      {{error.Created | date:'short': '' : translate.currentLang}}&nbsp;&nbsp;
                    </div>
                    <div *ngIf="error.TypeId == 0">
                      {{error.Line}}:{{error.Col}}&nbsp;&nbsp;
                    </div>
                    <div>
                      {{error.Message}}
                    </div>
                  </div>
                </div>
              </mat-tab>
            </mat-tab-group>

          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
      <tr mat-row *matRowDef="let process; columns: columnsToDisplay;"
          class="dataIO-element-row"
          [class.dataIO-expanded-row]="expandedElement === process"
          (click)="expandedElement = expandedElement === process ? null : process">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="dataIO-detail-row"></tr>
    </table>
  </div>
  <mat-paginator [pageSize]="pageSize" [length]="resultsLength"></mat-paginator>
</div>
