import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { DataAnalysisComponent } from '../data-analysis.component';
import { CustomFormat, Field, Line, ProcessService, ProcessServiceModel } from '../../process.Service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { plainToClass } from 'class-transformer';
import { Schedule } from '../../scheduler/scheduler.component';
import { ProcessDefinitionEvent } from '../process-definition/process-definition.component';

@Component({
  selector: 'app-automations',
  templateUrl: './automations.component.html',
  styleUrls: ['./automations.component.css']
})
export class AutomationsComponent {
  selectedIndex: number = 0;
  details: any = [
    { name: 'Active', description: 'Active ', processes: [] = [] },
    { name: 'Definitions', description: 'Definitions', proceses: [] = [] },
    { name: 'Completed', description: 'Completed', processes: [] = [] },
    { name: 'Failures', description: 'Failures', processes: [] = [] },
    { name: 'Abandoned', description: 'Abandoned', processes: [] = [] }
  ];
  dialogRef: MatDialogRef<any, any>;

  @ViewChild('importDataDialog') dataDefinitionDialog: TemplateRef<any>;

  constructor(private processService: ProcessService,
    public dialog: MatDialog) {
  }

  addProcess(process: ProcessServiceModel) {
    this.details[1].processes.splice(0, 0, process);
    this.selectedIndex = 1;
  }

  newProcess(isExport: boolean, automate: boolean) {
    let process = new ProcessServiceModel();

    // Basic details
    let schedule = new Schedule("", new Date(), new Date(), "");
    process.Cron = schedule.cron;
    process.Start = schedule.start;
    process.Finish = schedule.end;
    process.Timezone = schedule.timezone;
    process.IsDeleted = false;
    process.Name = this.analysis.survey.Name;

    process.ProcessTypeId = isExport ? 2 : 0;
    process.Task.Entity = isExport ? "Invites" : "SurveyPanel";

    // file Transfer Information
    process.Task.Type = isExport ? "EXPORT" : "IMPORT";
    process.Task.FileTransfer.Type = automate ? "SFTP" : "SPECIFIC";
    process.Task.FileTransfer.Passive = false;
    process.Task.FileTransfer.Ssl = false;
    process.Task.FileTransfer.Port = 22;

    process.ReferenceId = process.Task.ReferenceID = this.analysis.survey.SurveyID;
    process.Task.Rules = null;
    process.Task.SplitMultiValueFields = true;
    process.Task.RemoveLineBreaks = true;
    process.Task.Timings = false;
    process.Task.SystemData = false;
    process.Task.Format = "CSV";
    process.Task.UseNumericValues = false;
    process.Task.AllData = true;
    process.Task.Start = new Date();
    process.Task.Finish = new Date();
    process.Task.Automated = automate;
    process.Task.Country = "";
    process.Task.Number = "";
    process.Task.Direction = 0;
    process.Task.Statuses = [];
    process.Task.Content = "";
    process.Task.IncludePanelData = false;
    this.addProcess(process);
  }

  copyProcess(oldProcess: ProcessServiceModel) {
    let process = new ProcessServiceModel();

    // Basic details
    process.ProcessId = 0;
    process.Cron = oldProcess.Cron;
    process.Start = process.Finish = new Date();
    process.Timezone = oldProcess.Timezone;
    process.IsDeleted = false;
    process.Name = oldProcess.Name;

    process.ProcessTypeId = oldProcess.ProcessTypeId;
    process.Task.Entity = oldProcess.Task.Entity;

    // file Transfer Information
    process.Task.Type = oldProcess.Task.Type;
    process.Task.FileTransfer.Type = oldProcess.Task.FileTransfer.Type;
    process.Task.FileTransfer.Passive = oldProcess.Task.FileTransfer.Passive;
    process.Task.FileTransfer.Ssl = oldProcess.Task.FileTransfer.Ssl;
    process.Task.FileTransfer.Port = oldProcess.Task.FileTransfer.Port;
    process.Task.FileTransfer.LocalPath = oldProcess.Task.FileTransfer.LocalFolder;
    process.Task.FileTransfer.Server = oldProcess.Task.FileTransfer.Server;
    process.Task.FileTransfer.Username = oldProcess.Task.FileTransfer.Username;
    process.Task.FileTransfer.Password = oldProcess.Task.FileTransfer.Password;
    process.Task.FileTransfer.ServerFolder = oldProcess.Task.FileTransfer.ServerFolder;
    process.Task.FileTransfer.LocalFolder = oldProcess.Task.FileTransfer.LocalFolder;
    process.Task.FileTransfer.File = oldProcess.Task.FileTransfer.File;
    process.Task.FileTransfer.Pattern = oldProcess.Task.FileTransfer.Pattern;
    process.Task.FileTransfer.Ssl = oldProcess.Task.FileTransfer.Ssl;

    process.ReferenceId = oldProcess.ReferenceId;
    process.Task.ReferenceID = oldProcess.Task.ReferenceID;
    process.Task.Rules = oldProcess.Task.Rules;
    process.Task.SplitMultiValueFields = oldProcess.Task.SplitMultiValueFields;
    process.Task.RemoveLineBreaks = oldProcess.Task.RemoveLineBreaks;
    process.Task.Timings = oldProcess.Task.Timings;
    process.Task.SystemData = oldProcess.Task.SystemData;
    process.Task.Format = oldProcess.Task.Format;
    process.Task.UseNumericValues = oldProcess.Task.UseNumericValues;
    process.Task.AllData = oldProcess.Task.AllData;
    process.Task.Start = process.Task.Finish = new Date();
    process.Task.From = false;
    process.Task.Automated = oldProcess.Task.Automated;
    process.Task.Headers = oldProcess.Task.Headers;
    process.Task.IncludePanelData = oldProcess.Task.IncludePanelData;

    process.Task.CustomFormat = new CustomFormat();
    process.Task.CustomFormat.Lines = [];
    process.Task.CustomFormat.Header = oldProcess.Task.CustomFormat.Header;
    process.Task.CustomFormat.Lines.push(new Line());
    for (let i = 0; i < oldProcess.Task.CustomFormat.Lines[0].Fields.length; i++) {
      let oldField = oldProcess.Task.CustomFormat.Lines[0].Fields[i];
      let field = new Field();
      field.Name = oldField.Name;
      field.Position = oldField.Position;
      field.Terminated = oldField.Terminated;
      field.Optional = oldField.Optional;
      field.EndsWith = oldField.EndsWith;
      field.Size = oldField.Size;
      field.Type = oldField.Type;
      field.ConditionValue = oldField.ConditionValue;
      field.Key = oldField.Key;
      field.Repeat = oldField.Repeat;
      field.Start = oldField.Start;
      field.Length = oldField.Length;
      field.Justification = oldField.Justification;
      field.Alias = oldField.Alias;
      field.Custom = oldField.Custom;
      field.Text = oldField.Text;
      field.Title = oldField.Title;
      field.ReceiptRequest = oldField.ReceiptRequest;
      field.Format = oldField.Format;
      process.Task.CustomFormat.Lines[0].Fields.push(field);
    }

    process.Task.Country = oldProcess.Task.Country;
    process.Task.Number = oldProcess.Task.Number;
    process.Task.Direction = oldProcess.Task.Direction;
    process.Task.Statuses = oldProcess.Task.Statuses;
    process.Task.Content = oldProcess.Task.Content;
    this.addProcess(process);
  }

  showImportDataDefinition() {
    this.dialogRef = this.dialog.open(this.dataDefinitionDialog);
  }

  importDataDefinition($event) {
    this.dialogRef.close();
    this.processService.getImport($event.name).subscribe(
      result => {
        let process = plainToClass(ProcessServiceModel, result);
        process.ProcessId = 0;
        process.ReferenceId = process.Task.ReferenceID = this.analysis.survey.SurveyID;
        process.Start = process.Finish = process.Task.Start = process.Task.Finish = new Date();
        process.Task.From = false;
        this.addProcess(process);
      });
  }

  getProcesses() {
    this.processService.getActiveProcessesOnly(this.analysis?.id ?? 0).subscribe(result => this.details[0].processes = result);
    this.processService.getCompletedProcessesOnly(this.analysis?.id ?? 0).subscribe(
      result => this.details[2].processes = result);
    this.processService.getDefinitions(this.analysis?.id ?? 0).subscribe(
      result => this.details[1].processes = result);
    this.processService.getErrors(this.analysis?.id ?? 0).subscribe(
      result => this.details[3].processes = result);
    this.processService.getAbandoned(this.analysis?.id ?? 0).subscribe(
      result => this.details[4].processes = result);
  }

  processClosed(event: ProcessDefinitionEvent) {
    switch (event.action) {
      case "closed":
        for (let i = 0; i < this.details[this.selectedIndex].processes.length; i++) {
          if (this.details[this.selectedIndex].processes[i].ProcessId == event.process.ProcessId) {
            if (event.process.ProcessId == 0) {
              this.details[this.selectedIndex].processes.splice(i, 1);
              return;
            }
          }
        }

        break;

      case "copy":
        this.copyProcess(event.process);
        break;
      case "saved":
        for (let i = 0; i < this.details[this.selectedIndex].processes.length; i++) {
          if (this.details[this.selectedIndex].processes[i].ProcessId == 0 || this.details[this.selectedIndex].processes[i].ProcessId == event.process.ProcessId) {
            this.details[this.selectedIndex].processes[i] = event.process;
            return;
          }
        }

        break;
      case "delete":
        this.deleteProcess(this.selectedIndex, event.process);
        if (this.selectedIndex == 0 || this.selectedIndex == 3) {
          this.deleteProcess(this.selectedIndex == 0 ? 3 : 0, event.process);
        }

        break;
      case "launched":
        for (let i = 0; i < this.details[this.selectedIndex].processes.length; i++) {
          if (this.details[this.selectedIndex].processes[i].ProcessId == event.process.ProcessId) {
            if (this.selectedIndex != 0) {
              this.details[0].processes.splice(0, 0, process);
              this.details[this.selectedIndex].processes.splice(i, 1);
            }

            return;
          }
        }

        break;
      case "abandon":
        this.abandonProcess(this.selectedIndex, event.process);
        this.details[4].processes.splice(0, 0, event.process);
        this.selectedIndex = 4;
        break;
    }
  }

  abandonProcess(index: number, process: ProcessServiceModel) {
    for (let i = 0; i < this.details[index].processes.length; i++) {
      if (this.details[index].processes[i].ProcessId == process.ProcessId) {
        this.processService.abandonProcess(process).subscribe(result => {
          this.details[index].processes.splice(i, 1);
        });
        return;
      }
    }
  }

  deleteProcess(index: number, process: ProcessServiceModel) {
    for (let i = 0; i < this.details[index].processes.length; i++) {
      if (this.details[index].processes[i].ProcessId == process.ProcessId) {
        this.processService.deleteProcess(process).subscribe(result => {
          this.details[index].processes.splice(i, 1);
        });
        return;
      }
    }
  }

  @Input()
  get analysis(): DataAnalysisComponent | undefined {
    return this._analysis
  }
  set analysis(value: DataAnalysisComponent | undefined) {
    this._analysis = value;
    this.getProcesses();
  }
  private _analysis: DataAnalysisComponent | undefined;
}
