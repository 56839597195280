<mat-card>
  <div class="row-flex panel-pricing-header-div">
    <div class="mr-10">
      <img *ngIf="panel && panel.Summary;" mat-card-sm-image src="assets/img/respondents_2.png">
      <img *ngIf="panel && panel.Summary == undefined;" mat-card-sm-image src="assets/img/buy_2.png">
    </div>
    <div *ngIf="survey?.PanelID === -1;then surveyOnly else panels"></div>
    <div *ngIf="!allowEditDelete && notEnough">
      <button mat-flat-button color="warn" (click)="editCurrentOptions()">{{ edit ? 'Save' : 'Not Enough Funds' }}</button>&nbsp;&nbsp;
    </div>
    <div *ngIf="allowEditDelete && notEnough" class="row-flex boxSizing-borderBox">
      <button mat-flat-button color="warn" (click)="editCurrentOptions()">{{ edit ? 'Save' : 'Not Enough Funds' }}</button>&nbsp;&nbsp;
      <button mat-stroked-button (click)="cancelChoice()">Delete</button>
    </div>
    <div *ngIf="allowEditDelete && !notEnough" class="row-flex boxSizing-borderBox">
      <button mat-stroked-button (click)="editCurrentOptions()">{{ edit ? 'Save' : 'Edit' }}</button>&nbsp;&nbsp;
      <button mat-stroked-button (click)="cancelChoice()">Delete</button>
    </div>
  </div>
  <div [@detailExpand]="edit ? 'expanded' : 'collapsed'">
    <div *ngIf="edit && survey && survey.PanelID == -1;then distribution"></div>
    <div *ngIf="edit && survey && panel?.PanelType == 1;then distribution"></div>
    <div *ngIf="edit && panel && panel?.PanelType == 2" class="row-flex align-start-flex">
      <mat-divider></mat-divider>
      <div class="panel-pricing-congratsMsg-div">
        <div>
          <span>Congratulations you have chosen to use one of our Pre-profiled Respondent Groups. If you change your mind you can delete this choice above and select another option from below.</span>
        </div>
      </div>
      <div *ngIf="edit;then pricing" class="display-flex"></div>
    </div>
    <div *ngIf="edit && panel && panel.PanelType == 0" class="row-flex align-start-flex">
      <mat-divider role="separator" class="mat-divider ng-tns-c331-127 mat-divider-horizontal mr-20" aria-orientation="horizontal"></mat-divider>
      <div class="panel-pricing-editPanel-container">
        <div>
          Refine your selection.
        </div>
        <div>
          <button mat-raised-button color="primary" (click)="editPanel()">Edit Panel</button>
        </div>
      </div>
      <div *ngIf="edit;then pricing" class="display-flex"></div>
    </div>
  </div>
</mat-card>

<ng-template #distribution>
  <div class="column-flex alItems-flex-start">
    <mat-divider class="fullWidth"></mat-divider>
    <div class="row-flex alItems-flex-start fullWidth">
      <div class="halfWidth">
        <app-survey-distribution #surveyDistribution [survey]="survey" [panel]="panel"></app-survey-distribution>
      </div>
      <div *ngIf="edit;then pricing" class="display-flex"></div>
    </div>
  </div>
</ng-template>

<ng-template #pricing>
  <app-pricing-summary #pricingSummary class="halfWidth padding-10" [showBalance]="true" [estimate]="estimate" [panel]="panel" [survey]="survey"></app-pricing-summary>
</ng-template>

<ng-template #surveyOnly>
  <img mat-card-image mat-card-sm-image src="assets/img/your_way_2.png">&nbsp;&nbsp;
  <div class="column-flex fullWidth">
    <div>Send surveys your way</div>
    <div>Make sure you have enough credit in your account to cover the estimated number of completes.</div>
  </div>
</ng-template>

<ng-template #panels>
  <div *ngIf="panel && panel.Summary;then yourPanel else cintPanel"></div>
</ng-template>

<ng-template #yourPanel>
  <div class="column-flex align-start-flex wd-90-per">
    <div>{{name}} - {{panel?.PanellistCount}} respondent(s) with {{panel?.Summary?.Emails}} email address(es) and {{panel?.Summary?.Devices}} Mobile Devices</div>
    <div>Total: £{{estimate.Total | number:'1.2'}}</div>
  </div>
</ng-template>

<ng-template #cintPanel>
  <div *ngIf="panel && panel.PanelType === 2;then yourCintPanel else brandPanel"></div>
</ng-template>

<ng-template #brandPanel>
  <div *ngIf="panel && estimate" class="column-flex align-start-flex wd-90-per">
    <div>{{name}} - {{estimate.Respondents}} respondent(s) that will automatically be contacted for their feedback</div>
    <div>Total: £{{estimate.Total | number:'1.2'}}</div>
  </div>
</ng-template>

<ng-template #yourCintPanel>
  <div *ngIf="panel" class="column-flex align-start-flex wd-90-per">
    <div>{{name}} - {{estimate.Respondents}} respondent(s) that will automatically be contacted for their feedback</div>
    <div>Total: £{{estimate.Total | number:'1.2'}}</div>
  </div>
</ng-template>

