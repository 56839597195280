import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService, SecurityKey } from '../authentication.Service';
import { MessageModel, MessageService } from '../message.Service';

@Component({
  selector: 'app-development',
  templateUrl: './development.component.html',
  styleUrls: ['./development.component.css']
})
export class DevelopmentComponent implements OnInit {

  temp: Observable<any>;
  showAlert = false;
  formGroup: FormGroup;
  transferType: string = "";

  constructor(private actRoute: ActivatedRoute
  ) {
    this.transferType = this.actRoute.snapshot.params.type;
    this.temp = new Observable();
  }

  ngOnInit(): void {
    this.initForm();
 }

  initForm() {
    this.formGroup = new FormGroup({
    });
  }

  onSubmit() {

  }

}
