import { Component, OnInit } from '@angular/core';
import { MissionService } from "../MissionService";
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { plainToClass } from 'class-transformer';
import { map } from 'rxjs/operators';
import { PanelService } from "../panel.Service";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { NewPanelComponent } from '../new-panel/new-panel.component';
import { SurveyService } from '../survey.Service';
import { EditReportNameComponent } from '../reports/edit-report-name/edit-report-name.component';
import { ReportModel } from '../question.service';
import { NameSurveyFromScratchComponent } from '../name-survey-from-scratch/name-survey-from-scratch.component';
import { AuthenticationService, OrganizationModel } from '../authentication.Service';
import { EditOrgNameComponent } from '../organization-detail/edit-org-name/edit-org-name.component';
import { version } from "../../environments/environment";
import { ChatService } from '../chat.Service';

@Component({
  selector: 'app-edit-toolbar',
  templateUrl: './edit-toolbar.component.html',
  styleUrls: ['./edit-toolbar.component.css'],
  providers: [MissionService]
})
export class EditToolbarComponent implements OnInit {
  confirmed = false;
  announced = false;
  id: string;
  editType: string;
  name: string = "";
  subscription: Subscription | null = null;
  displayName: boolean;
  nameType: string;
  currentVersion: string;
  currentYear = new Date().getFullYear();
  messages = 0;
  organisation: OrganizationModel | null = null;

  constructor(private dialog: MatDialog,
    private missionService: MissionService,
    private actRoute: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private panelService: PanelService,
    private surveyService: SurveyService,
    private authService: AuthenticationService,
    private router: Router,
    private chatService: ChatService
  ) {
    this.chatService.getUnreadMessageCount().subscribe(response => { this.messages = response });
    this.currentVersion = version;
    this.id = this.actRoute.snapshot.params.id;
    this.editType = this.actRoute.snapshot.params.type;
    this.displayName = true;
    this.nameType = "";
    switch (this.editType) {
      case "panel":
        this.nameType = "Panel";
        this.panelService.getPanel(this.id).subscribe(result => { this.name = result.Name; });
        break;
      case "user":
      case "template":
      case "panelist":
      case "webhook":
      case "securitykey":
      case "keyword":
      case "tinyurl":
      case "request":
      case "smshomekey":
      case "smshomekeyword":
      case "smshomewebhook":
      case "network":
      case "basic":
      case "hmac":
      case "profile":
      case "whatsapp":
        this.displayName = false;
        break;
      case "organization":
      case "adminorg":
      case "smshomeorg":
        this.authService.getOrganization(this.id).subscribe(result => { this.organisation = result; this.name = result.Name; });
        break;
      case "survey":
        this.nameType = "Survey";
        this.surveyService.getSurveySummary(Number(this.id)).subscribe(result => { this.name = result.Name; });
        break;
      case "summary":
      case "delivery-summary":
        this.nameType = "Summary";
        this.surveyService.getSurveySummary(Number(this.id)).subscribe(result => { this.name = result.Name; });
        break;
      case "report":
      case "testreport":
        this.nameType = "Report";
        this.surveyService.getSurveySummary(Number(this.id)).subscribe(result => {
          if (result.ReportModel === "") {
            this.name = result.Name;
          }
          else {
            const temp = plainToClass(ReportModel, JSON.parse(result.ReportModel))[0];
            if (temp == null || temp.Title == null || temp.Title == "") {
              this.name = result.Name;
            }
            else {
              this.name = temp.Title;
            }
          }
        });
        break;
    }

    missionService.missionConfirmed$.subscribe(
      () => {
      });
  }

  onClick() {
    if (this.editType === "report" || this.editType === "testreport") {
      this.missionService.announceMission({ data: { action: "back" } });
    }
    else {
      this.missionService.announceMission("Back");
    }
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));

  isHandsetLandscape$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.HandsetLandscape)
    .pipe(map(result => result.matches));

  isHandsetPortrait$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.HandsetPortrait)
    .pipe(map(result => result.matches));

  ngOnInit(): void {
  }

  gotoInbox() {
    this.router.navigate(['../inbox'])
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }

  isTester() {
    return this.authService.isAuthorized(['diy']);
  }

  goToHome() {
    this.surveyService.getSurveyCount()
      .subscribe(response => {
        if (response > 0) {
          this.router.navigate(['../surveydashboard']);
        } else {
          this.router.navigate(['../startfromtemplate']);
        }
      });
  }

  openTerms() {
    const url = this.router.createUrlTree(['/termsofservice'])
    window.open(url.toString(), '_blank')
  }

  openPrivacy() {
    const url = this.router.createUrlTree(['/privacystatement'])
    window.open(url.toString(), '_blank')
  }

  openBasicDialog(): void {
    let dialogRef;
    let opened = false;
    switch (this.editType) {
      case "panel":
        dialogRef = this.dialog.open(NewPanelComponent, { data: { id: this.id } });
        opened = true;
        break;
      case "survey":
      case "summary":
        dialogRef = this.dialog.open(NameSurveyFromScratchComponent, {
          data: { id: this.id }
        });
        opened = true;
        break;
      case "report":
      case "testreport":
        dialogRef = this.dialog.open(EditReportNameComponent, { data: { id: this.id } });
        opened = true;
        break;
      case "organization":
      case "adminorg":
        dialogRef = this.dialog.open(EditOrgNameComponent, { data: { id: this.id, model: this.organisation } });
        opened = true;
        break;
    }

    if (opened) {
      dialogRef?.afterClosed().subscribe(result => {
        if ((this.editType == "survey" || this.editType == "summary") && result == 'no') {
          return;
        }

        if (result) {
          this.name = result.name;
        }

        if (this.editType === "report" || this.editType === "testreport") {
          this.missionService.announceMission({ data: { action: "update", result: result } });
        }
      });
    }
  }
}
