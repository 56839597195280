<div #scroller *ngIf="editor?.interview" cdkDropList class="questionnaire-sidebar-list custom-scroll-bar" (cdkDropListDropped)="drop($event)">
  <div class="questionnaire-sidebar-box" *ngFor="let question of questions; let i = index" (click)="selectQuestion(question.Index, question.ParentIndex)" cdkDrag>
    <div id="option{{i}}" [class]="isSelected(i, question.Index, question.ParentIndex) ? 'questionnaire-sidebar-selected' : 'questionnaire-sidebar-notSelected'">
      <div class="questionnaire-sidebar-custom-placeholder" *cdkDragPlaceholder></div>
      <div *ngIf="question.ParentIndex > -1" class="width-15">

      </div>
      <div class="qLib-icon">
        <mat-icon>{{question.Icon}}</mat-icon>
      </div>
      <div class="questionnaire-sidebar-text">
        <span [innerHTML]="question.Summary"></span>
      </div>
      <div class="width-30">
        <div class="questionnaire-sidebar-hide" cdkDragHandle>
          <mat-icon>drag_indicator</mat-icon>
        </div>
      </div>
    </div>
    <div *ngIf="i + 1 == questions?.length">{{ scrollToSelection() }}</div>
  </div>
</div>
