<span *ngIf="display">{{summary}}</span>
<div *ngIf="!display" [formGroup]="optionFormGroup" class="fullWidth">
  <div formArrayName="questionOption" class="column-flex">
    <div *ngFor="let opt of optionArr.controls; let i = index" [formGroupName]="i" class="column-flex">
      <div class="embed-data-option-div">
        <div *ngIf="i === 0 && shouldDisplayRuleType(0)" class="adv-routing-if-div">If</div>
        <div *ngIf="isSkip(i)" class="padding-20 display-inline-flex"><span> Skip to </span></div>
        <mat-form-field *ngIf="!isSkip(i) && i > 0 && !isError(i) && !isCarry(i)" appearance="outline" class="adv-routing-andOr-field">
          <mat-select formControlName="link">
            <mat-option>None</mat-option>
            <mat-option value="or">Or</mat-option>
            <mat-option value="and">And</mat-option>
          </mat-select>
        </mat-form-field>
        &nbsp;
        <div *ngIf="shouldDisplayRuleType(i) && !isError(i)">
          <mat-form-field appearance="outline" class="routing adv-routing-embed-field">
            <mat-label> Rule Type </mat-label>
            <mat-select formControlName="ruleType">
              <mat-option value="goto" [hidden]="hideGoto(i)">Goto</mat-option>
              <mat-option value="question">Question</mat-option>
              <mat-option value="profile">Profile</mat-option>
              <mat-option value="embed">Embed Data</mat-option>
              <mat-option value="carry" [hidden]="true">Carry Forward</mat-option>
              <mat-option value="skip" [hidden]="true">Skip</mat-option>
              <mat-option value="reply" [hidden]="true">Reply with Message</mat-option>
            </mat-select>
          </mat-form-field>
          &nbsp;
        </div>
        <span *ngIf="isError(i)">When&nbsp;</span>
        <div *ngIf="isError(i)">
          <mat-form-field appearance="outline">
            <mat-label> Error Type </mat-label>
            <mat-select formControlName="condition">
              <mat-option>None</mat-option>
              <mat-option *ngFor="let error of errorTypeForQuestions(i)" [value]="error.errorType">
                {{error.description}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          &nbsp;
        </div>
        <div *ngIf="isError(i) && shouldDisplayErrorMessage(i)">
          <mat-form-field appearance="outline">
            <mat-label> Error Message </mat-label>
            <input matInput placeholder="Error Message" formControlName="answer">
          </mat-form-field>
          &nbsp;
        </div>
        <div *ngIf="isError(i) && shouldDisplayAttempts(i)">
          <mat-form-field appearance="outline">
            <mat-label> Attempts </mat-label>
            <input matInput placeholder="Attempts" formControlName="answer" mask="9*">
          </mat-form-field>
          &nbsp;
        </div>
        <span *ngIf="isError(i)">Goto&nbsp;</span>
        <div *ngIf="isError(i) && (shouldDisplayErrorMessage(i) || shouldDisplayAttempts(i))">
          <mat-form-field appearance="outline" class="adv-routing-embed-field">
            <mat-label> Question </mat-label>
            <mat-select formControlName="questionId">
              <mat-option>None</mat-option>
              <mat-option *ngFor="let question of questionsForErrors(i)" [value]="question.QuestionId">
                {{question.FullName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          &nbsp;
        </div>
        <div *ngIf="shouldDisplayQuestion(i)">
          <mat-form-field appearance="outline" class="adv-routing-question-field">
            <mat-label> Question </mat-label>
            <div class="row-flex">
              <input matInput placeholder="Question" formControlName="questionName">
              <input *ngIf="!isSkip(i)" matInput formControlName="questionElement">
            </div>
            <button matSuffix mat-icon-button aria-label="Clear" class="adv-routing-dropDownArrow-btn" [matMenuTriggerFor]="questionMenu">
              <mat-icon>arrow_drop_down</mat-icon>
            </button>
          </mat-form-field>
          <mat-menu #questionMenu="matMenu">
            <ng-template *ngIf="!isSkip(i)" matMenuContent>
              <button mat-menu-item *ngFor="let question of questionsForRuleType(i, 'route');let j = index" [matMenuTriggerFor]="questionFormatMenu" [matMenuTriggerData]="{question: question, index: i}">
                <span>{{question.FullName}}</span>
              </button>
            </ng-template>
            <ng-template *ngIf="isSkip(i)" matMenuContent>
              <button mat-menu-item *ngFor="let question of questionsForRuleType(i, 'route');let j = index" (click)="questionSelected($event, question, i, '')">
                <span>{{question.FullName}}</span>
              </button>
            </ng-template>
          </mat-menu>
          <mat-menu #questionFormatMenu="matMenu">
            <ng-template matMenuContent let-question="question" let-index="index">
              <button mat-menu-item *ngFor="let format of getFieldFormats(question)" (click)="questionSelected($event, question, index, format)">{{format}}</button>
            </ng-template>
          </mat-menu>
          &nbsp;
        </div>
        <span *ngIf="isSkip(i)">if&nbsp;</span>
        <div *ngIf="this.ruleTypeCheck(i, 'rank') || (isSkip(i) && shouldDisplayOptions(i))">
          <mat-form-field appearance="outline">
            <mat-label> Options </mat-label>
            <mat-select formControlName="options" [multiple]="isMultipleAnswer(i)">
              <!--mat-option *ngIf="!isMultipleAnswer(i)">None</!--mat-option-->
              <mat-option *ngFor="let option of categories(i).List" [value]="option.Name">
                {{categoryName(option.Name)}} - {{option.Label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          &nbsp;
        </div>
        <div *ngIf="isSkip(i) && shouldDisplayCondition(i) && conditionType(i) != null">
          <mat-form-field appearance="outline" class="adv-routing-condition-field">
            <mat-label> Condition </mat-label>
            <mat-select formControlName="condition">
              <mat-option>None</mat-option>
              <mat-option *ngFor="let cond of conditionType(i)?.conditions; let j = index" [value]="cond.id">
                {{cond.value}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          &nbsp;
        </div>
        <div *ngIf="isSkip(i) && shouldDisplayConditionValue(i)">
          <mat-form-field appearance="outline">
            <mat-label> Value </mat-label>
            <input matInput placeholder="Value" formControlName="answer">
          </mat-form-field>
          &nbsp;
        </div>
        <div *ngIf="shouldDisplayEmbeddedData(i)">
          <mat-form-field appearance="outline" class="adv-routing-embed-field">
            <mat-label> Embed </mat-label>
            <mat-select formControlName="questionId">
              <mat-option>None</mat-option>
              <mat-option *ngFor="let question of questionsForRuleType(i, 'embed')" [value]="question.QuestionId">
                {{question.FullName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          &nbsp;
        </div>
        <div *ngIf="!isSkip(i) && shouldDisplayOptions(i)">
          <mat-form-field appearance="outline">
            <mat-label> Options </mat-label>
            <mat-select formControlName="options" [multiple]="isMultipleAnswer(i)">
              <!--mat-option *ngIf="!isMultipleAnswer(i)">None</!--mat-option-->
              <mat-option *ngFor="let option of categories(i).List" [value]="option.Name">
                {{categoryName(option.Name)}} - {{option.Label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          &nbsp;
        </div>
        <div *ngIf="!isSkip(i) && shouldDisplayCondition(i) && conditionType(i) != null">
          <mat-form-field appearance="outline" class="adv-routing-condition-field">
            <mat-label> Condition </mat-label>
            <mat-select formControlName="condition">
              <mat-option>None</mat-option>
              <mat-option *ngFor="let cond of conditionType(i)?.conditions; let j = index" [value]="cond.id">
                {{cond.value}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          &nbsp;
        </div>
        <div *ngIf="!isSkip(i) && shouldDisplayConditionValue(i)">
          <mat-form-field appearance="outline">
            <mat-label> Value </mat-label>
            <input matInput placeholder="Value" formControlName="answer">
          </mat-form-field>
          &nbsp;
        </div>
        <div>
          <button class="padding-0" mat-icon-button matTooltip="Remove rule" matTooltipPosition="above" [hidden]="!optionValid(i)" (click)="deleteCondition(i)">
            <mat-icon>remove_circle_outline</mat-icon>
          </button>
          <button class="padding-0" mat-icon-button matTooltip="Add new rule" matTooltipPosition="above" [hidden]="!optionValid(i)" (click)="addNewCondition(i)">
            <mat-icon>add_circle_outline</mat-icon>
          </button>
        </div>
      </div>
      <div *ngIf="showAddRemove" class="mt-2">
        <span mat-line-end>
          <button *ngIf="i === 0" mat-button (click)="onDeleteRule()">Remove Rule</button>
        </span>
      </div>
    </div>
    <div *ngIf="shouldDisplayAction()">
      <button mat-button (click)="onAddAction()">Add Action</button>
    </div>
  </div>
</div>
