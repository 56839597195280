<div>
  <div *ngIf="showDisplayLogic()" class="alert alert-primary adv-prop-ruleLayout" role="alert">
    <mat-icon>account_tree</mat-icon>
    <div>
      <button mat-icon-button [matMenuTriggerFor]="advMenu" aria-label="icon-button with a menu">
        <mat-icon class="dialog-close-icon">more_vert</mat-icon>
      </button>
      <mat-menu #advMenu="matMenu">
        <button mat-menu-item (click)="displayLogic()">Edit</button>
        <button mat-menu-item (click)="deleteDisplayLogic()">Delete</button>
      </mat-menu>
    </div>
    {{model?.DisplayLogic?.buildSummary("Display ")}}
  </div>
  <div *ngIf="showSkipLogic()" class="alert alert-primary fieldRow adv-prop-skipLogic-div" role="alert">
    <mat-icon>low_priority</mat-icon>
    <div>
      <button mat-icon-button [matMenuTriggerFor]="advSkipMenu" aria-label="icon-button with a menu">
        <mat-icon class="dialog-close-icon">more_vert</mat-icon>
      </button>
      <mat-menu #advSkipMenu="matMenu">
        <button mat-menu-item (click)="skipLogic()">Edit</button>
        <button mat-menu-item (click)="deleteSkipLogic()">Delete</button>
      </mat-menu>
    </div>
    {{model?.SkipLogic?.buildSummary("Skip ")}}
  </div>
  <div *ngIf="showCarryForwardLogic()" class="alert alert-primary adv-prop-ruleLayout" role="alert">
    <div>
      <mat-icon class="survey-flow-qn-icon">read_more</mat-icon>
    </div>
    <div>
      <button mat-icon-button [matMenuTriggerFor]="advCFMenu" aria-label="icon-button with a menu">
        <mat-icon class="dialog-close-icon">more_vert</mat-icon>
      </button>
      <mat-menu #advCFMenu="matMenu">
        <button mat-menu-item (click)="carryForwardLogic()">Edit</button>
        <button mat-menu-item (click)="deleteCarryForwardLogic()">Delete</button>
      </mat-menu>
    </div>
    <div>
      {{model?.CarryForward?.buildSummary("Carry Forward ")}}
    </div>
  </div>
  <div *ngIf="showAnswerChoiceDisplayLogic()" class="alert alert-primary adv-prop-ruleLayout" role="alert">
    <mat-icon>filter_list</mat-icon>
    <div>
      <button mat-icon-button [matMenuTriggerFor]="advCDMenu" aria-label="icon-button with a menu">
        <mat-icon class="dialog-close-icon">more_vert</mat-icon>
      </button>
      <mat-menu #advCDMenu="matMenu">
        <button mat-menu-item (click)="answerChoiceDisplayLogic()">Edit</button>
        <button mat-menu-item (click)="deleteAnswerChoiceDisplayLogic()">Delete</button>
      </mat-menu>
    </div>
    {{model?.AnswerChoiceLogic?.buildSummary("Display Choices ")}}
  </div>
  <div *ngIf="showErrorLogic()" class="alert alert-primary adv-prop-ruleLayout" role="alert">
    <mat-icon>error_outline</mat-icon>
    <div>
      <button mat-icon-button [matMenuTriggerFor]="advErrorMenu" aria-label="icon-button with a menu">
        <mat-icon class="dialog-close-icon">more_vert</mat-icon>
      </button>
      <mat-menu #advErrorMenu="matMenu">
        <button mat-menu-item (click)="errorLogic()">Edit</button>
        <button mat-menu-item (click)="deleteErrorLogic()">Delete</button>
      </mat-menu>
    </div>
    {{model?.ErrorLogic?.buildSummary("Error Handling ")}}
  </div>
</div>
