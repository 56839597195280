import { Component, OnInit, ViewChild, ElementRef, Input, Optional, Self, OnDestroy, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, NgControl, ControlValueAccessor } from '@angular/forms';
import { FocusMonitor } from '@angular/cdk/a11y';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from "rxjs";
import { RangeBox } from "../range-box/range-box.component";

export class Category {
  questionId: number;
  id: number;
  name: string;
  set: boolean;
  amount: number;
  range: RangeBox;

  constructor(questionId: number, id: number, name: string, set: boolean, min: number, max: number, amount: number) {
    this.questionId = questionId;
    this.id = id;
    this.name = name;
    this.set = set;
    this.amount = amount;
    this.range = new RangeBox(min, max);
  }
}

@Component({
  selector: 'category-selector',
  templateUrl: './category-selector.component.html',
  styleUrls: ['./category-selector.component.css']
})
export class CategorySelectorComponent implements OnInit, OnChanges, ControlValueAccessor, MatFormFieldControl<Category[]>, OnDestroy {
  items?: FormArray;
  form: FormGroup = new FormGroup({});;
  list: Category[] = [];
  stateChanges = new Subject<void>();
  onChange = (_: any) => {};
  onTouched = () => {};
  disabled: boolean = false;
  ready = false;

  constructor(@Optional() @Self() public ngControl: NgControl,
    private _focusMonitor: FocusMonitor,
    private formBuilder: FormBuilder
  ) {
    this.form = this.formBuilder.group({
      items: this.formBuilder.array([this.createItem(new Category(0, 0, "temp", false, 0, 0,0))])
    });
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.onChange(changes);
  }

  id: string = "";
  placeholder: string = "";
  focused: boolean = false;
  empty: boolean = true;
  shouldLabelFloat: boolean = true;
  required: boolean = false;
  errorState: boolean = false;
  controlType?: string = "";
  autofilled?: boolean;

  get itemArr(): FormArray {
    return this.form.controls.items as FormArray;
  }

  itemFormGroup(index: number): FormGroup {
    return this.itemArr.controls[index] as FormGroup;
  }

  setDescribedByIds(ids: string[]): void {
  }

  onContainerClick(event: MouseEvent): void {
  }

  writeValue(obj: Category[] | null): void {
    this.value = obj;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  @Input()
  get categoryList(): Category[] {
    return this.list;
  }

  set categoryList(categories: Category[] | null) {
    this.ready = false;
    this.list = categories ?? [];
    this.items = this.form.get('items') as FormArray;
    this.items.clear();
    let i = 0;
    this.list.forEach(item => {
      this.items?.push(this.createItem(item));
    });
    this.ready = true;
  }

  @Input()
  get value(): Category[] {
    return this.categoryList;
  }

  set value(selected: Category[] | null) {
    this.categoryList = selected;
  }

  @Input()
  get selected(): Category[] {
    const select: Category[] = [];
    this.list.forEach(item => {
      if (item.set) {
        select.push(item);
      }
    });
    return select;
  }

  @Output()
  change: EventEmitter<Category> = new EventEmitter<Category>();

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.stateChanges.complete();
  }

  createItem(category: Category): FormGroup {
    return this.formBuilder.group({
      cat: new FormControl(category.set),
      name: new FormControl(category.name)
    });
  }

  selectAll($event: any) {
    this.setValues(true);
  }

  deselectAll($event: any) {
    this.setValues(false);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }


  setValues(selected: boolean) {
    this.list.forEach(cat => {
      cat.set = selected;
      this.change.emit(cat);
    });
    this.items = this.form.get('items') as FormArray;
    this.items.controls.forEach(item => {
      let control = item as FormGroup;
      control.controls['cat'].setValue(selected);
    });
  }

  onClick($event: any, name: string) {
    for (let i = 0; i < this.list.length; i++) {
      if (this.list[i].name === name) {
        this.list[i].set = !this.list[i].set;
        return this.change.emit(this.list[i]);
      }
    }
  }
}
