import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../authentication.Service';
import { ChatMessage, ChatService } from '../../chat.Service';
import { UserProfile } from '../../user.Service';
import { ComposeMessageComponent } from '../compose-message/compose-message.component';

@Component({
  selector: 'app-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.css']
})
export class InboxComponent implements OnInit {

  resultsLength = 0;
  searchKey: string = "";
  noMessages = true;
  messages: ChatMessage[] = [];
  dataSource = new MatTableDataSource<ChatMessage>(this.messages);
  isAdmin = false;
  messagePageSize: number = 20;
  filter: string = "";
  fromUsers: UserProfile[] = [];

  @ViewChild('table', { static: true }) table: any | undefined;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | undefined;
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    if (this.dataSource) {
      this.dataSource.sort = value;
    }
  }

  columnsToDisplay: string[] = ['from', 'subject', 'created', 'action'];

  constructor(public translate: TranslateService, public dialog: MatDialog,
    private datePipe: DatePipe,
    private chatService: ChatService,
    private authService: AuthenticationService) {
    this.getMessages();
    this.isAdmin = this.authService.isAuthorized(['Admin']);
  }

  getMessages() {
    this.chatService.getMessages().subscribe(
      result => {
        this.messages = result;
        this.loadMessages();
      }
    );
  }

  loadMessages() {
    this.dataSource = new MatTableDataSource(this.messages);
    if (this.paginator != undefined) {
      this.dataSource.paginator = this.paginator;
    }

    this.dataSource.sort = this.sort;
    this.dataSource.filter = this.searchKey;
    this.resultsLength = this.messages.length;
    this.noMessages = this.resultsLength === 0;
  }

  ngOnInit(): void {
  }

  from(message: ChatMessage) {
    if (message.From == undefined) {
      message.From = new UserProfile();
      this.chatService.getFromUser(message.ChatMessageId).subscribe(result => message.From = result);
    }

    let temp = message.From.UserName + " (" + message.From.FirstName + " " + message.From.LastName + ")";
    if (!message.IsRead) {
      temp = "<strong>" + temp + "</strong>";
    }

    return temp;
  }

  date(message: ChatMessage) {
    let date = new Date();
    let diffTime = Math.abs(date.getTime() - new Date(message.Created).getTime());
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays == 0) {
      return this.datePipe.transform(message.Created, 'h:mm a');
    }

    if (diffDays < 7) {
      return this.datePipe.transform(message.Created, 'EEEE, h:mm a');
    }

    return this.datePipe.transform(message.Created, 'dd/MM/YYYY, h:mm a');
  }

  messageDescription(message: ChatMessage) {
    let subject = message.Subject;
    subject += "<div style='white-space:wrap; overflow:hidden; text-overflow: ellipsis;'>"
    if (!message.IsRead) {
      subject = "<strong>" + subject + "</strong>";
    }

    let content = message.Content.replace(/<\/?[^>]+(>|$)/g, "").replace("&nbsp;", " ").replace("&amp;", "&");
    if (content.length > 200) {
      subject += content.substring(0, 200);
    }
    else {
      subject += content;
    }

    subject += "</div>";

    return subject;
  }

  newMessageDialog(): void {
    const dialogRef = this.dialog.open(ComposeMessageComponent,
      {
        data: { id: 0 },
        width: '90%'
      });
    dialogRef.afterClosed().subscribe(result => {
      this.getMessages();
    });
  }

  edit(message: ChatMessage): void {
    const dialogRef = this.dialog.open(ComposeMessageComponent,
      {
        data: { message: message, reply: false },
        width: '90%'
      });
    dialogRef.afterClosed().subscribe(result => {
      this.getMessages();
    });
  }

  replyMessage(message: ChatMessage) {
    const dialogRef = this.dialog.open(ComposeMessageComponent,
      {
        data: { message: message, reply: true },
        width: '90%'
      });
    dialogRef.afterClosed().subscribe(result => {
      this.getMessages();
    });
  }

  deleteMessage(message: ChatMessage) {
    this.chatService.deleteMessage(message).subscribe(
      result => {
        this.getMessages();
      }
    );
  }

  applyFilter(event: any) {

  }

  onSearchClear(event: any) {

  }
}
