import { Component } from '@angular/core';
import { AuthenticationService, OrganizationModel } from '../../authentication.Service';

@Component({
  selector: 'app-favorite-org',
  templateUrl: './favorite-org.component.html',
  styleUrls: ['./favorite-org.component.css']
})
export class FavoriteOrgComponent {
  organizations: OrganizationModel[] = [];
  constructor(private authService: AuthenticationService) {
    this.authService.getOrganizationSummariesForMember().subscribe(result => {
      this.organizations = result;
    });
  }
}
